:root{
        --tds-modal-padding-header-vertical:var(--tds-spacing-7,12px);
--tds-modal-padding-header-left:var(--tds-spacing-9,16px);
--tds-modal-padding-header-right:var(--tds-spacing-7,12px);
--tds-modal-padding-footer:var(--tds-spacing-9,16px);
--tds-modal-padding-semantic-modal:var(--tds-spacing-11,24px);
--tds-modal-padding-body-horizontal:var(--tds-spacing-9,16px);
--tds-modal-padding-body-top:var(--tds-spacing-9,16px);
--tds-modal-padding-body-bottom:var(--tds-spacing-5,8px);
--tds-modal-margin-button-group-left:var(--tds-spacing-5,8px);
--tds-modal-margin-semantic-top:var(--tds-spacing-11,24px);
--tds-modal-margin-top-base:var(--tds-spacing-11,24px);
--tds-modal-margin-icon-left:var(--tds-spacing-9,16px);
--tds-modal-margin-body-top-s:var(--tds-spacing-4,6px);
--tds-modal-margin-body-top-m:var(--tds-spacing-7,12px);
--tds-modal-icon-size-l:60px;
--tds-modal-icon-size-m:36px;
--tds-modal-icon-size-s:32px;

    }
    
            
@layer utilities{

            
.tds-modal-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }
.tds-modal-header-title
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-5 font-tds-font-family-body tds-font-weight-semibold ;

                    }
.tds-modal-text-title
{
                        @apply  text-tds-font-size-6 leading-tds-line-height-6 font-tds-font-family-heading tds-font-weight-semibold ;

                    }
.tds-modal-text-default
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular ;

                    }

        }
            