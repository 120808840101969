:root{
        --tds-tag-margin-base:var(--tds-spacing-3,4px);
--tds-tag-margin-s:var(--tds-spacing-2,2px);
--tds-tag-padding-horizontal-s:var(--tds-spacing-3,4px);
--tds-tag-padding-horizontal-m:var(--tds-spacing-4,6px);
--tds-tag-padding-horizontal-l:var(--tds-spacing-5,8px);
--tds-tag-padding-vertical-m:var(--tds-spacing-1,1px);
--tds-tag-padding-vertical-l:3px;
--tds-tag-padding-tag-checkable-horizontal-s:var(--tds-spacing-5,8px);
--tds-tag-padding-tag-checkable-horizontal-m:var(--tds-spacing-6,10px);
--tds-tag-padding-tag-checkable-horizontal-l:var(--tds-spacing-7,12px);
--tds-tag-padding-tag-checkable-vertical-xs:var(--tds-spacing-2,2px);
--tds-tag-padding-tag-checkable-vertical-s:var(--tds-spacing-3,4px);
--tds-tag-padding-tag-checkable-vertical-m:var(--tds-spacing-4,6px);

    }
    
            
@layer utilities{

            
.tds-tag-border-radius-s
{
                        @apply rounded-tds-border-radius-xs ;

                    }
.tds-tag-border-radius-m
{
                        @apply rounded-tds-border-radius-s ;

                    }
.tds-tag-border-radius-circle
{
                        @apply rounded-tds-border-radius-circle ;

                    }

        }
            