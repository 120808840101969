.upload-image-create-product .tds-upload-drag {
    height: 100%;
    width: 100%;
}

.upload-image-create-product .tds-upload-drag .tds-upload-btn {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.upload-image-create-product .tds-upload-select {
    height: 100%;
    width: 100%;
}

.upload-image-create-product .tds-upload-select .tds-upload {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

