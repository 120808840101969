:root{
        --tds-tree-padding-vertical-s:var(--tds-spacing-2,2px);
--tds-tree-padding-vertical-m:var(--tds-spacing-3,4px);
--tds-tree-padding-vertical-l:var(--tds-spacing-4,6px);
--tds-tree-padding-horizontal-m:var(--tds-spacing-3,4px);
--tds-tree-height-switcher-s:28px;
--tds-tree-height-switcher-m:36px;
--tds-tree-height-switcher-l:44px;
--tds-tree-width-switcher:28px;
--tds-tree-margin-left-m:var(--tds-spacing-3,4px);
--tds-tree-margin-left-l:var(--tds-spacing-5,8px);
--tds-tree-icon-size-s:14px;

    }
    
            
@layer utilities{

            
.tds-tree-text
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular ;

                    }
.tds-tree-icon-size-base
{
                        @apply  text-tds-font-size-6 leading-tds-line-height-4 ;

                    }
.tds-tree-border-radius-switcher
{
                        @apply rounded-tds-border-radius-xxs ;

                    }
.tds-tree-border-radius-item-s
{
                        @apply rounded-tds-border-radius-xs ;

                    }
.tds-tree-border-radius-item-m
{
                        @apply rounded-tds-border-radius-s ;

                    }

        }
            