.placeholder {
    background: #ffff00;
    padding: 4px 2px;
    outline-offset: -2px;
    line-height: 1em;
    margin: 0 1px;
}

.placeholder::selection {
    display: none;
}