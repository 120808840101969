:root{
        --tds-spinner-margin-top-s:var(--tds-spacing-3,4px);
--tds-spinner-margin-top-base:var(--tds-spacing-5,8px);
--tds-spinner-height-s:16px;
--tds-spinner-height-m:32px;
--tds-spinner-height-l:48px;
--tds-spinner-width-s:16px;
--tds-spinner-width-m:32px;
--tds-spinner-width-l:48px;

    }
    
            
            