.tds-tour {
    @apply tds-font-weight-regular tds-body-2 tds-color-text-primary;
    .tds-tour-arrow-content {
        @apply w-0 h-0 block border-8 tds-color-border-white;
    }
    .tds-tour-arrow {
        @apply absolute  block bg-transparent pointer-events-none z-tds-z-index-1;
        &-top {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            .tds-tour-arrow-content {
                @apply border-b-0  border-x-transparent #{!important};
            }
        }
        &-bottom {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            .tds-tour-arrow-content {
                @apply border-t-0  border-x-transparent #{!important};
            }
        }
        &-left {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            .tds-tour-arrow-content {
                @apply border-r-0 border-y-transparent #{!important};
            }
        }
        &-right {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            .tds-tour-arrow-content {
                @apply border-l-0 border-y-transparent #{!important};
            }
        }
    }
    .tds-tour-content {
        @apply p-2 relative ;
    }
    .tds-tour-inner {
        @apply tds-color-surface-medium rounded-md relative tds-tour-shadow;
    }
    .tds-tour-inner-footer {
        @apply w-full flex items-center justify-between p-3;
        &.tds-tour-inner-footer-no-progress {
            @apply justify-end;
        }
    }
    .tds-tour-inner-content {
        @apply p-3;
    }
    .tds-tour-inner-title {
        @apply py-2 px-3 relative tds-body-1 tds-font-weight-semibold;
    }
    .tds-tour-dot{
        @apply w-[var(--tds-badge-width-dot-m)] h-[var(--tds-badge-height-dot-m)] tds-color-background-neutral-subtle mr-0.5 inline-block rounded-full;
        &-active{
            @apply tds-color-background-primary-subtle
        }
    }
    &-closable {
        .tds-tour-inner-title {
            @apply pr-11;
        }
        .tds-tour-inner-close {
            @apply absolute top-2 right-3 z-tds-z-index-1;
        }
        &.tds-tour-no-title {
            .tds-tour-inner-content {
                @apply pr-11;
            }
        }
    }
    &:not(.tds-tour-status-default) {
        @apply tds-color-text-white;
        button[tds-button-close] {
            @apply tds-color-text-white;
        }
        .tds-tour-dot{
            @apply  tds-tour-color-dot-background-inverse-inactive;
            &-active{
                @apply  tds-color-background-white;
            }
        }
    }
    &.tds-tour-status-info {
        .tds-tour-inner {
            @apply tds-color-background-info-subtle;
        }
        .tds-tour-arrow-content {
            @apply tds-color-border-info;
        }
    }
    &.tds-tour-status-error {
        .tds-tour-inner {
            @apply tds-color-background-error-subtle;
        }
        .tds-tour-arrow-content {
            @apply tds-color-border-error;
        }
    }
    &.tds-tour-status-warning {
        .tds-tour-inner {
            @apply tds-color-background-warning-subtle;
        }
        .tds-tour-arrow-content {
            @apply tds-color-border-warning;
        }
    }
    &.tds-tour-status-success {
        @apply tds-color-text-white;
        .tds-tour-inner {
            @apply tds-color-background-success-subtle;
        }
        .tds-tour-arrow-content {
            @apply tds-color-border-success;
        }
    }
}
