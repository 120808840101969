.tds-column-settings-panel {
    @apply flex flex-col;
}
.tds-column-settings-sortable {
    .tds-column-settings-item {
        @apply flex items-center;
        .tds-column-settings-item-icon {
            @apply flex-shrink-0 flex  items-center justify-between mr-2 cursor-move;
            > span {
                @apply tds-btn-icon-size-base tds-color-icon-secondary;
            }
        }
        .tds-column-settings-item-checkbox {
            @apply flex-auto;
            .tds-checkbox-label {
                @apply truncate w-32;
            }
        }
    }
}
.tds-column-settings-sortable.cdk-drop-list-dragging .tds-column-settings-item:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.tds-column-settings-item-placeholder {
    @apply min-h-24 w-full tds-color-background-neutral-minimalist border-tds-border-width-s tds-color-border-primary border-dashed;
    // background: #ccc;
    // border: dotted 3px #999;
    // min-height: 24px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.tds-column-settings-item.cdk-drag-preview {
    @apply flex items-center;
    .tds-column-settings-item-icon {
        @apply flex-shrink-0 flex  items-center justify-between mr-2 cursor-move;
        > span {
            @apply tds-btn-icon-size-base tds-color-icon-secondary;
        }
    }
    .tds-column-settings-item-checkbox {
        @apply flex-auto;
    }
}

.tds-column-settings-overlay{
    .tds-popover-inner{
        @apply py-[--tds-dropdown-padding-vertical-s] px-0;
    }
    .tds-popover-inner-content{
        @apply w-[170px]
    }
    .tds-column-settings-item{
        @apply px-[--tds-dropdown-padding-horizontal] py-[--tds-dropdown-padding-vertical-m]
    }
}