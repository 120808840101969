tds-time-picker.tds-time-picker {
    @apply w-full  relative box-border m-0 p-0 border-tds-border-width-s tds-color-border-primary rounded-tds-border-radius-xs  flex items-stretch tds-color-surface-lowest;
    .tds-picker-input {
        @apply flex-auto flex items-center;
        input {
            @apply tds-font-weight-regular tds-body-2 tds-color-text-primary placeholder:tds-color-text-placeholder focus:outline-none focus:ring-transparent focus:border-transparent shadow-none border-0 bg-transparent text-current pl-0 py-0 m-0 w-full outline-none  box-border;
        }
    }
    .tds-picker-clear {
        @apply flex items-center absolute inset-y-0 right-0 opacity-0;
    }
    .tds-picker-suffix {
        @apply tds-color-icon-primary flex items-center tds-icon-size-m;
    }
    &:not(.tds-time-picker-disabled) {
        @apply cursor-pointer;
        &:hover {
            @apply tds-color-border-brand-primary;

            &.tds-time-picker-alow-allow {
                .tds-picker-clear {
                    @apply opacity-100 z-[1];
                }
                .tds-picker-suffix {
                    @apply opacity-0;
                }
            }
        }
        &.tds-time-picker-focused {
            @apply outline-none tds-color-border-brand-primary ring tds-color-ring-primary;
        }
    }
    &.tds-time-picker-disabled {
        @apply cursor-not-allowed  tds-color-background-disable;
        .tds-picker-input {
            input {
                @apply tds-color-text-disable placeholder:tds-color-text-disable cursor-not-allowed select-none;
            }
        }
        .tds-picker-suffix {
            @apply tds-color-icon-disable cursor-not-allowed;
        }
    }
    //===============Size================
    &.tds-time-picker-sm {
        .tds-picker-input {
            @apply pl-2.5 py-1;
        }
        .tds-picker-clear,
        .tds-picker-suffix {
            @apply px-1;
        }
    }
    &.tds-time-picker-md {
        .tds-picker-input {
            @apply pl-3 py-1.5;
        }
        .tds-picker-clear,
        .tds-picker-suffix {
            @apply px-1.5;
        }
    }
    &.tds-time-picker-lg {
        .tds-picker-input {
            @apply pl-4 py-2;
        }
        .tds-picker-clear.tds-picker-clear,
        .tds-picker-suffix {
            @apply px-2;
        }
    }
    &.tds-time-picker-xl {
        .tds-picker-input {
            @apply pl-4 py-2.5;
        }
        .tds-picker-clear,
        .tds-picker-suffix {
            @apply px-2;
        }
    }
}
tds-form-field {
    tds-time-picker.tds-time-picker {
        @apply border-0 ring-0 ring-transparent;
    }
    tds-time-picker.tds-time-picker.ng-invalid.ng-touched:not(.tds-time-picker-focused) {
        @apply border-0 ring-0 ring-transparent;
    }
}
.tds-time-picker-panel {
    @apply flex flex-col pr-0 box-border;
    .tds-picker-content {
        @apply flex h-56 overflow-y-hidden flex-auto;
    }
    .tds-picker-time-panel-column {
        @apply h-full relative overflow-y-hidden hover:overflow-y-auto tds-panel-scroll  flex flex-col py-[--tds-date-time-picker-padding-time-wheel-vertical] w-[--tds-date-time-picker-width-time-wheel] tds-font-weight-regular tds-body-2;
        .tds-picker-time-panel-cell {
            @apply flex-auto  items-center justify-center py-[--tds-date-time-picker-padding-time-cell-vertical] mb-px;
            &:not(.tds-picker-time-panel-cell-disabled) {
                @apply tds-color-text-primary cursor-pointer;
                &.tds-picker-time-panel-cell-selected {
                    @apply tds-color-text-brand-primary  font-semibold;
                }
            }
            &.tds-picker-time-panel-cell-disabled {
                @apply cursor-not-allowed tds-color-text-disable;
            }
            .tds-picker-time-panel-cell-inner {
                @apply w-[--tds-date-time-picker-width-time-cell] ml-tds-spacing-5 text-center;
            }
        }
    }
    .tds-picker-footer {
        @apply py-[--tds-date-time-picker-padding-calendar-footer-vertical] border-t-tds-border-width-s  tds-color-border-primary;
    }
}
.tds-picker-time-panel-dropdown-content {
    @apply  tds-color-surface-high rounded-tds-border-radius-xs shadow-tds-shadow-l-light dark:shadow-tds-shadow-l-dark;
}
