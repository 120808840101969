@layer utilities {
    tds-table {
        table {
            border-collapse: separate;
            border-spacing: 0;
        }
        .tds-table-thead > tr > th {
            @apply py-[--tds-table-padding-header-vertical-m] px-[--tds-table-padding-horizontal]  tds-body-2 tds-font-weight-semibold;
        }
        .tds-table-tbody > tr:not(.tds-table-placeholder) > td {
            transition: background 0.1s;
            @apply border-b;
        }
        .tds-table-tbody > tr > td:not(.tds-disable-td):not(.tds-table-cell-with-append) {
            @apply py-[--tds-table-padding-vertical-m] px-[--tds-table-padding-horizontal];
        }
        .tds-table-tfoot > tr > td:not(.tds-disable-td):not(.tds-table-cell-with-append) {
            @apply py-[--tds-table-padding-header-vertical-m] px-[--tds-table-padding-horizontal];
        }

        .tds-table-ping-left .tds-table-cell-fix-left-last:before {
            background-color: transparent !important;
        }
        .tds-table-ping-left .tds-table-cell-fix-left-first:after,
        .tds-table-ping-left .tds-table-cell-fix-left-last:after,
        .tds-table-ping-left:not(.tds-table-has-fix-left) .tds-table-container:before {
            box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
        }
        .tds-table-cell-fix-left-first:after,
        .tds-table-cell-fix-left-last:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0px;
            width: 30px;
            transform: translateX(100%);
            transition: box-shadow 0.3s;
            content: "";
            pointer-events: none;
        }
        .tds-table-ping-right .tds-table-cell-fix-right-first:after,
        .tds-table-ping-right .tds-table-cell-fix-right-last:after,
        .tds-table-ping-right:not(.tds-table-has-fix-right) .tds-table-container:after {
            box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
        }
        .tds-table-cell-fix-right-first:after,
        .tds-table-cell-fix-right-last:after {
            position: absolute;
            top: 0;
            bottom: 0px;
            left: 0;
            width: 30px;
            transform: translateX(-100%);
            transition: box-shadow 0.3s;
            content: "";
            pointer-events: none;
        }
        .tds-table-hide-scrollbar {
            // &.tds-custom-scroll {
            //     scrollbar-width: thin;
            //     scrollbar-color: var(--tdsScrollbarBG) var(--tdsScrollbarBG);
            // }

            &.tds-custom-scroll::-webkit-scrollbar-thumb {
                background-color: var(--tdsScrollbarBG);
                border-radius: 6px;
                border: 0px solid var(--tdsScrollbarBG);
                height: 50%;
            }
            // scrollbar-color: var(--tdsScrollbarBG) var(--tdsScrollbarBG);
            &::-webkit-scrollbar {
                background-color: var(--tdsScrollbarBG);
            }
        }

        .tds-table-expanded-row > .tds-disable-td {
            @apply p-0;
            > .tds-table-expanded-row-fixed {
                @apply sticky left-0 overflow-hidden;
            }
        }
        > .tds-table-spin {
            @apply flex flex-auto relative w-full;
            > .tds-spin-container {
                @apply flex flex-col h-full w-full;
                > div.tds-table {
                    @apply flex flex-col flex-auto min-h-0 w-full overflow-hidden;
                    &.tds-table-bordered {
                        @apply border-tds-border-width-s;
                    }
                    &.tds-table-out-bordered {
                        @apply border-tds-border-width-s;
                        &.tds-table-out-bordered-has-pagination {
                            @apply border-b-0;
                        }
                        &:not(.tds-table-out-bordered-has-pagination) {
                            .tds-table-tbody > tr:not(.tds-table-placeholder):last-child > td{
                                @apply border-b-0;
                            }
                        }
                    }
                    .tds-table-content {
                        @apply overflow-auto;
                    }
                    > tds-table-inner-scroll {
                        @apply flex flex-col flex-auto min-h-0 w-full overflow-hidden;
                    }
                    > tds-table-inner-default {
                        @apply w-full flex flex-col min-h-0 flex-auto;
                    }
                    .tds-table-title {
                        @apply tds-font-weight-semibold tds-heading-6 flex flex-col;
                        // &-bordered {
                        //     @apply border-b;
                        // }
                    }
                    .tds-table-footer {
                        @apply flex flex-col;
                    }
                }
                .tds-table-pagination {
                    @apply w-full py-1.5;
                    &.tds-table-pagination-out-bordered {
                        @apply border-x border-b;
                    }
                }
            }
            //filter
        }
    }
    tds-table.tds-table-fullscreen {
        @apply h-full;
        tds-spin {
            @apply h-full;
            .tds-spin-container {
                @apply h-full;
                .tds-table {
                    tds-table-inner-scroll {
                        .tds-table-body {
                            @apply relative h-0;
                            .tds-table-inner-wrapper {
                                @apply absolute  inset-0;
                            }
                        }
                    }
                }
            }
        }
    }
    tds-table {
        .tds-table-thead
            > tr
            > th.tds-resizable:not(:last-child):not(.tds-table-selection-column):not(
                .tds-table-row-expand-icon-cell
            ):not([colspan]):before {
            @apply absolute top-1/2 right-0 w-px h-[1.6em]  -translate-y-1/2 transition-[background-color] duration-300 content-[''];
        }
        &.tds-table-theme-default {
            .tds-table-thead > tr > th {
                @apply tds-color-background-neutral-minimalist  tds-color-text-primary;
                &:hover {
                    @apply tds-color-background-neutral-minimal;
                }
            }
            .tds-table-tbody > tr:not(.tds-table-placeholder) > td {
                @apply tds-color-border-primary tds-color-text-primary;
            }

            .tds-table-tbody > tr.tds-table-row:not(.tds-row-selected):hover > td {
                @apply tds-color-background-neutral-minimalist;
            }
            .tds-table-cell-fix-left {
                @apply tds-color-surface-lowest;
            }
            .tds-table-cell-fix-right {
                @apply tds-color-surface-lowest;
            }
            .tds-table-tbody > tr.tds-row-selected > td {
                @apply bg-tds-color-blue-50-light dark:bg-tds-color-blue-50-dark;
            }
            > .tds-table-spin {
                > .tds-spin-container {
                    > div.tds-table {
                        &.tds-table-bordered {
                            @apply tds-color-border-primary;
                        }
                        &.tds-table-out-bordered {
                            @apply tds-color-border-primary;
                        }
                        .tds-table-title {
                            @apply tds-color-text-primary;
                            &-bordered {
                                @apply tds-color-border-primary;
                            }
                        }
                        .tds-table-footer {
                            @apply tds-color-text-primary;
                        }
                    }
                    .tds-table-pagination-out-bordered {
                        @apply tds-color-border-primary;
                    }
                }
                //filter
                tds-table-filter {
                    tds-filter-trigger {
                        .tds-table-filter-trigger {
                            &.active {
                                > span {
                                    @apply tds-color-background-primary-subtle tds-color-icon-white;
                                }
                            }
                            > span {
                                @apply tds-color-icon-secondary  tds-color-background-neutral-minimalist;
                            }
                        }
                    }
                }
                tds-table-sorters {
                    .tds-table-column-sorter-inner {
                        .tds-table-column-sorter-up,
                        .tds-table-column-sorter-down {
                            &.active {
                                @apply tds-color-icon-brand-primary;
                            }
                        }
                        .tds-table-column-sorter-default {
                            @apply tds-color-icon-secondary;
                        }
                    }

                    .tds-table-column-sorter {
                        @apply tds-color-icon-secondary;
                    }
                }
            }
            .tds-table-thead
                > tr
                > th.tds-resizable:not(:last-child):not(.tds-table-selection-column):not(
                    .tds-table-row-expand-icon-cell
                ):not([colspan]):before {
                @apply tds-color-background-neutral-minimalist;
            }
        }
        &.tds-table-theme-light {
            .tds-table-thead > tr > th {
                @apply tds-color-background-neutral-minimalist-light  tds-color-text-primary-light;
                &:hover {
                    @apply tds-color-background-neutral-minimal-light;
                }
            }
            .tds-table-tbody > tr:not(.tds-table-placeholder) > td {
                @apply tds-color-border-primary-light tds-color-text-primary-light;
            }

            .tds-table-tbody > tr.tds-table-row:not(.tds-row-selected):hover > td {
                @apply tds-color-background-neutral-minimalist-light;
            }
            .tds-table-cell-fix-left {
                @apply tds-color-surface-lowest-light;
            }
            .tds-table-cell-fix-right {
                @apply tds-color-surface-lowest-light;
            }
            .tds-table-tbody > tr.tds-row-selected > td {
                @apply bg-tds-color-blue-50-light;
            }
            > .tds-table-spin {
                > .tds-spin-container {
                    > div.tds-table {
                        &.tds-table-bordered {
                            @apply tds-color-border-primary-light;
                        }
                        &.tds-table-out-bordered {
                            @apply tds-color-border-primary-light;
                        }
                        .tds-table-title {
                            @apply tds-color-text-primary-light;
                            &-bordered {
                                @apply tds-color-border-primary-light;
                            }
                        }
                        .tds-table-footer {
                            @apply tds-color-text-primary-light;
                        }
                    }
                    .tds-table-pagination-out-bordered {
                        @apply tds-color-border-primary-light;
                    }
                }
                //filter
                tds-table-filter {
                    tds-filter-trigger {
                        .tds-table-filter-trigger {
                            &.active {
                                > span {
                                    @apply tds-color-background-primary-subtle-light tds-color-icon-white-light;
                                }
                            }
                            > span {
                                @apply tds-color-icon-secondary-light  tds-color-background-neutral-minimalist-light;
                            }
                        }
                    }
                }
                tds-table-sorters {
                    .tds-table-column-sorter-inner {
                        .tds-table-column-sorter-up,
                        .tds-table-column-sorter-down {
                            &.active {
                                @apply tds-color-icon-brand-primary-light;
                            }
                        }
                        .tds-table-column-sorter-default {
                            @apply tds-color-icon-secondary-light;
                        }
                    }

                    .tds-table-column-sorter {
                        @apply tds-color-icon-secondary-light;
                    }
                }
            }
            .tds-table-thead
                > tr
                > th.tds-resizable:not(:last-child):not(.tds-table-selection-column):not(
                    .tds-table-row-expand-icon-cell
                ):not([colspan]):before {
                @apply tds-color-background-neutral-minimalist-light;
            }
        }
        &.tds-table-theme-dark {
            .tds-table-thead > tr > th {
                @apply tds-color-background-neutral-minimalist-dark  tds-color-text-primary-dark;
                &:hover {
                    @apply tds-color-background-neutral-minimal-dark;
                }
            }
            .tds-table-tbody > tr:not(.tds-table-placeholder) > td {
                @apply tds-color-border-primary-dark tds-color-text-primary-dark;
            }

            .tds-table-tbody > tr.tds-table-row:not(.tds-row-selected):hover > td {
                @apply tds-color-background-neutral-minimalist-dark;
            }
            .tds-table-cell-fix-left {
                @apply tds-color-surface-lowest-dark;
            }
            .tds-table-cell-fix-right {
                @apply tds-color-surface-lowest-dark;
            }
            .tds-table-tbody > tr.tds-row-selected > td {
                @apply bg-tds-color-blue-50-dark;
            }
            > .tds-table-spin {
                > .tds-spin-container {
                    > div.tds-table {
                        &.tds-table-bordered {
                            @apply tds-color-border-primary-dark;
                        }
                        &.tds-table-out-bordered {
                            @apply tds-color-border-primary-dark;
                        }
                        .tds-table-title {
                            @apply tds-color-text-primary-dark;
                            &-bordered {
                                @apply tds-color-border-primary-dark;
                            }
                        }
                        .tds-table-footer {
                            @apply tds-color-text-primary-dark;
                        }
                    }
                    .tds-table-pagination-out-bordered {
                        @apply tds-color-border-primary-dark;
                    }
                }
                //filter
                tds-table-filter {
                    tds-filter-trigger {
                        .tds-table-filter-trigger {
                            &.active {
                                > span {
                                    @apply tds-color-background-primary-subtle-dark tds-color-icon-white-dark;
                                }
                            }
                            > span {
                                @apply tds-color-icon-secondary-dark  tds-color-background-neutral-minimalist-dark;
                            }
                        }
                    }
                }
                tds-table-sorters {
                    .tds-table-column-sorter-inner {
                        .tds-table-column-sorter-up,
                        .tds-table-column-sorter-down {
                            &.active {
                                @apply tds-color-icon-brand-primary-dark;
                            }
                        }
                        .tds-table-column-sorter-default {
                            @apply tds-color-icon-secondary-dark;
                        }
                    }

                    .tds-table-column-sorter {
                        @apply tds-color-icon-secondary-dark;
                    }
                }
            }
            .tds-table-thead
                > tr
                > th.tds-resizable:not(:last-child):not(.tds-table-selection-column):not(
                    .tds-table-row-expand-icon-cell
                ):not([colspan]):before {
                @apply tds-color-background-neutral-minimalist-dark;
            }
        }
        &.tds-table-rounded {
            > .tds-table-spin {
                > .tds-spin-container {
                    > div.tds-table {
                        @apply rounded-tr-md rounded-tl-md;
                        &:not(.tds-table-has-pagination) {
                            @apply rounded-br-md rounded-bl-md;
                        }
                    }
                    .tds-table-pagination {
                        @apply rounded-br-md rounded-bl-md;
                    }
                }
            }
        }
    }

    tds-table-sorters {
        .tds-table-column-sorter-inner {
            .tds-table-column-sorter-up,
            .tds-table-column-sorter-down {
                @apply tds-btn-icon-size-xs;
            }
            .tds-table-column-sorter-default {
                @apply tds-btn-icon-size-xs;
            }
        }
    }
}
