.tds-skeleton {
    @apply table w-full;
    &.tds-skeleton-with-avatar {
        @apply flex flex-col;
        .tds-skeleton-header {
            @apply flex;
            > tds-skeleton-element {
                @apply pr-[--tds-skeleton-padding-right-l];
            }
            .tds-skeleton-title-subtitle {
                @apply flex-auto;
                .tds-skeleton-title {
                    @apply h-[var(--tds-skeleton-height-title)] tds-skeleton-color-background-default tds-skeleton-border-radius;
                }
                .tds-skeleton-subtitle {
                    @apply h-[var(--tds-skeleton-height-body)] tds-skeleton-color-background-default tds-skeleton-border-radius  mt-[--tds-skeleton-margin-top-m];
                }
            }
        }
        .tds-skeleton-content {
            @apply block w-full;
        }
    }
}

.tds-skeleton-header {
    @apply table-cell pr-4 align-top;
    .tds-skeleton-avatar {
        @apply inline-block align-top  tds-color-icon-inactive w-8 h-8 leading-8;

        &.tds-skeleton-avatar-lg {
            @apply w-12 h-12 leading-[48px];
        }
        &.tds-skeleton-avatar-sm {
            @apply w-6 h-6 leading-6;
        }
        &.tds-skeleton-avatar-circle {
            @apply tds-skeleton-border-radius;
        }
    }
}
.tds-skeleton-content {
    @apply table-cell pr-4 align-top;

    .tds-skeleton-paragraph {
        @apply mt-[--tds-skeleton-margin-top-l];
    }

    .tds-skeleton-paragraph {
        @apply p-0;
    }

    .tds-skeleton-paragraph > li {
        @apply w-full h-[var(--tds-skeleton-height-body)] list-none tds-skeleton-color-background-default tds-skeleton-border-radius;
    }

    .tds-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
        width: 61%;
    }

    .tds-skeleton-paragraph > li + li {
        @apply mt-[--tds-skeleton-margin-top-l];
    }
}

.tds-skeleton-round .tds-skeleton-content .tds-skeleton-title,
.tds-skeleton-round .tds-skeleton-content .tds-skeleton-paragraph > li {
    border-radius: 100px;
}

.tds-skeleton.tds-skeleton-active .tds-skeleton-title,
.tds-skeleton.tds-skeleton-active .tds-skeleton-subtitle,
.tds-skeleton.tds-skeleton-active .tds-skeleton-content .tds-skeleton-paragraph > li {
    @apply tds-skeleton-color-gradient-right-active;
    // background: linear-gradient(90deg, #dbe0e6 25%, #f2f5f8 37%, #dbe0e6 63%);
    background-size: 400% 100%;
    animation: tds-skeleton-loading 1.4s ease infinite;
}

.tds-skeleton.tds-skeleton-active .tds-skeleton-avatar,
.tds-skeleton.tds-skeleton-active .tds-skeleton-button,
.tds-skeleton.tds-skeleton-active .tds-skeleton-input,
.tds-skeleton.tds-skeleton-active .tds-skeleton-image {
    @apply tds-skeleton-color-gradient-right-active;
    // background: linear-gradient(90deg, #dbe0e6 25%, #f2f5f8 37%, #dbe0e6 63%);
    background-size: 400% 100%;
    animation: tds-skeleton-loading 1.4s ease infinite;
}

.tds-skeleton.tds-skeleton-block,
.tds-skeleton.tds-skeleton-block .tds-skeleton-button,
.tds-skeleton.tds-skeleton-block .tds-skeleton-input {
    @apply w-full;
}

.tds-skeleton-element {
    @apply inline-block w-auto;
    .tds-skeleton-button {
        @apply inline-block align-top tds-skeleton-color-background-default rounded-sm w-16 min-w-[64px] h-8 leading-8;
        &.tds-skeleton-button-circle {
            @apply w-8 h-8 tds-skeleton-border-radius;
        }
        &.tds-skeleton-button-round {
            border-radius: 32px;
        }
    }
    .tds-skeleton-button-lg {
        @apply w-20 min-w-[80px] h-10 leading-10;
        &.tds-skeleton-button-circle {
            @apply w-10 min-w-[40px]  tds-skeleton-border-radius;
        }
        &.tds-skeleton-button-round {
            border-radius: 40px;
        }
    }

    .tds-skeleton-button-sm {
        width: 48px;
        min-width: 48px;
        height: 24px;
        line-height: 24px;
        &.tds-skeleton-button-circle {
            width: 24px;
            min-width: 24px;
            border-radius: 50%;
        }
        &.tds-skeleton-button-round {
            border-radius: 24px;
        }
    }

    .tds-skeleton-avatar {
        @apply w-8 h-8 leading-8 inline-block align-top tds-skeleton-color-background-default ;
        &.tds-skeleton-avatar-circle {
            @apply tds-skeleton-border-radius;
        }
    }

    .tds-skeleton-avatar-lg {
        @apply w-12 h-12 leading-[48px] ;
        &.tds-skeleton-avatar-circle {
            @apply tds-skeleton-border-radius;
        }
    }

    .tds-skeleton-avatar-sm {        
        &.tds-skeleton-avatar-circle {
            @apply tds-skeleton-border-radius;
        }
        @apply w-6 h-6 leading-6; 
    }

    .tds-skeleton-input {
        @apply tds-skeleton-color-background-default inline-block align-top w-full;
        
        min-width: 160px;
        height: 32px;
        line-height: 32px;
    }

    .tds-skeleton-input-lg {
        min-width: 200px;
        height: 40px;
        line-height: 40px;
    }

    .tds-skeleton-input-sm {
        min-width: 120px;
        height: 24px;
        line-height: 24px;
    }

    .tds-skeleton-image {
        @apply tds-skeleton-color-background-default tds-skeleton-border-radius-image flex items-center justify-center align-top w-24 h-24 leading-[96px];
    }

    .tds-skeleton-image.tds-skeleton-image-circle {
        @apply tds-skeleton-border-radius;
    }

    .tds-skeleton-image-path {
        @apply fill-current;
    }

    .tds-skeleton-image-svg {
        @apply w-16 h-16 max-w-[112px] max-h-[112px] tds-color-icon-inactive;
    }

    .tds-skeleton-image-svg.tds-skeleton-image-circle {
        @apply tds-skeleton-border-radius;
    }
}

@keyframes tds-skeleton-loading {
    0% {
        background-position: 100% 50%;
    }

    to {
        background-position: 0 50%;
    }
}

.tds-skeleton-rtl {
    direction: rtl;
}

.tds-skeleton-rtl .tds-skeleton-header {
    padding-right: 0;
    padding-left: 16px;
}

@keyframes tds-skeleton-loading-rtl {
    0% {
        background-position: 0% 50%;
    }

    to {
        background-position: 100% 50%;
    }
}
