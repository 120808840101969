.tds-data-table {
    @apply block w-full h-full box-border;
    > tds-table.tds-data-table-content {
        > tds-spin {
            > .tds-spin-container {
                > .tds-table-pagination {
                    @apply py-1.5;
                }
            }
        }
    }
    &.tds-data-table-border {
        > tds-table.tds-data-table-content {
            .tds-table-tbody > tr:not(.tds-table-placeholder).tds-data-table-border > td {
                @apply border-b;
                &:last-child {
                    @apply border-r;
                }
                &:not(:first-child) {
                    @apply border-l;
                }
            }
            .tds-table-tbody > tr.tds-data-table-border:nth-child(2) > td {
                @apply border-t-0;
            }

            thead > tr:not(.tds-table-placeholder):not(.tds-data-table-border-group).tds-data-table-border > th {
                @apply border-b;
                &:last-child {
                    @apply border-r;
                }
                &:not(:first-child) {
                    @apply border-l;
                }
            }
            thead > tr:not(.tds-table-placeholder).tds-data-table-border-group.tds-data-table-border > th {
                @apply border-b;
                // &:last-child {
                //     @apply border-r;
                // }
                &:not(:first-child) {
                    @apply border-l;
                }
            }
            thead > tr > th.tds-data-table-th-group-first {
                @apply border-l;
            }
            thead > tr > th.tds-data-table-th-group-last {
                @apply border-r;
            }
            thead > tr > th.tds-data-table-th-group {
                @apply text-center;
            }
        }
        table {
            @apply border-separate;
        }
        > tds-table.tds-data-table-content {
            > tds-spin {
                > .tds-spin-container {
                    > .tds-table-pagination {
                        @apply border-x border-b;
                    }
                    > .tds-table-bordered {
                        @apply border-tds-border-width-s;
                    }
                }
            }
        }
        &.tds-data-table-height-auto {
            > tds-table.tds-data-table-content {
                .tds-table-tbody > tr:last-child.tds-data-table-border > td {
                    @apply border-b-0;
                }
            }
        }
    }
    &.tds-data-table-borderless {
        > tds-table.tds-data-table-content {
            .tds-table-tbody > tr:not(.tds-table-placeholder).tds-table-expanded-row  {
                @apply border-none;
            }
        }
    }
    &.tds-data-table-height-auto {
        > tds-table.tds-data-table-content {
            @apply h-auto;
            > tds-spin > .tds-spin-container {
                @apply h-auto;
                > .tds-table > tds-table-inner-scroll > .tds-table-body {
                    @apply h-auto;
                }
            }
        }
    }
    &.tds-data-table-has-expand {
        > tds-table.tds-data-table-content {
            .tds-table-tbody > tr:not(.tds-table-placeholder) > td.tds-data-table-td-default {
                @apply cursor-pointer;
            }
        }
    }
    &.tds-data-table-theme-default {
        > tds-table.tds-data-table-content {
           
            > tds-spin {
                > .tds-spin-container {                   
                    .tds-table-tbody > tr:not(.tds-table-placeholder).tds-data-table-tr-selected > td {
                        @apply bg-tds-color-blue-50-light dark:bg-tds-color-blue-50-dark;
                    }
                    .tds-table{
                        @apply tds-color-surface-lowest;
                    }
                    .tds-table-pagination{
                        @apply tds-color-surface-lowest;
                    }
                }
            }
        }
        &.tds-data-table-border {
            > tds-table.tds-data-table-content {
                .tds-table-tbody > tr:not(.tds-table-placeholder).tds-data-table-border > td {
                    @apply tds-color-border-primary;
                }
                thead > tr:not(.tds-table-placeholder):not(.tds-data-table-border-group).tds-data-table-border > th {
                    @apply border-b tds-color-border-primary;
                }
                thead > tr:not(.tds-table-placeholder).tds-data-table-border-group.tds-data-table-border > th {
                    @apply tds-color-border-primary;
                }
            }

            > tds-table.tds-data-table-content {
                > tds-spin {
                    > .tds-spin-container {
                        > .tds-table-pagination {
                            @apply tds-color-border-primary;
                        }
                        > .tds-table-bordered {
                            @apply tds-color-border-primary;
                        }
                    }
                }
            }
        }
    }
    &.tds-data-table-theme-light {
        > tds-table.tds-data-table-content {
           
            > tds-spin {
                > .tds-spin-container {
                    .tds-table-tbody > tr:not(.tds-table-placeholder).tds-data-table-tr-selected > td {
                        @apply bg-tds-color-blue-50-light;
                    }
                    .tds-table{
                        @apply tds-color-surface-lowest-light;
                    }
                    .tds-table-pagination{
                        @apply tds-color-surface-lowest-light;
                    }
                }
            }
        }
        &.tds-data-table-border {
            > tds-table.tds-data-table-content {
                .tds-table-tbody > tr:not(.tds-table-placeholder).tds-data-table-border > td {
                    @apply tds-color-border-primary-light;
                }
                thead > tr:not(.tds-table-placeholder):not(.tds-data-table-border-group).tds-data-table-border > th {
                    @apply border-b tds-color-border-primary-light;
                }
                thead > tr:not(.tds-table-placeholder).tds-data-table-border-group.tds-data-table-border > th {
                    @apply tds-color-border-primary-light;
                }
            }

            > tds-table.tds-data-table-content {
                > tds-spin {
                    > .tds-spin-container {
                        > .tds-table-pagination {
                            @apply tds-color-border-primary-light;
                        }
                        > .tds-table-bordered {
                            @apply tds-color-border-primary-light;
                        }
                    }
                }
            }
        }
    }
    &.tds-data-table-theme-dark {
        > tds-table.tds-data-table-content {
           
            > tds-spin {
                > .tds-spin-container {
                    .tds-table-tbody > tr:not(.tds-table-placeholder).tds-data-table-tr-selected > td {
                        @apply bg-tds-color-blue-50-dark;
                    }
                    .tds-table{
                        @apply tds-color-surface-lowest-dark;
                    }
                    .tds-table-pagination{
                        @apply tds-color-surface-lowest-dark;
                    }
                }
            }
        }
        &.tds-data-table-border {
            > tds-table.tds-data-table-content {
                .tds-table-tbody > tr:not(.tds-table-placeholder).tds-data-table-border > td {
                    @apply tds-color-border-primary-dark;
                }
                thead > tr:not(.tds-table-placeholder):not(.tds-data-table-border-group).tds-data-table-border > th {
                    @apply border-b tds-color-border-primary-dark;
                }
                thead > tr:not(.tds-table-placeholder).tds-data-table-border-group.tds-data-table-border > th {
                    @apply tds-color-border-primary-dark;
                }
            }

            > tds-table.tds-data-table-content {
                > tds-spin {
                    > .tds-spin-container {
                        > .tds-table-pagination {
                            @apply tds-color-border-primary-dark;
                        }
                        > .tds-table-bordered {
                            @apply tds-color-border-primary-dark;
                        }
                    }
                }
            }
        }
    }
}
