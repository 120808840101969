tds-modal-confirm-container {
    .tds-modal-confirm {
        @apply max-w-[calc(100vw-16px)] tds-color-surface-lowest  tds-color-text-primary top-24  tds-modal-border-radius box-border pointer-events-none relative my-auto mx-auto;
        .tds-modal-content {
            @apply p-[--tds-modal-padding-semantic-modal] pointer-events-auto;
            &.tds-modal-content-default {
                .tds-modal-body {
                    .tds-modal-confirm-body-wrapper {
                        .tds-modal-confirm-icon {
                            i {
                                font-size: var(--tds-modal-icon-size-l);
                                line-height: var(--tds-modal-icon-size-l);
                            }
                            @apply w-full flex items-center justify-center;
                            &.tds-modal-confirm-icon-warning {
                                @apply tds-color-icon-warning;
                            }
                            &.tds-modal-confirm-icon-info {
                                @apply tds-color-icon-info;
                            }
                            &.tds-modal-confirm-icon-success {
                                @apply tds-color-icon-success;
                            }

                            &.tds-modal-confirm-icon-error {
                                @apply tds-color-icon-error;
                            }
                            //custom icon
                            .tds-modal-confirm-icon-custom {
                                @apply flex items-center justify-center w-[--tds-modal-icon-size-l] h-[--tds-modal-icon-size-l] rounded-full tds-color-icon-white;
                                i {
                                    font-size: var(--tds-modal-icon-size-m);
                                    line-height: var(--tds-modal-icon-size-m);
                                }
                            }
                            .tds-modal-confirm-icon-custom-warning {
                                @apply tds-color-background-warning-subtle;
                            }
                            .tds-modal-confirm-icon-custom-info {
                                @apply tds-color-background-info-subtle;
                            }
                            .tds-modal-confirm-icon-custom-success {
                                @apply tds-color-background-success-subtle;
                            }

                            .tds-modal-confirm-icon-custom-error {
                                @apply tds-color-background-error-subtle;
                            }
                        }
                        .tds-modal-confirm-body-btns {
                            @apply w-full;
                            .tds-modal-confirm-body {
                                @apply mt-[--tds-modal-margin-body-top-m];
                                .tds-modal-confirm-title {
                                    @apply text-center break-words tds-modal-text-title tds-color-text-primary;
                                }
                                .tds-modal-confirm-content {
                                    @apply mt-[--tds-modal-margin-body-top-s] text-center break-words tds-color-text-primary tds-modal-text-default;
                                }
                            }
                            .tds-modal-confirm-btns {
                                @apply flex justify-center mt-[--tds-modal-margin-semantic-top];
                                button {
                                    @apply min-w-[100px] ml-[--tds-modal-margin-button-group-left];
                                    &:first-child {
                                        @apply ml-0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.tds-modal-content-compact {
                .tds-modal-body {
                    .tds-modal-confirm-body-wrapper {
                        @apply w-full flex;
                        .tds-modal-confirm-icon {
                            &.tds-modal-confirm-icon-warning {
                                @apply tds-color-icon-warning;
                            }
                            &.tds-modal-confirm-icon-info {
                                @apply tds-color-icon-info;
                            }
                            &.tds-modal-confirm-icon-success {
                                @apply tds-color-icon-success;
                            }

                            &.tds-modal-confirm-icon-error {
                                @apply tds-color-icon-error;
                            }
                            i {
                                font-size: var(--tds-modal-icon-size-s);
                                line-height: var(--tds-modal-icon-size-s);
                            }
                            //custom icon
                            .tds-modal-confirm-icon-custom:not(.tds-modal-confirm-icon-custom-confirm) {
                                @apply w-[--tds-modal-icon-size-s] h-[--tds-modal-icon-size-s] rounded-full p-1.5 tds-color-icon-white;
                                i {
                                    @apply text-xl leading-5;
                                }
                            }
                            .tds-modal-confirm-icon-custom-confirm {
                                i {
                                    font-size: var(--tds-modal-icon-size-s);
                                    line-height: var(--tds-modal-icon-size-s);
                                }
                            }
                            .tds-modal-confirm-icon-custom-warning {
                                @apply tds-color-background-warning-subtle;
                            }
                            .tds-modal-confirm-icon-custom-info {
                                @apply tds-color-background-info-subtle;
                            }
                            .tds-modal-confirm-icon-custom-success {
                                @apply tds-color-background-success-subtle;
                            }

                            .tds-modal-confirm-icon-custom-error {
                                @apply tds-color-background-error-subtle;
                            }
                        }
                        .tds-modal-confirm-body-btns {
                            @apply ml-[--tds-modal-margin-icon-left] flex-auto;
                            .tds-modal-confirm-body {
                                @apply w-full;
                                .tds-modal-confirm-title {
                                    @apply break-words tds-modal-header-title tds-color-text-primary;
                                }
                                .tds-modal-confirm-content {
                                    @apply mt-[--tds-modal-margin-body-top-s] break-words tds-color-text-primary tds-modal-text-default tds-custom-scroll overflow-auto max-h-[calc(100vh-250px)] ;
                                }
                            }
                            .tds-modal-confirm-btns {
                                @apply w-full  flex justify-end mt-[--tds-modal-margin-semantic-top];
                                button {
                                    @apply min-w-[100px] ml-[--tds-modal-margin-button-group-left];
                                    &:first-child {
                                        @apply ml-0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .tds-modal-button-close {
            @apply absolute right-4 top-4 pointer-events-auto;
        }
    }
}
