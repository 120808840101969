:root{
        --tds-transfer-padding-horizontal:var(--tds-spacing-7,12px);
--tds-transfer-padding-vertical-xs:var(--tds-spacing-3,4px);
--tds-transfer-padding-vertical-s:var(--tds-spacing-4,6px);
--tds-transfer-padding-vertical-m:var(--tds-spacing-5,8px);
--tds-transfer-padding-vertical-l:var(--tds-spacing-6,10px);
--tds-transfer-margin-xs:var(--tds-spacing-1,1px);
--tds-transfer-margin-s:var(--tds-spacing-3,4px);
--tds-transfer-margin-m:var(--tds-spacing-5,8px);

    }
    
            
@layer utilities{

            
.tds-transfer-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }
.tds-transfer-icon-size-base
{
                        @apply  text-tds-font-size-6 leading-tds-line-height-4 ;

                    }

        }
            