.tds-divider {
    @apply box-border m-0 p-0 text-body-2   border-t;
    &.tds-divider-theme-default {
        @apply tds-color-text-primary tds-color-border-primary;
    }
    &.tds-divider-theme-light {
        @apply tds-color-text-primary-light tds-color-border-primary-light;
    }
    &.tds-divider-theme-dark {
        @apply tds-color-text-primary-dark tds-color-border-primary-dark;
    }
}
.tds-divider-vertical {
    @apply relative inline-block align-middle top-[-0.06em]  h-[0.9em] mx-2 my-0 border-t-0 border-l;
}
.tds-divider-horizontal {
    @apply flex clear-both w-full min-w-full my-6 mx-0;
}
.tds-divider-horizontal.tds-divider-with-text {
    @apply flex my-4 mx-0 text-body-2  whitespace-nowrap text-center border-t-0;
    &:before {
        @apply relative top-1/2 w-1/2  translate-y-1/2 content-[''];
        border-top: 1px solid transparent;
        border-top-color: inherit;
        border-bottom: 0;
    }
    &:after {
        @apply relative top-1/2 w-1/2 translate-y-1/2 content-[''];
        border-top: 1px solid transparent;
        border-top-color: inherit;
        border-bottom: 0;
    }
}
.tds-divider-horizontal.tds-divider-with-text-left {
    &:before {
        top: 50%;
        width: 5%;
    }
    &:after {
        top: 50%;
        width: 95%;
    }
}
.tds-divider-horizontal.tds-divider-with-text-right {
    &:before {
        top: 50%;
        width: 95%;
    }
    &:after {
        top: 50%;
        width: 5%;
    }
}
.tds-divider-inner-text {
    display: inline-block;
    padding: 0 1em;
}
.tds-divider-dashed {
    // @apply tds-color-border-primary;
    background: none;
    border-style: dashed;
    border-width: 1px 0 0;
}
.tds-divider-horizontal.tds-divider-with-text.tds-divider-dashed {
    &:before {
        border-style: dashed none none;
    }
    &:after {
        border-style: dashed none none;
    }
}
.tds-divider-vertical.tds-divider-dashed {
    border-width: 0 0 0 1px;
}
.tds-divider-plain.tds-divider-with-text {
    @apply text-body-2 ;
}
.tds-divider-horizontal.tds-divider-with-text-left.tds-divider-no-default-orientation-margin-left {
    &:before {
        width: 0;
    }
    &:after {
        width: 100%;
    }
    .tds-divider-inner-text {
        padding-left: 0;
    }
}
.tds-divider-horizontal.tds-divider-with-text-right.tds-divider-no-default-orientation-margin-right {
    &:before {
        width: 100%;
    }
    &:after {
        width: 0;
    }
    .tds-divider-inner-text {
        padding-right: 0;
    }
}
.tds-divider-rtl {
    direction: rtl;
}
.tds-divider-rtl.tds-divider-horizontal.tds-divider-with-text-left {
    &:before {
        width: 95%;
    }
    &:after {
        width: 5%;
    }
}
.tds-divider-rtl.tds-divider-horizontal.tds-divider-with-text-right {
    &:before {
        width: 5%;
    }
    &:after {
        width: 95%;
    }
}
