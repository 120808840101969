:root{
        --tds-rate-margin-s:var(--tds-spacing-2,2px);
--tds-rate-margin-m:var(--tds-spacing-3,4px);
--tds-rate-margin-l:var(--tds-spacing-5,8px);

    }
    
            
@layer utilities{

            
.tds-rate-color-icon-active
{
                            @apply text-tds-color-amber-400-light dark:text-tds-color-amber-400-dark;

                        }                
                        
.tds-rate-color-icon-active-light
{
                            @apply text-tds-color-amber-400-light;

                        }
                        
.tds-rate-color-icon-active-dark
{
                            @apply text-tds-color-amber-400-dark;

                        }
                        
.tds-rate-color-icon-inactive
{
                            @apply text-tds-color-neutral-200-light dark:text-tds-color-neutral-200-dark;

                        }                
                        
.tds-rate-color-icon-inactive-light
{
                            @apply text-tds-color-neutral-200-light;

                        }
                        
.tds-rate-color-icon-inactive-dark
{
                            @apply text-tds-color-neutral-200-dark;

                        }
                        

        }
            