.tds-pagination {
    &.tds-pagination-default {
        @apply block box-border;
        > ul {
            @apply w-full flex items-center;
        }
    }
    > ul {
        @apply flex items-center;
        li[tds-pagination-item] {
            @apply text-center align-middle box-content select-auto border tds-pagination-border-radius border-transparent transition-all duration-200 ml-[--tds-pagination-margin-s] inline-flex items-center justify-center;
            &.tds-pagination-prev,
            &.tds-pagination-next,
            &.tds-pagination-jump-next,
            &.tds-pagination-jump-prev {
                @apply flex items-center justify-center;
                .tds-pagination-item-link {
                    @apply flex;
                    i {
                        @apply leading-4 text-xl;
                    }
                }
            }
            &:not(.tds-pagination-disabled) {
                @apply cursor-pointer;
            }
            &.tds-pagination-disabled {
                @apply cursor-not-allowed;
                .tds-pagination-item-link {
                    @apply cursor-not-allowed;
                }
            }

            > a {
                @apply text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular select-none;
            }
            .tds-pagination-item-link {
                .tds-pagination-item-container {
                    @apply flex;
                    .tds-pagination-item-link-icon {
                        @apply text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular hidden;
                    }
                    .tds-pagination-item-ellipsis {
                        @apply text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular;
                    }
                }
            }
            &:hover {
                .tds-pagination-item-link {
                    .tds-pagination-item-container {
                        .tds-pagination-item-link-icon {
                            @apply inline;
                        }
                        .tds-pagination-item-ellipsis {
                            @apply hidden;
                        }
                    }
                }
            }
        }
        li[tds-pagination-options] {
            @apply ml-[--tds-pagination-margin-xl] flex items-center;
            .tds-pagination-options-quick-jumper {
                @apply flex items-center;
                > tds-form-field {
                    @apply w-[--tds-pagination-width-select-page] mx-3;
                }
            }
            .tds-pagination-options-size-changer {
                @apply w-[--tds-pagination-width-select-page];
            }
        }

        li.tds-pagination-total {
            @apply ml-auto pr-2.5;            
            .tds-pagination-btn-refresh {
                @apply ml-tds-spacing-5;
            }
            
        }
        li.tds-pagination-simple-pager {
            tds-form-field {
                @apply w-[--tds-pagination-width-select-page] mr-2;
            }
        }
    }

    &.tds-pagination-bordered {
        > ul {
            li[tds-pagination-item] {
                @apply border-tds-border-width-s;
                &.tds-pagination-item-selected {
                    @apply border-transparent;
                }
            }
        }
    }
    &.tds-pagination-size-sm {
        > ul {
            li[tds-pagination-item] {
                &.tds-pagination-item-size-sm {
                    @apply min-w-[--tds-pagination-min-width-s] min-h-[--tds-pagination-min-height-s];
                    > a {
                        @apply p-[--tds-pagination-padding-number-s];
                    }
                }
            }
        }
    }
    &.tds-pagination-size-md {
        > ul {
            li[tds-pagination-item] {
                &.tds-pagination-item-size-md {
                    @apply min-w-[--tds-pagination-min-width-m] min-h-[--tds-pagination-min-height-m];
                    > a {
                        @apply p-[--tds-pagination-padding-number-m];
                    }
                }
            }
        }
    }
    &.tds-pagination-theme-default {
        > ul {
            li[tds-pagination-item] {
                &:not(.tds-pagination-disabled) {
                    @apply tds-color-text-tertiary hover:tds-color-background-neutral-minimalist;
                    .tds-pagination-item-link {
                        @apply tds-color-icon-tertiary;
                    }
                }
                &.tds-pagination-disabled {
                    @apply tds-color-icon-disable;
                    .tds-pagination-item-link {
                        @apply tds-color-icon-disable;
                    }
                }
                &.tds-pagination-item-selected {
                    @apply tds-color-text-white tds-color-background-primary-subtle hover:tds-color-background-primary-subtle;
                }
            }
        }
        &.tds-pagination-bordered {
            > ul {
                li[tds-pagination-item] {
                    @apply tds-color-border-primary;
                }
            }
        }
    }
    &.tds-pagination-theme-light {
        > ul {
            li[tds-pagination-item] {
                &:not(.tds-pagination-disabled) {
                    @apply tds-color-text-tertiary-light hover:tds-color-background-neutral-minimalist-light;
                    .tds-pagination-item-link {
                        @apply tds-color-icon-tertiary-light;
                    }
                }
                &.tds-pagination-disabled {
                    @apply tds-color-icon-disable-light;
                    .tds-pagination-item-link {
                        @apply tds-color-icon-disable-light;
                    }
                }
                &.tds-pagination-item-selected {
                    @apply tds-color-text-white-light tds-color-background-primary-subtle-light hover:tds-color-background-primary-subtle-light;
                }
            }
        }
        &.tds-pagination-bordered {
            > ul {
                li[tds-pagination-item] {
                    @apply tds-color-border-primary-light;
                }
            }
        }
    }
    &.tds-pagination-theme-dark {
        > ul {
            li[tds-pagination-item] {
                &:not(.tds-pagination-disabled) {
                    @apply tds-color-text-tertiary-dark hover:tds-color-background-neutral-minimalist-dark;
                    .tds-pagination-item-link {
                        @apply tds-color-icon-tertiary-dark;
                    }
                }
                &.tds-pagination-disabled {
                    @apply tds-color-icon-disable-dark;
                    .tds-pagination-item-link {
                        @apply tds-color-icon-disable-dark;
                    }
                }
                &.tds-pagination-item-selected {
                    @apply tds-color-text-white-dark tds-color-background-primary-subtle-dark hover:tds-color-background-primary-subtle-dark;
                }
            }
        }
        &.tds-pagination-bordered {
            > ul {
                li[tds-pagination-item] {
                    @apply tds-color-border-primary-dark;
                }
            }
        }
    }
}
