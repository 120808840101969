:root{
        --tds-select-margin-s:var(--tds-spacing-3,4px);
--tds-select-margin-m:var(--tds-spacing-4,6px);
--tds-select-margin-l:var(--tds-spacing-5,8px);
--tds-select-padding-s:var(--tds-spacing-3,4px);
--tds-select-padding-m:var(--tds-spacing-4,6px);
--tds-select-padding-l:var(--tds-spacing-5,8px);
--tds-select-padding-xl:var(--tds-spacing-6,10px);
--tds-select-padding-left-s:var(--tds-spacing-5,8px);
--tds-select-padding-left-m:var(--tds-spacing-6,10px);
--tds-select-padding-left-l:var(--tds-spacing-7,12px);
--tds-select-padding-left-xl:var(--tds-spacing-8,14px);
--tds-select-padding-tag-m:var(--tds-spacing-3,4px);
--tds-select-padding-tag-l:var(--tds-spacing-4,6px);
--tds-select-padding-range-dropdown-menu:var(--tds-spacing-9,16px);
--tds-select-padding-tree-dropdown-menu:var(--tds-spacing-5,8px);

        .compact{
            --tds-select-margin-m:var(--tds-spacing-3,4px);
--tds-select-margin-l:var(--tds-spacing-3,4px);
--tds-select-padding-m:var(--tds-spacing-3,4px);
--tds-select-padding-l:var(--tds-spacing-3,4px);
--tds-select-padding-xl:var(--tds-spacing-3,4px);
--tds-select-padding-left-s:var(--tds-spacing-4,6px);
--tds-select-padding-left-m:var(--tds-spacing-4,6px);
--tds-select-padding-left-l:var(--tds-spacing-4,6px);
--tds-select-padding-left-xl:var(--tds-spacing-4,6px);

        }
    }
    
            
@layer utilities{

            
.tds-select-color-select-range-background
{
                            @apply tds-color-background-neutral-minimal-light dark:tds-color-background-neutral-boldest-dark;

                        }                
                        
.tds-select-color-select-range-background-light
{
                            @apply tds-color-background-neutral-minimal-light;

                        }
                        
.tds-select-color-select-range-background-dark
{
                            @apply tds-color-background-neutral-boldest-dark;

                        }
                        

        }
            