.custom-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 6px;
  }
}