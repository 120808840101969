:root{
        --tds-scroll-bar-width-xs:2px;
--tds-scroll-bar-width-s:4px;
--tds-scroll-bar-width-m:6px;
--tds-scroll-bar-width-l:8px;
--tds-scroll-bar-width-xl:10px;
--tds-scroll-bar-height-xs:2px;
--tds-scroll-bar-height-s:4px;
--tds-scroll-bar-height-m:6px;
--tds-scroll-bar-height-l:8px;
--tds-scroll-bar-height-xl:10px;

    }
    
            
@layer utilities{

            
.tds-scroll-bar-border-radius
{
                        @apply rounded-tds-border-radius-circle ;

                    }

        }
            