:root{
        --tds-header-padding-horizontal-s:var(--tds-spacing-5,8px);
--tds-header-padding-horizontal-m:var(--tds-spacing-7,12px);
--tds-header-padding-vertical-m:var(--tds-spacing-2,2px);
--tds-header-padding-vertical-l:var(--tds-spacing-4,6px);
--tds-header-margin-s:var(--tds-spacing-5,8px);
--tds-header-margin-m:var(--tds-spacing-9,16px);
--tds-header-min-height:46px;
--tds-header-height-logo:42px;

    }
    
            
            