:root{
        --tds-cascader-padding-horizontal:var(--tds-spacing-7,12px);
--tds-cascader-padding-vertical-s:var(--tds-spacing-4,6px);
--tds-cascader-padding-vertical-m:var(--tds-spacing-5,8px);
--tds-cascader-padding-vertical-l:var(--tds-spacing-6,10px);
--tds-cascader-margin:var(--tds-spacing-5,8px);

    }
    
            
            