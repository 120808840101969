.tds-popover {
    @apply relative tds-color-text-primary tds-body-2;
}
.tds-popover-content {
    @apply relative;
}
.tds-popover-arrow {
    @apply absolute block bg-transparent pointer-events-none;
}
.tds-popover-arrow-content {
    @apply block border-8 tds-color-border-white-light dark:tds-color-border-tertiary-dark;
}
.tds-popover-inner {
    @apply shadow-tds-shadow-xl-light dark:shadow-tds-shadow-xl-dark tds-color-surface-medium tds-popover-border-radius p-[--tds-popover-padding];
}
.tds-popover-title {
    @apply tds-body-1 tds-font-weight-semibold w-full mb-[--tds-popover-margin-s];
}
.tds-popover-inner-content {
    @apply tds-color-text-primary;
}
.tds-popover-inner-footer {
    @apply mt-[--tds-popover-margin-m];
}
