.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    @apply pointer-events-none top-0 left-0 h-full w-full;
    
}

.cdk-overlay-container {
    @apply fixed z-60;
}

.cdk-overlay-container:empty {
    @apply   hidden;
}

.cdk-global-overlay-wrapper {
    @apply  flex absolute z-60;   
}

.cdk-overlay-pane {
    @apply  absolute pointer-events-auto box-border flex max-w-full max-h-full z-1000 ;
}

.cdk-overlay-backdrop {  
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    @apply absolute top-0 bottom-0 left-0 right-0 pointer-events-auto opacity-0 z-60;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    @apply  opacity-100
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6
}



.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    @apply   opacity-0
}

.cdk-overlay-connected-position-bounding-box {
    @apply   absolute flex flex-col  z-1000;
    min-width: 1px;
    min-height: 1px
}

.cdk-global-scrollblock {
    @apply    fixed w-full overflow-y-auto;
}
.cdk-visually-hidden{
    @apply pointer-events-none border-0 h-px -m-px overflow-hidden p-0 w-px absolute whitespace-nowrap outline-0 ;  
  
    clip: rect(0 0 0 0);
    -webkit-appearance: none;
    -moz-appearance: none;  
}
@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
      }
  
      .no-scrollbar {
        -ms-overflow-style: none; 
        scrollbar-width: none;  
      }
  }

