tds-filter-status {
    @apply inline-block;
    tds-filter-status-item {
        @apply inline-block cursor-pointer;
        &.tds-filter-status-item-default {
            @apply p-4 inline-flex items-center text-body-2  font-semibold text-neutral-1-400 dark:text-d-neutral-1-400 group-hover:text-neutral-1-900 dark:group-hover:text-d-neutral-1-900 border-b-3 border-transparent;

            .tds-filter-status-item-count {
                @apply text-caption-2 font-semibold py-0.5 px-1.5 rounded-full flex text-neutral-1-500  dark:text-d-neutral-1-500 bg-neutral-3-100 dark:bg-d-neutral-1-400  group-hover:text-white group-hover:bg-primary-1 ml-2;
            }
            &.tds-filter-status-item-active {
                @apply border-b-3 border-primary-1 dark:border-d-primary-1 text-neutral-1-900 dark:text-d-neutral-1-900;
                .tds-filter-status-item-count {
                    @apply text-white bg-primary-1 dark:bg-d-primary-1;
                }
            }
            &:not(.tds-filter-status-item-disabled) {
                @apply hover:border-b-3 hover:border-primary-1;
            }
        }
        &.tds-filter-status-item-disabled {
            @apply cursor-not-allowed;
        }
    }
}
