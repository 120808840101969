tds-layout {
    &-header {
        @apply block;
    }
    &-footer {
        @apply block;
    }
    &-content {
        @apply block;
    }
}

.tds-layout {
    @apply flex flex-auto flex-col min-h-0  tds-layout-text-default;
    &,
    * {
        @apply box-border;
    }
    &.tds-layout-has-sider {
        @apply flex-row;
        > .tds-layout,
        > .tds-layout-content {
            @apply w-0;
        }
    }
    &-header,
    &-footer {
        @apply flex-grow-0 flex-shrink-0 basis-auto;
    }
    &-header {
        @apply h-14 py-2.5 px-4  leading-[56px];
        &.tds-layout-has-header {
            @apply p-0;
            .tds-header {
                @apply h-full py-0;
            }
        }
    }

    &-footer {
        @apply py-4 px-6;
    }
    &-content {
        @apply flex-auto min-h-0 p-[--tds-layout-padding-body] tds-layout-text-default;
    }
    &-sider {
        @apply relative min-w-0 h-full;
        transition: all 0.2s;
        &-children {
            @apply h-full;
            margin-top: -0.1px;
            padding-top: 0.1px;
            .tds-menu-inline-collapsed {
                width: auto;
            }
        }
    }
    &-has-trigger {
        @apply pb-12;
    }

    &-right {
        order: 1;
    }
    &-trigger {
        transition: all 0.2s;
    }

    &-zero-width {
        > * {
            overflow: hidden;
        }

        &-trigger {
            @apply absolute top-14  -right-[34px] z-[1px]  cursor-pointer;

            transition: background 0.3s ease;

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: transparent;
                transition: all 0.3s;
                content: "";
            }

            &:hover::after {
                background: rgba(255, 255, 255, 0.1);
            }

            // &-right {
            //     left: -@layout-zero-trigger-width;
            //     border-radius: @border-radius-base 0 0 @border-radius-base;
            // }
        }
    }
    &.tds-layout {
        &-theme-default {
            @apply tds-color-surface-sunken tds-color-text-primary;
        }
        &-theme-light {
            @apply tds-color-surface-sunken-light  tds-color-text-primary-light;
        }
        &-theme-dark {
            @apply tds-color-surface-sunken-dark tds-color-text-primary-dark;
        }
    }
}
.tds-layout-sider-dark {
    // @apply bg-d-neutral-3-200;
}
