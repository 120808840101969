.tds-anchor-link {
    @apply flex flex-col box-border  pr-[--tds-anchor-padding-right] pl-[--tds-anchor-padding-left]  tds-body-2 tds-font-weight-regular;
    .tds-anchor-link-title {
        @apply py-[--tds-anchor-padding-vertical];
    }
    .tds-anchor-link-title-group {
        @apply tds-body-2  tds-font-weight-semibold;
    }
    &-theme-default {
        @apply tds-color-text-secondary;
        &.tds-anchor-link-active {
            // @apply tds-color-border-brand-primary  tds-color-text-brand-primary;
            > .tds-anchor-link-title {
                @apply tds-color-text-brand-primary hover:tds-color-text-brand-primary;
            }
        }
        .tds-anchor-link-title {
            @apply tds-color-text-secondary hover:tds-color-text-brand-primary;
        }
        .tds-anchor-link-title-group {
            @apply tds-color-text-primary;
        }
    }
    &-theme-light {
        @apply tds-color-text-secondary-light;
        &.tds-anchor-link-active {
            // @apply tds-color-border-brand-primary-light  tds-color-text-brand-primary-light;
            > .tds-anchor-link-title {
                @apply tds-color-text-brand-primary-light hover:tds-color-text-brand-primary-light;
            }
        }
        .tds-anchor-link-title {
            @apply tds-color-text-secondary-light hover:tds-color-text-brand-primary-light;
        }
        .tds-anchor-link-title-group {
            @apply tds-color-text-primary-light;
        }
    }
    &-theme-dark {
        @apply tds-color-text-secondary-dark;
        &.tds-anchor-link-active {
            // @apply tds-color-border-brand-primary-dark  tds-color-text-brand-primary-dark;
            > .tds-anchor-link-title {
                @apply tds-color-text-brand-primary-dark hover:tds-color-text-brand-primary-dark;
            }
        }
        .tds-anchor-link-title {
            @apply tds-color-text-secondary-dark hover:tds-color-text-brand-primary-dark;
        }
        .tds-anchor-link-title-group {
            @apply tds-color-text-primary-dark;
        }
    }
}
.tds-anchor-wrapper {
    .tds-anchor {
        @apply flex flex-col border-l-tds-border-width-s relative;
    }
}
.tds-anchor-fixed {
    @apply fixed;
}
.tds-anchor {
    &-theme-default {
        .tds-anchor-wrapper {
            .tds-anchor {
                @apply tds-color-border-secondary;
            }
        }
    }
    &-theme-light {
        .tds-anchor-wrapper {
            .tds-anchor {
                @apply tds-color-border-secondary-light;
            }
        }
    }
    &-theme-dark {
        .tds-anchor-wrapper {
            .tds-anchor {
                @apply tds-color-border-secondary-dark;
            }
        }
    }
}
.tds-anchor-ink-ball {
    @apply tds-color-background-primary-subtle -left-px absolute w-[3px] h-8 -mt-3 top-3;
}
