tds-calendar-header{
    @apply  w-full flex items-center justify-between py-2;
}

tds-calendar-mode-day{
    @apply block w-full h-full;
    tds-calendar-mode-day-stack{
        @apply block w-full h-full relative overflow-auto border tds-color-border-primary ;
        table.tds-calendar-mode-day-stack-vertical{
            @apply table-fixed min-w-full h-full border-collapse  rounded-lg;
            tr.tds-calendar-segment{
                td.tds-calendar-col-lock{
                    // @apply sticky left-0 z-10;
                    @apply align-top text-center  tds-color-surface-lowest pt-2 border-r tds-color-border-primary; 
                    >span{
                        @apply  text-body-2 font-semibold text-neutral-1-400 ;
                    }
                    
                }
                td.tds-calendar-col-lock.activeTime{
                    >span
                    {
                        @apply text-primary-1;
                    }
                }
                td.tds-calendar-col-event{
                    @apply cursor-pointer align-top;
                    .tds-calendar-wrapper-events{
                        @apply flex flex-wrap w-full min-h-[50px] h-full;
                    }
                }
                // td.tds-calendar-col-lock:after{
                //     @apply  bg-transparent absolute top-0 right-0 bottom-0 pointer-events-none;
                //         box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);          
                //         width: 30px;
                //         transform: translateX(100%);
                //         transition: box-shadow 0.3s;
                //         content: "";          
                //     }
                td{
                    @apply  border-b  tds-color-border-primary 
                }    
            }        
            
         }
         table.tds-calendar-mode-day-stack-horizontal{
            @apply table-fixed min-w-full h-full border-collapse ;
            tr{
                th{
                    @apply bg-neutral-3-50 text-neutral-1-700 text-header-2 text-center py-2.5 font-semibold;
                }
                th.activeTime{
                    @apply text-primary-1;
                }
                th,td{
                    @apply  border-b tds-color-border-primary  min-w-[250px];

                }
                th:not(:last-child),td:not(:last-child){
                    @apply border-r;
                }
                
            }      
            
         }
    }
    .tds-calendar-mode-day-timeline{
        @apply w-full h-full  relative overflow-auto border tds-color-border-primary  ;
        table{
            tr td.tds-calendar-col-lock:after{
             @apply  bg-transparent absolute top-0 right-0 bottom-0 pointer-events-none;
                 box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);          
                 width: 30px;
                 transform: translateX(100%);
                 transition: box-shadow 0.3s;
                 content: "";          
             }
             tr.tds-calendar-segment-start:not(:first-child){
                 @apply border-t tds-color-border-primary;
             }
             tr.tds-calendar-segment{
                 @apply border-t tds-color-border-primary border-dashed
             }
            }
    }
}
tds-calendar-mode-week{
    @apply w-full h-full block relative overflow-auto ;
    table
    {
        @apply  w-full h-full border-collapse ;
        thead tr th {
            @apply  sticky top-0 z-10 w-[14%];
            
            div.today{
                @apply text-primary-1;
            }
          }
         th{
            // @apply border-b tds-color-border-primary ;
            @apply bg-neutral-3-50 text-center p-2.5;
        }
         tr th:not(:last-child),tr td:not(:last-child){
             @apply border-r tds-color-border-primary ;
         }
         tbody{
            tr td{
                @apply align-top;
            }
         }
    }
}
tds-calendar-mode-month{
    @apply w-full h-full block relative overflow-auto ;
    table
    {
        @apply  w-full h-full border-collapse ;
        thead tr th {
            @apply  sticky top-0 z-10 w-[14%];           
           
          }
         th{
            // @apply border-b tds-color-border-primary ;
            @apply bg-neutral-3-50 text-center p-2.5;
        }
         tr th:not(:last-child),tr td:not(:last-child){
             @apply border-r tds-color-border-primary ;
         }
         tbody{
            tr td{
                @apply align-top border-b  tds-color-border-primary text-sm;
                >div
                    >div{
                        @apply text-neutral-1-800 text-body-2 font-normal;
                        .isToday{
                            @apply bg-primary-1 text-white rounded-full p-1.5 w-8 h-8 text-center inline-block;
                        }
                    }
                &.disabledDate{
                        @apply cursor-not-allowed;
                    >div
                    >div{
                        @apply    text-neutral-1-300;
                    
                    }
                   
                    
                }

            }
         }
    }
}
tds-calendar-mode-year{
    @apply w-full h-full block relative overflow-auto ;
    table
    {
        @apply  w-full h-full border-collapse ;
       
         tr th:not(:last-child),tr td:not(:last-child){
             @apply border-r tds-color-border-primary ;
         }
         tbody{
            tr td{
                @apply align-top border-b  tds-color-border-primary text-sm;
                >div
                    >div{
                        @apply text-neutral-1-800 text-body-2 font-normal capitalize ;
                        .isToday{
                            @apply bg-primary-1 text-white rounded-full p-1.5  text-center inline-block;
                        }
                    }
                &.disabledDate{
                        @apply cursor-not-allowed;
                    >div
                    >div{
                        @apply    text-neutral-1-300;
                    
                    }
                   
                    
                }

            }
         }
    }
}
tds-calendar{
    &.tds-picker-calendar{
        @apply flex flex-col w-full  h-full;
        .tds-picker-calendar-body{
            @apply flex-auto w-full h-full;
        }
    }
}
