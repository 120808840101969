tds-rate {
    .tds-rate {
        @apply box-border m-0 p-0 inline-block;
        li.tds-rate-star {
            @apply relative inline-block;
            .tds-rate-star-second {
                @apply select-none text-current;
            }
            .tds-rate-star-first {
                @apply select-none  absolute left-0 top-0 w-1/2 h-full overflow-hidden opacity-0 text-current;
                &.tds-rate-star-first-half{
                    @apply opacity-100;
                }
            }
            
        }
    }
    &.tds-rate-size-sm {
        .tds-rate {
            @apply tds-icon-size-s;
            li.tds-rate-star:not(:first-child) {
                @apply ml-[--tds-rate-margin-s];
            }
        }
    }
    &.tds-rate-size-md {
        .tds-rate {
            @apply tds-icon-size-m;
            li.tds-rate-star:not(:first-child) {
                @apply ml-[--tds-rate-margin-m];
            }
        }
    }
    &.tds-rate-size-lg {
        .tds-rate {
            @apply tds-icon-size-l;
            li.tds-rate-star:not(:first-child) {
                @apply ml-[--tds-rate-margin-l];
            }
        }
    }
    &.tds-rate-theme-default{
       > .tds-rate {
            @apply  tds-rate-color-icon-inactive;
          >  li.tds-rate-star {
                .tds-rate-star-first {                    
                    &.tds-rate-star-first-half{
                        @apply tds-rate-color-icon-active;
                    }
                }
                &.tds-rate-star-full {
                    @apply tds-rate-color-icon-active;
                }
            }
        }
    }

    &.tds-rate-theme-light{
        > .tds-rate {
            @apply  tds-rate-color-icon-inactive-light;
          >  li.tds-rate-star {
                .tds-rate-star-first {                    
                    &.tds-rate-star-first-half{
                        @apply tds-rate-color-icon-active-light;
                    }
                }
                &.tds-rate-star-full {
                    @apply tds-rate-color-icon-active-light;
                }
            }
        }
    }
    &.tds-rate-theme-dark{
        > .tds-rate {
            @apply  tds-rate-color-icon-inactive-dark;
          >  li.tds-rate-star {
                .tds-rate-star-first {                    
                    &.tds-rate-star-first-half{
                        @apply tds-rate-color-icon-active-dark;
                    }
                }
                &.tds-rate-star-full {
                    @apply tds-rate-color-icon-active-dark;
                }
            }
        }
    }
}
