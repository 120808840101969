:root{
        --tds-input-padding-horizontal-xs:var(--tds-spacing-3,4px);
--tds-input-padding-horizontal-s:var(--tds-spacing-5,8px);
--tds-input-padding-horizontal-m:var(--tds-spacing-6,10px);
--tds-input-padding-horizontal-l:var(--tds-spacing-7,12px);
--tds-input-padding-horizontal-xl:var(--tds-spacing-8,14px);
--tds-input-padding-s:var(--tds-spacing-3,4px);
--tds-input-padding-m:var(--tds-spacing-4,6px);
--tds-input-padding-l:var(--tds-spacing-5,8px);
--tds-input-padding-xl:var(--tds-spacing-6,10px);
--tds-input-margin-s:var(--tds-spacing-3,4px);
--tds-input-margin-m:var(--tds-spacing-5,8px);

        .compact{
            --tds-input-padding-horizontal-s:var(--tds-spacing-4,6px);
--tds-input-padding-horizontal-m:var(--tds-spacing-4,6px);
--tds-input-padding-horizontal-l:var(--tds-spacing-4,6px);
--tds-input-padding-horizontal-xl:var(--tds-spacing-4,6px);
--tds-input-padding-m:var(--tds-spacing-3,4px);
--tds-input-padding-l:var(--tds-spacing-3,4px);
--tds-input-padding-xl:var(--tds-spacing-3,4px);
--tds-input-margin-m:var(--tds-spacing-3,4px);

        }
    }
    
            
@layer utilities{

            
.tds-input-icon-size-m
{
                        @apply  text-tds-font-size-6 leading-tds-line-height-4 compact:text-tds-font-size-4 compact:leading-tds-line-height-3;

                    }
.tds-input-icon-size-s
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-3 ;

                    }
.tds-input-icon-size-xs
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-3 ;

                    }
.tds-input-border-radius
{
                        @apply rounded-tds-border-radius-xs ;

                    }
.tds-input-label
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold compact:text-tds-font-size-2 compact:leading-tds-line-height-3 compact:font-tds-font-family-body compact:tds-font-weight-semibold;

                    }
.tds-input-text-base
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular compact:text-tds-font-size-2 compact:leading-tds-line-height-3 compact:font-tds-font-family-body compact:tds-font-weight-regular;

                    }
.tds-input-text-xs
{
                        @apply  text-tds-font-size-2 leading-tds-line-height-3 font-tds-font-family-body tds-font-weight-regular ;

                    }

        }
            