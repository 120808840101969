tds-tag {
    @apply inline-flex box-border;

    &.tds-tag-mode-default {
        @apply tds-tag-border-radius-s;
        &.tds-tag-type-default {
            @apply text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-semibold tds-color-text-white border-tds-border-width-s border-transparent;
            &.tds-tag-theme-default {
                &.tds-tag-secondary,&.tds-tag-neutral {
                    @apply tds-color-background-neutral-boldest ;
                }
                &.tds-tag-primary {
                    @apply tds-color-background-primary-subtle ;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-subtle ;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-subtle ;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-subtle ;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-subtle ;
                }
                &.tds-tag-default {
                    @apply tds-color-surface-lowest tds-color-border-primary tds-color-text-quaternary;
                }
            }
            &.tds-tag-theme-light {
                &.tds-tag-secondary,&.tds-tag-neutral {
                    @apply tds-color-background-neutral-boldest-light ;
                }
                &.tds-tag-primary {
                    @apply tds-color-background-primary-subtle-light ;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-subtle-light ;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-subtle-light ;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-subtle-light ;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-subtle-light ;
                }
                &.tds-tag-default {
                    @apply tds-color-surface-lowest-light tds-color-border-primary-light tds-color-text-quaternary-light;
                }
            }
            &.tds-tag-theme-dark {
                &.tds-tag-secondary,&.tds-tag-neutral {
                    @apply tds-color-background-neutral-boldest-dark ;
                }
                &.tds-tag-primary {
                    @apply tds-color-background-primary-subtle-dark ;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-subtle-dark;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-subtle-dark;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-subtle-dark;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-subtle-dark;
                }
                &.tds-tag-default {
                    @apply tds-color-surface-lowest-dark tds-color-border-primary-dark tds-color-text-quaternary-dark;
                }
            }
            
        }
        &.tds-tag-type-outline {
            @apply text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-regular  border-tds-border-width-s;
            &.tds-tag-theme-default {
                &.tds-tag-primary {
                    @apply tds-color-background-primary-minimal tds-color-border-brand-primary  tds-color-text-brand-primary;
                }
                &.tds-tag-secondary {
                    @apply tds-color-background-neutral-minimal tds-color-text-tertiary tds-color-border-neutral-minimalist;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-minimal tds-color-border-success  tds-color-text-success;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-minimal tds-color-border-info  tds-color-text-info;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-minimal tds-color-border-error  tds-color-text-error;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-minimal tds-color-border-warning  tds-color-text-warning;
                }
            }
            &.tds-tag-theme-light {
                &.tds-tag-primary {
                    @apply tds-color-background-primary-minimal-light tds-color-border-brand-primary-light  tds-color-text-brand-primary-light;
                }
                &.tds-tag-secondary {
                    @apply tds-color-background-neutral-minimal-light  tds-color-text-tertiary-light tds-color-border-neutral-minimalist-light;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-minimal-light tds-color-border-success-light  tds-color-text-success-light;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-minimal-light tds-color-border-info-light  tds-color-text-info-light;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-minimal-light tds-color-border-error-light  tds-color-text-error-light;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-minimal-light tds-color-border-warning-light  tds-color-text-warning-light;
                }
            }
            &.tds-tag-theme-dark {
                &.tds-tag-primary {
                    @apply tds-color-background-primary-minimal-dark tds-color-border-brand-primary-dark  tds-color-text-brand-primary-dark;
                }
                &.tds-tag-secondary {
                    @apply tds-color-background-neutral-minimal-dark  tds-color-text-tertiary-dark tds-color-border-neutral-minimalist-dark;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-minimal-dark tds-color-border-success-dark  tds-color-text-success-dark;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-minimal-dark tds-color-border-info-dark  tds-color-text-info-dark;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-minimal-dark tds-color-border-error-dark  tds-color-text-error-dark;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-minimal-dark tds-color-border-warning-dark  tds-color-text-warning-dark;
                }
            }
        }
        &.tds-tag-size-sm {
            @apply px-[--tds-tag-padding-horizontal-s] py-0 text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-semibold;
        }
        &.tds-tag-size-md {
            @apply px-[--tds-tag-padding-horizontal-m] py-[--tds-tag-padding-vertical-m] text-tds-font-size-2 leading-tds-line-height-3 font-tds-font-family-body tds-font-weight-semibold;
        }
        &.tds-tag-size-lg {
            @apply px-[--tds-tag-padding-horizontal-m] py-[--tds-tag-padding-vertical-m] text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold;
        }
        &.tds-tag-size-xl {
            @apply px-[--tds-tag-padding-horizontal-l] py-[--tds-tag-padding-vertical-l] text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold;
        }
    }
    &.tds-tag-mode-closeable {
        @apply border-tds-border-width-s  inline-flex box-border items-center tds-tag-border-radius-s ;
        &.tds-tag-size-sm {
            @apply  text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-regular;            
        }
        &.tds-tag-size-md {
            @apply  text-tds-font-size-2 leading-tds-line-height-3 font-tds-font-family-body tds-font-weight-regular;
        }
        &.tds-tag-size-lg {
            @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular;
        }
        &.tds-tag-size-xl {
            @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular;
        }
        &.tds-tag-type-default {
            &.tds-tag-theme-default {
                &.tds-tag-neutral {
                    @apply tds-color-background-neutral-minimal tds-color-text-primary border-transparent hover:tds-color-background-neutral-subtle;
                }
                &.tds-tag-secondary {
                    @apply tds-color-surface-lowest tds-color-text-primary tds-color-border-primary hover:tds-color-surface-low-hover;
                }
                &.tds-tag-primary {
                    @apply tds-color-background-primary-subtle tds-color-text-white border-transparent hover:tds-color-background-primary-subtle-hover;
                    .tds-tag-close-icon {
                        @apply tds-color-text-white;
                    }
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-minimal tds-color-text-primary hover:tds-color-background-info-minimal-hover border-transparent;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-minimal tds-color-text-primary hover:tds-color-background-success-minimal-hover border-transparent ;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-minimal tds-color-text-primary hover:tds-color-background-warning-minimal-hover border-transparent ;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-minimal tds-color-text-primary hover:tds-color-background-error-minimal-hover border-transparent ;
                }
            }
            &.tds-tag-theme-light {
                &.tds-tag-neutral {
                    @apply tds-color-background-neutral-minimal-light tds-color-text-primary-light border-transparent hover:tds-color-background-neutral-subtle-light;
                }
                &.tds-tag-secondary {
                    @apply tds-color-surface-lowest-light tds-color-text-primary tds-color-border-primary-light hover:tds-color-surface-low-hover-light;
                }
                &.tds-tag-primary {
                    @apply tds-color-background-primary-subtle-light tds-color-text-white-light border-transparent hover:tds-color-background-primary-subtle-hover-light;
                    .tds-tag-close-icon {
                        @apply tds-color-text-white-light;
                    }
                }
            }
            &.tds-tag-theme-dark {
                &.tds-tag-secondary {
                    @apply tds-color-surface-lowest-dark tds-color-text-primary tds-color-border-primary-dark hover:tds-color-surface-low-hover-dark;
                }
                &.tds-tag-primary {
                    @apply tds-color-background-primary-subtle-dark tds-color-text-white-dark border-transparent hover:tds-color-background-primary-subtle-hover-dark;
                    .tds-tag-close-icon {
                        @apply tds-color-text-white-dark;
                    }
                }
                &.tds-tag-neutral {
                    @apply tds-color-background-neutral-minimal-dark tds-color-text-primary-dark border-transparent hover:tds-color-background-neutral-subtle-dark;
                }
            }
        }
        &.tds-tag-type-outline {
            @apply  border-tds-border-width-s;
            &.tds-tag-theme-default {
                &.tds-tag-primary {
                    @apply tds-color-background-primary-minimal tds-color-border-brand-primary  tds-color-text-brand-primary hover:tds-color-background-primary-minimal-hover;
                }
                &.tds-tag-secondary,&.tds-tag-neutral  {
                    @apply tds-color-background-neutral-minimal hover:tds-color-background-neutral-minimal tds-color-text-tertiary tds-color-border-neutral-minimalist;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-minimal tds-color-border-success  tds-color-text-success hover:tds-color-background-success-minimal-hover;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-minimal tds-color-border-info  tds-color-text-info hover:tds-color-background-info-minimal-hover;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-minimal tds-color-border-error  tds-color-text-error hover:tds-color-background-error-minimal-hover;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-minimal tds-color-border-warning  tds-color-text-warning hover:tds-color-background-warning-minimal-hover;
                }
            }
            &.tds-tag-theme-light {
                &.tds-tag-primary {
                    @apply tds-color-background-primary-minimal-light tds-color-border-brand-primary-light  tds-color-text-brand-primary-light hover:tds-color-background-primary-minimal-hover-light;
                }
                &.tds-tag-secondary,&.tds-tag-neutral {
                    @apply tds-color-background-neutral-minimal-light  hover:tds-color-background-neutral-subtle-light tds-color-text-tertiary-light tds-color-border-neutral-minimalist-light;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-minimal-light tds-color-border-success-light  tds-color-text-success-light hover:tds-color-background-success-minimal-hover-light;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-minimal-light tds-color-border-info-light  tds-color-text-info-light hover:tds-color-background-info-minimal-hover-light;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-minimal-light tds-color-border-error-light  tds-color-text-error-light hover:tds-color-background-error-minimal-hover-light;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-minimal-light tds-color-border-warning-light  tds-color-text-warning-light hover:tds-color-background-warning-minimal-hover-light;
                }
            }
            &.tds-tag-theme-dark {
                &.tds-tag-primary {
                    @apply tds-color-background-primary-minimal-dark tds-color-border-brand-primary-dark  tds-color-text-brand-primary-dark hover:tds-color-background-primary-minimal-hover-dark;
                }
                &.tds-tag-secondary,&.tds-tag-neutral {
                    @apply tds-color-background-neutral-minimal-dark  hover:tds-color-background-neutral-subtle-dark tds-color-text-tertiary-dark tds-color-border-neutral-minimalist-dark;
                }
                &.tds-tag-success {
                    @apply tds-color-background-success-minimal-dark tds-color-border-success-dark  tds-color-text-success-dark hover:tds-color-background-success-minimal-hover-dark;
                }
                &.tds-tag-info {
                    @apply tds-color-background-info-minimal-dark tds-color-border-info-dark  tds-color-text-info-dark hover:tds-color-background-info-minimal-hover-dark;
                }
                &.tds-tag-error {
                    @apply tds-color-background-error-minimal-dark tds-color-border-error-dark  tds-color-text-error-dark hover:tds-color-background-error-minimal-hover-dark;
                }
                &.tds-tag-warning {
                    @apply tds-color-background-warning-minimal-dark tds-color-border-warning-dark  tds-color-text-warning-dark hover:tds-color-background-warning-minimal-hover-dark;
                }
            }
        }
    
        .tds-tag-close-icon {
            @apply cursor-pointer text-tds-font-size-4 leading-tds-line-height-3 tds-color-icon-secondary;
        }
        
        &.tds-tag-size-sm {
            @apply px-[--tds-tag-padding-horizontal-s] py-0;
            .tds-tag-close-icon {
                @apply ml-[--tds-tag-margin-s];
            }
        }
        &.tds-tag-size-md {
            @apply px-[--tds-tag-padding-horizontal-m] py-[--tds-tag-padding-vertical-m];
            .tds-tag-close-icon {
                @apply ml-[--tds-tag-margin-base];
            }
        }
        &.tds-tag-size-lg {
            @apply px-[--tds-tag-padding-horizontal-m] py-[--tds-tag-padding-vertical-m];
            .tds-tag-close-icon {
                @apply ml-[--tds-tag-margin-base];
            }
        }
        &.tds-tag-size-xl {
            @apply px-[--tds-tag-padding-horizontal-l] py-[--tds-tag-padding-vertical-l];
            .tds-tag-close-icon {
                @apply ml-[--tds-tag-margin-base];
            }
        }
       
    }
    &.tds-tag-mode-checkable {
        @apply tds-tag-border-radius-m text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold border-tds-border-width-s focus:outline-none inline-block;
        &:not(.tds-tag-disabled) {
            @apply cursor-pointer;
        }
        &.tds-tag-theme-default{
            &:not(.tds-tag-mode-checkable-checked) {
                @apply tds-color-text-tertiary tds-color-border-primary hover:tds-color-surface-low-hover;
            }
           
            &.tds-tag-mode-checkable-checked {
                @apply tds-color-border-brand-primary tds-color-background-primary-subtle tds-color-text-white;
            }
        }
        &.tds-tag-theme-light{
            &:not(.tds-tag-mode-checkable-checked) {
                @apply tds-color-text-tertiary-light tds-color-border-primary-light hover:tds-color-surface-low-hover-light;
            }
           
            &.tds-tag-mode-checkable-checked {
                @apply tds-color-border-brand-primary-light tds-color-background-primary-subtle-light tds-color-text-white-light;
            }
        }
        &.tds-tag-theme-dark{
            &:not(.tds-tag-mode-checkable-checked) {
                @apply tds-color-text-tertiary-dark tds-color-border-primary-dark hover:tds-color-surface-low-hover-dark;
            }
           
            &.tds-tag-mode-checkable-checked {
                @apply tds-color-border-brand-primary tds-color-background-primary-subtle tds-color-text-white;
            }
        }
        

        &.tds-tag-size-sm {
            @apply py-[--tds-tag-padding-tag-checkable-vertical-xs] px-[--tds-tag-padding-tag-checkable-horizontal-s];
        }
        &.tds-tag-size-md {
            @apply py-[--tds-tag-padding-tag-checkable-vertical-s] px-[--tds-tag-padding-tag-checkable-horizontal-m];
        }
        &.tds-tag-size-lg {
            @apply py-[--tds-tag-padding-tag-checkable-vertical-m] px-[--tds-tag-padding-tag-checkable-horizontal-l];
        }
       
    }
    &.rounded-full {
        @apply rounded-tds-border-radius-circle #{!important}
    }
}

tds-tag-multi-color {
    @apply inline-flex box-content border rounded  justify-center items-center text-tds-font-size-2 leading-tds-line-height-3 font-tds-font-family-body tds-font-weight-regular  tds-color-text-primary;
    .tds-tag-multi-color-icon {
        @apply flex justify-center items-center mr-1;
        > span {
            @apply text-xs leading-3;
        }
    }
    .tds-tag-multi-color-text {
        @apply flex items-center justify-center;
    }
    &.tds-tag-size-sm {
        @apply px-[--tds-tag-padding-horizontal-s] py-0;
    }
    &.tds-tag-size-md {
        @apply py-[--tds-tag-padding-vertical-m] px-[--tds-tag-padding-horizontal-m];
    }
    &.tds-tag-size-lg {
        @apply py-[--tds-tag-padding-vertical-l] px-[--tds-tag-padding-horizontal-l];
    }
    &.rounded-full {
        @apply rounded-tds-border-radius-circle #{!important}
    }
}
