.tds-drawer {
    &:not(.tds-drawer-open) {
        @apply pointer-events-none h-0 opacity-0 transition-[opacity,height] duration-[0.3s,0s] ease-[linear,ease] delay-[0s,0.3s];
    }
    .tds-drawer-mask {
        @apply absolute top-0 left-0  w-full tds-color-overlay-default;
    }
    .tds-drawer-content-wrapper {
        @apply absolute w-full h-full;
        transition-duration: 0.3s, 0.3s;
        transition-delay: 0s, 0s;
        transition-property: transform, box-shadow;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        .tds-drawer-content {
            @apply tds-color-surface-lowest h-full w-full relative overflow-auto border-0 z-10 tds-color-text-primary tds-drawer-text-default;
            .tds-drawer-wrapper-body {
                @apply flex flex-col  w-full;
                .tds-drawer-header {
                    @apply flex items-center justify-between py-[--tds-drawer-padding-vertical] px-[--tds-drawer-padding-horizontal];
                }
            }
            .tds-drawer-title {
                @apply flex-auto;
                > div {
                    @apply break-words tds-drawer-text-title;
                }
            }
            .tds-drawer-close {
                @apply box-border;
            }
            .tds-drawer-body {
                @apply flex-auto w-full p-[--tds-drawer-padding-body] tds-color-border-primary;
            }
            .tds-drawer-footer {
                border-style:var(--tds-border-style-base,solid);
                @apply w-full py-[--tds-drawer-padding-vertical] px-[--tds-drawer-padding-horizontal] border-t-tds-border-width-s  ;
                .tds-drawer-footer-template {
                    @apply w-full;
                }
            }
        }
    }
    &.tds-drawer-open {
        @apply delay-[0s] duration-300 transition-transform w-full;
        .tds-drawer-mask {
            @apply pointer-events-auto h-full opacity-100 transition-none;
        }
    }
    &-left,
    &-right {
        @apply top-0 w-0 h-full;
        .tds-drawer-content-wrapper {
            @apply h-full top-0;
            .tds-drawer-wrapper-body {
                @apply h-full;
            }
        }
        &.tds-drawer-open {
            @apply w-full;
        }
    }
    &-left {
        @apply left-0;
        .tds-drawer-content-wrapper {
            @apply left-0;
        }
    }
    &-right {
        @apply right-0;
        .tds-drawer-content-wrapper {
            @apply right-0;
        }
    }
    &-bottom {
        @apply bottom-0;
        .tds-drawer-content-wrapper {
            @apply bottom-0 left-1/2 -translate-x-1/2 max-w-[100vw];
            .tds-drawer-content {
                @apply rounded-t-lg;
            }
            .tds-drawer-wrapper-body {
                @apply h-full;
            }
        }
    }
    &-top {
        @apply top-0;
        .tds-drawer-content-wrapper {
            @apply top-0 left-1/2 -translate-x-1/2  max-w-[100vw];
            .tds-drawer-content {
                @apply rounded-b-lg;
            }
            .tds-drawer-wrapper-body {
                @apply h-full;
            }
        }
    }
}
