tds-radio-group {
    .tds-radio-button-wrapper {
        @apply py-1.5 px-3 border-y  mx-0 relative cursor-pointer tds-btn-text-base;
        &:first-child {
            @apply rounded-l-md border-l;
        }
        &:last-child {
            @apply rounded-r-md border-r;
        }

        transition:
            color 0.3s,
            background 0.3s,
            border-color 0.3s,
            box-shadow 0.3s;
    }
    .tds-radio-button-wrapper:not(:first-child):before {
        @apply absolute -top-px -left-px block w-px box-content h-full py-px px-0 border-l;
        transition: background-color 0.3s;
        content: "";
    }
    .tds-radio-button-wrapper-checked:not(.tds-radio-button-wrapper-disabled) {
        @apply border-tds-border-width-s ;
        z-index: 1;
        &:not(:first-child) {
            @apply border-l-0;
        }
    }
    &.tds-radio-theme-default {
        .tds-radio-button-wrapper {
            @apply tds-color-border-primary;
        }
        .tds-radio-button-wrapper:not(:first-child):before {
            @apply tds-color-border-primary;
        }
        .tds-radio-button-wrapper-checked:not(.tds-radio-button-wrapper-disabled) {
            @apply tds-color-border-brand-primary tds-color-text-brand-primary;

            &:not(:first-child):before {
                @apply tds-color-background-primary-subtle;
            }
        }
    }
    &.tds-radio-theme-light {
        .tds-radio-button-wrapper {
            @apply tds-color-border-primary-light;
        }
        .tds-radio-button-wrapper:not(:first-child):before {
            @apply tds-color-border-primary-light;
        }
        .tds-radio-button-wrapper-checked:not(.tds-radio-button-wrapper-disabled) {
            @apply tds-color-border-brand-primary-light tds-color-text-brand-primary-light;

            &:not(:first-child):before {
                @apply tds-color-background-primary-subtle-light;
            }
        }
    }
    &.tds-radio-theme-dark {
        .tds-radio-button-wrapper {
            @apply tds-color-border-primary-dark;
        }
        .tds-radio-button-wrapper:not(:first-child):before {
            @apply tds-color-border-primary-dark;
        }
        .tds-radio-button-wrapper-checked:not(.tds-radio-button-wrapper-disabled) {
            @apply tds-color-border-brand-primary-dark tds-color-text-brand-primary-dark;

            &:not(:first-child):before {
                @apply tds-color-background-primary-subtle-dark;
            }
        }
    }
}
.tds-radio-wrapper,
.tds-radio-button-wrapper {
    @apply relative inline-flex box-border p-0 mr-[--tds-radio-margin] items-center tds-color-text-primary text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular cursor-pointer;
    input {
        @apply cursor-pointer absolute inset-0 opacity-0 z-10;
    }
    &-disabled {
        @apply cursor-not-allowed;
        input {
            @apply cursor-not-allowed;
        }
    }
    
}

.tds-radio-wrapper {
    .tds-radio {
        @apply relative p-0 m-0 inline-block outline-none;
        .tds-radio-inner {
            @apply relative top-0 left-0 block  rounded-tds-border-radius-circle mr-[--tds-radio-margin] border-tds-border-width-s ;
        }
        &-checked {
            .tds-radio-inner {
                @apply border-tds-border-width-xl;
            }
        }
        &-disabled {
            .tds-radio-inner {
                @apply border-tds-border-width-s ;
            }
            &.tds-radio-checked {
                .tds-radio-inner {
                    @apply border-tds-border-width-xl;
                }
            }
        }
    }

    &.tds-radio-theme-default {
        .tds-radio {
            .tds-radio-inner {
                @apply tds-color-surface-lowest tds-color-border-primary;
            }
            &-checked {
                .tds-radio-inner {
                    @apply tds-color-border-brand-primary;
                }
            }
            &-disabled {
                .tds-radio-inner {
                    @apply tds-color-border-disable  tds-color-background-disable;
                }
                &.tds-radio-checked {
                    .tds-radio-inner {
                        @apply tds-color-border-disable  tds-color-background-white;
                    }
                }
            }
        }
        &:not(.tds-radio-wrapper-disabled):hover {
            .tds-radio-inner {
                @apply tds-color-surface-lowest tds-color-border-brand-primary;
            }
        }
    }
    &.tds-radio-theme-light {
        .tds-radio {
            .tds-radio-inner {
                @apply tds-color-surface-lowest-light tds-color-border-primary-light;
            }
            &-checked {
                .tds-radio-inner {
                    @apply tds-color-border-brand-primary-light;
                }
            }
            &-disabled {
                .tds-radio-inner {
                    @apply tds-color-border-disable-light  tds-color-background-disable-light;
                }
                &.tds-radio-checked {
                    .tds-radio-inner {
                        @apply tds-color-border-disable-light  tds-color-background-white-light;
                    }
                }
            }
        }
        &:not(.tds-radio-wrapper-disabled):hover {
            .tds-radio-inner {
                @apply tds-color-surface-lowest-light tds-color-border-brand-primary-light;
            }
        }
    }
    &.tds-radio-theme-dark {
        .tds-radio {
            .tds-radio-inner {
                @apply tds-color-surface-lowest-dark  tds-color-border-primary-dark;
            }
            &-checked {
                .tds-radio-inner {
                    @apply tds-color-border-brand-primary-dark;
                }
            }
            &-disabled {
                .tds-radio-inner {
                    @apply tds-color-border-disable-dark   tds-color-background-disable-dark;
                }
                &.tds-radio-checked {
                    .tds-radio-inner {
                        @apply tds-color-border-disable-dark   tds-color-background-white-dark;
                    }
                }
            }
        }
        &:not(.tds-radio-wrapper-disabled):hover {
            .tds-radio-inner {
                @apply tds-color-surface-lowest-dark  tds-color-border-brand-primary-dark;
            }
        }
    }

    &.tds-radio-size-xs {
        .tds-radio-inner {
            @apply w-[--tds-radio-height-s] h-[--tds-radio-height-s];
        }
    }
    &.tds-radio-size-sm {
        .tds-radio-inner {
            @apply w-[--tds-radio-height-s] h-[--tds-radio-height-s];
        }
    }
    &.tds-radio-size-md {
        .tds-radio-inner {
            @apply w-[--tds-radio-height-m] h-[--tds-radio-height-m];
        }
    }
    &.tds-radio-size-lg {
        .tds-radio-inner {
            @apply w-[--tds-radio-height-l] h-[--tds-radio-height-l];
        }
    }
    &.tds-radio-size-xl {
        .tds-radio-inner {
            @apply w-[--tds-radio-height-l] h-[--tds-radio-height-l];
        }
    }
}
