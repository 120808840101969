.tds-list {
    @apply box-border m-0 p-0 tds-font-weight-regular tds-body-2 tds-color-text-primary  list-none relative tds-color-surface-lowest;
}
.tds-list-item {
    @apply flex items-center justify-between  py-[--tds-list-padding-vertical] tds-color-text-primary;
}
.tds-list * {
    @apply outline-none;
}

.tds-list-pagination {
    @apply mt-6 text-right;
}

.tds-list-pagination .tds-pagination-options {
    @apply text-left;
}

.tds-list-more {
    @apply mt-5 text-center;
}

.tds-list-more button {
    @apply pr-7 pl-7;
}

.tds-list-spin {
    min-height: 40px;
    text-align: center;
}

.tds-list-empty-text {
    padding: 16px;
    color: #00000040;
    font-size: 14px;
    text-align: center;
}

.tds-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
}


.tds-list-item-meta {
    display: flex;
    flex: 1;
    align-items: flex-start;
    max-width: 100%;
}

.tds-list-item-meta-avatar {
    margin-right: 16px;
}

.tds-list-item-meta-content {
    @apply tds-color-text-primary flex-grow flex-shrink-0 w-0;
}

.tds-list-item-meta-title {
    @apply mb-1 tds-color-text-primary text-body-2 font-semibold;
}

.tds-list-item-meta-title > a {
    @apply tds-color-text-primary;

    transition: all 0.3s;
}

.tds-list-item-meta-title > a:hover {
    color: var(--tds-primary-color);
}

.tds-list-item-meta-description {
    @apply text-caption-2 font-regular tds-color-text-secondary;
}

.tds-list-item-action {
    flex: 0 0 auto;
    margin-left: 48px;
    padding: 0;
    font-size: 0;
    list-style: none;
}

.tds-list-item-action > li {
    @apply relative inline-block text-center px-2 text-caption-2 font-regular tds-color-text-secondary;
}

.tds-list-item-action > li:first-child {
    @apply pl-0;
}

.tds-list-item-action-split {
    @apply absolute top-1/2 right-0 w-px h-3.5 mt-[-7px] bg-neutral-2-200 dark:bg-d-neutral-2-200;
}

.tds-list-header,
.tds-list-footer {
    @apply bg-transparent;
}

.tds-list-header,
.tds-list-footer {
    @apply py-3;
}

.tds-list-empty {
    @apply px-4 py-0 tds-color-text-primary text-center;
}

.tds-list-split .tds-list-item {
    @apply border-b tds-color-border-secondary;
}

.tds-list-split .tds-list-item:last-child {
    @apply border-b-0;
}

.tds-list-split .tds-list-header {
    @apply border-b tds-color-border-secondary;
}

.tds-list-split.tds-list-empty .tds-list-footer {
    @apply border-t tds-color-border-secondary;
}

.tds-list-loading .tds-list-spin-nested-loading {
    min-height: 32px;
}

.tds-list-split.tds-list-something-after-last-item .tds-spin-container > .tds-list-items > .tds-list-item:last-child {
    @apply border-b tds-color-border-secondary;
}

.tds-list-lg .tds-list-item {
    padding: 16px 24px;
}

.tds-list-sm .tds-list-item {
    padding: 8px 16px;
}

.tds-list-vertical .tds-list-item {
    align-items: initial;
}

.tds-list-vertical .tds-list-item-main {
    display: block;
    flex: 1;
}

.tds-list-vertical .tds-list-item-extra {
    margin-left: 40px;
}

.tds-list-vertical .tds-list-item-meta {
    margin-bottom: 16px;
}

.tds-list-vertical .tds-list-item-meta-title {
    @apply mb-3 tds-color-text-primary;
}

.tds-list-vertical .tds-list-item-action {
    @apply mt-4 ml-auto;
}

.tds-list-vertical .tds-list-item-action > li {
    padding: 0 16px;
}

.tds-list-vertical .tds-list-item-action > li:first-child {
    padding-left: 0;
}

.tds-list-grid .tds-col > .tds-list-item {
    display: block;
    max-width: 100%;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.tds-list-item-no-flex {
    @apply block;
}

.tds-list:not(.tds-list-vertical) .tds-list-item-no-flex .tds-list-item-action {
    float: right;
}

.tds-list-bordered {
    @apply border tds-color-border-secondary rounded-sm;
}

.tds-list-bordered .tds-list-header,
.tds-list-bordered .tds-list-footer,
.tds-list-bordered .tds-list-item {
    @apply px-3;
}

.tds-list-bordered .tds-list-pagination {
    margin: 16px 24px;
}

.tds-list-bordered.tds-list-sm .tds-list-item,
.tds-list-bordered.tds-list-sm .tds-list-header,
.tds-list-bordered.tds-list-sm .tds-list-footer {
    padding: 8px 16px;
}

.tds-list-bordered.tds-list-lg .tds-list-item,
.tds-list-bordered.tds-list-lg .tds-list-header,
.tds-list-bordered.tds-list-lg .tds-list-footer {
    padding: 16px 24px;
}

@media screen and (max-width: 768px) {
    .tds-list-item-action,
    .tds-list-vertical .tds-list-item-extra {
        margin-left: 24px;
    }
}

@media screen and (max-width: 576px) {
    .tds-list-item {
        flex-wrap: wrap;
    }

    .tds-list-item-action {
        margin-left: 12px;
    }

    .tds-list-vertical .tds-list-item {
        flex-wrap: wrap-reverse;
    }

    .tds-list-vertical .tds-list-item-main {
        min-width: 220px;
    }

    .tds-list-vertical .tds-list-item-extra {
        margin: auto auto 16px;
    }
}

.tds-list-rtl {
    direction: rtl;
    text-align: right;
}

.tds-list-rtl .ReactVirtualized__List .tds-list-item {
    direction: rtl;
}

.tds-list-rtl .tds-list-pagination {
    text-align: left;
}

.tds-list-rtl .tds-list-item-meta-avatar {
    margin-right: 0;
    margin-left: 16px;
}

.tds-list-rtl .tds-list-item-action {
    margin-right: 48px;
    margin-left: 0;
}

.tds-list.tds-list-rtl .tds-list-item-action > li:first-child {
    padding-right: 0;
    padding-left: 16px;
}

.tds-list-rtl .tds-list-item-action-split {
    right: auto;
    left: 0;
}

.tds-list-rtl.tds-list-vertical .tds-list-item-extra {
    margin-right: 40px;
    margin-left: 0;
}

.tds-list-rtl.tds-list-vertical .tds-list-item-action {
    margin-right: auto;
}

.tds-list-rtl .tds-list-vertical .tds-list-item-action > li:first-child {
    padding-right: 0;
    padding-left: 16px;
}

.tds-list-rtl .tds-list:not(.tds-list-vertical) .tds-list-item-no-flex .tds-list-item-action {
    float: left;
}

@media screen and (max-width: 768px) {
    .tds-list-rtl .tds-list-item-action,
    .tds-list-rtl .tds-list-vertical .tds-list-item-extra {
        margin-right: 24px;
        margin-left: 0;
    }
}

@media screen and (max-width: 576px) {
    .tds-list-rtl .tds-list-item-action {
        margin-right: 22px;
        margin-left: 0;
    }

    .tds-list-rtl.tds-list-vertical .tds-list-item-extra {
        margin: auto auto 16px;
    }
}

tds-list,
tds-list tds-spin,
tds-list-header,
tds-list-footer,
tds-list-pagination,
tds-list-empty,
tds-list-item-extra {
    display: block;
}
