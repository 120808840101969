.tds-auto-optgroup {
    @apply flex w-full flex-col;
    .tds-select-item-group {
        @apply px-[--tds-dropdown-padding-horizontal] py-[--tds-dropdown-padding-vertical-s] tds-color-text-primary tds-dropdown-text tds-dropdown-color-title-background tds-dropdown-title-base;
    }
}
.tds-auto-option {
    @apply px-[--tds-dropdown-padding-horizontal]  tds-color-text-primary tds-dropdown-text  flex w-full;

    &.tds-select-item-option-selected:not(.tds-select-item-option-disabled),
    &.tds-select-item-option-active:not(.tds-select-item-option-disabled) {
        @apply tds-color-background-neutral-minimalist;
    }
    &:not(.tds-select-item-option-disabled) {
        @apply cursor-pointer;
        &:hover {
            @apply tds-color-background-neutral-minimalist;
        }
    }
    &.tds-select-item-option-disabled {
        @apply cursor-not-allowed;
    }
    .tds-select-item-option-content {
        @apply flex-auto truncate;
    }
    &-sm {
        .tds-select-item-option-content {
            @apply py-[--tds-dropdown-padding-vertical-s];
        }
    }

    &-md {
        .tds-select-item-option-content {
            @apply py-[--tds-dropdown-padding-vertical-m];
        }
    }
    &-lg {
        .tds-select-item-option-content {
            @apply py-[--tds-dropdown-padding-vertical-l];
        }
    }
    &:not(:first-child) {
        @apply mt-px;
    }
}

.tds-autocomplete-dropdown {
    @apply w-full my-1 py-[--tds-dropdown-padding-vertical-s] shadow-tds-shadow-l-light dark:shadow-tds-shadow-l-dark rounded-tds-border-radius-xs tds-color-surface-high;
}
.tds-autocomplete-wrapper {
    @apply max-h-[256px] overflow-y-auto;
    overflow-anchor: none;
}
