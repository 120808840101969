:root{
        --tds-switch-width-handle-s:12px;
--tds-switch-width-handle-m:14px;
--tds-switch-width-handle-l:16px;
--tds-switch-height-s:18px;
--tds-switch-height-m:20px;
--tds-switch-height-l:22px;
--tds-switch-width-s:32px;
--tds-switch-width-m:38px;
--tds-switch-width-l:44px;
--tds-switch-margin:var(--tds-spacing-5,8px);

        .compact{
            --tds-switch-width-handle-m:12px;
--tds-switch-width-handle-l:12px;
--tds-switch-height-m:18px;
--tds-switch-height-l:18px;
--tds-switch-width-m:32px;
--tds-switch-width-l:32px;
--tds-switch-margin:var(--tds-spacing-3,4px);

        }
    }
    
            
@layer utilities{

            
.tds-switch-color-overlay-loading
{
                            @apply bg-tds-color-white-light/35 dark:bg-tds-color-white-dark/35;

                        }                
                        
.tds-switch-color-overlay-loading-light
{
                            @apply bg-tds-color-white-light/35;

                        }
                        
.tds-switch-color-overlay-loading-dark
{
                            @apply bg-tds-color-white-dark/35;

                        }
                        
.tds-switch-text
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular compact:text-tds-font-size-2 compact:leading-tds-line-height-3 compact:font-tds-font-family-body compact:tds-font-weight-regular;

                    }

        }
            