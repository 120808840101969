a.tds-button {
    @apply inline-block;
}
.tds-button {
    @apply relative;
    .tds-button-loading-mask {
        @apply absolute -inset-px tds-btn-color-overlay-loading cursor-progress;
    }
    i {
        @apply tds-btn-icon-size-base;
    }
    .tds-button-suffix,
    .tds-button-prefix {
        @apply tds-btn-icon-size-base inline-flex;
    }
    .tds-button-suffix {
        @apply ml-[var(--tds-btn-margin-base)];
    }
    .tds-button-prefix {
        @apply mr-[var(--tds-btn-margin-base)];
    }
    &.tds-button-secondary {
        &.tds-button-secondary-theme-default {
            .tds-button-suffix,
            .tds-button-prefix {
                @apply tds-color-icon-secondary;
            }
        }
        &.tds-button-secondary-theme-light {
            .tds-button-suffix,
            .tds-button-prefix {
                @apply tds-color-icon-secondary-light;
            }
        }
        &.tds-button-secondary-theme-dark {
            .tds-button-suffix,
            .tds-button-prefix {
                @apply tds-color-icon-secondary-dark;
            }
        }
    }
    &.tds-button-xs {
        @apply py-[var(--tds-btn-padding-vertical-xs)] px-[var(--tds-btn-padding-horizontal-xs)] h-[--tds-btn-height-xs] tds-btn-border-radius-xs;
        * {
            @apply tds-btn-text-xs;
        }
        .tds-button-loading-mask {
            @apply tds-btn-border-radius-xs;
        }
        i,
        .tds-button-suffix,
        .tds-button-prefix {
            @apply tds-btn-icon-size-xs;
        }
    }
    &.tds-button-sm {
        @apply py-[var(--tds-btn-padding-vertical-xs)] px-[var(--tds-btn-padding-horizontal-xs)]  h-[--tds-btn-height-s] tds-btn-border-radius-base;
        .tds-button-loading-mask {
            @apply tds-btn-border-radius-base;
        }
    }
    &.tds-button-md {
        @apply py-[var(--tds-btn-padding-vertical-m)] px-[var(--tds-btn-padding-horizontal-m)]  h-[--tds-btn-height-m] tds-btn-border-radius-base;
        .tds-button-loading-mask {
            @apply tds-btn-border-radius-base;
        }
    }
    &.tds-button-lg {
        @apply py-[var(--tds-btn-padding-vertical-l)] px-[var(--tds-btn-padding-horizontal-l)] h-[--tds-btn-height-l] tds-btn-border-radius-base;
        .tds-button-loading-mask {
            @apply tds-btn-border-radius-base;
        }
    }
    &.tds-button-xl {
        @apply py-[var(--tds-btn-padding-vertical-xl)] px-[var(--tds-btn-padding-horizontal-xl)]  h-[--tds-btn-height-xl] tds-btn-border-radius-base;
        .tds-button-loading-mask {
            @apply tds-btn-border-radius-base;
        }
    }
    &.tds-button-loading {
        @apply cursor-wait select-none;
    }
    &.tds-button-border-style-dash {
        @apply border-dashed;
    }
    &.rounded-full {
        border-radius: 9999px !important;
        .tds-button-loading-mask {
            border-radius: 9999px !important;
        }
    }
    &.tds-button-disable {
        &.tds-button-theme-default {
            .tds-button-suffix,
            .tds-button-prefix {
                @apply tds-color-icon-disable;
            }
        }
        &.tds-button-theme-light {
            .tds-button-suffix,
            .tds-button-prefix {
                @apply tds-color-icon-disable-light;
            }
        }
        &.tds-button-theme-dark {
            .tds-button-suffix,
            .tds-button-prefix {
                @apply tds-color-icon-disable-dark;
            }
        }
    }
    &.tds-button-border-style-dashed {
        @apply border-dashed;
    }
}
.tds-button-type-default {
    @apply box-border border whitespace-nowrap  tds-btn-text-base border-transparent;
    &:not(.tds-button-disabled) {
        &:not(.tds-button-loading) {
            @apply cursor-pointer  focus:outline-none focus:ring hover:border-transparent;
        }
        &:not(.tds-button-background-style-minimal) {
            &.tds-button-primary {
                &-theme-default {
                    @apply tds-color-text-white tds-color-ring-primary tds-color-border-brand-primary tds-color-background-primary-subtle;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-primary-subtle-hover focus:tds-color-border-brand-primary focus:tds-color-background-primary-subtle;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-white-light tds-color-ring-primary-light tds-color-border-brand-primary-light tds-color-background-primary-subtle-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-primary-subtle-hover-light focus:tds-color-background-primary-subtle-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-white-dark tds-color-ring-primary-dark tds-color-border-brand-primary-dark tds-color-background-primary-subtle-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-primary-subtle-hover-dark focus:tds-color-background-primary-subtle-dark;
                    }
                }
            }
            &.tds-button-secondary {
                &-theme-default {
                    @apply tds-color-text-primary tds-color-ring-primary tds-color-background-neutral-minimalist;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-neutral-minimal focus:tds-color-ring-primary focus:tds-color-border-brand-primary focus:tds-color-background-neutral-minimalist;
                    }
                    i {
                        @apply tds-color-icon-secondary;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-primary-light tds-color-ring-primary-light tds-color-surface-lowest-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-neutral-minimalist-light focus:tds-color-ring-primary-light focus:tds-color-border-brand-primary-light focus:tds-color-surface-lowest-light;
                    }
                    i {
                        @apply tds-color-icon-secondary-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-primary-dark tds-color-ring-primary-dark tds-color-surface-lowest-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-neutral-minimalist-dark focus:tds-color-ring-primary-dark focus:tds-color-border-brand-primary-dark focus:tds-color-surface-lowest-dark;
                    }
                    i {
                        @apply tds-color-icon-secondary-dark;
                    }
                }
            }
            &.tds-button-success {
                &-theme-default {
                    @apply tds-color-text-white tds-color-ring-success tds-color-background-success-subtle tds-color-border-success;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-success-subtle-hover  focus:tds-color-border-success focus:tds-color-background-success-subtle;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-white-light tds-color-ring-success-light tds-color-background-success-subtle-light tds-color-border-success-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-success-subtle-hover-light  focus:tds-color-border-success-light focus:tds-color-background-success-subtle-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-white-dark tds-color-ring-success-dark tds-color-background-success-subtle-dark tds-color-border-success-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-success-subtle-hover-dark  focus:tds-color-border-success-dark focus:tds-color-background-success-subtle-dark;
                    }
                }
            }
            &.tds-button-info {
                &-theme-default {
                    @apply tds-color-text-white tds-color-ring-info tds-color-background-info-subtle tds-color-border-info;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-info-subtle-hover focus:tds-color-border-info focus:tds-color-background-info-subtle;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-white-light tds-color-ring-info-light tds-color-background-info-subtle-light tds-color-border-info-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-info-subtle-hover-light focus:tds-color-border-info-light focus:tds-color-background-info-subtle-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-white-dark tds-color-ring-info-dark tds-color-background-info-subtle-hover-dark tds-color-border-info-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-info-subtle-hover-dark  focus:tds-color-border-info-dark focus:tds-color-background-info-subtle-dark;
                    }
                }
            }
            &.tds-button-warning {
                &-theme-default {
                    @apply tds-color-text-white tds-color-ring-warning tds-color-background-warning-subtle tds-color-border-warning;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-warning-subtle-hover  focus:tds-color-border-warning focus:tds-color-background-warning-subtle;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-white-light tds-color-ring-warning-light tds-color-background-warning-subtle-light tds-color-border-warning-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-warning-subtle-hover-light  focus:tds-color-border-warning-light focus:tds-color-background-warning-subtle-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-white-dark tds-color-ring-warning-dark tds-color-background-warning-subtle-dark tds-color-border-warning-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-warning-subtle-hover-dark  focus:tds-color-border-warning-dark focus:tds-color-background-warning-subtle-dark;
                    }
                }
            }
            &.tds-button-error {
                &-theme-default {
                    @apply tds-color-text-white tds-color-ring-error tds-color-background-error-subtle tds-color-border-error;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-error-subtle-hover  focus:tds-color-border-error focus:tds-color-background-error-subtle;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-white-light tds-color-ring-error-light tds-color-background-error-subtle-light tds-color-border-error-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-error-subtle-hover-light focus:tds-color-border-error-light focus:tds-color-background-error-subtle-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-white-dark tds-color-ring-error-dark tds-color-background-error-subtle-dark tds-color-border-error-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-error-subtle-hover-dark focus:tds-color-border-error-dark focus:tds-color-background-error-subtle-dark;
                    }
                }
            }
        }
        &.tds-button-background-style-minimal {
            &.tds-button-primary {
                &-theme-default {
                    @apply tds-color-text-brand-primary tds-color-ring-primary tds-color-background-primary-minimal;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-primary-minimal-hover focus:border-transparent focus:tds-color-background-primary-minimal;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-brand-primary-light tds-color-ring-primary-light tds-color-background-primary-minimal-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-primary-minimal-hover-light focus:tds-color-background-primary-minimal-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-brand-primary-dark tds-color-ring-primary-dark tds-color-background-primary-minimal-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-primary-minimal-hover-dark focus:tds-color-background-primary-minimal-dark;
                    }
                }
            }
            &.tds-button-success {
                &-theme-default {
                    @apply tds-color-text-success tds-color-ring-success tds-color-background-success-minimal;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-success-minimal-hover  focus:tds-color-background-success-minimal;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-success-light tds-color-ring-success-light tds-color-background-success-minimal-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-success-minimal-hover-light  focus:tds-color-background-success-minimal-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-success-dark tds-color-ring-success-dark tds-color-background-success-minimal-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-success-minimal-hover-dark focus:tds-color-background-success-minimal-dark;
                    }
                }
            }
            &.tds-button-info {
                &-theme-default {
                    @apply tds-color-text-info tds-color-ring-info tds-color-background-info-minimal;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-info-minimal-hover  focus:tds-color-background-info-minimal;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-info-light tds-color-ring-info-light tds-color-background-info-minimal-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-info-minimal-hover-light  focus:tds-color-background-info-minimal-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-info-dark tds-color-ring-info-dark tds-color-background-info-minimal-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-info-minimal-hover-dark focus:tds-color-background-info-minimal-dark;
                    }
                }
            }
            &.tds-button-warning {
                &-theme-default {
                    @apply tds-color-text-warning tds-color-ring-warning tds-color-background-warning-minimal;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-warning-minimal-hover  focus:tds-color-background-warning-minimal;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-warning-light tds-color-ring-warning-light tds-color-background-warning-minimal-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-warning-minimal-hover-light  focus:tds-color-background-warning-minimal-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-warning-dark tds-color-ring-warning-dark tds-color-background-warning-minimal-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-warning-minimal-hover-dark focus:tds-color-background-warning-minimal-dark;
                    }
                }
            }
            &.tds-button-error {
                &-theme-default {
                    @apply tds-color-text-error tds-color-ring-error tds-color-background-error-minimal;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-error-minimal-hover  focus:tds-color-background-error-minimal;
                    }
                }
                &-theme-light {
                    @apply tds-color-text-error-light tds-color-ring-error-light tds-color-background-error-minimal-light;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-error-minimal-hover-light  focus:tds-color-background-error-minimal-light;
                    }
                }
                &-theme-dark {
                    @apply tds-color-text-error-dark tds-color-ring-error-dark tds-color-background-error-minimal-dark;
                    &:not(.tds-button-loading) {
                        @apply hover:tds-color-background-error-minimal-hover-dark focus:tds-color-background-error-minimal-dark;
                    }
                }
            }
        }
    }
    &.tds-button-disabled {
        @apply cursor-not-allowed;
        &.tds-button {
            &-theme-default {
                @apply tds-color-background-disable tds-color-text-disable;
            }
            &-theme-light {
                @apply tds-color-background-disable-light tds-color-text-disable-light;
            }
            &-theme-dark {
                @apply tds-color-background-disable-dark tds-color-text-disable-dark;
            }
        }
    }
    i {
        @apply mr-[var(--tds-btn-margin-base)];
    }
}

.tds-button-type-outline {
    @apply focus:outline-none box-border  whitespace-nowrap border bg-transparent   relative tds-btn-text-base;
    &:not(.tds-button-disabled) {
        &:not(.tds-button-loading) {
            @apply cursor-pointer  focus:outline-none focus:ring-3;
            &.tds-button-background-style-default {
                &.tds-button-theme-default {
                    @apply tds-color-surface-low focus:tds-color-surface-low hover:tds-color-background-neutral-minimalist;
                }
                &.tds-button-theme-light {
                    @apply tds-color-surface-low-light focus:tds-color-surface-low-light hover:tds-color-background-neutral-minimalist-light;
                }
                &.tds-button-theme-dark {
                    @apply tds-color-surface-low-dark focus:tds-color-surface-low-dark hover:tds-color-background-neutral-minimalist-dark;
                }
            }
            &.tds-button-background-style-ghost {
                &.tds-button-theme-default {
                    @apply focus:tds-color-background-neutral-minimalist hover:tds-color-background-neutral-minimalist;
                }
                &.tds-button-theme-light {
                    @apply focus:tds-color-background-neutral-minimalist-light hover:tds-color-background-neutral-minimalist-light;
                }
                &.tds-button-theme-dark {
                    @apply focus:tds-color-background-neutral-minimalist-dark hover:tds-color-background-neutral-minimalist-dark;
                }
            }
            &.tds-button-background-style-minimal {
                &.tds-button-secondary {
                    &.tds-button-theme-default {
                        @apply tds-color-surface-low focus:tds-color-surface-low hover:tds-color-background-neutral-minimalist;
                    }
                    &.tds-button-theme-light {
                        @apply tds-color-surface-low-light focus:tds-color-surface-low-light hover:tds-color-background-neutral-minimalist-light;
                    }
                    &.tds-button-theme-dark {
                        @apply tds-color-surface-low-dark focus:tds-color-surface-low-dark hover:tds-color-background-neutral-minimalist-dark;
                    }
                }
            }
        }

        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-text-primary tds-color-ring-primary tds-color-border-primary;
                &:not(.tds-button-loading) {
                    @apply focus:tds-color-border-brand-primary;
                }
                i {
                    @apply tds-color-icon-secondary;
                }
            }
            &-theme-light {
                @apply tds-color-text-primary-light tds-color-ring-primary-light tds-color-border-primary-light;
                &:not(.tds-button-loading) {
                    @apply focus:tds-color-border-brand-primary-light;
                }
                i {
                    @apply tds-color-icon-secondary-light;
                }
            }
            &-theme-dark {
                @apply tds-color-text-primary-dark tds-color-ring-primary-dark tds-color-border-primary-dark;
                &:not(.tds-button-loading) {
                    @apply focus:tds-color-border-brand-primary-dark;
                }
                i {
                    @apply tds-color-icon-secondary-dark;
                }
            }
        }

        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-text-brand-primary tds-color-ring-primary tds-color-border-brand-primary;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-primary-minimal hover:tds-color-background-primary-minimal-hover focus:tds-color-background-primary-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-brand-primary-light tds-color-ring-primary-light tds-color-border-brand-primary-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-primary-minimal-light hover:tds-color-background-primary-minimal-hover-light focus:tds-color-background-primary-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-brand-primary-dark tds-color-ring-primary-dark tds-color-border-brand-primary-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-primary-minimal-dark hover:tds-color-background-primary-minimal-hover-dark focus:tds-color-background-primary-minimal-dark;
                    }
                }
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-text-success tds-color-ring-success tds-color-border-success;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-success-minimal hover:tds-color-background-success-minimal-hover focus:tds-color-background-success-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-success-light tds-color-ring-success-light tds-color-border-success-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-success-minimal-light hover:tds-color-background-success-minimal-hover-light focus:tds-color-background-success-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-success-dark tds-color-ring-success-dark tds-color-border-success-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-success-minimal-dark hover:tds-color-background-success-minimal-hover-dark focus:tds-color-background-success-minimal-dark;
                    }
                }
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-text-info tds-color-ring-info tds-color-border-info;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-info-minimal hover:tds-color-background-info-minimal-hover focus:tds-color-background-info-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-info-light tds-color-ring-info-light tds-color-border-info-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-info-minimal-light hover:tds-color-background-info-minimal-hover-light focus:tds-color-background-info-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-info-dark tds-color-ring-info-dark tds-color-border-info-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-info-minimal-dark hover:tds-color-background-info-minimal-hover-dark focus:tds-color-background-info-minimal-dark;
                    }
                }
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-text-warning tds-color-ring-warning tds-color-border-warning;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-warning-minimal hover:tds-color-background-warning-minimal-hover focus:tds-color-background-warning-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-warning-light tds-color-ring-warning-light tds-color-border-warning-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-warning-minimal-light hover:tds-color-background-warning-minimal-hover-light focus:tds-color-background-warning-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-warning-dark tds-color-ring-warning-dark tds-color-border-warning-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-warning-minimal-dark hover:tds-color-background-warning-minimal-hover-dark focus:tds-color-background-warning-minimal-dark;
                    }
                }
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-text-error tds-color-ring-error tds-color-border-error;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-error-minimal hover:tds-color-background-error-minimal-hover focus:tds-color-background-error-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-error-light tds-color-ring-error-light tds-color-border-error-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-error-minimal-light hover:tds-color-background-error-minimal-hover-light focus:tds-color-background-error-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-error-dark tds-color-ring-error-dark tds-color-border-error-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-error-minimal-dark hover:tds-color-background-error-minimal-hover-dark focus:tds-color-background-error-minimal-dark;
                    }
                }
            }
        }
    }

    &.tds-button-disabled {
        @apply cursor-not-allowed;
        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-text-disable tds-color-border-disable tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light tds-color-border-disable-light tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark tds-color-border-disable-dark tds-color-background-disable-dark;
            }
        }
        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-text-disable tds-color-border-disable tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light tds-color-border-disable-light tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark tds-color-border-disable-dark tds-color-background-disable-dark;
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-text-disable tds-color-border-disable tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light tds-color-border-disable-light tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark tds-color-border-disable-dark tds-color-background-disable-dark;
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-text-disable tds-color-border-disable tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light tds-color-border-disable-light tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark tds-color-border-disable-dark tds-color-background-disable-dark;
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-text-disable tds-color-border-disable tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light tds-color-border-disable-light tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark tds-color-border-disable-dark tds-color-background-disable-dark;
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-text-disable tds-color-border-disable tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light tds-color-border-disable-light tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark tds-color-border-disable-dark tds-color-background-disable-dark;
            }
        }
    }
    i {
        @apply mr-[var(--tds-btn-margin-base)];
    }
}

.tds-button-type-flat {
    &.tds-button {
        @apply focus:outline-none tds-btn-text-base box-border  focus:ring whitespace-nowrap border border-transparent relative;
        //  bg-white dark:bg-d-neutral-3-300;
    }
    &:not(.tds-button-disabled) {
        &:not(.tds-button-loading) {
            @apply cursor-pointer  focus:outline-none focus:ring;
        }
        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-text-brand-primary tds-color-ring-primary;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist;
                }
            }
            &-theme-light {
                @apply tds-color-text-brand-primary-light tds-color-ring-primary-light;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-light;
                }
            }
            &-theme-dark {
                @apply tds-color-text-brand-primary-dark tds-color-ring-primary-dark;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-dark;
                }
            }
        }
        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-text-primary tds-color-ring-primary;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist;
                }
            }
            &-theme-light {
                @apply tds-color-text-primary-light tds-color-ring-primary-light;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-light;
                }
            }
            &-theme-dark {
                @apply tds-color-text-primary-dark  tds-color-ring-primary-dark;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-dark;
                }
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-text-success tds-color-ring-success;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist;
                }
            }
            &-theme-light {
                @apply tds-color-text-success-light tds-color-ring-success-light;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-light;
                }
            }
            &-theme-dark {
                @apply tds-color-text-success-dark  tds-color-ring-success-dark;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-dark;
                }
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-text-info tds-color-ring-info;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist;
                }
            }
            &-theme-light {
                @apply tds-color-text-info-light tds-color-ring-info-light;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-light;
                }
            }
            &-theme-dark {
                @apply tds-color-text-info-dark  tds-color-ring-info-dark;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-dark;
                }
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-text-warning tds-color-ring-warning;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist;
                }
            }
            &-theme-light {
                @apply tds-color-text-warning-light tds-color-ring-warning-light;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-light;
                }
            }
            &-theme-dark {
                @apply tds-color-text-warning-dark  tds-color-ring-warning-dark;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-dark;
                }
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-text-error tds-color-ring-error;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist;
                }
            }
            &-theme-light {
                @apply tds-color-text-error-light tds-color-ring-error-light;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-light;
                }
            }
            &-theme-dark {
                @apply tds-color-text-error-dark  tds-color-ring-error-dark;
                &:not(.tds-button-loading) {
                    @apply hover:tds-color-background-neutral-minimalist-dark;
                }
            }
        }
    }
    &.tds-button-disabled {
        @apply cursor-not-allowed;
        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-text-disable  tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light  tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark  tds-color-background-disable-dark;
            }
        }
        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-text-disable  tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light  tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark  tds-color-background-disable-dark;
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-text-disable  tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light  tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark  tds-color-background-disable-dark;
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-text-disable  tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light  tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark  tds-color-background-disable-dark;
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-text-disable  tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light  tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark  tds-color-background-disable-dark;
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-text-disable  tds-color-background-disable;
            }
            &-theme-light {
                @apply tds-color-text-disable-light  tds-color-background-disable-light;
            }
            &-theme-dark {
                @apply tds-color-text-disable-dark  tds-color-background-disable-dark;
            }
        }
    }
}

.tds-button-type-icon,
.tds-button-type-outline-icon {
    &.tds-button {
        @apply focus:outline-none box-border focus:ring whitespace-nowrap border tds-btn-icon-size-base relative;
        &.tds-button-xs {
            @apply p-[var(--tds-btn-padding-icon-xs)] size-[--tds-btn-height-xs] tds-btn-border-radius-xs;
            * {
                @apply tds-btn-icon-size-xs;
            }
        }
        &.tds-button-sm {
            @apply p-[var(--tds-btn-padding-icon-xs)] size-[--tds-btn-height-s] tds-btn-border-radius-base;
        }
        &.tds-button-md {
            @apply p-[var(--tds-btn-padding-icon-m)] size-[--tds-btn-height-m] tds-btn-border-radius-base;
        }
        &.tds-button-lg {
            @apply p-[var(--tds-btn-padding-icon-l)] size-[--tds-btn-height-l] tds-btn-border-radius-base;
        }
        &.tds-button-xl {
            @apply p-[var(--tds-btn-padding-icon-xl)] size-[--tds-btn-height-xl] tds-btn-border-radius-base;
        }
        &.rounded-full {
            border-radius: 9999px !important;
        }
    }
}

.tds-button-type-icon {
    @apply box-border whitespace-nowrap border tds-btn-icon-size-base border-transparent;
    &:not(.tds-button-disabled) {
        &:not(.tds-button-loading) {
            @apply cursor-pointer  focus:outline-none focus:ring-3;
        }
        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-icon-secondary tds-color-ring-primary tds-color-background-neutral-minimalist hover:tds-color-border-primary hover:tds-color-background-neutral-minimal focus:tds-color-ring-primary focus:tds-color-border-brand-primary focus:tds-color-background-neutral-minimalist border-transparent;
            }
            &-theme-light {
                @apply tds-color-icon-secondary-light tds-color-ring-primary-light tds-color-background-neutral-minimalist-light border-transparent hover:tds-color-border-primary-light hover:tds-color-background-neutral-minimal-light focus:tds-color-ring-primary-light focus:tds-color-border-brand-primary-light focus:tds-color-background-neutral-minimalist-light;
            }
            &-theme-dark {
                @apply tds-color-icon-secondary-dark tds-color-ring-primary-dark tds-color-background-neutral-minimalist-dark border-transparent hover:tds-color-border-primary-dark hover:tds-color-background-neutral-minimal-dark focus:tds-color-ring-primary-dark focus:tds-color-border-brand-primary-dark focus:tds-color-surface-lowest-dark;
            }
        }

        &:not(.tds-button-background-style-minimal) {
            &.tds-button-primary {
                &-theme-default {
                    @apply tds-color-icon-white  tds-color-ring-primary  tds-color-background-primary-subtle hover:border-transparent hover:tds-color-background-primary-subtle-hover focus:tds-color-background-primary-subtle;
                }
                &-theme-light {
                    @apply tds-color-icon-white-light tds-color-ring-primary-light  tds-color-background-primary-subtle-light hover:border-transparent hover:tds-color-background-primary-subtle-hover-light focus:tds-color-background-primary-subtle-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-white-dark tds-color-ring-primary-dark tds-color-background-primary-subtle-dark hover:border-transparent hover:tds-color-background-primary-subtle-hover-dark focus:tds-color-background-primary-subtle-dark;
                }
            }
            &.tds-button-success {
                &-theme-default {
                    @apply tds-color-icon-white tds-color-ring-success tds-color-background-success-subtle tds-color-border-success hover:tds-color-background-success-subtle-hover  focus:tds-color-border-success focus:tds-color-background-success-subtle;
                }
                &-theme-light {
                    @apply tds-color-icon-white-light tds-color-ring-success-light tds-color-background-success-subtle-light tds-color-border-success-light hover:tds-color-background-success-subtle-hover-light hover:tds-color-border-success-light focus:tds-color-border-success-light focus:tds-color-background-success-subtle-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-white-dark tds-color-ring-success-dark tds-color-background-success-subtle-dark tds-color-border-success-dark hover:tds-color-background-success-subtle-hover-dark hover:tds-color-border-success-dark focus:tds-color-border-success-dark focus:tds-color-background-success-subtle-dark;
                }
            }
            &.tds-button-info {
                &-theme-default {
                    @apply tds-color-icon-white tds-color-ring-info tds-color-background-info-subtle tds-color-border-info hover:tds-color-background-info-subtle-hover hover:border-transparent focus:tds-color-border-info focus:tds-color-background-info-subtle;
                }
                &-theme-light {
                    @apply tds-color-icon-white-light tds-color-ring-info-light tds-color-background-info-subtle-light tds-color-border-info-light hover:tds-color-background-info-subtle-hover-light hover:border-transparent focus:tds-color-border-info-light focus:tds-color-background-info-subtle-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-white-dark tds-color-ring-info-dark tds-color-background-info-subtle-dark tds-color-border-info-dark hover:tds-color-background-info-subtle-hover-dark hover:border-transparent focus:tds-color-border-info-dark focus:tds-color-background-info-subtle-dark;
                }
            }
            &.tds-button-warning {
                &-theme-default {
                    @apply tds-color-icon-white tds-color-ring-warning tds-color-background-warning-subtle tds-color-border-warning hover:tds-color-background-warning-subtle-hover hover:border-transparent focus:tds-color-border-warning focus:tds-color-background-warning-subtle;
                }
                &-theme-light {
                    @apply tds-color-icon-white-light tds-color-ring-warning-light tds-color-background-warning-subtle-light tds-color-border-warning-light hover:tds-color-background-warning-subtle-hover-light hover:border-transparent focus:tds-color-border-warning-light focus:tds-color-background-warning-subtle-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-white-dark tds-color-ring-warning-dark tds-color-background-warning-subtle-dark tds-color-border-warning-dark hover:tds-color-background-warning-subtle-hover-dark hover:border-transparent focus:tds-color-border-warning-dark focus:tds-color-background-warning-subtle-dark;
                }
            }
            &.tds-button-error {
                &-theme-default {
                    @apply tds-color-icon-white tds-color-ring-error tds-color-background-error-subtle tds-color-border-error hover:tds-color-background-error-subtle-hover hover:border-transparent focus:tds-color-border-error focus:tds-color-background-error-subtle;
                }
                &-theme-light {
                    @apply tds-color-icon-white-light tds-color-ring-error-light tds-color-background-error-subtle-light tds-color-border-error-light hover:tds-color-background-error-subtle-hover-light hover:border-transparent focus:tds-color-border-error-light focus:tds-color-background-error-subtle-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-white-dark tds-color-ring-error-dark tds-color-background-error-subtle-dark tds-color-border-error-dark hover:tds-color-background-error-subtle-hover-dark hover:border-transparent focus:tds-color-border-error-dark focus:tds-color-background-error-subtle-dark;
                }
            }
        }
        &.tds-button-background-style-minimal {
            &.tds-button-primary {
                &-theme-default {
                    @apply tds-color-icon-brand-primary  tds-color-ring-primary  tds-color-background-primary-minimal hover:tds-color-background-primary-minimal-hover  focus:tds-color-background-primary-minimal;
                }
                &-theme-light {
                    @apply tds-color-icon-brand-primary-light  tds-color-ring-primary-light tds-color-background-primary-minimal-light  hover:tds-color-background-primary-minimal-hover-light focus:tds-color-background-primary-minimal-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-brand-primary-dark  tds-color-ring-primary-dark tds-color-background-primary-minimal-dark  hover:tds-color-background-primary-minimal-hover-dark focus:tds-color-background-primary-minimal-dark;
                }
            }
            &.tds-button-success {
                &-theme-default {
                    @apply tds-color-icon-success tds-color-ring-success tds-color-background-success-minimal hover:tds-color-background-success-minimal-hover   focus:tds-color-background-success-minimal;
                }
                &-theme-light {
                    @apply tds-color-icon-success-light tds-color-ring-success-light tds-color-background-success-minimal-light  hover:tds-color-background-success-minimal-hover-light  focus:tds-color-background-success-minimal-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-success-dark tds-color-ring-success-dark tds-color-background-success-minimal-dark  hover:tds-color-background-success-minimal-hover-dark  focus:tds-color-background-success-minimal-dark;
                }
            }
            &.tds-button-info {
                &-theme-default {
                    @apply tds-color-icon-info tds-color-ring-info tds-color-background-info-minimal hover:tds-color-background-info-minimal-hover   focus:tds-color-background-info-minimal;
                }
                &-theme-light {
                    @apply tds-color-icon-info-light tds-color-ring-info-light tds-color-background-info-minimal-light  hover:tds-color-background-info-minimal-hover-light  focus:tds-color-background-info-minimal-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-info-dark tds-color-ring-info-dark tds-color-background-info-minimal-dark  hover:tds-color-background-info-minimal-hover-dark  focus:tds-color-background-info-minimal-dark;
                }
            }
            &.tds-button-warning {
                &-theme-default {
                    @apply tds-color-icon-warning tds-color-ring-warning tds-color-background-warning-minimal hover:tds-color-background-warning-minimal-hover   focus:tds-color-background-warning-minimal;
                }
                &-theme-light {
                    @apply tds-color-icon-warning-light tds-color-ring-warning-light tds-color-background-warning-minimal-light  hover:tds-color-background-warning-minimal-hover-light  focus:tds-color-background-warning-minimal-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-warning-dark tds-color-ring-warning-dark tds-color-background-warning-minimal-dark  hover:tds-color-background-warning-minimal-hover-dark  focus:tds-color-background-warning-minimal-dark;
                }
            }
            &.tds-button-error {
                &-theme-default {
                    @apply tds-color-icon-error tds-color-ring-error tds-color-background-error-minimal hover:tds-color-background-error-minimal-hover focus:tds-color-background-error-minimal;
                }
                &-theme-light {
                    @apply tds-color-icon-error-light tds-color-ring-error-light tds-color-background-error-minimal-light  hover:tds-color-background-error-minimal-hover-light  focus:tds-color-background-error-minimal-light;
                }
                &-theme-dark {
                    @apply tds-color-icon-error-dark tds-color-ring-error-dark tds-color-background-error-minimal-dark  hover:tds-color-background-error-minimal-hover-dark focus:tds-color-background-error-minimal-dark;
                }
            }
        }
    }
    &.tds-button-disabled {
        @apply cursor-not-allowed;
        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-background-disable tds-color-icon-disable tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-background-disable-light tds-color-icon-disable-light tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-background-disable-dark tds-color-icon-disable-dark tds-color-border-disable-dark;
            }
        }
        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-background-disable tds-color-icon-disable tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-background-disable-light tds-color-icon-disable-light tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-background-disable-dark tds-color-icon-disable-dark tds-color-border-disable-dark;
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-background-disable tds-color-icon-disable tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-background-disable-light tds-color-icon-disable-light tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-background-disable-dark tds-color-icon-disable-dark tds-color-border-disable-dark;
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-background-disable tds-color-icon-disable tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-background-disable-light tds-color-icon-disable-light tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-background-disable-dark tds-color-icon-disable-dark tds-color-border-disable-dark;
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-background-disable tds-color-icon-disable tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-background-disable-light tds-color-icon-disable-light tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-background-disable-dark tds-color-icon-disable-dark tds-color-border-disable-dark;
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-background-disable tds-color-icon-disable tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-background-disable-light tds-color-icon-disable-light tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-background-disable-dark tds-color-icon-disable-dark tds-color-border-disable-dark;
            }
        }
    }
}

.tds-button-type-outline-icon {
    @apply focus:outline-none box-border  whitespace-nowrap border bg-transparent relative tds-btn-icon-size-base;
    &:not(.tds-button-disabled) {
        &:not(.tds-button-loading) {
            @apply cursor-pointer  focus:outline-none focus:ring-3;
            &.tds-button-background-style-default {
                &.tds-button-theme-default {
                    @apply tds-color-surface-low focus:tds-color-surface-low hover:tds-color-background-neutral-minimalist;
                }
                &.tds-button-theme-light {
                    @apply tds-color-surface-low-light focus:tds-color-surface-low-light hover:tds-color-background-neutral-minimalist-light;
                }
                &.tds-button-theme-dark {
                    @apply tds-color-surface-low-dark focus:tds-color-surface-low-dark hover:tds-color-background-neutral-minimalist-dark;
                }
            }
            &.tds-button-background-style-ghost {
                &.tds-button-theme-default {
                    @apply focus:tds-color-background-neutral-minimalist hover:tds-color-background-neutral-minimalist;
                }
                &.tds-button-theme-light {
                    @apply focus:tds-color-background-neutral-minimalist-light hover:tds-color-background-neutral-minimalist-light;
                }
                &.tds-button-theme-dark {
                    @apply focus:tds-color-background-neutral-minimalist-dark hover:tds-color-background-neutral-minimalist-dark;
                }
            }
            &.tds-button-background-style-minimal {
                &.tds-button-secondary {
                    &.tds-button-theme-default {
                        @apply tds-color-surface-low focus:tds-color-surface-low hover:tds-color-background-neutral-minimalist;
                    }
                    &.tds-button-theme-light {
                        @apply tds-color-surface-low-light focus:tds-color-surface-low-light hover:tds-color-background-neutral-minimalist-light;
                    }
                    &.tds-button-theme-dark {
                        @apply tds-color-surface-low-dark focus:tds-color-surface-low-dark hover:tds-color-background-neutral-minimalist-dark;
                    }
                }
            }
        }

        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-icon-secondary tds-color-ring-primary tds-color-border-primary;
                &:not(.tds-button-loading) {
                    @apply focus:tds-color-border-brand-primary;
                }
            }
            &-theme-light {
                @apply tds-color-icon-secondary-light tds-color-ring-primary-light tds-color-border-primary-light;
                &:not(.tds-button-loading) {
                    @apply focus:tds-color-border-brand-primary-light;
                }
            }
            &-theme-dark {
                @apply tds-color-icon-secondary-dark tds-color-ring-primary-dark tds-color-border-primary-dark;
                &:not(.tds-button-loading) {
                    @apply focus:tds-color-border-brand-primary-dark;
                }
            }
        }
        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-text-brand-primary tds-color-ring-primary tds-color-border-brand-primary;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-primary-minimal hover:tds-color-background-primary-minimal-hover focus:tds-color-background-primary-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-brand-primary-light tds-color-ring-primary-light tds-color-border-brand-primary-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-primary-minimal-light hover:tds-color-background-primary-minimal-hover-light focus:tds-color-background-primary-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-brand-primary-dark tds-color-ring-primary-dark tds-color-border-brand-primary-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-primary-minimal-dark hover:tds-color-background-primary-minimal-hover-dark focus:tds-color-background-primary-minimal-dark;
                    }
                }
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-text-success tds-color-ring-success tds-color-border-success;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-success-minimal hover:tds-color-background-success-minimal-hover focus:tds-color-background-success-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-success-light tds-color-ring-success-light tds-color-border-success-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-success-minimal-light hover:tds-color-background-success-minimal-hover-light focus:tds-color-background-success-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-success-dark tds-color-ring-success-dark tds-color-border-success-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-success-minimal-dark hover:tds-color-background-success-minimal-hover-dark focus:tds-color-background-success-minimal-dark;
                    }
                }
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-text-info tds-color-ring-info tds-color-border-info;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-info-minimal hover:tds-color-background-info-minimal-hover focus:tds-color-background-info-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-info-light tds-color-ring-info-light tds-color-border-info-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-info-minimal-light hover:tds-color-background-info-minimal-hover-light focus:tds-color-background-info-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-info-dark tds-color-ring-info-dark tds-color-border-info-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-info-minimal-dark hover:tds-color-background-info-minimal-hover-dark focus:tds-color-background-info-minimal-dark;
                    }
                }
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-text-warning tds-color-ring-warning tds-color-border-warning;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-warning-minimal hover:tds-color-background-warning-minimal-hover focus:tds-color-background-warning-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-warning-light tds-color-ring-warning-light tds-color-border-warning-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-warning-minimal-light hover:tds-color-background-warning-minimal-hover-light focus:tds-color-background-warning-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-warning-dark tds-color-ring-warning-dark tds-color-border-warning-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-warning-minimal-dark hover:tds-color-background-warning-minimal-hover-dark focus:tds-color-background-warning-minimal-dark;
                    }
                }
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-text-error tds-color-ring-error tds-color-border-error;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-error-minimal hover:tds-color-background-error-minimal-hover focus:tds-color-background-error-minimal;
                    }
                }
            }
            &-theme-light {
                @apply tds-color-text-error-light tds-color-ring-error-light tds-color-border-error-light;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-error-minimal-light hover:tds-color-background-error-minimal-hover-light focus:tds-color-background-error-minimal-light;
                    }
                }
            }
            &-theme-dark {
                @apply tds-color-text-error-dark tds-color-ring-error-dark tds-color-border-error-dark;
                &:not(.tds-button-loading) {
                    &.tds-button-background-style-minimal {
                        @apply tds-color-background-error-minimal-dark hover:tds-color-background-error-minimal-hover-dark focus:tds-color-background-error-minimal-dark;
                    }
                }
            }
        }
    }
    &.tds-button-disabled {
        @apply cursor-not-allowed;
        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-icon-disable  tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light  tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark  tds-color-border-disable-dark;
            }
        }
        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-icon-disable  tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light  tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark  tds-color-border-disable-dark;
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-icon-disable  tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light  tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark  tds-color-border-disable-dark;
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-icon-disable  tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light  tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark  tds-color-border-disable-dark;
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-icon-disable  tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light  tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark  tds-color-border-disable-dark;
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-icon-disable  tds-color-border-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light  tds-color-border-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark  tds-color-border-disable-dark;
            }
        }
    }
}

.tds-button-type-flat-icon {
    &.tds-button {
        @apply focus:outline-none box-border  focus:ring whitespace-nowrap tds-btn-icon-size-base relative border border-transparent;
        &.tds-button-xs {
            @apply p-[var(--tds-btn-padding-icon-xs)] h-[--tds-btn-height-xs] tds-btn-border-radius-xs;
            * {
                @apply tds-btn-icon-size-xs;
            }
        }
        &.tds-button-sm {
            @apply p-[var(--tds-btn-padding-icon-xs)] h-[--tds-btn-height-s] tds-btn-border-radius-base;
        }
        &.tds-button-md {
            @apply p-[var(--tds-btn-padding-icon-m)] h-[--tds-btn-height-m] tds-btn-border-radius-base;
        }
        &.tds-button-lg {
            @apply p-[var(--tds-btn-padding-icon-l)] h-[--tds-btn-height-l] tds-btn-border-radius-base;
        }
        &.tds-button-xl {
            @apply p-[var(--tds-btn-padding-icon-xl)] h-[--tds-btn-height-xl] tds-btn-border-radius-base;
        }
    }
    &:not(.tds-button-disabled) {
        &:not(.tds-button-loading) {
            @apply cursor-pointer  focus:outline-none focus:ring;
            &.tds-button-primary,
            &.tds-button-secondary,
            &.tds-button-success,
            &.tds-button-info,
            &.tds-button-warning,
            &.tds-button-error {
                &-theme-default {
                    @apply hover:tds-color-background-neutral-minimalist;
                }
                &-theme-light {
                    @apply hover:tds-color-background-neutral-minimalist-light;
                }
                &-theme-dark {
                    @apply hover:tds-color-background-neutral-minimalist-dark;
                }
            }
        }
        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-icon-brand-primary tds-color-ring-primary;
            }
            &-theme-light {
                @apply tds-color-icon-brand-primary-light tds-color-ring-primary-light;
            }
            &-theme-dark {
                @apply tds-color-icon-brand-primary-dark tds-color-ring-primary-dark;
            }
        }
        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-icon-secondary tds-color-ring-primary;
            }
            &-theme-light {
                @apply tds-color-icon-secondary-light tds-color-ring-primary-light;
            }
            &-theme-dark {
                @apply tds-color-icon-secondary-dark  tds-color-ring-primary-dark;
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-icon-success tds-color-ring-success;
            }
            &-theme-light {
                @apply tds-color-icon-success-light tds-color-ring-success-light;
            }
            &-theme-dark {
                @apply tds-color-icon-success-dark  tds-color-ring-success-dark;
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-icon-info tds-color-ring-info;
            }
            &-theme-light {
                @apply tds-color-icon-info-light tds-color-ring-info-light;
            }
            &-theme-dark {
                @apply tds-color-icon-info-dark  tds-color-ring-info-dark;
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-icon-warning tds-color-ring-warning;
            }
            &-theme-light {
                @apply tds-color-icon-warning-light tds-color-ring-warning-light;
            }
            &-theme-dark {
                @apply tds-color-icon-warning-dark  tds-color-ring-warning-dark;
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-icon-error tds-color-ring-error;
            }
            &-theme-light {
                @apply tds-color-icon-error-light tds-color-ring-error-light;
            }
            &-theme-dark {
                @apply tds-color-icon-error-dark  tds-color-ring-error-dark;
            }
        }
    }
    &.tds-button-disabled {
        @apply cursor-not-allowed;
        &.tds-button-primary {
            &-theme-default {
                @apply tds-color-icon-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark;
            }
        }
        &.tds-button-secondary {
            &-theme-default {
                @apply tds-color-icon-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark;
            }
        }
        &.tds-button-success {
            &-theme-default {
                @apply tds-color-icon-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark;
            }
        }
        &.tds-button-info {
            &-theme-default {
                @apply tds-color-icon-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark;
            }
        }
        &.tds-button-warning {
            &-theme-default {
                @apply tds-color-icon-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark;
            }
        }
        &.tds-button-error {
            &-theme-default {
                @apply tds-color-icon-disable;
            }
            &-theme-light {
                @apply tds-color-icon-disable-light;
            }
            &-theme-dark {
                @apply tds-color-icon-disable-dark;
            }
        }
    }
}

tds-button-group {
    .tds-button:first-child:not(:last-child) {
        @apply rounded-r-none border-r-0;
    }
    .tds-button:last-child:not(:first-child) {
        @apply rounded-l-none border-l-0;
    }
    .tds-button:not(:first-child):not(:last-child) {
        @apply rounded-none border-l-0 border-r-0;
    }
}

a.tds-button-close {
    @apply inline-block;
}
.tds-button-close {
    @apply focus:outline-none box-border  whitespace-nowrap  border-0 tds-btn-icon-size-xs relative tds-color-icon-secondary hover:tds-color-background-neutral-minimalist inline-flex items-center justify-center;
    > i {
        @apply tds-btn-icon-size-xs;
    }
    &-xs {
        @apply p-0 size-[--tds-btn-size-btn-close-s] tds-btn-border-radius-xs;
    }
    &-sm {
        @apply p-0  size-[--tds-btn-size-btn-close-s] tds-btn-border-radius-xs;
    }
    &-md {
        @apply p-[var(--tds-btn-padding-btn-close-m)] size-[--tds-btn-size-btn-close-m] tds-btn-border-radius-base;
    }
    &-lg {
        @apply p-[var(--tds-btn-padding-btn-close-l)] size-[--tds-btn-size-btn-close-l]  tds-btn-border-radius-base;
    }
    &-xl {
        @apply p-[var(--tds-btn-padding-btn-close-l)] size-[--tds-btn-size-btn-close-xl] tds-btn-border-radius-base;
    }
    &-type-base {
        @apply rounded-full tds-color-background-neutral-minimalist hover:tds-color-background-neutral-minimal;
    }
}

.tds-button-text {
    @apply focus:outline-none box-border  border-0  relative;
    .tds-button-text-suffix,
    .tds-button-text-prefix {
        @apply inline-flex;
    }

    &-size {
        &-sm {
            @apply tds-caption-1 tds-font-weight-semibold;
            .tds-button-text-suffix,
            .tds-button-text-prefix {
                @apply tds-icon-size-s;
            }
            .tds-button-text-suffix {
                @apply ml-0.5;
            }
            .tds-button-text-prefix {
                @apply mr-0.5;
            }
        }
        &-md {
            @apply tds-body-2 tds-font-weight-semibold;
            .tds-button-text-suffix,
            .tds-button-text-prefix {
                @apply tds-icon-size-m;
            }
            .tds-button-text-suffix {
                @apply ml-1;
            }
            .tds-button-text-prefix {
                @apply mr-1;
            }
        }
    }
    &.tds-button-text-disabled {
        @apply cursor-not-allowed;
        &.tds-button-text-theme-default {
            @apply tds-color-text-disable;
        }

        &.tds-button-text-theme-light {
            @apply tds-color-text-disable-light;
        }
        &.tds-button-text-theme-dark {
            @apply tds-color-text-disable-dark;
        }
    }
    &:not(.tds-button-text-disabled) {
        &:hover {
            .tds-button-text-content {
                @apply underline underline-offset-4;
            }
        }
        &.tds-button-text-primary {
            &.tds-button-text-theme-default {
                @apply tds-color-text-brand-primary;
            }

            &.tds-button-text-theme-light {
                @apply tds-color-text-brand-primary-light;
            }
            &.tds-button-text-theme-dark {
                @apply tds-color-text-brand-primary-dark;
            }
        }
        &.tds-button-text-secondary {
            &.tds-button-text-theme-default {
                @apply tds-color-text-primary;
                .tds-button-text-suffix,
                .tds-button-text-prefix {
                    @apply tds-color-icon-secondary;
                }
            }

            &.tds-button-text-theme-light {
                @apply tds-color-text-primary-light;
                .tds-button-text-suffix,
                .tds-button-text-prefix {
                    @apply tds-color-icon-secondary-light;
                }
            }
            &.tds-button-text-theme-dark {
                @apply tds-color-text-primary-dark;
                .tds-button-text-suffix,
                .tds-button-text-prefix {
                    @apply tds-color-icon-secondary-dark;
                }
            }
        }
        &.tds-button-text-info {
            &.tds-button-text-theme-default {
                @apply tds-color-text-info;
            }

            &.tds-button-text-theme-light {
                @apply tds-color-text-info-light;
            }
            &.tds-button-text-theme-dark {
                @apply tds-color-text-info-dark;
            }
        }
        &.tds-button-text-success {
            &.tds-button-text-theme-default {
                @apply tds-color-text-success;
            }

            &.tds-button-text-theme-light {
                @apply tds-color-text-success-light;
            }
            &.tds-button-text-theme-dark {
                @apply tds-color-text-success-dark;
            }
        }
        &.tds-button-text-warning {
            &.tds-button-text-theme-default {
                @apply tds-color-text-warning;
            }

            &.tds-button-text-theme-light {
                @apply tds-color-text-warning-light;
            }
            &.tds-button-text-theme-dark {
                @apply tds-color-text-warning-dark;
            }
        }
        &.tds-button-text-error {
            &.tds-button-text-theme-default {
                @apply tds-color-text-error;
            }

            &.tds-button-text-theme-light {
                @apply tds-color-text-error-light;
            }
            &.tds-button-text-theme-dark {
                @apply tds-color-text-error-dark;
            }
        }
    }
}
