@layer utilities {
    .tds-input {
        @apply tds-input-text-base  shadow-none  text-current  m-0 w-full outline-none  box-border  border-tds-border-width-s  tds-input-border-radius   focus:ring ;
        &.tds-input-sm {
            @apply py-[--tds-input-padding-s] px-[--tds-input-padding-horizontal-s] ;
        }
        &.tds-input-md {
            @apply py-[--tds-input-padding-m] px-[--tds-input-padding-horizontal-m];
        }
        &.tds-input-lg {
            @apply py-[--tds-input-padding-l] px-[--tds-input-padding-horizontal-l];
        }
        &.tds-input-xl {
            @apply py-[--tds-input-padding-xl] px-[--tds-input-padding-horizontal-xl];
        }
        &.tds-input-error {
            @apply  ring-0 dark:ring-0 ;
        }
        &.tds-input-warning {
            @apply ring-0 dark:ring-0 ;
        }
        &.tds-input-borderless {
            @apply border-0;
        }
        &.tds-input-disabled {
            @apply cursor-not-allowed ;
        }
        &.tds-input-theme-default {
            @apply tds-color-surface-lowest placeholder:tds-color-text-placeholder tds-color-border-primary  focus:tds-color-ring-primary focus:tds-color-border-brand-primary;
            &.tds-input-error {
                @apply tds-color-border-error   focus:tds-color-border-error;
            }
            &.tds-input-warning {
                @apply tds-color-border-warning  focus:tds-color-border-warning;
            }
            &.tds-input-disabled {
                @apply tds-color-background-disable  tds-color-border-disable tds-color-text-disable;
            }
        }
        &.tds-input-theme-light {
            @apply tds-color-surface-lowest-light placeholder:tds-color-text-placeholder-light tds-color-border-primary-light  focus:tds-color-ring-primary-light focus:tds-color-border-brand-primary-light;
            &.tds-input-error {
                @apply tds-color-border-error-light   focus:tds-color-border-error-light;
            }
            &.tds-input-warning {
                @apply tds-color-border-warning-light  focus:tds-color-border-warning-light;
            }
            &.tds-input-disabled {
                @apply tds-color-background-disable-light  tds-color-border-disable-light tds-color-text-disable-light;
            }
        }
        &.tds-input-theme-dark {
            @apply tds-color-surface-lowest-dark placeholder:tds-color-text-placeholder-dark tds-color-border-primary-dark  focus:tds-color-ring-primary-dark focus:tds-color-border-brand-primary-dark;
            &.tds-input-error {
                @apply tds-color-border-error-dark   focus:tds-color-border-error-dark;
            }
            &.tds-input-warning {
                @apply tds-color-border-warning-dark  focus:tds-color-border-warning-dark;
            }
            &.tds-input-disabled {
                @apply tds-color-background-disable-dark  tds-color-border-disable-dark tds-color-text-disable-dark;
            }
        }
    }
}
