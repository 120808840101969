.tds-transfer-customize-list .tds-transfer-list {
    flex: 1 1 50%;
    width: auto;
    height: auto;
    min-height: 200px;
}

.tds-transfer-customize-list .tds-table-wrapper .tds-table-small {
    border: 0;
    border-radius: 0;
}

.tds-transfer-customize-list .tds-table-wrapper .tds-table-small .tds-table-selection-column {
    width: 40px;
    min-width: 40px;
}

.tds-transfer-customize-list
    .tds-table-wrapper
    .tds-table-small
    > .tds-table-content
    > .tds-table-body
    > table
    > .tds-table-thead
    > tr
    > th {
    background: #fafafa;
}

.tds-transfer-customize-list .tds-table-wrapper .tds-table-small > .tds-table-content .tds-table-row:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.tds-transfer-customize-list .tds-table-wrapper .tds-table-small .tds-table-body {
    margin: 0;
}

.tds-transfer-customize-list .tds-table-wrapper .tds-table-pagination.tds-pagination {
    margin: 16px 0 4px;
}

.tds-transfer-customize-list .tds-input[disabled] {
    background-color: transparent;
}

.tds-transfer-status-error .tds-transfer-list {
    border-color: var(--tds-error-color);
}

.tds-transfer-status-error .tds-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9;
}

.tds-transfer-status-error .tds-transfer-list-search:not([disabled]):hover {
    border-color: var(--tds-primary-5);
    border-right-width: 1px;
}

.tds-input-rtl .tds-transfer-status-error .tds-transfer-list-search:not([disabled]):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}

.tds-transfer-status-error .tds-transfer-list-search:not([disabled]):focus {
    border-color: var(--tds-primary-color-hover);
    box-shadow: 0 0 0 2px var(--tds-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
}

.tds-input-rtl .tds-transfer-status-error .tds-transfer-list-search:not([disabled]):focus {
    border-right-width: 0;
    border-left-width: 1px !important;
}

.tds-transfer-status-warning .tds-transfer-list {
    border-color: var(--tds-warning-color);
}

.tds-transfer-status-warning .tds-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9;
}

.tds-transfer-status-warning .tds-transfer-list-search:not([disabled]):hover {
    border-color: var(--tds-primary-5);
    border-right-width: 1px;
}

.tds-input-rtl .tds-transfer-status-warning .tds-transfer-list-search:not([disabled]):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
}

.tds-transfer-status-warning .tds-transfer-list-search:not([disabled]):focus {
    border-color: var(--tds-primary-color-hover);
    box-shadow: 0 0 0 2px var(--tds-primary-color-outline);
    border-right-width: 1px;
    outline: 0;
}

.tds-input-rtl .tds-transfer-status-warning .tds-transfer-list-search:not([disabled]):focus {
    border-right-width: 0;
    border-left-width: 1px !important;
}

.tds-transfer {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1.66667;
    position: relative;
    display: flex;
    align-items: stretch;
}

.tds-transfer-disabled .tds-transfer-list {
    background: #f5f5f5;
}

.tds-transfer-list {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 200px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.tds-transfer-list-with-pagination {
    width: 250px;
    height: auto;
}

.tds-transfer-list-search .anticon-search {
    color: rgba(0, 0, 0, 0.25);
}

.tds-transfer-list-header {
    display: flex;
    flex: none;
    align-items: center;
    height: 36px;
    padding: 7px 8px 8px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px 2px 0 0;
}

.tds-transfer-list-header > :not(:last-child) {
    margin-right: 4px;
}

.tds-transfer-list-header > * {
    flex: none;
}

.tds-transfer-list-header-title {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tds-transfer-list-header-dropdown {
    font-size: 10px;
    transform: translateY(10%);
    cursor: pointer;
}

.tds-transfer-list-header-dropdown[disabled] {
    cursor: not-allowed;
}

.tds-transfer-list-body {
    display: flex;
    flex: auto;
    flex-direction: column;
    overflow: hidden;
    font-size: 12px;
}

.tds-transfer-list-body-search-wrapper {
    position: relative;
    flex: none;
    padding: 8px;
}

.tds-transfer-list-content {
    flex: auto;
    margin: 0;
    padding: 0;
    overflow: auto;
    list-style: none;
}

.tds-transfer-list-content-item {
    display: flex;
    align-items: center;
    min-height: 28px;
    padding: 3px 8px;
    line-height: 22px;
    transition: all 0.3s;
}

.tds-transfer-list-content-item > :not(:last-child) {
    margin-right: 8px;
}

.tds-transfer-list-content-item > * {
    flex: none;
}

.tds-transfer-list-content-item-text {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tds-transfer-list-content-item-remove {
    position: relative;
    color: #d9d9d9;
    cursor: pointer;
    transition: all 0.3s;
}

.tds-transfer-list-content-item-remove:hover {
    color: var(--tds-primary-color-hover);
}

.tds-transfer-list-content-item-remove::after {
    position: absolute;
    top: -3px;
    right: -50%;
    bottom: -3px;
    left: -50%;
    content: "";
}

.tds-transfer-list-content-item:not(.tds-transfer-list-content-item-disabled):hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.tds-transfer-list-content-item:not(
        .tds-transfer-list-content-item-disabled
    ).tds-transfer-list-content-item-checked:hover {
    background-color: var(--tds-primary-color-active-deprecated-d-02);
}

.tds-transfer-list-content-show-remove
    .tds-transfer-list-content-item:not(.tds-transfer-list-content-item-disabled):hover {
    background: 0 0;
    cursor: default;
}

.tds-transfer-list-content-item-checked {
    background-color: var(--tds-primary-1);
}

.tds-transfer-list-content-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.tds-transfer-list-pagination {
    padding: 4px 0;
    text-align: right;
}

.tds-transfer-list-body-not-found {
    flex: none;
    width: 100%;
    margin: auto 0;
    color: rgba(0, 0, 0, 0.25);
    text-align: center;
}

.tds-transfer-rtl .tds-transfer-list-header-title,
.tds-transfer-rtl .tds-transfer-list-pagination {
    text-align: left;
}

.tds-transfer-operation {
    display: flex;
    flex: none;
    flex-direction: column;
    align-self: center;
    margin: 0 8px;
}

.tds-transfer-operation .tds-button {
    display: block;
    .tds-button-prefix,
    .tds-button-suffix {
        @apply m-0;
    }
}

.tds-transfer-operation .tds-button:first-child {
    margin-bottom: 4px;
}

.tds-transfer-operation .tds-button .anticon {
    font-size: 12px;
}

.tds-transfer .tds-empty-image {
    max-height: -4px;
}

.tds-transfer-rtl .tds-transfer-list-search {
    padding-right: 8px;
    padding-left: 24px;
}

.tds-transfer-rtl .tds-transfer-list-search-action {
    right: auto;
    left: 12px;
}

.tds-transfer-rtl .tds-transfer-list-footer,
.tds-transfer-rtl .tds-transfer-list-header {
    right: 0;
    left: auto;
}

.tds-transfer-rtl .tds-transfer-list-header > :not(:last-child) {
    margin-right: 0;
    margin-left: 4px;
}

.tds-transfer-rtl .tds-transfer-list-content-item > :not(:last-child) {
    margin-right: 0;
    margin-left: 8px;
}
