:root{
        --tds-notification-padding:var(--tds-spacing-9,16px);
--tds-notification-margin-left:var(--tds-spacing-9,16px);
--tds-notification-margin-top-s:var(--tds-spacing-5,8px);
--tds-notification-margin-top-m:var(--tds-spacing-9,16px);
--tds-notification-margin-outside-horizontal:var(--tds-spacing-9,16px);
--tds-notification-width:424px;

    }
    
            
@layer utilities{

            
.tds-notification-shadow
{
                            @apply shadow-tds-shadow-xl-light dark:shadow-tds-shadow-xl-dark;

                        }                
                        
.tds-notification-shadow-light
{
                            @apply shadow-tds-shadow-xl-light;

                        }
                        
.tds-notification-shadow-dark
{
                            @apply shadow-tds-shadow-xl-dark;

                        }
                        
.tds-notification-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }
.tds-notification-icon-size
{
                        @apply  text-tds-font-size-7 leading-tds-line-height-5 ;

                    }
.tds-notification-title
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-5 font-tds-font-family-body tds-font-weight-semibold ;

                    }
.tds-notification-text-default
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular ;

                    }

        }
            