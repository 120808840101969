html {
  .tds-custom-scroll,
  .tds-panel-scroll {
    --tdsScrollbarBG: transparent;
    --tdsThumbBG: rgba(var(--tds-color-neutral-200-light, 219, 224, 230), 1);
    // &:hover{
    //   --tdsScrollbarBG :rgba(var(--tds-color-neutral-50-light,242,245,248), 1)
    // }
  }
}

html .dark {
  .tds-custom-scroll,
  .tds-panel-scroll {
    --tdsScrollbarBG: transparent;
    --tdsThumbBG: rgba(var(--tds-color-neutral-200-dark, 58, 64, 75), 1);
    // &:hover{
    //   --tdsScrollbarBG :rgba(var(--tds-color-neutral-50-dark,34,38,45), 1)
    // }
  }
}
@layer utilities {
  .tds-custom-scroll {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    // scrollbar-width: thin;
    // scrollbar-color: var(--tdsThumbBG) var(--tdsScrollbarBG);
    &::-webkit-scrollbar-track {
      background: var(--tdsScrollbarBG);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--tdsThumbBG);
      border-radius: 6px;
      border: 0px solid var(--tdsScrollbarBG);
      height: 50%;
    }
    &::-webkit-scrollbar-corner {
      background: var(--tdsScrollbarBG);
    }
  }
  .tds-panel-scroll {
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--tdsThumbBG);
      border-radius: 6px;
      border: 0px solid var(--tdsScrollbarBG);
      height: 50%;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
}
body:not(.tds-ios),
body:not(.tds-disabled-custom-scroll) {
  @apply tds-custom-scroll;
}

.tds-select-panel {
  .cdk-virtual-scroll-content-wrapper {
    width: 100%;
  }
}
/* Modern browsers with `scrollbar-*` support */
@supports (scrollbar-width: auto) and (not selector(::-webkit-scrollbar)) {
  .tds-custom-scroll,
  .tds-panel-scroll {
    scrollbar-width: thin;
    scrollbar-color: var(--tdsThumbBG) var(--tdsScrollbarBG);
  }
}
//table
.tds-table-hide-scrollbar {
  &.tds-custom-scroll {
    --tdsScrollbarBG: rgba(var(--tds-color-neutral-50-light,242,245,248), 1);
  }
}
.dark {
  .tds-table-hide-scrollbar {
    &.tds-custom-scroll {
      --tdsScrollbarBG: rgba(var(--tds-color-neutral-50-dark,34,38,45), 1);
    }
  }
}
