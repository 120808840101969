:root{
        --tds-page-header-padding-s:var(--tds-spacing-4,6px);
--tds-page-header-padding-m:var(--tds-spacing-5,8px);
--tds-page-header-padding-l:var(--tds-spacing-6,10px);
--tds-page-header-padding-xl:var(--tds-spacing-7,12px);
--tds-page-header-margin-s:var(--tds-spacing-3,4px);
--tds-page-header-margin-m:var(--tds-spacing-5,8px);

    }
    
            
            