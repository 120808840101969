tds-alert {
    @apply box-border tds-body-2 tds-font-weight-regular;
    .tds-alert {
        @apply p-[var(--tds-alert-padding)] flex w-full relative;
        .tds-alert-content {
            @apply flex;
        }
        .tds-alert-close-icon {
            @apply absolute right-3 cursor-pointer break-all;
        }
        .tds-alert-icon {
            @apply mr-[var(--tds-alert-margin-s)] flex;
            .tds-alert-text-icon {
                @apply tds-icon-size-l;
            }
        }

        .tds-alert-footer {
            @apply pt-[var(--tds-alert-margin-top-m)] tds-color-text-primary break-all;
        }
        .tds-alert-description {
            @apply pt-[var(--tds-alert-margin-top-s)] tds-color-text-primary break-all;
        }
        .tds-alert-message {
            @apply break-all;
        }
        &.tds-alert-with-description {
            .tds-alert-content {
                @apply flex-col;
            }
            .tds-alert-message {
                @apply tds-body-1 tds-font-weight-semibold;
            }
        }
        &.tds-alert-banner {
            @apply justify-center relative items-center;
            &:not(.tds-alert-with-description) {
                .tds-alert-message {
                    @apply mr-tds-spacing-13;
                }
            }

            .tds-alert-close-icon {
                @apply absolute  right-3;
            }

            .tds-alert-content {
                @apply items-center;
            }
        }
        &:not(.tds-alert-banner) {
            @apply w-full  tds-alert-border-radius;
            &:not(.tds-alert-with-description) {
                @apply tds-color-text-primary;
            }
            .tds-alert-message {
                @apply flex items-center mr-tds-spacing-15;
            }
        }
    }
    &.tds-alert-theme-default {
        > .tds-alert {
            .tds-alert-footer {
                @apply tds-color-text-primary;
            }
            &.tds-alert-banner {
                &:not(.tds-alert-with-description) {
                    .tds-alert-message {
                        @apply tds-color-text-white;
                    }
                }

                .tds-alert-close-icon {
                    > button[tds-button-close] {
                        @apply tds-color-icon-white hover:tds-color-icon-secondary;
                    }
                }

                .tds-alert-description {
                    @apply tds-color-text-white;
                }

                &.tds-alert-success {
                    @apply tds-color-background-success-subtle;
                }
                &.tds-alert-info {
                    @apply tds-color-background-info-subtle;
                }
                &.tds-alert-warning {
                    @apply tds-color-background-warning-subtle;
                }
                &.tds-alert-error {
                    @apply tds-color-background-error-subtle;
                }
                .tds-alert-text-icon {
                    @apply tds-color-icon-white;
                }
            }
            &:not(.tds-alert-banner) {
                .tds-alert-message {
                    @apply tds-color-text-primary;
                }

                .tds-alert-description {
                    @apply tds-color-text-primary;
                }
                &.tds-alert-success {
                    @apply tds-color-background-success-minimal;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-success;
                    }
                }
                &.tds-alert-info {
                    @apply tds-color-background-info-minimal;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-info;
                    }
                }
                &.tds-alert-warning {
                    @apply tds-color-background-warning-minimal;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-warning;
                    }
                }
                &.tds-alert-error {
                    @apply tds-color-background-error-minimal;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-error;
                    }
                }
            }
        }
    }
    &.tds-alert-theme-light {
        > .tds-alert {
            .tds-alert-footer {
                @apply tds-color-text-primary-light;
            }
            &.tds-alert-banner {
                &:not(.tds-alert-with-description) {
                    .tds-alert-message {
                        @apply tds-color-text-white-light;
                    }
                }

                .tds-alert-close-icon {
                    > button[tds-button-close] {
                        @apply tds-color-icon-white-light hover:tds-color-icon-secondary-light;
                    }
                }
                .tds-alert-description {
                    @apply tds-color-text-white-light;
                }

                &.tds-alert-success {
                    @apply tds-color-background-success-subtle-light;
                }
                &.tds-alert-info {
                    @apply tds-color-background-info-subtle-light;
                }
                &.tds-alert-warning {
                    @apply tds-color-background-warning-subtle-light;
                }
                &.tds-alert-error {
                    @apply tds-color-background-error-subtle-light;
                }
                .tds-alert-text-icon {
                    @apply tds-color-icon-white-light;
                }
            }
            &:not(.tds-alert-banner) {
                .tds-alert-message {
                    @apply tds-color-text-primary-light;
                }

                .tds-alert-description {
                    @apply tds-color-text-primary-light;
                }
                &.tds-alert-success {
                    @apply tds-color-background-success-minimal-light;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-success-light;
                    }
                }
                &.tds-alert-info {
                    @apply tds-color-background-info-minimal-light;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-info-light;
                    }
                }
                &.tds-alert-warning {
                    @apply tds-color-background-warning-minimal-light;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-warning-light;
                    }
                }
                &.tds-alert-error {
                    @apply tds-color-background-error-minimal-light;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-error-light;
                    }
                }
            }
        }
    }
    &.tds-alert-theme-dark {
        > .tds-alert {
            .tds-alert-footer {
                @apply tds-color-text-primary-dark;
            }
            &.tds-alert-banner {
                &:not(.tds-alert-with-description) {
                    .tds-alert-message {
                        @apply tds-color-text-white-dark;
                    }
                }

                .tds-alert-close-icon {
                    > button[tds-button-close] {
                        @apply tds-color-icon-white-dark hover:tds-color-icon-secondary-dark;
                    }
                }
                .tds-alert-description {
                    @apply tds-color-text-white-dark;
                }

                &.tds-alert-success {
                    @apply tds-color-background-success-subtle-dark;
                }
                &.tds-alert-info {
                    @apply tds-color-background-info-subtle-dark;
                }
                &.tds-alert-warning {
                    @apply tds-color-background-warning-subtle-dark;
                }
                &.tds-alert-error {
                    @apply tds-color-background-error-subtle-dark;
                }
                .tds-alert-text-icon {
                    @apply tds-color-icon-white-dark;
                }
            }
            &:not(.tds-alert-banner) {
                .tds-alert-message {
                    @apply tds-color-text-primary-dark;
                }

                .tds-alert-description {
                    @apply tds-color-text-primary-dark;
                }
                &.tds-alert-success {
                    @apply tds-color-background-success-minimal-dark;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-success-dark;
                    }
                }
                &.tds-alert-info {
                    @apply tds-color-background-info-minimal-dark;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-info-dark;
                    }
                }
                &.tds-alert-warning {
                    @apply tds-color-background-warning-minimal-dark;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-warning-dark;
                    }
                }
                &.tds-alert-error {
                    @apply tds-color-background-error-minimal-dark;
                    .tds-alert-text-icon {
                        @apply tds-color-icon-error-dark;
                    }
                }
            }
        }
    }
}
