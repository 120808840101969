.tds-card {
    @apply block tds-card-border-radius tds-color-text-primary tds-color-surface-lowest;
}
.tds-card-header {
    @apply py-[--tds-card-padding-m] px-[--tds-card-padding-l] block w-full text-tds-font-size-4 leading-tds-line-height-5 font-tds-font-family-body tds-font-weight-semibold;
}
.tds-card-content {
    @apply py-[--tds-card-padding-m] px-[--tds-card-padding-l] h-auto block w-full tds-font-weight-regular tds-body-2;
}
.tds-card-actions {
    @apply py-[--tds-card-padding-m] px-[--tds-card-padding-l] min-h-0 block w-full;
}
.tds-card-bordered {
    @apply tds-card-border-radius border tds-color-border-secondary shadow-1-sm;
}
.tds-card-title {
    @apply pb-3.5 text-tds-font-size-4 leading-tds-line-height-5 font-tds-font-family-body tds-font-weight-semibold;
}
.tds-card-sm {
    .tds-card-header,
    .tds-card-content {
        @apply p-2;
    }
    .tds-card-actions {
        @apply px-2;
    }
}

// .tds-card-md,
// .tds-card-lg,
// .tds-card-xl {
// }
