tds-badge {
    @apply box-border m-0 p-0 relative inline-block leading-none;
}
.tds-badge-status {
    @apply align-baseline;
}
.tds-badge-theme-default {
    &.tds-badge-status {
        &-primary {
            .tds-badge-status-dot {
                @apply tds-color-background-primary-subtle;
            }
        }
        &-secondary {
            .tds-badge-status-dot {
                @apply tds-color-background-neutral-boldest;
            }
        }
        &-success {
            .tds-badge-status-dot {
                @apply tds-color-background-success-subtle;
            }
        }

        &-info {
            .tds-badge-status-dot {
                @apply tds-color-background-info-subtle;
            }
        }
        &-warning {
            .tds-badge-status-dot {
                @apply tds-color-background-warning-subtle;
            }
        }
        &-error {
            .tds-badge-status-dot {
                @apply tds-color-background-error-subtle;
            }
        }
    }
    tds-badge-sup {
        @apply tds-color-background-primary-subtle tds-color-text-white;
    }
}

.tds-badge-theme-light {
    &.tds-badge-status {
        &-primary {
            .tds-badge-status-dot {
                @apply tds-color-background-primary-subtle-light;
            }
        }
        &-secondary {
            .tds-badge-status-dot {
                @apply tds-color-background-neutral-boldest-light;
            }
        }
        &-success {
            .tds-badge-status-dot {
                @apply tds-color-background-success-subtle-light;
            }
        }

        &-info {
            .tds-badge-status-dot {
                @apply tds-color-background-info-subtle-light;
            }
        }
        &-warning {
            .tds-badge-status-dot {
                @apply tds-color-background-warning-subtle-light;
            }
        }
        &-error {
            .tds-badge-status-dot {
                @apply tds-color-background-error-subtle-light;
            }
        }
    }
    tds-badge-sup {
        @apply tds-color-background-primary-subtle-light tds-color-text-white-light;
    }
}
.tds-badge-theme-dark {
    &.tds-badge-status {
        &-primary {
            .tds-badge-status-dot {
                @apply tds-color-background-primary-subtle-dark;
            }
        }
        &-secondary {
            .tds-badge-status-dot {
                @apply tds-color-background-neutral-boldest-dark;
            }
        }
        &-success {
            .tds-badge-status-dot {
                @apply tds-color-background-success-subtle-dark;
            }
        }

        &-info {
            .tds-badge-status-dot {
                @apply tds-color-background-info-subtle-dark;
            }
        }
        &-warning {
            .tds-badge-status-dot {
                @apply tds-color-background-warning-subtle-dark;
            }
        }
        &-error {
            .tds-badge-status-dot {
                @apply tds-color-background-error-subtle-dark;
            }
        }
    }
    tds-badge-sup {
        @apply tds-color-background-primary-subtle-dark tds-color-text-white-dark;
    }
}
.tds-badge-not-a-wrapper:not(.tds-badge-status) {
    @apply align-middle;
}
.tds-badge {
    .tds-badge-status-dot {
        @apply relative -top-px inline-block  align-middle rounded-tds-border-radius-circle w-[--tds-badge-width-dot-m] h-[--tds-badge-height-dot-m];
    }
    .tds-badge-status-text {
        @apply ml-[--tds-badge-margin-m];
    }
    &-size-sm {
        .tds-badge-status-dot {
            @apply w-[--tds-badge-width-dot-s] h-[--tds-badge-height-dot-s];
        }
        .tds-scroll-number {
            @apply h-[--tds-badge-min-height-s] text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-semibold;
            &.tds-badge-sup-standalone {
                @apply min-w-[--tds-badge-min-width-s];
            }
            .tds-scroll-number-only {
                @apply h-[--tds-badge-min-height-s];
                .tds-scroll-number-only-unit {
                    @apply h-[--tds-badge-min-height-s] m-0;
                }
            }
        }
    }
    &-size-md {
        .tds-badge-status-dot {
            @apply w-[--tds-badge-width-dot-m] h-[--tds-badge-height-dot-m];
        }
        .tds-scroll-number {
            @apply h-[--tds-badge-min-height-m] text-tds-font-size-2 leading-tds-line-height-3 font-tds-font-family-body tds-font-weight-semibold;
            &.tds-badge-sup-standalone {
                @apply min-w-[--tds-badge-min-width-m];
            }
            .tds-scroll-number-only {
                @apply h-[--tds-badge-min-height-m];
                .tds-scroll-number-only-unit {
                    @apply h-[--tds-badge-min-height-m] m-0;
                }
            }
        }
    }
    &-size-lg {
        .tds-badge-status-dot {
            @apply w-[--tds-badge-width-dot-l] h-[--tds-badge-height-dot-l];
        }
        .tds-scroll-number {
            @apply h-[--tds-badge-min-height-l] text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold;
            &.tds-badge-sup-standalone {
                @apply min-w-[--tds-badge-min-width-l];
            }
            .tds-scroll-number-only {
                @apply h-[--tds-badge-min-height-l];
                .tds-scroll-number-only-unit {
                    @apply h-[--tds-badge-min-height-l] m-0;
                }
            }
        }
    }
    // &-size-xl {
    //     .tds-badge-status-dot {
    //         @apply w-3 h-3;
    //     }
    // }
}
.tds-badge-count {
    @apply px-1.5;
    &.tds-badge-sup-standalone {
        @apply relative flex items-center justify-center;
    }
    &:not(.tds-badge-sup-standalone) {
        @apply absolute;
    }
    &.tds-badge-multiple-words {
        @apply px-2;
    }
}
.tds-badge-dot {
    @apply absolute z-auto overflow-hidden py-0  rounded-tds-border-radius-circle;
    &.tds-badge-sup {
        &-size-sm {
            @apply w-[--tds-badge-width-dot-s] h-[--tds-badge-height-dot-s];
        }
        &-size-md {
            @apply w-[--tds-badge-width-dot-m] h-[--tds-badge-height-dot-m];
        }
        &-size-lg {
            @apply w-[--tds-badge-width-dot-l] h-[--tds-badge-height-dot-l];
        }
        // &-size-xl {
        //     @apply w-3 h-3;
        // }
    }
}
.tds-scroll-number {
    @apply overflow-hidden  py-0 text-center whitespace-nowrap rounded-tds-border-radius-circle;
}
.tds-badge-sup-placement-top-left {
    @apply top-0 left-0;
}
.tds-badge-sup-placement-top-right {
    @apply top-0 right-0;
}
.tds-badge-sup-placement-bottom-left {
    @apply bottom-0 left-0;
}
.tds-badge-sup-placement-bottom-right {
    @apply bottom-0 right-0;
}
.tds-scroll-number-only {
    @apply relative inline-block;
    .tds-scroll-number-only-unit {
        @apply flex items-center justify-center;
    }
}
