.tds-tooltip {
    @apply p-[--tds-tooltip-padding-outer-box-m] relative tds-color-text-white tds-body-2  tds-font-weight-regular;
}
.tds-tooltip-arrow-content {
    @apply block;
}
.tds-tooltip-content {
    @apply p-[--tds-tooltip-padding-outer-box-s] relative;
}
.tds-tooltip-inner {
    @apply break-words;
}
.tds-tooltip-arrow {
    @apply absolute block bg-transparent pointer-events-none;
}
.tds-tooltip:not(.tds-tooltip-custom) {
    @apply max-w-[--tds-tooltip-max-width];
}
.tds-tooltip-default:not(.tds-tooltip-custom) {
    @apply max-w-[--tds-tooltip-max-width];
    .tds-tooltip-inner {
        @apply tds-tooltip-color-background-default tds-tooltip-border-radius p-[--tds-tooltip-margin-default];
    }
    .tds-tooltip-arrow-content {
        @apply tds-tooltip-color-arrow-border-default  border-tds-border-width-xl ;
    }
}
.tds-tooltip-info {
    .tds-tooltip-inner {
        @apply tds-color-background-info-subtle p-[--tds-tooltip-margin-default] tds-tooltip-border-radius;
    }
    .tds-tooltip-arrow-content {
        @apply border-tds-border-width-xl tds-color-border-info;
    }
}
.tds-tooltip-success {
    .tds-tooltip-inner {
        @apply tds-color-background-success-subtle p-[--tds-tooltip-margin-default] tds-tooltip-border-radius;
    }
    .tds-tooltip-arrow-content {
        @apply border-tds-border-width-xl tds-color-border-success;
    }
}

.tds-tooltip-error {
    .tds-tooltip-inner {
        @apply tds-color-background-error-subtle p-[--tds-tooltip-margin-default] tds-tooltip-border-radius;
    }
    .tds-tooltip-arrow-content {
        @apply border-tds-border-width-xl tds-color-border-error;
    }
}
.tds-tooltip-warning {
    .tds-tooltip-inner {
        @apply tds-color-background-warning-subtle p-[--tds-tooltip-margin-default] tds-tooltip-border-radius;
    }
    .tds-tooltip-arrow-content {
        @apply border-tds-border-width-xl tds-color-border-warning;
    }
}
.tds-tooltip-color-arrow-border-default
{
                    @apply border-tds-color-black-light/80 dark:tds-color-border-secondary-dark;

                }                
                
.tds-tooltip-color-arrow-border-default-light
{
                    @apply border-tds-color-black-light/80;

                }
                
.tds-tooltip-color-arrow-border-default-dark
{
                    @apply tds-color-border-secondary-dark;

                }
                