tds-color-picker {
    @apply box-border m-0 p-0  relative inline-block cursor-pointer w-full;
    &.tds-color-picker {
        &.tds-color-picker-input {
            .tds-color-picker-selector {
                @apply flex items-center justify-center border-tds-border-width-s w-full  rounded   pl-3;
            }
            .tds-color-picker-selector-input {
                @apply flex-auto   flex;
                > .selector-input {
                    @apply h-5 tds-body-2 tds-font-weight-regular  focus:outline-none ring-0 focus:border-transparent shadow-none border-0 bg-transparent text-current p-0 m-0  outline-none  box-border uppercase w-full select-all;
                }
            }
            .tds-color-picker-value-view {
                @apply h-[--tds-color-selection-height-color-block-s] w-[--tds-color-selection-width-color-block-s]  tds-color-border-primary border  rounded-sm relative mr-2;
                .tds-color-picker-value-view-alpha {
                    background-image: conic-gradient(
                        rgba(0, 0, 0, 0.06) 0 25%,
                        transparent 0 50%,
                        rgba(0, 0, 0, 0.06) 0 75%,
                        transparent 0
                    );
                    background-size: 8px 8px;
                }
                > div {
                    @apply pointer-events-none select-none absolute;
                }
                div:first-child {
                    @apply left-0 right-1/2 inset-y-0 rounded-l-sm;
                }
                div:last-child {
                    @apply left-1/2 right-0 inset-y-0 rounded-r-sm;
                }
            }
            .tds-color-picker-icon-clear {
                @apply flex absolute inset-0 justify-center items-center opacity-0 z-[1];
                transition: all 0.2s ease-out;
            }
            .tds-color-picker-icon-dropdown {
                @apply flex px-2 items-center justify-center cursor-pointer tds-color-icon-primary tds-icon-size-m relative;
            }

            &.tds-color-picker-sm {
                .tds-color-picker-selector {
                    @apply pl-2.5 py-1;
                }
            }
            &.tds-color-picker-md {
                .tds-color-picker-selector {
                    @apply pl-3 py-1.5;
                }
            }
            &.tds-color-picker-lg {
                .tds-color-picker-selector {
                    @apply pl-3.5 py-2;
                }
            }
            &.tds-color-picker-xl {
                .tds-color-picker-selector {
                    @apply pl-4  py-2.5;
                }
            }
            &.tds-color-picker-focused {
                .tds-color-picker-selector {
                    @apply outline-none  ring;
                }
            }
            &.ng-invalid.ng-touched:not(.tds-color-picker-focused) {
                .tds-color-picker-selector {
                    @apply outline-none ring-0;
                }
            }
        }
        &.tds-color-picker-lable {
            .tds-color-picker-selector {
                @apply h-5 w-full;
            }
        }
        &.tds-color-picker-tpye-compact {
            @apply inline-block w-[140px];
            &.tds-color-picker-input {
                .tds-color-picker-selector {
                    @apply p-0 h-[28px];
                    // .tds-color-picker-value-view {
                    //     @apply rounded-none rounded-l-[3px];
                    // }
                    .tds-color-picker-icon-dropdown {
                        @apply hidden;
                    }
                    // .tds-color-picker-icon-clear {
                    //     @apply hidden;
                    // }
                    .tds-color-picker-value-view {
                        @apply ml-1;

                        div:first-child {
                            @apply rounded-l-[2px];
                        }
                        div:last-child {
                            @apply rounded-r-[2px];
                        }
                    }
                    .tds-color-picker-selector-input {
                        @apply flex items-center h-full;
                        > .selector-input {
                            @apply w-[54px] mr-1 h-4 tds-caption-1;
                        }
                    }
                    .input-alpha {
                        @apply flex items-center justify-center w-[48px] h-full px-1 border-l border-l-transparent;
                        tds-input-number.tds-input-number {
                            @apply border-none ring-0 border-0 ring-transparent w-5;
                            &.tds-input-number-focused {
                                @apply border-none ring-0 ring-transparent border-0;
                            }
                            .tds-input-number-input-wrap {
                                @apply p-0;
                                .tds-input-number-input {
                                    @apply tds-caption-1 h-4;
                                }
                            }
                        }
                        .input-uint {
                            @apply flex items-center justify-center;
                            i {
                                @apply text-sm leading-[14px]  tds-color-icon-tertiary;
                            }
                        }
                    }
                }

                // &.tds-color-picker-sm {
                //     .tds-color-picker-value-view {
                //         @apply h-[28px] w-[28px];
                //     }
                //     .tds-color-picker-selector-input {
                //         @apply my-1;
                //     }
                // }
                // &.tds-color-picker-md {
                //     .tds-color-picker-value-view {
                //         @apply h-[32px] w-[32px];
                //     }
                //     .tds-color-picker-selector-input {
                //         @apply my-1.5;
                //     }
                // }
                // &.tds-color-picker-lg {
                //     .tds-color-picker-value-view {
                //         @apply h-[36px] w-[36px];
                //     }
                //     .tds-color-picker-selector-input {
                //         @apply my-2;
                //     }
                // }
                // &.tds-color-picker-xl {
                //     .tds-color-picker-value-view {
                //         @apply h-[40px] w-[40px];
                //     }
                //     .tds-color-picker-selector-input {
                //         @apply my-2.5;
                //     }
                // }
            }
            &.tds-color-picker-focused.tds-color-picker-bordered {
                &.tds-color-picker-input {
                    .tds-color-picker-selector {
                        @apply ring-0 ring-transparent;
                        .input-alpha {
                            @apply tds-color-border-brand-primary;
                        }
                    }
                }
            }
            &:not(.tds-color-picker-disabled).tds-color-picker-bordered {
                &.tds-color-picker {
                    &.tds-color-picker-input {
                        .tds-color-picker-selector:hover {
                            .input-alpha {
                                @apply border-l   tds-color-border-brand-primary;
                            }
                        }
                    }
                }
            }
            &:not(.tds-color-picker-bordered):not(.tds-color-picker-disabled) {
                &.tds-color-picker-input {
                    .tds-color-picker-selector {
                        @apply border-transparent;
                        .input-alpha {
                            @apply border-transparent;
                        }
                    }
                }
                &.tds-color-picker-focused {
                    &.tds-color-picker-input {
                        .tds-color-picker-selector {
                            @apply ring-0 ring-transparent;
                        }
                    }
                }
            }
        }
        &.tds-color-picker-allow-clear:hover {
            .tds-color-picker-icon-clear {
                @apply opacity-100;
            }
        }
    }
    &.tds-color-picker-disabled {
        @apply cursor-not-allowed;
        &.tds-color-picker {
            &.tds-color-picker-input {
                .tds-color-picker-selector {
                    div,
                    span,
                    input {
                        @apply cursor-not-allowed;
                    }
                }
            }
        }
    }
    &.tds-color-picker-inline {
        @apply w-[240px];
        &.tds-color-picker-bordered {
            @apply border-tds-border-width-s  rounded tds-color-border-primary w-[242px];
        }
    }

    &.tds-color-picker-theme-default {
        @apply tds-color-text-primary;
        &.tds-color-picker {
            &.tds-color-picker-input {
                .tds-color-picker-selector {
                    @apply tds-color-surface-lowest tds-color-border-primary;
                }
                .tds-color-picker-selector-input {
                    @apply tds-color-text-primary placeholder:tds-color-text-placeholder;
                }
                .tds-color-picker-value-view {
                    @apply bg-tds-color-neutral-300-light dark:bg-tds-color-neutral-300-dark;
                }
                // .tds-color-picker-icon-clear {
                //     @apply tds-btn-color-icon-default;
                // }

                &.tds-color-picker-focused {
                    .tds-color-picker-selector {
                        @apply tds-color-border-brand-primary  tds-color-ring-primary;
                    }
                }
                &.ng-invalid.ng-touched:not(.tds-color-picker-focused) {
                    .tds-color-picker-selector {
                        @apply tds-color-border-error;
                    }
                }
            }
        }
        &.tds-color-picker-disabled {
            &.tds-color-picker {
                &.tds-color-picker-input {
                    .tds-color-picker-selector {
                        @apply tds-color-background-disable;
                    }
                    .tds-color-picker-icon-dropdown {
                        @apply tds-color-icon-disable;
                    }
                }
            }
        }
        &.tds-color-picker-inline {
            @apply tds-color-surface-lowest;
            &.tds-color-picker-bordered {
                @apply tds-color-border-primary;
            }
        }
        &:not(.tds-color-picker-disabled) {
            &.tds-color-picker {
                &.tds-color-picker-input {
                    .tds-color-picker-selector:hover {
                        @apply tds-color-border-brand-primary;
                    }
                }
            }
        }
    }
    &.tds-color-picker-theme-light {
        @apply tds-color-text-primary-light;
        &.tds-color-picker {
            &.tds-color-picker-input {
                .tds-color-picker-selector {
                    @apply tds-color-surface-lowest-light tds-color-border-primary-light;
                }
                .tds-color-picker-selector-input {
                    @apply tds-color-text-primary-light placeholder:tds-color-text-placeholder-light;
                }
                .tds-color-picker-value-view {
                    @apply bg-tds-color-neutral-300-light;
                }
                // .tds-color-picker-icon-clear {
                //     @apply tds-btn-color-icon-default-light;
                // }

                &.tds-color-picker-focused {
                    .tds-color-picker-selector {
                        @apply tds-color-border-brand-primary-light  tds-color-ring-primary-light;
                    }
                }
                &.ng-invalid.ng-touched:not(.tds-color-picker-focused) {
                    .tds-color-picker-selector {
                        @apply tds-color-border-error-light;
                    }
                }
            }
        }
        &.tds-color-picker-disabled {
            &.tds-color-picker {
                &.tds-color-picker-input {
                    .tds-color-picker-selector {
                        @apply tds-color-background-disable-light;
                    }
                    .tds-color-picker-icon-dropdown {
                        @apply tds-color-icon-disable-light;
                    }
                }
            }
        }
        &:not(.tds-color-picker-disabled) {
            &.tds-color-picker {
                &.tds-color-picker-input {
                    .tds-color-picker-selector:hover {
                        @apply tds-color-border-brand-primary-light;
                    }
                }
            }
        }
    }
    &.tds-color-picker-theme-dark {
        @apply tds-color-text-primary-dark;
        &.tds-color-picker {
            &.tds-color-picker-input {
                .tds-color-picker-selector {
                    @apply tds-color-surface-lowest-dark tds-color-border-primary-dark;
                }
                .tds-color-picker-selector-input {
                    @apply tds-color-text-primary-dark placeholder:tds-color-text-placeholder-dark;
                }
                .tds-color-picker-value-view {
                    @apply bg-tds-color-neutral-300-dark;
                }
                // .tds-color-picker-icon-clear {
                //     @apply tds-btn-color-icon-default-dark;
                // }

                &.tds-color-picker-focused {
                    .tds-color-picker-selector {
                        @apply tds-color-border-brand-primary-dark  tds-color-ring-primary-dark;
                    }
                }
                &.ng-invalid.ng-touched:not(.tds-color-picker-focused) {
                    .tds-color-picker-selector {
                        @apply tds-color-border-error-dark;
                    }
                }
            }
        }
        &.tds-color-picker-disabled {
            &.tds-color-picker {
                &.tds-color-picker-input {
                    .tds-color-picker-selector {
                        @apply tds-color-background-disable-dark;
                    }
                    .tds-color-picker-icon-dropdown {
                        @apply tds-color-icon-disable-dark;
                    }
                }
            }
        }
        &:not(.tds-color-picker-disabled) {
            &.tds-color-picker {
                &.tds-color-picker-input {
                    .tds-color-picker-selector:hover {
                        @apply tds-color-border-brand-primary-dark;
                    }
                }
            }
        }
    }
    &.tds-color-picker-input-hex {
        @apply max-w-[150px];
        &.tds-color-picker-bordered {
            @apply border-tds-border-width-s  rounded tds-color-border-primary;
        }
    }
}

.tds-color-picker-dropdown {
    @apply top-full left-0 relative w-full  overflow-x-hidden   shadow-1-lg rounded;
    &.tds-color-picker-theme-default {
        @apply tds-color-surface-high;
    }
    &.tds-color-picker-theme-light {
        @apply tds-color-surface-high-light;
    }
    &.tds-color-picker-theme-dark {
        @apply tds-color-surface-high-dark;
    }
    &-placement-top,
    &-placement-topLeft,
    &-placement-topRight {
        @apply mb-1;
    }

    &-placement-right,
    &-placement-rightTop,
    &-placement-rightBottom {
        @apply ml-1;
    }

    &-placement-bottom,
    &-placement-bottomLeft,
    &-placement-bottomRight {
        @apply mt-1;
    }

    &-placement-left,
    &-placement-leftTop,
    &-placement-leftBottom {
        @apply mr-1;
    }
}
tds-panel-color-picker {
    @apply block w-full;
    .tds-panel-color-picker-wrapper {
        @apply block w-full pb-4 select-none;
        > * {
            @apply select-none;
        }
    }

    &.tds-panel-color-picker-mode-picker {
        tds-swatch-color-picker,
        tds-pallete-color-picker {
            @apply border-t  pt-1.5 mt-1.5;
        }
    }
    &.tds-panel-color-picker-mode-swatch {
        .tds-panel-color-picker-wrapper {
            @apply pb-0;
        }
        tds-swatch-color-picker {
            @apply pb-4;
        }
        tds-pallete-color-picker {
            @apply border-t  pt-1.5 mt-1.5;
        }
    }
    &.tds-panel-color-picker-mode-pallete {
        tds-swatch-color-picker {
            @apply pb-0;
        }
        .tds-panel-color-picker-wrapper {
            @apply pb-0;
        }
    }
}
tds-swatch-color-picker {
    @apply w-full flex  flex-col py-3 px-2.5 cursor-default;
    .tds-swatch-color-picker-title {
        @apply tds-caption-2 tds-font-weight-semibold;
    }
    .tds-swatch-color-picker-body {
        @apply w-full flex flex-row gap-y-1 gap-x-1 flex-wrap items-center pt-1;
        .tds-swatch-color-picker-item {
            @apply w-[18px] h-[18px] border tds-color-border-primary flex items-center justify-center;
            span {
                @apply text-base leading-6;
            }
        }
    }
    &:not(.tds-swatch-color-picker-disabled) {
        .tds-swatch-color-picker-item {
            @apply cursor-pointer;
            transition: all 0.2s ease-in-out;
            &:hover,
            .selected {
                transform: scale(1.1);
            }
        }
    }
    &.tds-swatch-color-picker-disabled {
        .tds-swatch-color-picker-item {
            @apply cursor-not-allowed;
        }
    }
}
tds-pallete-color-picker {
    @apply w-full flex  flex-col py-3 px-2.5 cursor-default;
    .tds-pallete-color-picker-title {
        @apply tds-caption-2 tds-font-weight-semibold;
    }
    .tds-pallete-color-picker-body {
        @apply w-full flex flex-col;
        .tds-pallete-color-picker-theme {
            @apply w-full flex flex-row gap-y-1 gap-x-1 flex-wrap items-center pt-1;
            .tds-pallete-color-picker-color {
                @apply w-[18px] h-[18px] border tds-color-border-primary flex items-center justify-center;
                span {
                    @apply text-base leading-6;
                }
            }
        }
    }
    &:not(.tds-pallete-color-picker-disabled) {
        .tds-pallete-color-picker-color {
            @apply cursor-pointer;
            transition: all 0.2s ease-in-out;
            &:hover,
            .selected {
                transform: scale(1.1);
            }
        }
    }
    &.tds-pallete-color-picker-disabled {
        .tds-pallete-color-picker-color {
            @apply cursor-not-allowed;
        }
    }
}
tds-input-color-picker {
    @apply w-full flex px-3 gap-x-0.5 mt-2.5;
    tds-select.select .tds-value-container-default .tds-value-template {
        @apply text-tds-font-size-2;
    }
    tds-select.tds-select-size-sm {
        .tds-select-selector {
            @apply rounded;
        }
        .tds-value-container-default {
            @apply py-[3px];
        }
    }

    .tds-input-number-sm .tds-input-number-input-wrap {
        @apply pl-1;
    }
    .tds-input-number .tds-input-number-input-wrap .tds-input-number-input,
    .tds-input-element {
        @apply tds-caption-1;
    }
    .alpha {
        @apply w-10 flex-shrink-0;
        .tds-input-number-input-wrap {
            @apply px-0;
        }
        .tds-form-field-suffix {
            @apply pl-0  flex;
        }
        .tds-input-number-input {
            @apply text-center w-full;
        }

        tds-input-number.tds-input-number-focused {
            @apply ring-0 border-0;
        }
    }
    // tds-form-field.tds-form-field-theme-default
    //     .tds-form-field-wrapper
    //     .tds-form-field-container
    //     .tds-form-field-container-input
    //     .tds-form-field-control.form-field-connection-container {
    //     @apply border-transparent;
    // }
   
    tds-form-field.tds-form-field-theme-default.tds-form-field-focused
        .tds-form-field-wrapper
        .tds-form-field-container
        .tds-form-field-container-input
        .tds-form-field-control.form-field-connection-container {
        @apply ring-0;
    }
}

.tds-color-picker-theme-default {
    tds-panel-color-picker {
        .tds-panel-color-picker-wrapper {
            @apply tds-color-text-primary;
        }

        &.tds-panel-color-picker-mode-picker {
            tds-swatch-color-picker {
                @apply tds-color-border-primary;
            }
            tds-pallete-color-picker {
                @apply tds-color-border-primary;
            }
        }
        &.tds-panel-color-picker-mode-swatch {
            tds-pallete-color-picker {
                @apply tds-color-border-primary;
            }
        }
    }
    tds-swatch-color-picker {
        .tds-swatch-color-picker-title {
            @apply tds-color-text-primary;
        }
        .tds-swatch-color-picker-body {
            .tds-swatch-color-picker-item {
                @apply tds-color-border-primary;
            }
        }
    }
    tds-pallete-color-picker {
        .tds-pallete-color-picker-title {
            @apply tds-color-text-primary;
        }
    }
    tds-input-color-picker {
        .alpha {
            &.tds-form-field-disabled {
                .tds-form-field-wrapper
                    .tds-form-field-container
                    .tds-form-field-container-input
                    .tds-form-field-control.form-field-connection-container,
                .tds-form-field-suffix {
                    @apply tds-color-surface-lowest;
                }
            }
        }
    }
}
.tds-color-picker-theme-light {
    tds-panel-color-picker {
        .tds-panel-color-picker-wrapper {
            @apply tds-color-text-primary-light;
        }

        &.tds-panel-color-picker-mode-picker {
            tds-swatch-color-picker {
                @apply tds-color-border-primary-light;
            }
            tds-pallete-color-picker {
                @apply tds-color-border-primary-light;
            }
        }
        &.tds-panel-color-picker-mode-swatch {
            tds-pallete-color-picker {
                @apply tds-color-border-primary-light;
            }
        }
    }
    tds-swatch-color-picker {
        .tds-swatch-color-picker-title {
            @apply tds-color-text-primary-light;
        }
        .tds-swatch-color-picker-body {
            .tds-swatch-color-picker-item {
                @apply tds-color-border-white-light;
            }
        }
    }
    tds-pallete-color-picker {
        .tds-pallete-color-picker-title {
            @apply tds-color-text-primary-light;
        }
    }
    tds-input-color-picker {
        .alpha {
            &.tds-form-field-disabled {
                .tds-form-field-wrapper
                    .tds-form-field-container
                    .tds-form-field-container-input
                    .tds-form-field-control.form-field-connection-container,
                .tds-form-field-suffix {
                    @apply tds-color-surface-lowest-light;
                }
            }
        }
    }
}
.tds-color-picker-theme-dark {
    tds-panel-color-picker {
        .tds-panel-color-picker-wrapper {
            @apply tds-color-text-primary-dark;
        }

        &.tds-panel-color-picker-mode-picker {
            tds-swatch-color-picker {
                @apply tds-color-border-primary-dark;
            }
            tds-pallete-color-picker {
                @apply tds-color-border-primary-dark;
            }
        }
        &.tds-panel-color-picker-mode-swatch {
            tds-pallete-color-picker {
                @apply tds-color-border-primary-dark;
            }
        }
    }

    tds-swatch-color-picker {
        .tds-swatch-color-picker-title {
            @apply tds-color-text-primary-dark;
        }
        .tds-swatch-color-picker-body {
            .tds-swatch-color-picker-item {
                @apply tds-color-border-white-light;
            }
        }
    }
    tds-pallete-color-picker {
        .tds-pallete-color-picker-title {
            @apply tds-color-text-primary-dark;
        }
    }
    tds-input-color-picker {
        .alpha {
            &.tds-form-field-disabled {
                .tds-form-field-wrapper
                    .tds-form-field-container
                    .tds-form-field-container-input
                    .tds-form-field-control.form-field-connection-container,
                .tds-form-field-suffix {
                    @apply tds-color-surface-lowest-dark;
                }
            }
        }
    }
}
tds-form-field {
    tds-color-picker {
        &.tds-color-picker {
            &.tds-color-picker-input {
                .tds-color-picker-selector {
                    @apply border-none ring-0;
                }
                &.ng-invalid.ng-touched:not(.tds-color-picker-focused) {
                    .tds-color-picker-selector {
                        @apply border-none ring-0 ring-transparent;
                    }
                }
            }
        }
    }
}
.tds-tds-color-picker-select-format {
    min-width: 80px !important;
    tds-option-item.tds-select-item.tds-select-item-option {
        @apply py-1 px-3;
        .tds-select-option-default-label {
            @apply tds-caption-1 tds-font-weight-regular;
        }
        .tds-select-option-default-icon > span {
            @apply tds-icon-size-xs;
        }
    }
}

.tds-color-picker-handler {
    @apply w-4 h-4 border border-white box-border rounded-full shadow-[0_0_0_1px] shadow-tds-color-neutral-200-light dark:shadow-tds-color-neutral-200-dark;
}

.tds-color-picker-select {
    @apply w-full h-full;
    .tds-color-picker-saturation {
        @apply h-full w-full select-none;
    }
}
.tds-color-picker-slider .tds-color-picker-palette {
    @apply h-2 select-none;
}
.tds-color-picker-handler-sm {
    @apply w-3 h-3;
}
.tds-color-picker-gradient {
    border-radius: 4px;
}
.tds-color-picker-select .tds-color-picker-palette {
    @apply overflow-hidden w-full h-full;
}
.tds-color-picker-slider-alpha {
    background-image: conic-gradient(
        rgba(0, 0, 0, 0.06) 0 25%,
        transparent 0 50%,
        rgba(0, 0, 0, 0.06) 0 75%,
        transparent 0
    );
    background-size: 8px 8px;
}
.tds-tooltip-color-picker {
    &.tds-tooltip-default {
        @apply select-none pointer-events-none p-0;
        &:not(.tds-tooltip-custom) .tds-tooltip-inner {
            @apply p-1 tds-caption-1 select-none pointer-events-none;
        }
        > * {
            @apply select-none pointer-events-none;
        }
    }
}
.cdk-overlay-pane:has(.tds-tooltip-color-picker) {
    @apply select-none pointer-events-none;
}
