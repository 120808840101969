.toc-main {
    padding: 0.5rem;
    border: 1px solid #1A6DE3;
    border-radius: 6px;
}

.toc-title{
    font-weight: 600;
    font-size: large;
    height: 2em;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.toc-description{
    display: flex;
    flex-direction: column;
    line-height: 1.5em;
}

.toc-description .toc-description-inner .h1 {
    margin-left: 0px;
}

.toc-description .toc-description-inner .h2 {
    margin-left: 2rem;
}

.toc-description .toc-description-inner .h3 {
    margin-left: 4rem;
}

.toc-description .toc-description-inner .h4 {
    margin-left: 6rem;
}

.toc-description .toc-description-inner .h5 {
    margin-left: 8rem;
}

.toc-description .toc-description-inner .h6 {
    margin-left: 10rem;
}

.toc-description a{
    text-decoration: none;
}

.toc-description a:hover{
    text-decoration: underline;
}