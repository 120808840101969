tds-page-header {
    @apply w-full block box-content;
    .tds-page-header-heading {
        @apply flex w-full py-[--tds-page-header-padding-l] px-[--tds-page-header-padding-xl];
        .tds-page-header-heading-left {
            @apply flex flex-col flex-auto pr-[--tds-page-header-padding-m];
            .tds-page-header-heading-left-title {
                @apply flex items-center;
            }
            .tds-page-header-heading-title {
                @apply inline-flex  text-tds-font-size-5 leading-tds-line-height-5 font-tds-font-family-heading tds-font-weight-semibold  justify-center items-center pr-[--tds-page-header-padding-m] ;
            }
            .tds-page-header-heading-sub-title {
                @apply inline-flex text-tds-font-size-2 leading-tds-line-height-3 font-tds-font-family-body tds-font-weight-regular   justify-center items-center relative pl-[--tds-page-header-padding-m];
                &:after {
                    content: "";
                    @apply absolute top-0 bottom-0 w-px h-full  left-0;
                }
            }
            tds-avatar[tds-page-header-avatar] {
                @apply mr-[--tds-page-header-margin-m];
            }
        }
        .tds-page-header-heading-extra {
            @apply flex flex-shrink-0 items-center;
        }
    }
    &.has-header-extra:not(.has-breadcrumb) {
        .tds-page-header-heading {
            @apply py-[--tds-page-header-padding-m];
            .tds-page-header-heading-left {
                @apply justify-center;
            }
        }
    }
    &.has-breadcrumb {
        .tds-page-header-heading {
            @apply py-[--tds-page-header-padding-s];
            .tds-page-header-heading-left {
                tds-breadcrumb {
                    @apply p-0 mb-[--tds-page-header-margin-s];
                }
            }
        }
    }
    .tds-page-header-content {
        @apply py-[--tds-page-header-padding-m] px-[--tds-page-header-padding-xl] w-full block box-border;
    }
    .tds-page-header-footer {
        @apply border-t-tds-border-width-s  box-border py-[--tds-page-header-padding-m] px-[--tds-page-header-padding-xl]  w-full block;
    }
    .tds-page-header-back {
        @apply inline-flex justify-center items-center mr-[--tds-page-header-margin-m];
        .tds-page-header-back-button {
            @apply p-[--tds-btn-padding-icon-xs] flex justify-center items-center rounded-full border border-transparent tds-color-background-neutral-minimalist tds-color-icon-secondary;
            > span {
                @apply tds-btn-icon-size-xs;
            }
            &:hover {
                @apply tds-color-background-neutral-minimal;
            }
        }
    }
    &.tds-page-header-theme-default {
        @apply tds-color-surface-lowest;
        .tds-page-header-heading {
            .tds-page-header-heading-left {
                .tds-page-header-heading-title {
                    @apply tds-color-text-primary;
                }
                .tds-page-header-heading-sub-title {
                    @apply tds-color-text-secondary;
                    &:after {
                        @apply tds-color-background-neutral-minimal;
                    }
                }
            }
        }
        .tds-page-header-footer {
            @apply tds-color-border-primary;
        }
        .tds-page-header-back {
            .tds-page-header-back-button {
                @apply tds-color-background-neutral-minimalist tds-color-icon-secondary;

                &:hover {
                    @apply tds-color-background-neutral-minimal;
                }
            }
        }
    }
    &.tds-page-header-theme-light {
        @apply tds-color-surface-lowest-light;
        .tds-page-header-heading {
            .tds-page-header-heading-left {
                .tds-page-header-heading-title {
                    @apply tds-color-text-primary-light;
                }
                .tds-page-header-heading-sub-title {
                    @apply tds-color-text-secondary-light;
                    &:after {
                        @apply tds-color-background-neutral-minimal-light;
                    }
                }
            }
        }
        .tds-page-header-footer {
            @apply tds-color-border-primary-light;
        }
        .tds-page-header-back {
            .tds-page-header-back-button {
                @apply tds-color-background-neutral-minimalist-light tds-color-icon-secondary-light;

                &:hover {
                    @apply tds-color-background-neutral-minimal-light;
                }
            }
        }
    }
    &.tds-page-header-theme-dark {
        @apply tds-color-surface-lowest-dark;
        .tds-page-header-heading {
            .tds-page-header-heading-left {
                .tds-page-header-heading-title {
                    @apply tds-color-text-primary-dark;
                }
                .tds-page-header-heading-sub-title {
                    @apply tds-color-text-secondary-dark;
                    &:after {
                        @apply tds-color-background-neutral-minimal-dark;
                    }
                }
            }
        }
        .tds-page-header-footer {
            @apply tds-color-border-primary-dark;
        }
        .tds-page-header-back {
            .tds-page-header-back-button {
                @apply tds-color-background-neutral-minimalist-dark tds-color-icon-secondary-dark;

                &:hover {
                    @apply tds-color-background-neutral-minimal-dark;
                }
            }
        }
    }
}
