tds-tabset {
    --tds-tabs-border-width-bottom-s:2px;
    --tds-tabs-border-width-bottom-m:3px;
    &.tds-tabs-line {
        @apply box-border m-0 p-0 flex w-full;
        &.tds-tabs-top,
        &.tds-tabs-bottom {
            > .tds-tabs-nav {
                .tds-tabs-nav-wrap {
                    &::before {
                        left: 0;
                        box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 8%);
                    }
                    &::after {
                        right: 0;
                        box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 8%);
                    }
                    &::before,
                    &::after {
                        @apply absolute z-[1] opacity-0 pointer-events-none inset-y-0 w-[30px];
                        transition: opacity 0.3s;
                        content: "";
                    }
                    &.tds-tabs-nav-wrap-ping-left::before {
                        @apply opacity-100;
                    }
                    &.tds-tabs-nav-wrap-ping-right::after {
                        @apply opacity-100;
                    }
                }
                .tds-tabs-nav-operations {
                    // @apply place-self-stretch;
                    > button {
                        @apply tds-color-surface-lowest px-[--tds-tabs-padding-m] py-[--tds-tabs-padding-m] inline-flex justify-center items-center;
                        > i {
                            @apply tds-icon-size-m tds-color-icon-secondary;
                        }
                    }
                }
            }
        }
        &.tds-tabs-right,
        &.tds-tabs-left {
            .tds-tabs-nav-list {
                &.tds-tabs-nav-list-vertical {
                    @apply flex-col;
                }
            }
        }
        &.tds-tabs-right {
            @apply flex-row-reverse py-4;
        }
        &.tds-tabs-bottom {
            @apply flex-col-reverse;
        }

        .tds-tabs-tab {
            @apply relative inline-block outline-none tds-body-2 tds-font-weight-semibold  py-[--tds-tabs-padding-xl];;

            .tds-tabs-tab-btn {
                @apply outline-none w-full  cursor-pointer   px-[--tds-tabs-padding-m] relative;
            }
            
            &.tds-tabs-tab-disabled {
                .tds-tabs-tab-btn {
                    @apply cursor-not-allowed;
                }
            }
        }
        &.tds-tabs-horizontal {
            &.tds-tabs-default {
                &.tds-tabs-top > .tds-tabs-nav:before,
                &.tds-tabs-bottom > .tds-tabs-nav:before,
                &.tds-tabs-top > div > .tds-tabs-nav:before,
                &.tds-tabs-bottom > div > .tds-tabs-nav:before {
                    @apply absolute right-0 left-0 bottom-px content-['']  border-b-tds-border-width-s;
                }
            }
            &.tds-tabs-borderless {
                &.tds-tabs-top > .tds-tabs-nav:before,
                &.tds-tabs-bottom > .tds-tabs-nav:before,
                &.tds-tabs-top > div > .tds-tabs-nav:before,
                &.tds-tabs-bottom > div > .tds-tabs-nav:before {
                    @apply border-0;
                }
            }
        }

        &.tds-tabs-default {
            .tds-tabs-tab-default {
                @apply px-0;
                .tds-tabs-tab-btn {
                    @apply px-[--tds-tabs-padding-m];
                }
            }
            > tds-tabs-nav {
                > .tds-tabs-nav-wrap {
                    > .tds-tabs-nav-list {
                        > .tds-tabs-ink-bar {
                            @apply absolute select-none pointer-events-none;
                        }
                    }
                }
            }

            &.tds-tabs-horizontal {
                .tds-tabs-ink-bar {
                    @apply h-[--tds-tabs-border-width-bottom-m] bottom-0;
                    transition:
                        width 0.3s,
                        left 0.3s,
                        right 0.3s;
                }
                &.tds-tabs-centered {
                    @apply w-full;
                    > tds-tabs-nav {
                        @apply w-full;
                        > .tds-tabs-nav-wrap {
                            @apply w-full;
                            > .tds-tabs-nav-list {
                                @apply w-full;
                                .tds-tabs-tab {
                                    @apply flex-auto flex justify-center items-center;
                                }
                            }
                        }
                    }
                }
            }
            &.tds-tabs-vertical {
                .tds-tabs-ink-bar {
                    @apply w-[--tds-tabs-border-width-bottom-m] left-0;
                    transition:
                        height 0.3s,
                        top 0.3s,
                        bottom 0.3s;
                }
            }
        }
        .tds-tabs-nav {
            @apply relative flex flex-none items-center px-0;
        }
        &.tds-tabset-theme-default {
            @apply tds-color-border-primary;
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-text-secondary;
                }
                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-brand-primary hover:tds-color-text-brand-primary;
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-disable;
                    }
                }
                &:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-text-brand-primary;
                    }
                }
            }
            &.tds-tabs-horizontal {
                &.tds-tabs-default {
                    &.tds-tabs-top > .tds-tabs-nav:before,
                    &.tds-tabs-bottom > .tds-tabs-nav:before,
                    &.tds-tabs-top > div > .tds-tabs-nav:before,
                    &.tds-tabs-bottom > div > .tds-tabs-nav:before {
                        @apply tds-color-border-primary;
                    }
                }
            }
            &.tds-tabs-default {
                > tds-tabs-nav {
                    > .tds-tabs-nav-wrap {
                        > .tds-tabs-nav-list {
                            > .tds-tabs-ink-bar {
                                @apply tds-color-background-primary-subtle;
                            }
                        }
                    }
                }
            }
        }
        &.tds-tabset-theme-light {
            @apply tds-color-border-primary-light;
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-text-secondary-light;
                }
                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-brand-primary-light hover:tds-color-text-brand-primary-light;
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-disable-light;
                    }
                }
                &:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-text-brand-primary-light;
                    }
                }
            }
            &.tds-tabs-horizontal {
                &.tds-tabs-default {
                    &.tds-tabs-top > .tds-tabs-nav:before,
                    &.tds-tabs-bottom > .tds-tabs-nav:before,
                    &.tds-tabs-top > div > .tds-tabs-nav:before,
                    &.tds-tabs-bottom > div > .tds-tabs-nav:before {
                        @apply tds-color-border-primary-light;
                    }
                }
            }
            &.tds-tabs-default {
                > tds-tabs-nav {
                    > .tds-tabs-nav-wrap {
                        > .tds-tabs-nav-list {
                            > .tds-tabs-ink-bar {
                                @apply tds-color-background-primary-subtle-light;
                            }
                        }
                    }
                }
            }
        }
        &.tds-tabset-theme-dark {
            @apply tds-color-border-primary-dark;
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-text-secondary-dark;
                }
                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-brand-primary-dark hover:tds-color-text-brand-primary-dark;
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-disable-dark;
                    }
                }
                &:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-text-brand-primary-dark;
                    }
                }
            }
            &.tds-tabs-horizontal {
                &.tds-tabs-default {
                    &.tds-tabs-top > .tds-tabs-nav:before,
                    &.tds-tabs-bottom > .tds-tabs-nav:before,
                    &.tds-tabs-top > div > .tds-tabs-nav:before,
                    &.tds-tabs-bottom > div > .tds-tabs-nav:before {
                        @apply tds-color-border-primary-dark;
                    }
                }
            }
            &.tds-tabs-default {
                > tds-tabs-nav {
                    > .tds-tabs-nav-wrap {
                        > .tds-tabs-nav-list {
                            > .tds-tabs-ink-bar {
                                @apply tds-color-background-primary-subtle-light;
                            }
                        }
                    }
                }
            }
        }
        &.tds-tabs-size-small {
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply py-[--tds-tabs-padding-m];
                }
            }
            &.tds-tabs-horizontal {
                .tds-tabs-ink-bar {
                    @apply h-[--tds-tabs-border-width-bottom-s];
                }
            }
            &.tds-tabs-vertical {
                .tds-tabs-ink-bar {
                    @apply w-[--tds-tabs-border-width-bottom-s];
                }
            }
        }
    }
    &.tds-tabs-pill {
        @apply box-border m-0 p-0   w-full;
        .tds-tabs-tab {
            @apply relative inline-block outline-none tds-body-2 tds-font-weight-semibold px-0;

            .tds-tabs-tab-btn {
                @apply rounded-tds-border-radius-circle outline-none w-full  cursor-pointer py-[--tds-tabs-padding-s] px-[--tds-tabs-padding-xl] relative;
            }

            &.tds-tabs-tab-disabled {
                .tds-tabs-tab-btn {
                    @apply cursor-not-allowed;
                }
            }
        }
        &.tds-tabset-theme-default {
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-text-secondary;
                }
                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-background-primary-subtle  tds-color-text-white hover:tds-color-text-white;
                        .tds-tabs-filter-count,
                        .tds-tabs-filter-icon {
                            @apply tds-color-icon-white hover:tds-color-text-white;
                        }
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-disable;
                    }
                }
                &:not(.tds-tabs-tab-disabled):not(.tds-tabs-tab-active) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-background-neutral-minimalist;
                    }
                }
            }
        }
        &.tds-tabset-theme-light {
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-text-secondary-light;
                }
                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-background-primary-subtle-light  tds-color-text-white-light hover:tds-color-text-white-light;
                        .tds-tabs-filter-count,
                        .tds-tabs-filter-icon {
                            @apply tds-color-icon-white-light hover:tds-color-text-white-light;
                        }
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-disable-light;
                    }
                }
                &:not(.tds-tabs-tab-disabled):not(.tds-tabs-tab-active) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-background-neutral-minimalist-light;
                    }
                }
            }
        }
        &.tds-tabset-theme-dark {
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-text-secondary-dark;
                }
                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-background-primary-subtle-dark  tds-color-text-white-dark hover:tds-color-text-white-dark;
                        .tds-tabs-filter-count,
                        .tds-tabs-filter-icon {
                            @apply tds-color-icon-white-dark hover:tds-color-text-white-dark;
                        }
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-disable-dark;
                    }
                }
                &:not(.tds-tabs-tab-disabled):not(.tds-tabs-tab-active) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-background-neutral-minimalist-dark;
                    }
                }
            }
        }
    }
    &.tds-tabs-pill-outline {
        .tds-tabs-tab {
            @apply relative inline-block outline-none tds-body-2 tds-font-weight-semibold px-0;

            .tds-tabs-tab-btn {
                @apply border-tds-border-width-s rounded-tds-border-radius-circle outline-none w-full  cursor-pointer  py-[--tds-tabs-padding-s] px-[--tds-tabs-padding-xl] relative;
            }

            &.tds-tabs-tab-disabled {
                .tds-tabs-tab-btn {
                    @apply cursor-not-allowed;
                }
            }
        }
        &.tds-tabset-theme-default {
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-border-primary  tds-color-text-secondary;
                    .tds-tabs-filter-count {
                        @apply tds-color-icon-secondary;
                    }
                }

                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-border-brand-primary tds-color-text-brand-primary;
                        .tds-tabs-filter-count,
                        .tds-tabs-filter-icon {
                            @apply tds-color-text-brand-primary;
                        }
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-border-disable tds-color-text-disable;
                    }
                }
                &:not(.tds-tabs-tab-disabled):not(.tds-tabs-tab-active) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-background-neutral-minimalist hover:tds-color-border-primary;
                    }
                }
            }
        }
        &.tds-tabset-theme-light {
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-border-primary-light  tds-color-text-secondary-light;
                    .tds-tabs-filter-count {
                        @apply tds-color-icon-secondary-light;
                    }
                }

                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-border-brand-primary-light tds-color-text-brand-primary-light;
                        .tds-tabs-filter-count,
                        .tds-tabs-filter-icon {
                            @apply tds-color-text-brand-primary-light;
                        }
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-border-disable-light tds-color-text-disable-light;
                    }
                }
                &:not(.tds-tabs-tab-disabled):not(.tds-tabs-tab-active) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-background-neutral-minimalist-light hover:tds-color-border-primary-light;
                    }
                }
            }
        }
        &.tds-tabset-theme-dark {
            .tds-tabs-tab {
                .tds-tabs-tab-btn {
                    @apply tds-color-border-primary-dark  tds-color-text-secondary-dark;
                    .tds-tabs-filter-count {
                        @apply tds-color-icon-secondary-dark;
                    }
                }

                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-border-brand-primary-dark tds-color-text-brand-primary-dark;
                        .tds-tabs-filter-count,
                        .tds-tabs-filter-icon {
                            @apply tds-color-text-brand-primary-dark;
                        }
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-border-disable-dark tds-color-text-disable-dark;
                    }
                }
                &:not(.tds-tabs-tab-disabled):not(.tds-tabs-tab-active) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-background-neutral-minimalist-dark hover:tds-color-border-primary-dark;
                    }
                }
            }
        }
    }
    .tds-tabs-nav-wrap {
        @apply relative flex flex-auto items-stretch overflow-hidden whitespace-nowrap max-w-full;
    }
    .tds-tabs-tab-btn.tds-tabs-tab-btn-closable {
        @apply flex items-center;
        .tds-button-close {
            @apply ml-2;
        }
    }
    .tds-tabs-nav-list:not(.tds-tabs-nav-list-vertical) {
        .tds-tabs-nav-add {
            @apply ml-2;
        }
    }
    &.tds-tabs-horizontal {
        @apply flex-col;
    }
}

.tds-tabs-content-holder {
    @apply h-full w-full;
}
.tds-tabs-content {
    @apply h-full w-full;
}

tds-tabset {
    &.tds-tabs {
        &.tds-tabs-card {
            @apply box-border m-0 p-0 flex  w-full;
            > .tds-tabs-nav {
                @apply m-0 p-0;
                .tds-tabs-tab {
                    @apply px-[--tds-tabs-padding-xl] py-[--tds-tabs-padding-m]  border rounded-t-tds-border-radius-s;
                    .tds-tabs-tab-btn {
                        @apply p-0;
                    }
                    &.tds-tabs-tab-active.tds-tabs-tab-default {
                        @apply border-b-transparent;
                    }
                    &:not(.tds-tabs-tab-disabled) {
                        @apply cursor-pointer;
                    }
                    &.tds-tabs-tab-disabled {
                        @apply cursor-not-allowed;
                    }
                }
            }

            &.tds-tabs-horizontal {
                @apply flex-col;
                &.tds-tabs-default {
                    &.tds-tabs-top {
                        > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before {
                            @apply bottom-0;
                        }
                    }
                    &.tds-tabs-top > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                    &.tds-tabs-bottom > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                    &.tds-tabs-top > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                    &.tds-tabs-bottom > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before {
                        @apply absolute right-0 left-0 content-['']  border-b-tds-border-width-s;
                    }
                    &.tds-tabs-top > .tds-tabs-content-holder > .tds-tabs-content-top > .tds-tabs-tabpane-active {
                        @apply border border-t-0;
                    }
                }
                &.tds-tabs-borderless {
                    &.tds-tabs-default {
                        &.tds-tabs-top > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-bottom > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-top > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-bottom > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before {
                            @apply border-0;
                        }
                        &.tds-tabs-top > .tds-tabs-content-holder > .tds-tabs-content-top > .tds-tabs-tabpane-active {
                            @apply border-0;
                        }
                    }
                }
            }
            &.tds-tabset-theme-default {
                .tds-tabs-nav {
                    .tds-tabs-tab {
                        @apply tds-color-border-primary;

                        &.tds-tabs-tab-active.tds-tabs-tab-default {
                            @apply tds-color-surface-high;
                        }
                    }
                }
                &.tds-tabs-horizontal {
                    &.tds-tabs-default {
                        &.tds-tabs-top > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-bottom > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-top > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-bottom > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before {
                            @apply tds-color-border-primary;
                        }
                        &.tds-tabs-top > .tds-tabs-content-holder > .tds-tabs-content-top > .tds-tabs-tabpane-active {
                            @apply tds-color-border-primary;
                        }
                    }
                }
            }
            &.tds-tabset-theme-light {
                .tds-tabs-nav {
                    .tds-tabs-tab {
                        @apply tds-color-border-primary-light;

                        &.tds-tabs-tab-active.tds-tabs-tab-default {
                            @apply tds-color-surface-high-light;
                        }
                    }
                }
                &.tds-tabs-horizontal {
                    &.tds-tabs-default {
                        &.tds-tabs-top > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-bottom > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-top > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-bottom > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before {
                            @apply tds-color-border-primary-light;
                        }
                        &.tds-tabs-top > .tds-tabs-content-holder > .tds-tabs-content-top > .tds-tabs-tabpane-active {
                            @apply tds-color-border-primary-light;
                        }
                    }
                }
            }
            &.tds-tabset-theme-dark {
                .tds-tabs-nav {
                    .tds-tabs-tab {
                        @apply tds-color-border-primary-dark;

                        &.tds-tabs-tab-active.tds-tabs-tab-default {
                            @apply tds-color-surface-high-dark;
                        }
                    }
                }
                &.tds-tabs-horizontal {
                    &.tds-tabs-default {
                        &.tds-tabs-top > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-bottom > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-top > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before,
                        &.tds-tabs-bottom > div > .tds-tabs-nav > .tds-tabs-nav-wrap:before {
                            @apply tds-color-border-primary-dark;
                        }
                        &.tds-tabs-top > .tds-tabs-content-holder > .tds-tabs-content-top > .tds-tabs-tabpane-active {
                            @apply tds-color-border-primary-dark;
                        }
                    }
                }
            }
            &.tds-tabs-size-small {
                .tds-tabs-nav {
                    .tds-tabs-tab {
                        @apply m-0 p-0;
                        .tds-tabs-tab-btn {
                            @apply py-[--tds-tabs-padding-m] px-[--tds-tabs-padding-m];
                        }
                    }
                }
            }
        }
    }

    &.tds-tabs-card.tds-tabs-default {
        &.tds-tabs-horizontal {
            > .tds-tabs-ink-bar {
                @apply invisible;
            }
        }
    }
}

.tds-tabs-filter {
    &.tds-tabs-filter-horizontal {
        .tds-tabs-default {
            .tds-tabs-tab-default {
                @apply px-0;
                .tds-tabs-tab-btn {
                    @apply px-4;
                }
            }
            > tds-tabs-nav {
                > .tds-tabs-nav-wrap {
                    > .tds-tabs-nav-list {
                        > .tds-tabs-ink-bar {
                            @apply absolute select-none pointer-events-none tds-color-background-primary-subtle;
                        }
                    }
                }
            }

            &.tds-tabs-horizontal {
                .tds-tabs-ink-bar {
                    @apply h-[--tds-tabs-border-width-bottom-m] bottom-0;
                    transition:
                        width 0.3s,
                        left 0.3s,
                        right 0.3s;
                }
            }
            &.tds-tabs-vertical {
                .tds-tabs-ink-bar {
                    @apply w-[--tds-tabs-border-width-bottom-m] left-0;
                    transition:
                        height 0.3s,
                        top 0.3s,
                        bottom 0.3s;
                }
            }
        }

        &.tds-tabs-filter-line {
            .tds-tabs {
                .tds-tabs-tab.tds-tabs-tab-custom {
                    @apply px-0;
                    .tds-tabs-tab-btn {
                        @apply flex items-center justify-center tds-color-text-secondary;
                        .tds-tabs-filter-count {
                            @apply rounded-tds-border-radius-circle tds-color-background-neutral-minimalist tds-color-text-secondary;
                        }
                    }
                    &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                        .tds-tabs-tab-btn {
                            @apply tds-color-text-brand-primary hover:tds-color-text-brand-primary;
                            .tds-tabs-filter-label {
                                @apply tds-color-text-brand-primary;
                            }
                            .tds-tabs-filter-count {
                                @apply tds-color-background-primary-subtle tds-color-text-white;
                            }
                            .tds-tabs-filter-icon {
                                @apply tds-color-icon-brand-primary;
                            }
                        }
                    }
                }
            }
        }
        &.tds-tabs-filter-pill {
            .tds-tabs-tab {
                @apply relative inline-block outline-none tds-body-2 tds-font-weight-semibold px-0;

                .tds-tabs-tab-btn {
                    @apply rounded-tds-border-radius-circle outline-none w-full tds-color-text-secondary cursor-pointer py-[--tds-tabs-padding-s] px-[--tds-tabs-padding-xl] relative;
                }

                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-background-primary-subtle;
                        .tds-tabs-filter-label {
                            @apply tds-color-text-white;
                        }
                        .tds-tabs-filter-count {
                            @apply tds-color-text-white;
                        }
                        .tds-tabs-filter-icon {
                            @apply tds-color-icon-white;
                        }
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-disable  cursor-not-allowed;
                    }
                }
                &:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply hover:tds-color-text-brand-primary;
                    }
                }
            }
        }
        &.tds-tabs-filter-pill-outline {
            .tds-tabs-tab {
                @apply relative inline-block outline-none tds-body-2 tds-font-weight-semibold px-0;

                .tds-tabs-tab-btn {
                    @apply border-tds-border-width-s tds-color-border-primary rounded-tds-border-radius-circle outline-none w-full tds-color-text-secondary cursor-pointer py-[--tds-tabs-padding-s] px-[--tds-tabs-padding-xl] relative;
                    .tds-tabs-filter-count {
                        @apply tds-color-icon-secondary;
                    }
                }

                &.tds-tabs-tab-active:not(.tds-tabs-tab-disabled) {
                    .tds-tabs-tab-btn {
                        @apply tds-color-border-brand-primary;
                        .tds-tabs-filter-label {
                            @apply tds-color-text-brand-primary;
                        }
                        .tds-tabs-filter-count {
                            @apply tds-color-text-brand-primary;
                        }
                        .tds-tabs-filter-icon {
                            @apply tds-color-icon-brand-primary;
                        }
                    }
                }
                &.tds-tabs-tab-disabled {
                    .tds-tabs-tab-btn {
                        @apply tds-color-text-disable cursor-not-allowed;
                    }
                }
                // &:not(.tds-tabs-tab-disabled):not(.tds-tabs-tab-active) {
                //     .tds-tabs-tab-btn {
                //         @apply hover:tds-color-text-secondary;
                //     }
                // }
            }
        }
    }
}
.tds-tabs-filter-content {
    @apply flex items-center justify-center;
    .tds-tabs-filter-icon {
        @apply tds-icon-size-m tds-color-icon-secondary mr-[--tds-tabs-margin-base] flex items-center justify-center;
    }
    .tds-tabs-filter-label {
        @apply tds-color-text-secondary;
    }
    .tds-tabs-filter-count {
        @apply ml-[--tds-tabs-margin-base] min-w-[--tds-badge-min-height-m] inline-block text-center tds-caption-1 tds-font-weight-semibold px-[--tds-badge-padding-horizontal-m] py-tds-spacing-2  tds-color-text-secondary;
    }
}
.tds-tabs-dropdown {
    .tds-tabs-filter-content {
        .tds-tabs-filter-icon {
            @apply flex-shrink;
        }
        .tds-tabs-filter-label {
            @apply flex-auto;
        }
        .tds-tabs-filter-count {
            @apply tds-color-icon-secondary;
        }
    }
}
