.tds-card-view {
    --tds-card-view-border-box-shadow: 1px 0 rgba(var(--tds-color-neutral-200-light, 219, 224, 230), 1),
        0 1px rgba(var(--tds-color-neutral-200-light, 219, 224, 230), 1),
        1px 1px rgba(var(--tds-color-neutral-200-light, 219, 224, 230), 1),
        1px 0 rgba(var(--tds-color-neutral-200-light, 219, 224, 230), 1) inset,
        0 1px rgba(var(--tds-color-neutral-200-light, 219, 224, 230), 1) inset;
    @apply tds-color-surface-low tds-card-border-radius relative tds-body-2 box-border m-0 p-0 tds-color-text-primary;
}
.tds-card-view:is(.dark *) {
    --tds-card-view-border-box-shadow: 1px 0 rgba(var(--tds-color-neutral-200-dark, 58, 64, 75), 1),
        0 1px rgba(var(--tds-color-neutral-200-dark, 58, 64, 75), 1),
        1px 1px rgba(var(--tds-color-neutral-200-dark, 58, 64, 75), 1),
        1px 0 rgba(var(--tds-color-neutral-200-dark, 58, 64, 75), 1) inset,
        0 1px rgba(var(--tds-color-neutral-200-dark, 58, 64, 75), 1) inset;
}
.tds-card-view-rtl {
    direction: rtl;
}

.tds-card-view-hoverable {
    cursor: pointer;
    transition:
        box-shadow 0.3s,
        border-color 0.3s;
}

.tds-card-view-hoverable:hover {
    @apply border-transparent  tds-shadow-xl;
}

.tds-card-view-bordered {
    @apply border tds-color-border-primary;
}

.tds-card-view-head {
    @apply min-h-[28px] py-0 px-[--tds-card-padding-l]  tds-color-text-primary bg-transparent;
    .tds-card-view-head-title {
        @apply tds-body-1 tds-font-weight-semibold;
    }
}

.tds-card-view-head:before {
    display: table;
    content: "";
}

.tds-card-view-head:after {
    display: table;
    clear: both;
    content: "";
}

.tds-card-view-head-wrapper {
    @apply flex items-center;
}

.tds-card-view-head-title {
    @apply py-[--tds-card-padding-l] px-0 inline-block flex-1 overflow-hidden whitespace-normal text-ellipsis;
}

.tds-card-view-head-title > .tds-typography,
.tds-card-view-head-title > .tds-typography-edit-content {
    @apply left-0 mt-0 mb-0;
}

// .tds-card-view-head .tds-tabs-top {
//     clear: both;
//     margin-bottom: -17px;
//     color: #000000d9;
//     font-weight: 400;
//     font-size: 14px;
// }

// .tds-card-view-head .tds-tabs-top-bar {
//     border-bottom: 1px solid rgba(0, 0, 0, 0.06);
// }

.tds-card-view-extra {
    @apply py-[--tds-card-padding-l] px-0 tds-color-text-primary ml-auto tds-body-2 tds-font-weight-regular;
}

.tds-card-view-rtl .tds-card-view-extra {
    @apply mr-auto ml-0;
}

.tds-card-view-body {
    @apply py-[--tds-card-padding-m] px-[--tds-card-padding-l];
}
.tds-card-view-body:has(.tds-card-view-meta) {
    @apply py-[--tds-card-padding-l];
}
.tds-card-view-body:before {
    display: table;
    content: "";
}

.tds-card-view-body:after {
    display: table;
    clear: both;
    content: "";
}

.tds-card-view-contain-grid .tds-card-view-body {
    @apply flex flex-wrap;
}

.tds-card-view-contain-grid:not(.tds-card-view-loading) .tds-card-view-body {
    // margin: -1px 0 0 -1px ;
    // padding: 0;
    @apply p-0 -mt-px mr-0 mb-0 -ml-px;
}

.tds-card-view-grid {
    @apply py-[--tds-card-padding-m] px-[--tds-card-padding-l] w-1/3 border-0 rounded-none dark:text-white;
    box-shadow: var(--tds-card-view-border-box-shadow);
    transition: all 0.3s;
}

.tds-card-view-grid-hoverable:hover {
    position: relative;
    z-index: 1;
    box-shadow:
        0 1px 2px -2px #00000029,
        0 3px 6px #0000001f,
        0 5px 12px 4px #00000017;
}

.tds-card-view-contain-tabs > .tds-card-view-head .tds-card-view-head-title {
    @apply pb-0;
}

.tds-card-view-contain-tabs > .tds-card-view-head .tds-card-view-extra {
    @apply pb-0;
}

.tds-card-view-bordered .tds-card-view-cover {
    @apply -mx-px -mt-px;
}

.tds-card-view-cover > * {
    @apply block w-full;
}

.tds-card-view-cover img {
    @apply rounded-t-md rounded-b-none;
}

.tds-card-view-actions {
    @apply bg-transparent flex m-0 p-0 border-t tds-color-border-primary list-none;
}

.tds-card-view-actions:before {
    display: table;
    content: "";
}

.tds-card-view-actions:after {
    display: table;
    clear: both;
    content: "";
}

.tds-card-view-actions > li {
    @apply text-center tds-color-icon-secondary my-[--tds-card-margin-m];
}

.tds-card-view-actions > li > span {
    @apply relative block min-w-[32px] tds-body-2 cursor-pointer h-full;
}

.tds-card-view-actions > li > span:hover {
    @apply tds-color-text-brand-primary;
    transition: color 0.3s;
}

.tds-card-view-actions > li > span a:not(.tds-button),
.tds-card-view-actions > li > span > [class^="tdsi-"] {
    @apply tds-icon-size-m inline-flex tds-color-icon-secondary w-full h-full items-center justify-center;
    transition: color 0.3s;
}

.tds-card-view-actions > li > span a:not(.tds-button):hover,
.tds-card-view-actions > li > span > [class^="tdsi-"]:hover {
    @apply tds-color-text-brand-primary;
}

// .tds-card-view-actions > li > span >  [class^="tdsi-"] {
//     @apply tds-icon-size-s;
// }

.tds-card-view-actions > li:not(:last-child) {
    @apply border-r tds-color-border-primary;
}

.tds-card-view-rtl .tds-card-view-actions > li:not(:last-child) {
    @apply border-r-0 border-l tds-color-border-primary;
}

.tds-card-view-type-inner .tds-card-view-head {
    @apply rounded-t-md rounded-b-none py-0 px-6;
}

.tds-card-view-type-inner .tds-card-view-head-title {
    // @apply p-
    padding: 12px 0;
    font-size: 14px;
}

.tds-card-view-type-inner .tds-card-view-body {
    padding: 16px 24px;
}

.tds-card-view-type-inner .tds-card-view-extra {
    padding: 13.5px 0;
}

.tds-card-view-meta {
    display: flex;
    margin: -4px 0;
}

.tds-card-view-meta:before {
    display: table;
    content: "";
}

.tds-card-view-meta:after {
    display: table;
    clear: both;
    content: "";
}

.tds-card-view-meta-avatar {
    @apply pr-[--tds-card-padding-l];
}

.tds-card-view-rtl .tds-card-view-meta-avatar {
    @apply pr-0 pl-[--tds-card-padding-l];
}

.tds-card-view-meta-detail {
    @apply flex-1 overflow-hidden;
}

.tds-card-view-meta-detail > div:not(:last-child) {
    @apply mb-[--tds-card-margin-m];
}

.tds-card-view-meta-title {
    @apply pt-1 overflow-hidden tds-body-1 tds-font-weight-semibold text-ellipsis whitespace-nowrap;
}

.tds-card-view-meta-description {
    @apply tds-body-2 tds-font-weight-regular;
}

.tds-card-view-loading {
    @apply overflow-hidden;
}

.tds-card-view-loading .tds-card-view-body {
    @apply select-none;
}

.tds-card-view-small > .tds-card-view-head > .tds-card-view-head-wrapper > .tds-card-view-head-title {
    @apply p-0;
}

.tds-card-view-small > .tds-card-view-head > .tds-card-view-head-wrapper > .tds-card-view-extra {
    @apply p-0;
}

.tds-card-view-small > .tds-card-view-body {
    @apply p-0;
}

.tds-card-view-rtl .tds-skeleton-header {
    padding: 0 0 0 16px;
}

tds-card-view {
    @apply block;
}

tds-card-view-meta {
    @apply block;
}

tds-card-view-loading {
    @apply block;
}
