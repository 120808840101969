.tds-gradient-color-picker {
   
    .tds-gradient-color-picker-selector {
        
        &-gradient {
            @apply flex   items-center justify-center px-1;
            .tds-gardient-preview {
                @apply h-5 w-5 rounded-sm border tds-color-border-primary;
            }
            .tds-gardient-type {
                @apply flex-auto first-letter:uppercase pl-1.5  tds-caption-1 tds-color-text-primary;
            }
        }
        &-solid {
            tds-color-picker.tds-color-picker.tds-color-picker-tpye-compact.tds-color-picker-input {
                @apply w-[138px];
                .tds-color-picker-selector {
                    @apply border-0 h-[26px];
                }
            }
        }
        .tds-panel-gradient-color-picker {
            @apply w-[242px] box-border;
            
        }
    }
    &.tds-gradient-color-picker-bordered {
        &.tds-gradient-color-picker-showinput{
            .tds-gradient-color-picker-selector {
                @apply border tds-color-border-primary;
            }
            &.tds-gradient-color-picker-focused {
                .tds-gradient-color-picker-selector {
                    @apply border tds-color-border-brand-primary;
                }
            }
            &:not(.tds-gradient-color-picker-disabled) {
                &:hover {
                    .tds-gradient-color-picker-selector {
                        @apply border tds-color-border-brand-primary;
                    }
                }
            }
        }
        &.tds-gradient-color-picker-inline {
            .tds-panel-gradient-color-picker {
                @apply border tds-color-border-primary rounded-md;
            }
        }
    }   
    &.tds-gradient-color-picker-showinput{
        @apply inline-block;
        .tds-gradient-color-picker-selector {
            @apply rounded w-full h-[28px] cursor-pointer tds-color-surface-lowest;
        }
        &:not(.tds-gradient-color-picker-inline){
            @apply  w-[140px];
        }
    }
}

.tds-angle-gradient-picker {
    @apply box-border flex  items-center;

    &-circle {
        @apply relative flex items-center select-none;
    }
    &-circle::after {
        content: "";
        @apply absolute rounded-full border-2 tds-color-border-primary inset-0 box-border pointer-events-none;
    }

    &-handle {
        @apply tds-color-background-primary-subtle rounded-full absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer;
        z-index: 2;
    }
}

.tds-color-stop-gradient-picker {
    @apply box-border flex flex-col items-center w-full rounded-md;
    .tds-color-stop-gradient-picker-percent {
        @apply w-full rounded border tds-color-border-primary px-1 box-border;
    }
    &-rectangle {
        @apply w-full h-[22px] relative bg-transparent select-none cursor-pointer;
        .point-target {
            @apply absolute top-full;
            .triangle {
                @apply mt-1 -ml-[7px] w-0 h-0 border-x-transparent border-x-[8px] border-b-[8px] border-b-tds-color-neutral-200-light dark:border-b-tds-color-neutral-200-dark select-none pointer-events-none;
            }

            .inner {
                @apply top-[2px] left-[-8px] relative w-0 h-0 border-x-transparent border-x-[8px] border-b-[8px] border-b-tds-color-white-light dark:border-b-tds-color-neutral-20-dark select-none pointer-events-none;
            }
        }
    }

    &-point {
        @apply absolute transform -translate-x-1/2 cursor-pointer rounded-full  w-[10px] h-[22px] top-0  border border-transparent;
        &.point-active {
            @apply cursor-move z-tds-z-index-1 w-[16px] h-[28px] -top-[3px] tds-color-border-primary;
            .point-content {
                @apply border-4 tds-color-border-white;
            }
        }
    }
    .point-content {
        @apply w-full h-full border border-white rounded-full;
    }
    &-color {
        @apply w-full  rounded-md border tds-color-border-primary p-px  bg-white dark:bg-tds-color-neutral-20-dark mt-2.5;
        .tds-color-picker {
            @apply rounded-md;
        }
        .tds-color-picker-select .tds-color-picker-palette .tds-color-picker-saturation {
            @apply rounded-t-md;
        }
    }
}

.tds-panel-gradient-color-picker {
    @apply flex flex-col tds-color-surface-lowest;
    .tds-panel-gradient-color-toolbar {
        @apply py-1.5 px-2 flex items-center justify-between;
        .tds-segmented.tds-segmented-type-default.tds-segmented-theme-default.tds-segmented-color-primary
            .tds-segmented-item {
            @apply border-transparent;
        }
        button.show-input {
            @apply ring-0 ring-transparent;
            &-active {
                @apply tds-color-background-primary-minimal;
            }
        }
    }
    .tds-panel-gradient-color-input {
        @apply py-1.5 px-2;
        textarea {
            @apply tds-color-background-neutral-minimalist tds-color-text-primary tds-caption-1  flex  items-center  focus:outline-none focus:border-transparent shadow-none border-0 bg-transparent text-current  py-1.5 px-1 m-0 w-full outline-none  box-border rounded;
            box-shadow: unset;
            max-height: 44px;
            min-height: 44px;
            height: 44px;
        }
    }
    .tds-panel-gradient-color-body {
        @apply border-t tds-color-border-primary;
        &.tds-panel-gradient-color-type-solid {
            @apply p-0;
        }
        .tds-segmented.tds-segmented-type-default.tds-segmented-theme-default.tds-segmented-color-secondary
            .tds-segmented-item {
            @apply border-transparent;
            .tds-segmented-item-label {
                @apply p-1 flex items-center  justify-center;
                svg {
                    @apply w-4 h-4;
                }
            }
            &:first-child {
                @apply rounded;
            }
            &:last-child {
                @apply rounded;
            }
        }

        // .tds-button-radial-gradient{
        //     @apply
        // }
        .tds-gradient-group-numberic {
            @apply flex border tds-color-border-primary rounded;
            tds-form-field.tds-gradient-color-picker-input-number {
                @apply w-[47px];
                .tds-form-field-wrapper
                    .tds-form-field-container
                    .tds-form-field-container-input
                    .tds-form-field-control.form-field-connection-container {
                    @apply border-0 h-[26px];
                }
            }
            tds-form-field.tds-gradient-color-picker-input-number:last-child {
                @apply border-l tds-color-border-primary;
            }
            &:hover,
            &.tds-gradient-group-numberic-focused {
                @apply tds-color-border-brand-primary;
                tds-form-field.tds-gradient-color-picker-input-number:last-child {
                    @apply tds-color-border-brand-primary;
                }
            }
        }
    }
}

tds-select.tds-color-picker-select .tds-value-container-default .tds-value-template {
    @apply text-tds-font-size-2;
}
tds-select.tds-color-picker-select tds-select-placeholder > div {
    @apply text-tds-font-size-2;
}
.tds-gradient-color-picker-panel-select {
    tds-option-item.tds-select-item.tds-select-item-option {
        @apply py-1 px-1;
        .tds-select-option-default-label {
            @apply tds-caption-1 tds-font-weight-regular;
        }
        .tds-select-option-default-icon > span {
            @apply tds-icon-size-xs;
        }
    }
}
tds-form-field.tds-gradient-color-picker-input-number {
    .tds-form-field-wrapper
        .tds-form-field-container
        .tds-form-field-container-input
        .tds-form-field-control.form-field-connection-container {
        @apply h-[28px]  items-center rounded;
    }
    .tds-input-number .tds-input-number-input-wrap .tds-input-number-input,
    .tds-input-element {
        @apply tds-caption-1;
    }
    .tds-input-number-input-wrap {
        @apply px-0;
    }
    .tds-form-field-suffix {
        @apply pl-0  flex;
    }
    .tds-input-number-input {
        @apply text-center w-full;
    }

    tds-input-number.tds-input-number-focused {
        @apply ring-0 border-0;
    }
    &.tds-form-field-focused {
        &.tds-form-field-default {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply outline-none ring-0 ring-transparent;
                    }
                }
            }
        }
    }

    // &.tds-form-field-theme-default
    //     .tds-form-field-wrapper
    //     .tds-form-field-container
    //     .tds-form-field-container-input
    //     .tds-form-field-control.form-field-connection-container {
    //     @apply border-transparent;
    // }
    // &.tds-form-field-theme-default.tds-form-field-focused
    //     .tds-form-field-wrapper
    //     .tds-form-field-container
    //     .tds-form-field-container-input
    //     .tds-form-field-control.form-field-connection-container {
    //     @apply ring-0;
    // }
}
.tds-edit-gradient-color-picker {
    .tds-radio-group {
        .tds-radio-button-wrapper.tds-radio-size-xs {
            @apply p-1 tds-caption-2;
        }
    }
}
.tds-color-stop-gradient-picker-color {
    > tds-color-picker {
        tds-panel-color-picker .tds-panel-color-picker-wrapper {
            @apply pb-0;
        }
        &.tds-color-picker-inline {
            @apply min-w-[216px] max-w-[220px];
        }
    }
}
.tds-input-gradient-color-picker {
    @apply box-border flex gap-1;
}
.tds-color-stop-gradient-picker-list-input {
    @apply mt-2;
    .tds-input-gradient-color-picker {
        @apply py-0.5 px-2 rounded-sm;
    }
    // .tds-gradient-color-picker-input-number {
    // }
    tds-form-field.tds-form-field-theme-default.tds-gradient-color-picker-input-number
        .tds-form-field-wrapper
        .tds-form-field-container
        .tds-form-field-container-input
        .tds-form-field-control.form-field-connection-container {
        @apply bg-transparent border-transparent dark:border-transparent;
        tds-input-number.tds-input-number-theme-default {
            @apply bg-transparent;
        }
    }

    tds-color-picker.tds-color-picker-theme-default.tds-color-picker.tds-color-picker-input .tds-color-picker-selector {
        @apply bg-transparent;
        tds-input-number.tds-input-number-theme-default {
            @apply bg-transparent;
        }
    }
}
.tds-gradient-color-picker-input-number-alpha {
    background-image: conic-gradient(
        rgba(0, 0, 0, 0.06) 0 25%,
        transparent 0 50%,
        rgba(0, 0, 0, 0.06) 0 75%,
        transparent 0
    );
    background-size: 8px 8px;
}
.tds-mesh-gradient-color-picker {
    tds-color-picker.tds-color-picker-inline {
        width: 216px;
        .tds-panel-color-picker-wrapper {
            @apply pb-0;
        }
    }
    tds-color-picker.tds-color-picker-theme-default.tds-color-picker.tds-color-picker-tpye-compact
        .tds-color-picker-selector {
        @apply bg-transparent;
        tds-input-number.tds-input-number-theme-default {
            @apply bg-transparent;
        }
    }
    .mesh-point-target {
        @apply absolute -top-[12px];
        .triangle {
            @apply mt-1 -ml-[7px] w-0 h-0 border-x-transparent border-x-[8px] border-b-[8px] border-b-tds-color-neutral-200-light dark:border-b-tds-color-neutral-200-dark select-none pointer-events-none;
        }

        .inner {
            @apply top-[2px] left-[-8px] relative w-0 h-0 border-x-transparent border-x-[8px] border-b-[8px] border-b-tds-color-white-light dark:border-b-tds-color-neutral-20-dark select-none pointer-events-none;
        }
    }
}

.tds-gradient-color-picker-dropdown {
    @apply top-full left-0 relative w-full  overflow-x-hidden   shadow-1-lg rounded tds-color-surface-high;
    // &.tds-color-picker-theme-default {
    //     @apply tds-color-surface-high;
    // }
    // &.tds-color-picker-theme-light {
    //     @apply tds-color-surface-high-light;
    // }
    // &.tds-color-picker-theme-dark {
    //     @apply tds-color-surface-high-dark;
    // }
    &-placement-top,
    &-placement-topLeft,
    &-placement-topRight {
        @apply mb-0.5;
    }

    &-placement-right,
    &-placement-rightTop,
    &-placement-rightBottom {
        @apply ml-0.5;
    }

    &-placement-bottom,
    &-placement-bottomLeft,
    &-placement-bottomRight {
        @apply mt-0.5;
    }

    &-placement-left,
    &-placement-leftTop,
    &-placement-leftBottom {
        @apply mr-0.5;
    }
}
