tds-tree-select {
    @apply box-border m-0 p-0 tds-color-text-primary relative inline-block cursor-pointer w-full;
    .tds-tree-select-selector {
        @apply flex rounded border-tds-border-width-s tds-color-border-primary tds-color-surface-lowest;

        tds-tree-select-placeholder {
            @apply pointer-events-none flex-1 overflow-hidden whitespace-nowrap tds-font-weight-regular tds-body-2 tds-color-text-placeholder;
        }
    }

    &.tds-tree-select-focused:not(.tds-tree-select-disabled) {
        .tds-tree-select-selector {
            @apply outline-none tds-color-border-brand-primary ring tds-color-ring-primary;
        }
    }
    .tds-tree-select-arrow {
        @apply absolute flex items-center justify-center top-0 bottom-0 right-0 tds-color-icon-primary tds-icon-size-s;

    }
    .tds-tree-select-clear {
        @apply absolute flex items-center justify-center top-0 bottom-0  tds-color-icon-primary cursor-pointer right-0 opacity-0 pointer-events-none z-[1];
        transition: opacity 0.2s ease-out;
    }
    &.tds-tree-select-single {
        tds-tree-select-search {
            input {
                @apply outline-none bg-transparent border-none appearance-none h-8 w-full p-0 m-0 tds-font-weight-regular tds-body-2;
            }
        }
        // ========================= Selector =========================
        .tds-tree-select-selector {
            display: flex;

            .tds-tree-select-selection-search {
                @apply absolute top-0 bottom-0 flex items-center;
                // right: @input-padding-horizontal-base;
                // left: @input-padding-horizontal-base;

                &-input {
                    @apply tds-font-weight-regular tds-body-2 w-full placeholder:tds-color-text-placeholder  p-0 m-0  h-5 outline-none focus:outline-none focus:border-transparent focus:shadow-none;
                }
            }

            .tds-tree-select-selection-item,
            .tds-tree-select-selection-placeholder {
                @apply p-0 leading-5;
                transition: all 0.3s;
            }

            .tds-tree-select-selection-item {
                @apply relative select-none w-full truncate tds-font-weight-regular tds-body-2 tds-color-text-primary;
            }

            .tds-tree-select-selection-placeholder {
                @apply transition-none pointer-events-none tds-font-weight-regular tds-body-2 tds-color-text-placeholder truncate;
            }

            // For common baseline align
            &::after,
            /* For '' value baseline align */
            .tds-tree-select-selection-item::after,
            /* For undefined value baseline align */
            .tds-tree-select-selection-placeholder::after {
                @apply inline-block w-0 invisible;
                content: "\a0";
            }
        }

        // Opacity selection if open
        &.tds-tree-select-open .tds-tree-select-selection-item {
            @apply tds-color-text-primary;
        }
        // ============================================================
        // // ==                          Size                          ==
        // // ============================================================
        &.tds-tree-select-sm {
            .tds-tree-select-selector {
                @apply h-[30px];
            }
            .tds-tree-select-selection-item,
            .tds-tree-select-selection-placeholder {
                @apply pl-2.5 pr-2.5;
            }
            .tds-tree-select-selection-search {
                @apply left-[11px] right-[11px];
            }
            .tds-tree-select-arrow {
                @apply w-[30px];
            }
            .tds-tree-select-clear {
                @apply w-[30px];
            }
            &.tds-tree-select-show-arrow {
                .tds-tree-select-selection-item,
                .tds-tree-select-selection-placeholder {
                    @apply pr-[30px];
                }
                .tds-tree-select-selection-search {
                    @apply right-[30px];
                }
            }
            // &.tds-tree-select-show-arrow.tds-tree-select-has-clear {
            //     .tds-tree-select-selection-item {
            //         @apply pr-[60px];
            //     }
            //     .tds-tree-select-selection-search {
            //         @apply right-[60px];
            //     }
            // }
        }
        &.tds-tree-select-md {
            .tds-tree-select-selector {
                @apply h-[34px];
            }
            .tds-tree-select-selection-item,
            .tds-tree-select-selection-placeholder {
                @apply pl-3 pr-3 py-1.5;
            }
            .tds-tree-select-selection-search {
                @apply left-[13px] right-[13px];
            }
            .tds-tree-select-arrow {
                @apply w-[34px];
            }
            .tds-tree-select-clear {
                @apply w-[34px];
            }
            &.tds-tree-select-show-arrow {
                .tds-tree-select-selection-item,
                .tds-tree-select-selection-placeholder {
                    @apply pr-[34px];
                }
                .tds-tree-select-selection-search {
                    @apply right-[34px];
                }
            }
            // &.tds-tree-select-show-arrow.tds-tree-select-has-clear {
            //     .tds-tree-select-selection-search {
            //         @apply right-[66px];
            //     }
            //     .tds-tree-select-selection-item {
            //         @apply pr-[66px];
            //     }
            // }
        }
        &.tds-tree-select-lg {
            .tds-tree-select-selector {
                @apply h-[38px];
            }
            .tds-tree-select-selection-item,
            .tds-tree-select-selection-placeholder {
                @apply pl-3.5 pr-3.5  py-2;
            }
            .tds-tree-select-selection-search {
                @apply left-[15px] right-[15px] py-2;
            }
            .tds-tree-select-arrow {
                @apply w-[38px];
            }
            .tds-tree-select-clear {
                @apply w-[38px];
            }
            &.tds-tree-select-show-arrow {
                .tds-tree-select-selection-item,
                .tds-tree-select-selection-placeholder {
                    @apply pr-[38px];
                }
                .tds-tree-select-selection-search {
                    @apply right-[38px];
                }
            }
            // &.tds-tree-select-show-arrow.tds-tree-select-has-clear {
            //     .tds-tree-select-selection-search {
            //         @apply right-[70px];
            //     }
            //     .tds-tree-select-selection-item {
            //         @apply pr-[70px];
            //     }
            // }
        }
        &.tds-tree-select-xl {
            .tds-tree-select-selector {
                @apply h-[42px];
            }
            .tds-tree-select-selection-item,
            .tds-tree-select-selection-placeholder {
                @apply pl-4 pr-4  py-2.5;
            }
            .tds-tree-select-selection-search {
                @apply left-[17px] right-[17px] py-2.5;
            }
            .tds-tree-select-arrow {
                @apply w-[42px];
            }
            .tds-tree-select-clear {
                @apply w-[42px];
            }
            
            // &.tds-tree-select-show-arrow.tds-tree-select-has-clear {
            //     .tds-tree-select-selection-search {
            //         @apply right-[74px];
            //     }
            //     .tds-tree-select-selection-item {
            //         @apply pr-[74px];
            //     }
            // }
        }
    }
   
    &.tds-tree-select-multiple {
        .tds-tree-select-selector {
            @apply relative flex flex-wrap max-w-full pl-3;
        }
        .tds-tree-select-selection-placeholder {
            @apply absolute truncate  tds-tree-text inset-0 pl-3  pr-[34px] top-1/2 h-5;
            transform: translateY(-50%);
            transition: all 0.3s;
        }
        .tds-tree-select-selection-search {
            @apply h-[32px] relative max-w-full flex-auto flex items-center;
            &-input {
                @apply p-0 m-0 w-full min-w-[4.1px] tds-tree-text  outline-none bg-transparent border-none appearance-none h-5 focus:outline-none focus:border-transparent focus:shadow-none;
            }
            &-mirror {
                @apply h-[32px] leading-[30px] absolute top-0 left-0 z-[999] whitespace-pre invisible tds-tree-text;
            }
        }
        .tds-tree-select-selection-item {
            @apply self-center mr-2 border-tds-border-width-s  inline-flex box-border items-center tds-tag-border-radius-s tds-color-background-neutral-minimal tds-color-text-primary border-transparent px-[--tds-tag-padding-horizontal-m] py-[--tds-tag-padding-vertical-m] my-1;
            .tds-tree-select-selection-item-remove {
                @apply cursor-pointer tds-icon-size-s tds-color-icon-secondary  ml-[--tds-tag-margin-base];
            }
            &.tds-tree-select-item-max-tag{
                @apply tds-color-background-info-minimal tds-color-text-info;
            }
        }
        &:not(.tds-tree-select-disabled) {
            .tds-tree-select-selection-item {
                @apply hover:tds-color-background-neutral-subtle;
            }
        }
        &.tds-tree-select-show-arrow {
            .tds-tree-select-selector {
                @apply pr-[34px];
                .tds-tree-select-arrow, .tds-tree-select-clear  {
                    @apply w-[34px];
                }
            }
        }
        // &.tds-tree-select-show-arrow.tds-tree-select-has-clear {
        //     .tds-tree-select-selector {
        //         @apply pr-[66px];
        //     }
        // }

        &.tds-tree-select-sm {
            .tds-tree-select-selector {
                @apply pl-2.5;
            }
            .tds-tree-select-selection-search {
                @apply h-[30px];

                // &-input {
                // }
                // &-mirror {
                // }
            }
            .tds-tree-select-selection-placeholder {
                @apply h-5;
            }
            .tds-tree-select-selection-item {
                @apply my-0.5 mr-1 py-0;
            }
            .tds-tree-select-arrow {
                @apply w-[30px];
            }
            .tds-tree-select-clear {
                @apply w-[30px];
            }
        }

        &.tds-tree-select-lg {
            .tds-tree-select-selection-search {
                @apply h-[38px];
                // &-input {
                // }
                // &-mirror {
                // }
            }
            .tds-tree-select-selection-item {
                @apply mt-1.5 mr-2 h-6 py-0;
            }
            
            .tds-tree-select-arrow {
                @apply w-[38px];
            }
            .tds-tree-select-clear {
                @apply w-[38px];
            }
        }

        &.tds-tree-select-xl {
            .tds-tree-select-selection-search {
                @apply h-[42px];
                &-input {
                }
                &-mirror {
                }
            }
            .tds-tree-select-selection-item {
                @apply mt-2 mr-2 h-6 py-0;
            }
            .tds-tree-select-arrow {
                @apply w-[42px];
            }
            .tds-tree-select-clear {
                @apply w-[42px];
            }

        }
    }
    &.tds-tree-select-show-search {
        @apply cursor-text;
    }
    &.tds-tree-select-disabled {
        @apply cursor-not-allowed;
        .tds-tree-select-selector {
            @apply tds-color-background-disable;
            tds-tree-select-placeholder {
                @apply tds-color-text-disable;
            }
            .tds-tree-select-arrow {
                @apply tds-color-icon-disable;
            }
        }
        .tds-tree-select-selection-search {
            @apply cursor-not-allowed;
            &-input {
                @apply cursor-not-allowed;
            }
        }
    }
    &.tds-tree-select-has-clear {
        &:hover {
            .tds-tree-select-clear {
                @apply opacity-100 pointer-events-auto;
            }
        }
    }
}
.tds-tree-select-dropdown {
    @apply top-full left-0 relative w-full my-1 overflow-auto  tds-color-surface-high shadow-1-lg rounded  p-[--tds-select-padding-tree-dropdown-menu];
    // tds-tree.tds-select-tree .tds-select-tree-treenode tds-tree-node-title {
    //     @apply -my-1;
    // }
}
tds-form-field {
    tds-tree-select {
        .tds-tree-select-selector {
            @apply border-0 w-full;
        }
        &.tds-tree-select-focused:not(.tds-tree-select-disabled) {
            .tds-tree-select-selector {
                @apply ring-0;
            }
        }
    }
}
