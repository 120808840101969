.tds-button-icon-no-spacing {
    @apply rounded-tds-border-radius-xxs p-0 focus:outline-none;
    &-size {
        &-sm {
            @apply tds-icon-size-s;
        }
        &-md {
            @apply tds-icon-size-m;
        }
    }
    &:not(.tds-button-icon-no-spacing-disabled) {
        &.tds-button-icon-no-spacing-theme-default {
            @apply hover:tds-color-background-neutral-minimalist;
        }
        &.tds-button-icon-no-spacing-theme-light {
            @apply hover:tds-color-background-neutral-minimalist-light;
        }
        &.tds-button-icon-no-spacing-theme-dark {
            @apply hover:tds-color-background-neutral-minimalist-dark;
        }
        &.tds-button-icon-no-spacing-primary {
            &.tds-button-icon-no-spacing-theme-default {
                @apply tds-color-icon-brand-primary;
            }
            &.tds-button-icon-no-spacing-theme-light {
                @apply tds-color-icon-brand-primary-light;
            }
            &.tds-button-icon-no-spacing-theme-dark {
                @apply tds-color-icon-brand-primary-dark;
            }
        }
        &.tds-button-icon-no-spacing-secondary {
            &.tds-button-icon-no-spacing-theme-default {
                @apply tds-color-icon-secondary;
            }
            &.tds-button-icon-no-spacing-theme-light {
                @apply tds-color-icon-secondary-light;
            }
            &.tds-button-icon-no-spacing-theme-dark {
                @apply tds-color-icon-secondary-dark;
            }
        }
        &.tds-button-icon-no-spacing-info {
            &.tds-button-icon-no-spacing-theme-default {
                @apply tds-color-icon-info;
            }
            &.tds-button-icon-no-spacing-theme-light {
                @apply tds-color-icon-info-light;
            }
            &.tds-button-icon-no-spacing-theme-dark {
                @apply tds-color-icon-info-dark;
            }
        }
        &.tds-button-icon-no-spacing-success {
            &.tds-button-icon-no-spacing-theme-default {
                @apply tds-color-icon-success;
            }
            &.tds-button-icon-no-spacing-theme-light {
                @apply tds-color-icon-success-light;
            }
            &.tds-button-icon-no-spacing-theme-dark {
                @apply tds-color-icon-success-dark;
            }
        }
        &.tds-button-icon-no-spacing-warning {
            &.tds-button-icon-no-spacing-theme-default {
                @apply tds-color-icon-warning;
            }
            &.tds-button-icon-no-spacing-theme-light {
                @apply tds-color-icon-warning-light;
            }
            &.tds-button-icon-no-spacing-theme-dark {
                @apply tds-color-icon-warning-dark;
            }
        }
        &.tds-button-icon-no-spacing-error {
            &.tds-button-icon-no-spacing-theme-default {
                @apply tds-color-icon-error;
            }
            &.tds-button-icon-no-spacing-theme-light {
                @apply tds-color-icon-error-light;
            }
            &.tds-button-icon-no-spacing-theme-dark {
                @apply tds-color-icon-error-dark;
            }
        }
    }
    &.tds-button-icon-no-spacing-disabled {
        @apply cursor-not-allowed;
        &.tds-button-icon-no-spacing-theme-default {
            @apply tds-color-icon-disable;
        }
        &.tds-button-icon-no-spacing-theme-light {
            @apply tds-color-icon-disable-light;
        }
        &.tds-button-icon-no-spacing-theme-dark {
            @apply tds-color-icon-disable-dark;
        }
    }
}
