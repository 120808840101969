.tds-dropdown-item {
    @apply py-[--tds-dropdown-padding-vertical-s] px-[--tds-dropdown-padding-horizontal] tds-dropdown-text;
    &:not(.tds-dropdown-item-disabled) {
        @apply tds-color-text-primary;
        &:hover {
            @apply tds-color-background-neutral-minimalist cursor-pointer;
        }
    }
    &.tds-dropdown-item-disabled {
        @apply tds-color-text-disable cursor-not-allowed;
    }
}
.tds-dropdown {
    .tds-dropdown-menu-outer-box {
        @apply py-[--tds-dropdown-padding-vertical-s] w-full;
        .tds-dropdown-menu-panel {
            @apply py-[--tds-dropdown-padding-vertical-s] w-full tds-color-surface-high  shadow-tds-shadow-l-light dark:shadow-tds-shadow-l-dark rounded;
        }
    }
}

