.tds-picker-header {
    @apply flex p-[--tds-date-time-picker-padding-calendar-header] tds-color-text-primary tds-body-2 tds-font-weight-semibold;
}
.tds-picker-body {
    @apply px-[--tds-date-time-picker-padding-calendar-body-horizontal] py-[--tds-date-time-picker-padding-calendar-body-vertical];
}
.tds-picker-content {
    td,
    th {
        @apply min-w-[var(--tds-date-time-picker-width-cell-date)];
    }
    thead > tr > th {
        @apply h-[var(--tds-date-time-picker-height-cell-date)] leading-[var(--tds-date-time-picker-height-cell-date)] tds-color-text-primary tds-body-2 tds-font-weight-semibold;
    }
    .tds-picker-cell-week {
        @apply leading-[var(--tds-date-time-picker-height-cell-date)]  min-h-[var(--tds-date-time-picker-height-cell-date)] text-center tds-color-text-primary  tds-body-2 tds-font-weight-semibold;
    }
    .tds-picker-cell {
        @apply px-[--tds-date-time-picker-padding-cell-date-horizontal] tds-color-text-secondary relative tds-font-weight-regular tds-body-2;
        &::before {
            @apply absolute top-1/2 left-0 right-0 z-[1] h-[var(--tds-date-time-picker-height-cell-date)];
            transform: translateY(-50%);
            transition: all 0.3s;
            content: "";
        }
    }
    .tds-picker-cell-inner {
        @apply inline-block z-[2] min-w-[var(--tds-date-time-picker-width-cell-date)] h-[var(--tds-date-time-picker-height-cell-date)] leading-[var(--tds-date-time-picker-height-cell-date)] text-center rounded-tds-border-radius-s relative border-tds-border-width-s border-transparent cursor-pointer;
        transition:
            background 0.3s,
            border 0.3s;
    }
    .tds-picker-cell.tds-picker-cell-disabled {
        .tds-picker-cell-inner {
            @apply pointer-events-none cursor-not-allowed tds-color-text-disable line-through;
        }
    }
    .tds-picker-cell-in-view {
        @apply tds-color-text-primary;
        &:after {
            @apply border-x-transparent;
        }
    }
    .tds-picker-cell-today {
        .tds-picker-cell-inner:before {
            @apply absolute inset-0 z-[1] content-[''] border-tds-border-width-s tds-color-border-brand-primary tds-date-time-picker-border-radius;
        }
        &.tds-picker-cell-disabled {
            .tds-picker-cell-inner:before {
                @apply tds-color-border-disable;
            }
        }
    }
    tr:not(.tds-picker-week-panel-row) {
        .tds-picker-cell-selected.tds-picker-cell-in-view {
            &:not(.tds-picker-cell-disabled) {
                .tds-picker-cell-inner {
                    @apply tds-color-border-brand-primary tds-color-background-primary-subtle tds-color-text-white;
                }
            }
        }
        .tds-picker-cell-in-view.tds-picker-cell-selected,
        .tds-picker-cell-in-view.tds-picker-cell-range-start,
        .tds-picker-cell-in-view.tds-picker-cell-range-end {
            .tds-picker-cell-inner {
                @apply tds-color-background-primary-subtle tds-color-text-white;
            }
        }
        .tds-picker-cell:hover:not(.tds-picker-cell-in-view):not(.tds-picker-cell-selected):not(
                .tds-picker-cell-disabled
            ),
        .tds-picker-cell:hover:not(.tds-picker-cell-selected):not(.tds-picker-cell-range-start):not(
                .tds-picker-cell-range-end
            ):not(.tds-picker-cell-range-hover-start):not(.tds-picker-cell-range-hover-end):not(
                .tds-picker-cell-disabled
            ) {
            .tds-picker-cell-inner {
                @apply tds-color-surface-medium-hover;
            }
        }
    }
    //week
    .tds-picker-week-panel-row {
        td {
            &.tds-picker-cell:first-child {
                @apply rounded-l-tds-border-radius-s rounded-r-none;
            }
            &:last-child {
                @apply rounded-r-tds-border-radius-s rounded-l-none;
            }
        }
        &.tds-picker-week-panel-row-show-week {
            td.tds-picker-cell:nth-child(2) {
                @apply rounded-l-tds-border-radius-s rounded-r-none;
            }
        }
    }
    .tds-picker-week-panel-row td {
        transition: background 0.3s;
    }

    .tds-picker-week-panel-row:hover td.tds-picker-cell {
        @apply tds-color-surface-medium-hover;
    }
    .tds-picker-week-panel-row-selected,
    .tds-picker-week-panel-row-selected:hover {
        td.tds-picker-cell:not(:first-child):not(:last-child) {
            @apply tds-date-time-picker-color-background-in-range;
        }
        td.tds-picker-cell:last-child,
        td.tds-picker-cell:first-child {
            @apply tds-color-background-primary-subtle tds-color-text-white;
        }
        &.tds-picker-week-panel-row-show-week {
            td.tds-picker-cell:nth-child(2) {
                @apply tds-color-background-primary-subtle tds-color-text-white;
            }
        }
    }
    .tds-picker-cell-in-view.tds-picker-cell-in-range {
        @apply relative;
        &:before {
            @apply tds-date-time-picker-color-background-in-range;
        }
    }
    .tds-picker-cell-in-view.tds-picker-cell-range-start:not(.tds-picker-cell-range-start-single):not(
            .tds-picker-cell-range-end
        )
        .tds-picker-cell-inner {
        @apply rounded-l-tds-border-radius-s rounded-r-none;
    }
    .tds-picker-cell-in-view.tds-picker-cell-range-start::before {
        @apply left-1/2;
    }
    .tds-picker-cell-in-view.tds-picker-cell-range-end:not(.tds-picker-cell-range-end-single):not(
            .tds-picker-cell-range-start
        )
        .tds-picker-cell-inner {
        @apply rounded-r-tds-border-radius-s rounded-l-none;
    }
    .tds-picker-cell-in-view.tds-picker-cell-range-end::before {
        @apply right-1/2;
    }
    .tds-picker-cell-in-view.tds-picker-cell-in-range.tds-picker-cell-range-hover::before,
    .tds-picker-cell-in-view.tds-picker-cell-range-end.tds-picker-cell-range-hover::before,
    .tds-picker-cell-in-view.tds-picker-cell-range-end:not(
            .tds-picker-cell-range-end-single
        ).tds-picker-cell-range-hover-end::before,
    .tds-picker-cell-in-view.tds-picker-cell-range-start.tds-picker-cell-range-hover::before,
    .tds-picker-cell-in-view.tds-picker-cell-range-start:not(
            .tds-picker-cell-range-start-single
        ).tds-picker-cell-range-hover-start::before {
        @apply tds-date-time-picker-color-background-in-range;
    }
    .tds-picker-cell-in-view.tds-picker-cell-range-start:not(.tds-picker-cell-range-start-single):before,
    .tds-picker-cell-in-view.tds-picker-cell-range-end:not(.tds-picker-cell-range-end-single):before {
        @apply tds-date-time-picker-color-background-in-range;
    }

    .tds-picker-cell-in-view.tds-picker-cell-range-hover-end.tds-picker-cell-range-end-single::after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-end.tds-picker-cell-range-start.tds-picker-cell-range-end.tds-picker-cell-range-start-near-hover::after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-end:not(.tds-picker-cell-in-range):not(
            .tds-picker-cell-range-start
        ):not(.tds-picker-cell-range-end)::after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-start.tds-picker-cell-range-start-single::after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-start.tds-picker-cell-range-start.tds-picker-cell-range-end.tds-picker-cell-range-end-near-hover::after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-start:not(.tds-picker-cell-in-range):not(
            .tds-picker-cell-range-start
        ):not(.tds-picker-cell-range-end)::after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover:not(.tds-picker-cell-in-range)::after {
        border-top: 1px dashed;
        border-bottom: 1px dashed;
        transform: translateY(-50%);
        // transition: all 0.3s;
        content: "";
        @apply absolute top-1/2 z-0 tds-color-border-brand-primary  h-[var(--tds-date-time-picker-height-cell-date)];
        //
    }
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-start:not(.tds-picker-cell-in-range):not(
            .tds-picker-cell-range-start
        ):not(.tds-picker-cell-range-end):after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-end:not(.tds-picker-cell-in-range):not(
            .tds-picker-cell-range-start
        ):not(.tds-picker-cell-range-end):after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-start.tds-picker-cell-range-start-single:after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-start.tds-picker-cell-range-start.tds-picker-cell-range-end.tds-picker-cell-range-end-near-hover:after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-end.tds-picker-cell-range-start.tds-picker-cell-range-end.tds-picker-cell-range-start-near-hover:after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-end.tds-picker-cell-range-end-single:after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover:not(.tds-picker-cell-in-range):after {
        border-top: 1px dashed;
        border-bottom: 1px dashed;
        transform: translateY(-50%);
        // transition: all 0.3s; //chưa fix dc lỗi hiển thị border khi hover
        content: "";
        @apply tds-color-border-brand-primary absolute top-1/2 z-0  h-[var(--tds-date-time-picker-height-cell-date)];
    }

    .tds-picker-cell-range-hover-start:after,
    .tds-picker-cell-range-hover-end:after,
    .tds-picker-cell-range-hover:after {
        right: 0;
        left: 2px;
    }

    .tds-picker-cell-range-hover.tds-picker-cell-range-start:after {
        right: 50%;
    }

    .tds-picker-cell-range-hover.tds-picker-cell-range-end:after {
        left: 50%;
    }

    tr > .tds-picker-cell-in-view.tds-picker-cell-range-hover:first-child:after,
    tr > .tds-picker-cell-in-view.tds-picker-cell-range-hover-end:first-child:after,
    .tds-picker-cell-in-view.tds-picker-cell-start.tds-picker-cell-range-hover-edge-start.tds-picker-cell-range-hover-edge-start-near-range:after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-edge-start:not(
            .tds-picker-cell-range-hover-edge-start-near-range
        ):after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-start:after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-start:not(.tds-picker-cell-in-range):not(
            .tds-picker-cell-range-start
        ):not(.tds-picker-cell-range-end):after {
        left: 6px;
        border-left: 1px dashed;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        @apply tds-color-border-brand-primary;
    }

    tr > .tds-picker-cell-in-view.tds-picker-cell-range-hover:last-child:after,
    tr > .tds-picker-cell-in-view.tds-picker-cell-range-hover-start:last-child:after,
    .tds-picker-cell-in-view.tds-picker-cell-end.tds-picker-cell-range-hover-edge-end.tds-picker-cell-range-hover-edge-end-near-range:after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-edge-end:not(
            .tds-picker-cell-range-hover-edge-end-near-range
        ):after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-end:after,
    .tds-picker-cell-in-view.tds-picker-cell-range-hover-end:not(.tds-picker-cell-in-range):not(
            .tds-picker-cell-range-start
        ):not(.tds-picker-cell-range-end):after {
        right: 6px;
        border-right: 1px dashed;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        @apply tds-color-border-brand-primary;
    }

    .tds-picker-cell-disabled {
        @apply tds-color-text-disable;
        pointer-events: none;
    }

    .tds-picker-cell-disabled .tds-picker-cell-inner {
        @apply bg-transparent;
    }

    .tds-picker-cell-disabled.tds-picker-cell-today .tds-picker-cell-inner:before {
        @apply tds-color-border-disable;
    }
}
.tds-picker-footer {
    @apply border-t-tds-border-width-s tds-color-border-primary py-[--tds-date-time-picker-padding-calendar-footer-vertical] px-[--tds-date-time-picker-padding-calendar-footer-horizontal];
    .tds-picker-ranges {
        @apply flex;
    }
}

.tds-calendar-footer-show-now,
.tds-calendar-footer-show-today,
.tds-picker-single {
    .tds-picker-footer {
        @apply flex w-full justify-center;
    }
}
.tds-calendar-footer-has-timepicker.tds-calendar-footer-range {
    .tds-picker-footer {
        @apply flex w-full justify-end;
    }
}
.tds-calendar-footer-show-apply.tds-calendar-footer-range {
    .tds-picker-footer.tds-picker-footer-show-apply {
        @apply flex w-full items-center py-1.5;
        .tds-picker-footer-value {
            @apply mx-2 tds-body-2 tds-font-weight-regular tds-color-text-primary;
        }
        .tds-picker-footer-messege-erorr {
            @apply max-w-[500px];
        }
    }
}
.tds-picker-date-panel
    .tds-picker-cell-in-view.tds-picker-cell-in-range.tds-picker-cell-range-hover-start
    .tds-picker-cell-inner:after {
    right: -6px;
    left: 0;
}

.tds-picker-date-panel
    .tds-picker-cell-in-view.tds-picker-cell-in-range.tds-picker-cell-range-hover-end
    .tds-picker-cell-inner:after {
    right: 0;
    left: -6px;
}
.tds-picker-panel
    > :not(.tds-picker-date-panel)
    .tds-picker-cell-in-view.tds-picker-cell-in-range.tds-picker-cell-range-hover-end::before,
.tds-picker-panel
    > :not(.tds-picker-date-panel)
    .tds-picker-cell-in-view.tds-picker-cell-in-range.tds-picker-cell-range-hover-start::before {
    @apply tds-date-time-picker-color-background-in-range;
}
.tds-picker-month-panel,
.tds-picker-year-panel,
.tds-picker-decade-panel {
    .tds-picker-content {
        .tds-picker-cell-inner {
            @apply min-w-[--tds-date-time-picker-width-cell-month-year] capitalize tds-body-1 tds-font-weight-regular;
            line-height: var(--tds-date-time-picker-height-cell-date) !important;
        }
        .tds-picker-cell {
            &.tds-picker-cell-in-view {
                @apply tds-color-text-primary;
            }
            &.tds-picker-cell-this-month,
            &.tds-picker-cell-this-year {
                &:not(.tds-picker-cell-selected) {
                    .tds-picker-cell-inner {
                        @apply tds-body-1 tds-font-weight-semibold tds-color-text-brand-primary;
                    }
                }
            }
        }
        tr {
            td {
                @apply py-2;
            }
        }
    }
}
.tds-picker-date-range-wrapper {
    .tds-picker-panel-container {
        @apply flex  items-stretch justify-center;
    }
    .tds-picker-date-range-menu-wrapper {
        @apply tds-color-border-primary border-r-tds-border-width-s  tds-color-text-primary tds-font-weight-regular tds-body-2 py-[--tds-date-time-picker-padding-quick-view-panel-vertical] min-w-[--tds-date-time-picker-width-cell-date-range-menu];
    }
    .tds-picker-date-range-menu-item {
        @apply py-[--tds-date-time-picker-padding-cell-date-range-vertical] px-[--tds-date-time-picker-padding-cell-date-range-horizontal];
        &-active {
            @apply tds-color-background-primary-subtle tds-color-text-white;
        }
        &:not(.tds-picker-date-range-menu-item-custom) {
            @apply hover:tds-color-background-primary-subtle hover:tds-color-text-white cursor-pointer;
        }
    }
}
.tds-picker {
    @apply relative box-border m-0 p-0 tds-color-text-primary tds-color-border-primary;
    &.tds-picker-inline {
        @apply inline-flex;
        .tds-picker-wrapper {
            @apply tds-color-surface-medium py-[--tds-date-time-picker-padding-calendar-vertical] tds-date-time-picker-border-radius;
        }
        &.tds-picker-range {
            .tds-picker-wrapper {
                @apply py-0;
            }
        }
    }

    &.tds-picker-range {
        .tds-picker-range-wrapper {
            @apply p-0;
        }
        &.tds-picker-hidden-input {
            @apply cursor-pointer;
        }
    }

    &:not(.tds-picker-inline) {
        .tds-picker-prefix {
            @apply relative flex-shrink-0  cursor-pointer  flex items-center  mr-tds-spacing-4;
            > i {
                @apply tds-color-icon-primary tds-icon-size-m;
            }
        }
        .tds-picker-suffix {
            @apply relative flex-shrink-0 tds-color-icon-primary tds-icon-size-m cursor-pointer  flex items-center ml-[--tds-select-margin-m];
        }
        .tds-picker-clear {
            transition: all 0.1s ease-out;
            @apply absolute inset-0 tds-color-surface-lowest opacity-0 items-center justify-center;
        }
        &.tds-picker-single {
            border-style: var(--tds-border-style-base, solid);
            @apply flex items-stretch border-tds-border-width-s  rounded-tds-border-radius-xs;
            .tds-picker-input {
                @apply flex-auto flex items-center;
                input {
                    @apply focus:ring-0 p-0 tds-font-weight-regular  tds-body-2  focus:outline-none focus:border-transparent shadow-none border-0 bg-transparent text-current m-0 w-full outline-none  box-border placeholder:tds-color-text-placeholder;
                }
            }
        }
        &.tds-picker-range {
            border-style: var(--tds-border-style-base, solid);
            @apply flex items-stretch border-tds-border-width-s rounded-tds-border-radius-xs;
            .tds-picker-input {
                @apply flex-auto;
                input {
                    @apply focus:ring-0 p-0 tds-font-weight-regular  tds-body-2  focus:outline-none focus:border-transparent shadow-none border-0 bg-transparent text-current m-0 w-full outline-none  box-border placeholder:tds-color-text-placeholder;
                }
            }
            .tds-picker-range-separator {
                @apply px-[--tds-date-time-picker-padding-date-range-hyphen-horizontal];
                .tds-picker-separator-default {
                    @apply text-tds-font-size-2 leading-3;
                }
            }
            .tds-picker-active-bar {
                bottom: 1px;
                height: 2px;
                margin-left: 7px;
                opacity: 0;
                transition: all 0.3s ease-out;
                @apply tds-color-background-primary-subtle pointer-events-none opacity-0;
            }
        }

        &.tds-picker-sm {
            @apply py-[--tds-select-padding-s] pl-[--tds-select-padding-left-s] pr-[--tds-select-padding-s];

            // .tds-picker-clear {
            //     @apply px-1;
            // }
            // .tds-picker-suffix {
            //     @apply flex items-center justify-center;
            // }
        }
        &.tds-picker-md {
            @apply py-[--tds-select-padding-m] pl-[--tds-select-padding-left-s] pr-[--tds-select-padding-m];
            // .tds-picker-range-wrapper-input {
            //     @apply pl-3 py-[--tds-select-padding-m];
            // }
            // .tds-picker-clear {
            //     @apply px-1.5;
            // }
            // .tds-picker-suffix {
            //     @apply flex items-center justify-center;
            // }
        }

        &.tds-picker-lg {
            @apply py-[--tds-select-padding-l] pl-[--tds-select-padding-left-l] pr-[--tds-select-padding-l];

            // .tds-picker-clear {
            //     @apply px-2;
            // }
            // .tds-picker-suffix {
            //     @apply flex items-center justify-center;
            // }
        }
        &.tds-picker-xl {
            @apply py-[--tds-select-padding-xl] pl-[--tds-select-padding-left-s] pr-[--tds-select-padding-xl];
            // .tds-picker-clear {
            //     @apply px-2;
            // }
            // .tds-picker-suffix {
            //     @apply flex items-center justify-center;
            // }
        }
    }
    &:not(.tds-picker-disabled) {
        &:not(.tds-picker-borderless) {
            @apply tds-color-surface-lowest;
        }
        .tds-picker-input {
            input {
                @apply tds-color-text-primary;
            }
        }
        &.tds-picker-show-clear {
            &:hover {
                .tds-picker-clear {
                    @apply opacity-100;
                }
            }
        }
    }
    &:not(.tds-picker-focused) {
        &.ng-invalid.ng-touched {
            @apply outline-none ring-0 tds-color-border-error;
        }
    }
    &.tds-picker-focused {
        .tds-picker-suffix {
            @apply bg-transparent border-transparent;
        }
        &:not(.tds-picker-disabled) {
            .tds-picker-active-bar {
                @apply opacity-100;
            }
        }
        &:not(.tds-picker-borderless) {
            @apply outline-none border-tds-border-width-s ring tds-color-ring-primary tds-color-border-brand-primary;
        }
    }
    &.tds-picker-disabled {
        @apply cursor-not-allowed tds-color-background-disable tds-color-border-disable tds-color-text-disable;
        .tds-picker-clear {
            @apply hidden cursor-not-allowed tds-color-background-disable;
        }
        .tds-picker-suffix {
            @apply cursor-not-allowed tds-color-text-disable;
        }
        .tds-picker-input {
            input {
                @apply cursor-not-allowed tds-color-text-disable;
            }
        }
    }
    &:not(.tds-picker-disabled):not(.tds-picker-focused):not(.ng-invalid.ng-touched):not(.tds-picker-borderless) {
        &:hover {
            @apply tds-color-border-brand-primary;
        }
    }
    &.tds-picker-borderless {
        @apply border-none border-transparent dark:border-transparent;
    }
    &.tds-picker-hidden-input {
        .tds-picker-input-wrapper {
            @apply h-5 flex-auto truncate tds-body-2 tds-font-weight-regular;
            &.tds-picker-input-placeholder {
                @apply tds-color-text-placeholder;
            }
            &.tds-picker-input-value {
                @apply tds-color-text-primary;
            }
        }
        &.tds-picker-borderless {
            @apply inline-flex;
            .tds-picker-input-wrapper {
                @apply flex-none;
                &.tds-picker-input-placeholder {
                    @apply text-right;
                }
                &.tds-picker-input-value {
                    @apply text-right;
                }
            }
        }
    }
}
.tds-picker-datetime-panel {
    @apply flex;
    .tds-picker-date-panel,
    .tds-picker-month-panel,
    .tds-picker-year-panel,
    .tds-picker-week-panel {
        @apply border-r-tds-border-width-s tds-color-border-primary;
    }
    tds-time-picker-panel {
        .tds-picker-header {
            @apply border-b-tds-border-width-s tds-color-border-primary text-center;
            .tds-picker-header-view {
                @apply flex items-center justify-center w-full;
                line-height: 30px !important;
            }
        }
    }
}
.tds-picker-dropdown-range-inline {
    .tds-picker-content {
        .tds-picker-cell.tds-picker-cell-in-view {
            &.tds-picker-cell-range-has-value:not(.tds-picker-cell-in-range):not(
                    .tds-picker-cell-range-hover-by-ranges
                ):after {
                @apply border-transparent;

                &.tds-picker-cell-range-hover-start,
                &.tds-picker-cell-range-hover-end {
                    @apply border-transparent;
                }
            }
            &.tds-picker-cell-range-start-single:not(.tds-picker-cell-range-hover-by-ranges) {
                &:not(.tds-picker-cell-range-hover-inline),
                &:not(.tds-picker-cell-range-hover-inline).tds-picker-cell-range-hover-start {
                    &:after {
                        @apply border-transparent;
                    }
                }
            }
        }
        .tds-picker-cell:not(.tds-picker-cell-selected):not(.tds-picker-cell-disabled):not(
                .tds-picker-cell-in-range
            ):not(.tds-picker-cell-range-start):not(.tds-picker-cell-range-end) {
            &.tds-picker-cell-range-hover-start:hover,
            &.tds-picker-cell-range-has-value.tds-picker-cell-range-hover-end:hover {
                .tds-picker-cell-inner {
                    @apply tds-color-surface-medium-hover;
                }
            }
        }
    }
}
tds-form-field {
    .tds-picker {
        &.tds-picker-single,
        &.tds-picker-range,
        &:not(.tds-picker-inline).tds-picker-single,
        &:not(.tds-picker-inline).tds-picker-range {
            @apply border-none ring-0;
            &.ng-invalid.ng-touched:not(.tds-picker-focused) {
                @apply border-none ring-0 ring-transparent;
            }
        }
    }
}
