.tds-resizable-preview {
    @apply absolute inset-x-0 z-0 border border-dashed tds-color-border-primary;
    
}

.tds-resizable-handle {
    @apply absolute z-[9] select-none;
}

.tds-resizable-handle-top {
    @apply top-[-5px] left-0 w-full h-2.5;
}

.tds-resizable-handle-right {
    @apply right-[-5px] top-0 h-full w-2.5;
}

.tds-resizable-handle-bottom {
    @apply bottom-[-5px] left-0 w-full h-2.5;
}

.tds-resizable-handle-left {
    @apply top-0 left-[-5px] w-2.5 h-full;
}

.tds-resizable-handle-topRight {
    @apply -inset-x-[5px] z-10 w-5 h-5;
}

.tds-resizable-handle-bottomRight {
    @apply -right-[5px] -bottom-[5px] z-10 w-5 h-5;
    
}

.tds-resizable-handle-bottomLeft {
    @apply -left-[5px] -bottom-[5px] z-10 w-5 h-5;
   
}

.tds-resizable-handle-topLeft {
    @apply -left-[5px] -top-[5px] z-10 w-5 h-5;
    
}

.tds-resizable .tds-resizable-handle-cursor-type-window.tds-resizable-handle-bottom,.tds-resizable .tds-resizable-handle-cursor-type-window.tds-resizable-handle-top {
    cursor: ns-resize
}

.tds-resizable .tds-resizable-handle-cursor-type-window.tds-resizable-handle-left,.tds-resizable .tds-resizable-handle-cursor-type-window.tds-resizable-handle-right {
    cursor: ew-resize
}

.tds-resizable .tds-resizable-handle-cursor-type-grid.tds-resizable-handle-bottom,.tds-resizable .tds-resizable-handle-cursor-type-grid.tds-resizable-handle-top {
    cursor: row-resize
}

.tds-resizable .tds-resizable-handle-cursor-type-grid.tds-resizable-handle-left,.tds-resizable .tds-resizable-handle-cursor-type-grid.tds-resizable-handle-right {
    cursor: col-resize
}

.tds-resizable .tds-resizable-handle-bottomRight,.tds-resizable .tds-resizable-handle-topLeft {
    cursor: nwse-resize
}

.tds-resizable .tds-resizable-handle-bottomLeft,.tds-resizable .tds-resizable-handle-topRight {
    cursor: nesw-resize
}

.tds-resizable-disabled .tds-resizable-handle {
    pointer-events: none
}