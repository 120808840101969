tds-timeline {
    --tds-timeline-margin-left:var(--tds-spacing-15,40px); 
    @apply box-border m-0 p-0 block;
    .tds-timeline {
        @apply box-border tds-body-2  tds-font-weight-regular tds-color-text-primary m-0 p-0 list-none;
        .tds-timeline-item {
            @apply relative m-0 pb-4 tds-body-2  tds-font-weight-regular list-none flex items-start justify-start;
            .tds-timeline-item-tail {
                @apply absolute top-[24px] left-[11px] h-[calc(100%-24px)] border-l-2 tds-color-border-primary;
            }
            .tds-timeline-item-head {
                @apply m-1.5 min-w-[--tds-timeline-width-circle] min-h-[--tds-timeline-height-circle] rounded-full border-2 box-content;
                &.tds-timeline-item-head-primary {
                    @apply tds-color-border-brand-primary;
                }
                &.tds-timeline-item-head-secondary {
                    @apply tds-color-border-neutral-subtle;
                }
                &.tds-timeline-item-head-info {
                    @apply tds-color-border-info;
                }
                &.tds-timeline-item-head-success {
                    @apply tds-color-border-success;
                }
                &.tds-timeline-item-head-warning {
                    @apply tds-color-border-warning;
                }
                &.tds-timeline-item-head-error {
                    @apply tds-color-border-error;
                }
                &.tds-timeline-item-head-custom {
                    @apply min-w-[16px] min-h-[16px] m-1  text-center border-0 rounded-none  box-content flex items-center justify-center;
                    .tds-timeline-item-head-spinner {
                        @apply w-4 h-4  block box-border;
                    }
                }
            }
            .tds-timeline-item-content {
                @apply relative break-words ml-[--tds-timeline-margin-left] pt-0.5;
            }
            &.tds-timeline-item-pending {
                .tds-timeline-item-head {
                    @apply tds-icon-size-s bg-transparent;
                }
                .tds-timeline-item-tail {
                    @apply hidden;
                }
                &.tds-timeline-item-head-custom {
                    @apply absolute w-4 h-4 p-0 m-1 text-center border-0 rounded-none  box-content tds-icon-size-s;
                    .tds-timeline-item-head-spinner {
                        @apply w-full h-full  block box-border;
                    }
                }
            }
            &.tds-timeline-item-last {
                > .tds-timeline-item-tail {
                    @apply hidden;
                }
                > .tds-timeline-item-content {
                    min-height: 48px;
                }
            }
        }
        &.tds-timeline-alternate {
            .tds-timeline-item-tail {
                @apply left-1/2 top-[24px] h-[calc(100%-24px)];
                // margin-left: 2px;
            }
            .tds-timeline-item-head {
                @apply left-1/2 absolute;
                margin-left: -5px;
            }
            .tds-timeline-item-head.tds-timeline-item-head-custom {
                @apply left-1/2 m-0 ml-[-12px] p-1 flex items-center justify-center;
            }

            .tds-timeline-item-left {
                .tds-timeline-item-content {
                    left: calc(50% - 16px);
                    width: calc(50% - 12px);
                    text-align: left;
                }
            }
            .tds-timeline-item-right {
                .tds-timeline-item-content {
                    width: calc(50% - 12px);
                    margin: 0;
                    text-align: right;
                }
            }
        }
        &.tds-timeline-right {
            .tds-timeline-item-tail {
                left: 50%;
            }
            .tds-timeline-item-head {
                @apply absolute left-1/2;
                margin-left: -4px;
            }
            .tds-timeline-item-head-custom {
                left: 50%;
                margin-left: 1px;
            }
            .tds-timeline-item-left {
                .tds-timeline-item-content {
                    left: calc(50% - 4px);
                    width: calc(50% - 14px);
                    text-align: left;
                }
            }
            .tds-timeline-item-right {
                .tds-timeline-item-content {
                    width: calc(50% - 12px);
                    margin: 0;
                    text-align: right;
                    width: calc(100% - 18px);
                }
                .tds-timeline-item-tail {
                    left: calc(100% - 6px);
                }
                .tds-timeline-item-head {
                    @apply absolute;
                    left: calc(100% - 6px);
                }
                .tds-timeline-item-head-custom {
                    left: calc(100% - 16px);
                }
            }
        }
        &.tds-timeline-label {
            .tds-timeline-item-tail {
                left: 50%;
            }
            .tds-timeline-item-head {
                position: absolute;
                left: 50%;
                margin-left: -4px;
            }
            .tds-timeline-item-head-custom {
                left: 50%;
                margin-left: 1px;
            }
            .tds-timeline-item-left {
                .tds-timeline-item-label {
                    top: 2px;
                }
                .tds-timeline-item-content {
                    left: calc(50% - 4px);
                    width: calc(50% - 14px);
                    text-align: left;
                }
            }
            .tds-timeline-item-right {
                .tds-timeline-item-content {
                    width: calc(50% - 12px);
                    margin: 0;
                    text-align: right;
                }
                .tds-timeline-item-label {
                    left: calc(50% + 14px);
                    width: calc(50% - 14px);
                    text-align: left;
                    top: 2px;
                }
            }
            .tds-timeline-item-label {
                position: absolute;
                top: -7.001px;
                width: calc(50% - 12px);
                text-align: right;
            }
        }
        &.tds-timeline-pending {
            &.tds-timeline-label{
                .tds-timeline-item-head.tds-timeline-item-head-custom {
                    margin-left: -6px;
                }
            }
            .tds-timeline-item-last {
                .tds-timeline-item-tail {
                    @apply block h-[calc(100%-24px)] border-l-2  border-dotted tds-color-border-primary;
                }
            }
        }
        &.tds-timeline-reverse {
            .tds-timeline-item-last {
                .tds-timeline-item-tail {
                    @apply hidden;
                }
            }
            .tds-timeline-item-pending {
                .tds-timeline-item-tail {
                    top: 24px;
                    @apply block h-[calc(100%-24px)] border-l-2  border-dotted tds-color-border-primary;
                }
                .tds-timeline-item-content {
                    min-height: 48px;
                }
            }
        }
    }
}
// .tds-timeline-rtl {
//     direction: rtl;
//     .tds-timeline-item-tail {
//         // right: 4px;
//         // left: auto;
//         // border-right: 2px solid rgba(0, 0, 0, 0.06);
//         // border-left: none;
//         @apply right-1 left-auto block h-[calc(100%-15px)]  border-r-2  border-solid border-r-neutral-2-200 dark:border-r-d-neutral-2-200 border-l-0;
//     }
//     .tds-timeline-item-head-custom {
//         right: 5px;
//         left: auto;
//         transform: translate(50%, -50%);
//     }
//     .tds-timeline-item-content {
//         margin: 0 18px 0 0;
//     }
// }
// .tds-timeline-rtl.tds-timeline.tds-timeline-alternate {
//     .tds-timeline-item-tail {
//         right: 50%;
//         left: auto;
//     }
//     .tds-timeline-item-head {
//         right: 50%;
//         left: auto;
//         margin-right: -4px;
//         margin-left: 0;
//     }
//     .tds-timeline-item-head-custom {
//         right: 50%;
//         left: auto;
//         margin-right: 1px;
//         margin-left: 0;
//     }
//     .tds-timeline-item-left {
//         .tds-timeline-item-content {
//             right: calc(50% - 4px);
//             left: auto;
//             text-align: right;
//         }
//     }
//     .tds-timeline-item-right {
//         .tds-timeline-item-content {
//             text-align: left;
//         }
//     }
// }
// .tds-timeline-rtl.tds-timeline.tds-timeline-right {
//     .tds-timeline-item-tail {
//         right: 50%;
//         left: auto;
//     }
//     .tds-timeline-item-head {
//         right: 50%;
//         left: auto;
//         margin-right: -4px;
//         margin-left: 0;
//     }
//     .tds-timeline-item-head-custom {
//         right: 50%;
//         left: auto;
//         margin-right: 1px;
//         margin-left: 0;
//     }
//     .tds-timeline-item-left {
//         .tds-timeline-item-content {
//             right: calc(50% - 4px);
//             left: auto;
//             text-align: right;
//         }
//     }
//     .tds-timeline-item-right {
//         .tds-timeline-item-content {
//             text-align: left;
//             width: 100%;
//             margin-right: 18px;
//             text-align: right;
//         }
//         .tds-timeline-item-tail {
//             right: 0;
//             left: auto;
//         }
//         .tds-timeline-item-head {
//             right: 0;
//             left: auto;
//         }
//         .tds-timeline-item-head-custom {
//             right: 0;
//             left: auto;
//         }
//     }
// }
// .tds-timeline-rtl.tds-timeline.tds-timeline-label {
//     .tds-timeline-item-tail {
//         right: 50%;
//         left: auto;
//     }
//     .tds-timeline-item-head {
//         right: 50%;
//         left: auto;
//         margin-right: -4px;
//         margin-left: 0;
//     }
//     .tds-timeline-item-head-custom {
//         right: 50%;
//         left: auto;
//         margin-right: 1px;
//         margin-left: 0;
//     }
//     .tds-timeline-item-left {
//         .tds-timeline-item-content {
//             right: calc(50% - 4px);
//             left: auto;
//             text-align: right;
//         }
//     }
//     .tds-timeline-item-right {
//         .tds-timeline-item-content {
//             text-align: left;
//         }
//         .tds-timeline-item-label {
//             right: calc(50% + 14px);
//             text-align: right;
//         }
//     }
//     .tds-timeline-item-label {
//         text-align: left;
//     }
// }
// .tds-timeline-rtl.tds-timeline.tds-timeline-pending {
//     .tds-timeline-item-last {
//         .tds-timeline-item-tail {
//             // border-right: 2px dotted rgba(0, 0, 0, 0.06);
//             // border-left: none;
//             @apply  border-r-2  border-dotted border-r-neutral-2-200 dark:border-r-d-neutral-2-200 border-l-0;
//         }
//     }
// }
// .tds-timeline-rtl.tds-timeline.tds-timeline-reverse {
//     .tds-timeline-item-pending {
//         .tds-timeline-item-tail {
//             // border-right: 2px dotted rgba(0, 0, 0, 0.06);
//             // border-left: none;
//             @apply  border-r-2  border-dotted border-r-neutral-2-200 dark:border-r-d-neutral-2-200 border-l-0;
//         }
//     }
// }
