:root{
        --tds-menu-padding-l:var(--tds-spacing-9,16px);
--tds-menu-padding-m:var(--tds-spacing-5,8px);
--tds-menu-padding-s:var(--tds-spacing-3,4px);
--tds-menu-padding-submenu-left-s:20px;
--tds-menu-padding-submenu-left-m:36px;
--tds-menu-padding-submenu-left-l:52px;
--tds-menu-margin-s:var(--tds-spacing-3,4px);
--tds-menu-margin-m:var(--tds-spacing-5,8px);
--tds-menu-margin-l:var(--tds-spacing-9,16px);
--tds-menu-width-expand-menu:256px;
--tds-menu-width-collapse-menu:52px;
--tds-menu-height-logo-default:60px;
--tds-menu-height-logo-collapse:28px;

    }
    
            
@layer utilities{

            
.tds-menu-color-text-default
{
                            @apply tds-color-text-primary-light dark:tds-color-text-quaternary-dark;

                        }                
                        
.tds-menu-color-text-default-light
{
                            @apply tds-color-text-primary-light;

                        }
                        
.tds-menu-color-text-default-dark
{
                            @apply tds-color-text-quaternary-dark;

                        }
                        
.tds-menu-color-menu-icon-active
{
                            @apply tds-color-icon-brand-primary-light dark:tds-color-icon-secondary-dark;

                        }                
                        
.tds-menu-color-menu-icon-active-light
{
                            @apply tds-color-icon-brand-primary-light;

                        }
                        
.tds-menu-color-menu-icon-active-dark
{
                            @apply tds-color-icon-secondary-dark;

                        }
                        
.tds-menu-border-radius-item
{
                        @apply rounded-tds-border-radius-xs ;

                    }

        }
            