:root{
        --tds-dropdown-padding-vertical-s:var(--tds-spacing-3,4px);
--tds-dropdown-padding-vertical-m:var(--tds-spacing-4,6px);
--tds-dropdown-padding-vertical-l:var(--tds-spacing-5,8px);
--tds-dropdown-padding-vertical-xl:var(--tds-spacing-6,10px);
--tds-dropdown-padding-horizontal:var(--tds-spacing-7,12px);
--tds-dropdown-padding-footer-vertical:var(--tds-spacing-5,8px);
--tds-dropdown-padding-body:var(--tds-spacing-7,12px);
--tds-dropdown-margin-left:var(--tds-spacing-5,8px);
--tds-dropdown-margin-item-top:var(--tds-spacing-1,1px);

        .compact{
            --tds-dropdown-padding-vertical-m:var(--tds-spacing-3,4px);
--tds-dropdown-padding-vertical-l:var(--tds-spacing-3,4px);
--tds-dropdown-padding-vertical-xl:var(--tds-spacing-3,4px);
--tds-dropdown-padding-horizontal:var(--tds-spacing-4,6px);
--tds-dropdown-padding-footer-vertical:var(--tds-spacing-4,6px);
--tds-dropdown-padding-body:var(--tds-spacing-6,10px);
--tds-dropdown-margin-left:var(--tds-spacing-3,4px);

        }
    }
    
            
@layer utilities{

            
.tds-dropdown-color-title-background
{
                            @apply tds-color-background-neutral-minimalist-light dark:tds-color-surface-highest-dark;

                        }                
                        
.tds-dropdown-color-title-background-light
{
                            @apply tds-color-background-neutral-minimalist-light;

                        }
                        
.tds-dropdown-color-title-background-dark
{
                            @apply tds-color-surface-highest-dark;

                        }
                        
.tds-dropdown-text
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular compact:text-tds-font-size-2 compact:leading-tds-line-height-3 compact:font-tds-font-family-body compact:tds-font-weight-regular;

                    }
.tds-dropdown-title-base
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold compact:text-tds-font-size-2 compact:leading-tds-line-height-3 compact:font-tds-font-family-body compact:tds-font-weight-semibold;

                    }
.tds-dropdown-title-s
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-5 font-tds-font-family-body tds-font-weight-semibold compact:text-tds-font-size-3 compact:leading-tds-line-height-4 compact:font-tds-font-family-body compact:tds-font-weight-semibold;

                    }
.tds-dropdown-icon-size
{
                        @apply  text-tds-font-size-6 leading-tds-line-height-4 compact:text-tds-font-size-4 compact:leading-tds-line-height-3;

                    }

        }
            