tds-breadcrumb {
    &.tds-breadcrumb {
        @apply flex items-center w-full;
        tds-breadcrumb-item {
            @apply text-[13px] leading-[20px] tds-font-weight-regular  flex items-center ml-[--tds-breadcrumb-margin] cursor-pointer;
            span.tds-breadcrumb-separator {
                @apply flex  ml-[--tds-breadcrumb-margin];
            }
        }
        tds-breadcrumb-separator {
            span.tds-breadcrumb-separator {
                @apply px-[--tds-breadcrumb-margin] flex;
            }
        }
        & > tds-breadcrumb-item:first-child {
            @apply ml-0;
        }
        & > tds-breadcrumb-item:last-child {
            @apply flex-auto;

            span.tds-breadcrumb-separator {
                @apply hidden w-0;
            }
        }
        & > tds-breadcrumb-separator:last-child {
            @apply hidden;
        }
        .tds-breadcrumb-link {
            @apply max-w-full truncate;
            span {
                @apply mr-tds-spacing-2;
            }
        }
        .tds-breadcrumb-overlay-link {
            @apply flex items-center;
        }
        .tds-breadcrumb-overlay-icon {
            @apply flex items-center leading-tds-line-height-3 text-tds-font-size-4 ml-tds-spacing-2;
        }
    }
    &.tds-breadcrumb-theme-default {
        &.tds-breadcrumb {
            tds-breadcrumb-item {
                @apply tds-color-text-secondary;
                span.tds-breadcrumb-separator {
                    @apply tds-color-icon-secondary;
                }
                &:hover {
                    @apply tds-color-text-brand-primary;
                }
            }
            tds-breadcrumb-separator {
                span.tds-breadcrumb-separator {
                    @apply tds-color-icon-secondary;
                }
            }

            & > tds-breadcrumb-item:last-child {
                @apply tds-color-text-brand-primary;
            }
        }
    }
    &.tds-breadcrumb-theme-light {
        &.tds-breadcrumb {
            tds-breadcrumb-item {
                @apply tds-color-text-secondary-light;
                span.tds-breadcrumb-separator {
                    @apply tds-color-icon-secondary-light;
                }
                &:hover {
                    @apply tds-color-text-brand-primary-light;
                }
            }
            tds-breadcrumb-separator {
                span.tds-breadcrumb-separator {
                    @apply tds-color-icon-secondary-light;
                }
            }

            & > tds-breadcrumb-item:last-child {
                @apply tds-color-text-brand-primary-light;
            }
        }
    }
    &.tds-breadcrumb-theme-dark {
        &.tds-breadcrumb {
            tds-breadcrumb-item {
                @apply tds-color-text-secondary-dark;
                span.tds-breadcrumb-separator {
                    @apply tds-color-icon-secondary-dark;
                }
                &:hover {
                    @apply tds-color-text-brand-primary-dark;
                }
            }
            tds-breadcrumb-separator {
                span.tds-breadcrumb-separator {
                    @apply tds-color-icon-secondary-dark;
                }
            }

            & > tds-breadcrumb-item:last-child {
                @apply tds-color-text-brand-primary-dark;
            }
        }
    }
}
