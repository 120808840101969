.tds-select-item {
    @apply flex w-full px-[--tds-dropdown-padding-horizontal] tds-dropdown-text justify-between items-center mb-tds-spacing-1;
    &-size-xs {
        @apply py-[--tds-dropdown-padding-vertical-s];
    }
    &-size-sm {
        @apply py-[--tds-dropdown-padding-vertical-s];
    }
    &-size-md {
        @apply py-[--tds-dropdown-padding-vertical-m];
    }
    &-size-lg {
        @apply py-[--tds-dropdown-padding-vertical-l];
    }
    &-size-xl {
        @apply py-[--tds-dropdown-padding-vertical-l];
    }
    &:not(.tds-select-item-option-disabled) {
        @apply cursor-pointer tds-color-text-primary  hover:tds-color-background-neutral-minimalist;
        &.tds-select-item-option-active {
            @apply tds-color-background-neutral-minimalist;
        }
        &.tds-select-item-option-selected {
            @apply tds-color-background-neutral-minimalist;
        }
    }

    &-option-disabled {
        @apply cursor-not-allowed  tds-color-text-disable;
    }
    &-option-hideSelected {
        @apply pointer-events-none hidden;
    }
}
.tds-select-option-default {
    @apply w-full flex;
    &-label {
        @apply flex-auto truncate ml-[--tds-dropdown-margin-left];
    }
    &-icon {
        @apply flex items-center;
        > span {
            @apply tds-color-icon-success text-sm;
        }
    }
}
.tds-select-option-group {
    @apply w-full px-[--tds-dropdown-padding-horizontal] tds-dropdown-title-base tds-color-text-primary items-center mb-px block box-border tds-dropdown-color-title-background py-[--tds-dropdown-padding-vertical-s];
}
.tds-select-panel {
    @apply py-1  relative w-full left-0 top-full block;
    &.tds-select-dropdown-placement-bottomLeft {
        transform-origin: left top;
    }
    &.tds-select-dropdown-placement-bottomRight {
        transform-origin: left top;
    }
    &.tds-select-dropdown-placement-topLeft {
        transform-origin: left bottom;
    }
    &.tds-select-dropdown-placement-topRight {
        transform-origin: left bottom;
    }
    .tds-select-panel-wrapper {
        @apply relative py-[--tds-dropdown-padding-vertical-s] w-full overflow-auto  shadow-tds-shadow-l-light dark:shadow-tds-shadow-l-dark rounded-tds-border-radius-xs;
        .tds-select-item-empty {
            @apply p-1;
        }
        cdk-virtual-scroll-viewport {
            @apply overflow-x-hidden w-full;
            .cdk-virtual-scroll-content-wrapper {
                .tds-select-item:last-child {
                    @apply mb-0;
                }
            }
        }
    }
    .tds-select-panel-wrapper-hasgroup {
        @apply py-0;
    }
    &.tds-select-dropdown-theme-default {
        .tds-select-panel-wrapper {
            @apply tds-color-surface-high;
        }
    }
    &.tds-select-dropdown-theme-light {
        .tds-select-panel-wrapper {
            @apply tds-color-surface-high-light;
        }
        .tds-select-item {
            &:not(.tds-select-item-option-disabled) {
                @apply tds-color-text-primary-light hover:tds-color-background-neutral-minimalist-light;
                &.tds-select-item-option-active {
                    @apply tds-color-background-neutral-minimalist-light;
                }
                &.tds-select-item-option-selected {
                    @apply tds-color-background-neutral-minimalist-light;
                }
            }
            &-option-disabled {
                @apply tds-color-text-disable-light;
            }
        }
        .tds-select-option-default {
            &-icon {
                > span {
                    @apply tds-color-icon-success-light;
                }
            }
        }
        .tds-select-option-group {
            @apply tds-color-text-primary-light tds-dropdown-color-title-background-light;
        }
    }
    &.tds-select-dropdown-theme-dark {
        .tds-select-panel-wrapper {
            @apply tds-color-surface-high-dark;
        }
        .tds-select-item {
            &:not(.tds-select-item-option-disabled) {
                @apply tds-color-text-primary-dark hover:tds-color-background-neutral-minimalist-dark;
                &.tds-select-item-option-active {
                    @apply tds-color-background-neutral-minimalist-dark;
                }
                &.tds-select-item-option-selected {
                    @apply tds-color-background-neutral-minimalist-dark;
                }
            }
            &-option-disabled {
                @apply tds-color-text-disable-dark;
            }
        }
        .tds-select-option-default {
            &-icon {
                > span {
                    @apply tds-color-icon-success-dark;
                }
            }
        }
        .tds-select-option-group {
            @apply tds-color-text-primary-dark tds-dropdown-color-title-background-dark;
        }
    }
}
tds-select {
    @apply cursor-pointer w-full block relative box-border m-0 p-0;
    .tds-select-selector {
        @apply w-full flex items-stretch  max-w-full  relative;
    }
    .tds-value-container {
        @apply w-full relative;
    }

    .tds-value-container-default {
        @apply w-full max-w-full relative;

        .tds-arrow-wrapper,
        .tds-clear-wrapper {
            @apply flex-grow-0 flex-shrink-0 tds-icon-size-s;
        }
        .tds-placeholder {
            @apply block mr-1;
        }
        .tds-value-template {
            @apply tds-body-2 tds-font-weight-regular  truncate box-border;
        }
        .tds-select-default-input {
            @apply absolute inset-0;
            &.tds-select-default-input-hidden {
                @apply w-0 h-0;
            }
        }
    }

    .tds-value-container-multiple {
        @apply w-full max-w-full relative flex-wrap flex-shrink flex-grow break-words flex;
        .tds-placeholder {
            @apply mr-1  block;
        }
        .tds-value {
            @apply flex max-w-full;
            .tds-value-tag {
                @apply w-full flex max-w-full items-center rounded-tds-border-radius-xs py-[--tds-tag-padding-vertical-m] px-[--tds-tag-padding-horizontal-m] ml-[--tds-select-margin-s];
                .tds-value-tag-lable {
                    @apply tds-body-2 tds-font-weight-regular whitespace-nowrap box-border truncate;
                }
                .tds-value-tag-delete {
                    @apply flex items-center ml-[--tds-tag-margin-base];
                    > i {
                        @apply tds-icon-size-s;
                    }
                }
                &.tds-value-tag-disabled {
                    .tds-value-tag-delete {
                        @apply hidden;
                    }
                }
                &.tds-value-max-tag {
                    .tds-value-tag-lable {
                        @apply tds-body-2 tds-font-weight-semibold;
                    }
                }
            }
        }
    }
    tds-select-placeholder {
        > div {
            @apply w-full flex items-center tds-body-2 tds-font-weight-regular truncate;
        }
    }
    tds-select-search {
        @apply box-border flex items-center h-full w-full relative max-w-full;
        .tds-select-search-input {
            @apply tds-body-2 tds-font-weight-regular focus:outline-none focus:border-transparent shadow-none border-0 bg-transparent text-current p-0 m-0 w-full outline-none  box-border;
        }
        .tds-select-search-mirror {
            @apply h-[20px] leading-[20px] absolute top-0 left-0 z-[999] whitespace-pre invisible tds-body-2 tds-font-weight-regular;
        }
    }
    &.tds-select-border {
        .tds-select-selector {
            @apply border-tds-border-width-s  rounded-tds-border-radius-xs;
            border-style:var(--tds-border-style-base,solid);
        }
        &:not(.tds-select-disabled) {
            .tds-select-selector {
                @apply rounded-tds-border-radius-xs;
            }
        }
    }
    .tds-select-button-icon {
        @apply absolute bottom-0 right-0 top-0 flex items-center justify-center;
        .tds-clear-wrapper {
            @apply absolute inset-0 opacity-0 flex items-center justify-center;
            transition: opacity 0.2s ease-out;
        }
        .tds-arrow-wrapper {
            @apply h-full flex items-center group-hover:border-transparent cursor-pointer relative opacity-100;
        }
    }
    &.tds-select-disabled {
        @apply cursor-not-allowed;
        input,
        .tds-select-button-icon .tds-arrow-wrapper {
            @apply cursor-not-allowed;
        }

        &.tds-select-border {
            .tds-select-selector {
                @apply rounded-tds-border-radius-xs;
            }
        }
    }
    &.tds-select-focused {
        &.tds-select-border {
            .tds-select-selector {
                @apply outline-none ring;
            }
        }
    }
    &.tds-select-theme-default {
        .tds-select-selector {
            @apply tds-color-surface-lowest;
        }
        .tds-value-container-default {
            .tds-value-template {
                @apply tds-color-text-primary;
            }
        }
        .tds-value-container-multiple {
            .tds-value {
                .tds-value-tag {
                    @apply tds-color-background-neutral-minimal;
                    .tds-value-tag-lable {
                        @apply tds-color-text-primary;
                    }
                    .tds-value-tag-delete {
                        @apply tds-color-icon-secondary ;
                    }

                    &:not(.tds-value-tag-disabled) {
                        @apply hover:tds-color-background-neutral-subtle;
                        &:hover {
                            .tds-value-tag-delete {
                                @apply tds-color-text-primary;
                            }
                        }
                    }
                    &.tds-value-max-tag {
                        @apply tds-color-background-info-minimal;
                        .tds-value-tag-lable {
                            @apply tds-color-text-info;
                        }
                    }
                }
            }
        }
        tds-select-placeholder {
            @apply absolute left-0 top-0 bottom-0;
            > div {
                @apply tds-color-text-placeholder;
            }
        }
        tds-select-search {
            .tds-select-search-input {
                @apply tds-color-text-primary placeholder:tds-color-text-placeholder;
            }
        }
        &.tds-select-border {
            &:not(.tds-select-disabled) {
                .tds-select-selector {
                    @apply tds-color-border-primary;
                    &:hover {
                        @apply tds-color-border-brand-primary;
                    }
                }
            }
        }
        .tds-select-button-icon {
            .tds-arrow-wrapper {
                @apply tds-color-icon-primary;
            }
        }
        &.tds-select-disabled {
            .tds-select-selector {
                @apply tds-color-background-disable;
                &:hover {
                    @apply tds-color-background-disable;
                }
            }
            &.tds-select-border {
                .tds-select-selector {
                    @apply tds-color-border-disable;
                    &:hover {
                        @apply tds-color-border-disable;
                    }
                }
            }
        }
        &.tds-select-focused {
            &.tds-select-border {
                .tds-select-selector {
                    @apply tds-color-ring-primary tds-color-border-brand-primary;
                }
            }
        }
    }
    &.tds-select-theme-light {
        .tds-select-selector {
            @apply tds-color-surface-lowest-light;
        }
        .tds-value-container-default {
            .tds-value-template {
                @apply tds-color-text-primary-light;
            }
        }
        .tds-value-container-multiple {
            .tds-value {
                .tds-value-tag {
                    @apply tds-color-background-neutral-minimal-light;
                    .tds-value-tag-lable {
                        @apply tds-color-text-primary-light;
                    }
                    .tds-value-tag-delete {
                        @apply tds-color-icon-secondary-light ;
                    }

                    &:not(.tds-value-tag-disabled) {
                        @apply hover:tds-color-background-neutral-subtle-light;
                        &:hover {
                            .tds-value-tag-delete {
                                @apply tds-color-text-primary-light;
                            }
                        }
                    }
                    &.tds-value-max-tag {
                        @apply tds-color-background-info-minimal-light;
                        .tds-value-tag-lable {
                            @apply tds-color-text-info-light;
                        }
                    }
                }
            }
        }
        tds-select-placeholder {
            > div {
                @apply tds-color-text-placeholder-light;
            }
        }
        tds-select-search {
            .tds-select-search-input {
                @apply tds-color-text-primary-light placeholder:tds-color-text-placeholder-light;
            }
        }
        &.tds-select-border {
            &:not(.tds-select-disabled) {
                .tds-select-selector {
                    @apply tds-color-border-primary-light;
                    &:hover {
                        @apply tds-color-border-brand-primary-light;
                    }
                }
            }
        }
        .tds-select-button-icon {
            .tds-arrow-wrapper {
                @apply tds-color-icon-primary-light;
            }
        }
        &.tds-select-disabled {
            .tds-select-selector {
                @apply tds-color-background-disable-light;
                &:hover {
                    @apply tds-color-background-disable-light;
                }
            }
            &.tds-select-border {
                .tds-select-selector {
                    @apply tds-color-border-disable-light;
                    &:hover {
                        @apply tds-color-border-disable-light;
                    }
                }
            }
        }
        &.tds-select-focused {
            &.tds-select-border {
                .tds-select-selector {
                    @apply tds-color-ring-primary-light tds-color-border-brand-primary-light;
                }
            }
        }
    }
    &.tds-select-theme-dark {
        .tds-select-selector {
            @apply tds-color-surface-lowest-dark;
        }
        .tds-value-container-default {
            .tds-value-template {
                @apply tds-color-text-primary-dark;
            }
        }
        .tds-value-container-multiple {
            .tds-value {
                .tds-value-tag {
                    @apply tds-color-background-neutral-minimal-dark;
                    .tds-value-tag-lable {
                        @apply tds-color-text-primary-dark;
                    }
                    .tds-value-tag-delete {
                        @apply tds-color-icon-secondary-dark ;
                    }

                    &:not(.tds-value-tag-disabled) {
                        @apply hover:tds-color-background-neutral-subtle-dark;
                        &:hover {
                            .tds-value-tag-delete {
                                @apply tds-color-text-primary-dark;
                            }
                        }
                    }
                    &.tds-value-max-tag {
                        @apply tds-color-background-info-minimal-dark;
                        .tds-value-tag-lable {
                            @apply tds-color-text-info-dark;
                        }
                    }
                }
            }
        }
        tds-select-placeholder {
            > div {
                @apply tds-color-text-placeholder-dark;
            }
        }
        tds-select-search {
            .tds-select-search-input {
                @apply tds-color-text-primary-dark placeholder:tds-color-text-placeholder-dark;
            }
        }
        &.tds-select-border {
            &:not(.tds-select-disabled) {
                .tds-select-selector {
                    @apply tds-color-border-primary-dark;
                    &:hover {
                        @apply tds-color-border-brand-primary-dark;
                    }
                }
            }
        }
        .tds-select-button-icon {
            .tds-arrow-wrapper {
                @apply tds-color-icon-primary-dark;
            }
        }
        &.tds-select-disabled {
            .tds-select-selector {
                @apply tds-color-background-disable-dark;
                &:hover {
                    @apply tds-color-background-disable-dark;
                }
            }
            &.tds-select-border {
                .tds-select-selector {
                    @apply tds-color-border-disable-dark;
                    &:hover {
                        @apply tds-color-border-disable-dark;
                    }
                }
            }
        }
        &.tds-select-focused {
            &.tds-select-border {
                .tds-select-selector {
                    @apply tds-color-ring-primary-dark tds-color-border-brand-primary-dark;
                }
            }
        }
    }
}
//========== size ===========

tds-select {
    .tds-select-selector {
        @apply pr-[--tds-select-padding-l];
        .tds-value-container {
            @apply relative;
        }
    }
    &:not(.tds-select-disabled) {
        .tds-value-container-multiple {
            .tds-select-input {
                @apply cursor-text;
            }
        }
    }

    &.tds-select-has-clear {
        // .tds-value-container {
        //     // 32 width btton close
        //     @apply pr-8;
        // }
        .tds-clear-wrapper {
            @apply pointer-events-none;
        }
        &:hover {
            .tds-clear-wrapper {
                @apply opacity-100 z-[1] pointer-events-auto;
            }
        }
    }

    &.tds-select-size-md {
        .tds-value-container-default {
            @apply py-[--tds-select-padding-m]  pl-[--tds-select-padding-left-m];
        }
        .tds-value-container-multiple {
            .tds-placeholder {
                @apply pl-[--tds-select-padding-left-m]  py-[--tds-select-padding-tag-l];
            }
            .tds-value {
                @apply py-[--tds-select-padding-s];
            }
            .tds-select-input {
                @apply my-1.5;
            }
        }
        .tds-select-button-icon {
            .tds-clear-wrapper {
                @apply px-2;
            }
            .tds-arrow-wrapper {
                @apply px-2;
            }
        }
        &.tds-select-show-arrow,
        &.tds-select-loading {
            .tds-select-selector {
                // 34 width btton arrow
                @apply pr-[34px];
            }
        }
    }
    &.tds-select-size-xs {
        .tds-select-button-icon {
            .tds-clear-wrapper {
                @apply px-2;
            }
            .tds-arrow-wrapper {
                @apply px-1.5;
            }
        }
        .tds-value-container-multiple {
            .tds-placeholder {
                @apply pl-2.5  py-1;
            }
            .tds-value {
                @apply py-tds-spacing-2;
            }
            .tds-select-input {
                @apply my-1;
            }
        }
        .tds-value-container-default {
            @apply py-[--tds-select-padding-s]  pl-[--tds-select-padding-left-s];
        }
        &.tds-select-show-arrow,
        &.tds-select-loading {
            .tds-select-selector {
                // 34 width btton arrow
                @apply pr-[30px];
            }
        }
    }
    &.tds-select-size-sm {
        .tds-select-button-icon {
            .tds-clear-wrapper {
                @apply px-2;
            }
            .tds-arrow-wrapper {
                @apply px-1.5;
            }
        }
        .tds-value-container-multiple {
            .tds-placeholder {
                @apply pl-2.5  py-1;
            }
            .tds-value {
                @apply py-tds-spacing-2;
            }
            .tds-select-input {
                @apply my-1;
            }
        }
        .tds-value-container-default {
            @apply py-[--tds-select-padding-s]  pl-[--tds-select-padding-left-s];
        }
        &.tds-select-show-arrow,
        &.tds-select-loading {
            .tds-select-selector {
                // 34 width btton arrow
                @apply pr-[30px];
            }
        }
    }

    &.tds-select-size-lg {
        .tds-select-button-icon {
            .tds-clear-wrapper {
                @apply px-2;
            }
            .tds-arrow-wrapper {
                @apply px-2.5;
            }
        }
        .tds-value-container-multiple {
            .tds-placeholder {
                @apply pl-4  py-2;
            }
            .tds-value {
                @apply py-[--tds-select-padding-tag-l];
            }
            .tds-select-input {
                @apply my-2;
            }
        }
        .tds-value-container-default {
            @apply py-[--tds-select-padding-l]  pl-[--tds-select-padding-left-l];
        }
        &.tds-select-show-arrow,
        &.tds-select-loading {
            .tds-select-selector {
                // 38 width btton arrow
                @apply pr-[38px];
            }
        }
    }

    &.tds-select-size-xl {
        .tds-select-button-icon {
            .tds-clear-wrapper {
                @apply px-2;
            }
            .tds-arrow-wrapper {
                @apply px-2.5;
            }
        }
        .tds-value-container-multiple {
            .tds-placeholder {
                @apply pl-4  py-2;
            }
            .tds-value {
                @apply py-tds-spacing-5;
            }
            .tds-select-input {
                @apply my-2;
            }
        }
        .tds-value-container-default {
            @apply py-[--tds-select-padding-l]  pl-[--tds-select-padding-left-l];
        }
        &.tds-select-show-arrow,
        &.tds-select-loading {
            .tds-select-selector {
                // 38 width btton arrow
                @apply pr-[38px];
            }
        }
    }
}
tds-form-field {
    tds-select {
        &.tds-select-border {
            .tds-select-selector {
                @apply border-none hover:bg-transparent border-0;
            }
        }
    }
    tds-select {
        &.tds-select-focused {
            &.tds-select-border {
                .tds-select-selector {
                    @apply border-none border-0 ring-0 ring-transparent;
                }
            }
        }
    }
    &.tds-form-field-has-prefix {
        tds-select {
            .tds-select-selector {
                .tds-value-container {
                    .tds-value-container-default {
                        @apply pl-0;
                    }
                }
            }
        }
    }
}

.tds-select-dropdown-handel-before-value-change {
    @apply invisible pointer-events-none;
}
