:root{
        --tds-steps-size-xs:16px;
--tds-steps-size-s:24px;
--tds-steps-size-m:32px;
--tds-steps-size-l:40px;
--tds-steps-margin-xs:var(--tds-spacing-2,2px);
--tds-steps-margin-s:var(--tds-spacing-3,4px);
--tds-steps-margin-m:var(--tds-spacing-5,8px);
--tds-steps-margin-l:var(--tds-spacing-7,12px);

    }
    
            
@layer utilities{

            
.tds-steps-icon-size-base
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-3 ;

                    }
.tds-steps-icon-size-m
{
                        @apply  text-tds-font-size-6 leading-tds-line-height-4 ;

                    }
.tds-steps-icon-size-l
{
                        @apply  text-tds-font-size-7 leading-tds-line-height-5 ;

                    }

        }
            