// tds-tree {
//     @apply box-border m-0 p-0 border-0;
//     tds-tree-node {
//         @apply flex items-stretch py-1.5;
//         tds-tree-node-title {
//             @apply flex items-center;
//             .tds-tree-title {
//                 @apply text-body-2  tds-color-text-primary;
//             }
//         }
//         &:hover {
//             @apply tds-color-background-neutral-minimalist;
//         }
//     }
//     &.tds-tree-show-line .tds-tree-indent-unit:not(.tds-tree-indent-unit-end)::before {
//         @apply absolute inset-0  -inset-y-1.5 border-l border-neutral-2-200 left-2.5;
//         content: "";
//     }
//     &[multiple] {
//         @apply p-0 m-0 border-none;
//     }
// }

tds-tree {
    @apply block;
    tds-tree-node-switcher {
        @apply flex items-center;
        .tds-tree-switcher-icon_open,
        .tds-tree-switcher-icon_close {
            @apply border-tds-border-width-s tds-color-border-neutral-minimalist tds-color-icon-secondary tds-tree-border-radius-switcher;
            line-height: var(--tds-tree-icon-size-s);
            font-size: var(--tds-tree-icon-size-s);
        }
        &.tds-tree-switcher_close {
            .tds-tree-switcher-icon_open {
                @apply hidden;
            }
        }
        &.tds-tree-switcher_open {
            .tds-tree-switcher-icon_close {
                @apply hidden;
            }
        }
    }
    &.tds-tree {
        @apply box-border m-0 p-0  tds-color-text-primary tds-font-weight-regular tds-body-2;
        .tds-tree-treenode {
            @apply flex outline-none items-center py-[--tds-tree-padding-vertical-m];
            tds-tree-node-switcher {
                @apply w-[--tds-tree-width-switcher] flex items-center justify-center;
                .tds-tree-switcher-icon {
                    @apply tds-color-icon-secondary tds-tree-icon-size-base transition-transform delay-75;
                }
                &.tds-tree-switcher_open {
                    .tds-tree-switcher-icon {
                        @apply rotate-90;
                    }
                }
            }
            tds-tree-node-checkbox {
                @apply px-[--tds-tree-padding-horizontal-m];
            }
            tds-tree-node-title {
                @apply px-[--tds-tree-padding-horizontal-m] flex items-center tds-font-weight-regular tds-body-2;
                &.tds-tree-node-content-wrapper {
                    .tds-tree-iconEle {
                        @apply tds-color-icon-secondary tds-tree-icon-size-base flex justify-center;
                        &:empty {
                            @apply hidden;
                        }
                    }
                    > .tds-tree-iconEle {
                        @apply ml-[--tds-tree-margin-left-l];
                    }
                    > :not(.tds-tree-iconEle) {
                        @apply ml-[--tds-tree-margin-left-m];
                    }
                }
            }

            &.tds-tree-treenode-switcher-open {
                .tds-tree-switcher-icon {
                    @apply rotate-90;
                }
            }

            &:not(.tds-tree-treenode-disabled) {
                &:hover {
                    @apply cursor-pointer tds-color-background-neutral-minimalist;
                    tds-tree-node-title {
                        &.tds-tree-node-content-wrapper {
                            .tds-tree-iconEle {
                                @apply tds-color-icon-info;
                            }
                        }
                    }
                }
                &.tds-tree-treenode-selected {
                    @apply cursor-pointer tds-color-background-neutral-minimalist;
                    tds-tree-node-title {
                        &.tds-tree-node-content-wrapper {
                            .tds-tree-iconEle {
                                @apply tds-color-icon-info;
                            }
                        }
                    }
                }
            }
            &.tds-tree-treenode-disabled {
                @apply tds-color-text-disable cursor-not-allowed;
                tds-tree-node-title {
                    &.tds-tree-node-content-wrapper {
                        .tds-tree-iconEle {
                            @apply tds-color-icon-disable;
                        }
                    }
                }
                tds-tree-node-switcher {
                    .tds-tree-switcher-icon {
                        @apply tds-color-icon-disable;
                    }
                }
            }
        }
    }
    &.tds-tree-block-node {
        .tds-tree-treenode {
            tds-tree-node-title {
                &.tds-tree-node-content-wrapper {
                    @apply flex-auto;
                }
            }
        }
    }
    &.tds-select-tree-block-node {
        .tds-select-tree-treenode {
            tds-tree-node-title {
                &.tds-select-tree-node-content-wrapper {
                    @apply flex-auto;
                }
            }
        }
    }
    &.tds-select-tree {
        @apply box-border m-0 p-0  tds-color-text-primary tds-font-weight-regular tds-body-2;
        .tds-select-tree-treenode {
            @apply flex outline-none items-center py-[--tds-tree-padding-vertical-s];
            tds-tree-node-switcher {
                @apply w-[--tds-tree-width-switcher] flex items-center justify-center;
                .tds-select-tree-switcher-icon {
                    @apply tds-color-icon-secondary tds-tree-icon-size-base transition-transform delay-75;
                }
                &.tds-select-tree-switcher_open {
                    .tds-select-tree-switcher-icon{
                        @apply rotate-90;
                    }
                }
            }
            tds-tree-node-checkbox {
                @apply px-[--tds-tree-padding-horizontal-m]  py-[--tds-tree-padding-vertical-m];
            }
            tds-tree-node-title {
                @apply px-[--tds-tree-padding-horizontal-m] flex items-center  py-[--tds-tree-padding-vertical-s];
                &.tds-select-tree-node-content-wrapper {
                    .tds-tree-iconEle {
                        @apply tds-color-icon-secondary tds-tree-icon-size-base;
                        &:empty {
                            @apply hidden;
                        }
                    }
                    > .tds-tree-iconEle {
                        @apply ml-[--tds-tree-margin-left-l];
                    }
                }
            }
            &.tds-select-tree-treenode-switcher-open {
                .tds-tree-switcher-icon {
                    @apply rotate-90;
                }
            }

            &:not(.tds-select-tree-treenode-disabled) {
                tds-tree-node-title {
                    &.tds-select-tree-node-content-wrapper {
                        @apply cursor-pointer;
                    }
                }
                &:hover:not(.tds-select-tree-treenode-selected) {
                    tds-tree-node-title {
                        &.tds-select-tree-node-content-wrapper {
                            @apply tds-color-background-info-minimal rounded-md;
                            .tds-select-tree-iconEle {
                                @apply tds-color-icon-info;
                            }
                        }
                    }
                }
                &.tds-select-tree-treenode-selected {
                    @apply cursor-pointer;
                    tds-tree-node-title {
                        &.tds-select-tree-node-content-wrapper {
                            @apply tds-color-background-info-minimal rounded-md;
                            .tds-tree-iconEle {
                                @apply tds-color-icon-info;
                            }
                        }
                    }
                }
            }
            &.tds-select-tree-treenode-disabled {
                @apply tds-color-text-disable cursor-not-allowed;
                tds-tree-node-title {
                    &.tds-select-tree-node-content-wrapper {
                        .tds-select-tree-iconEle {
                            @apply tds-color-icon-disable;
                        }
                    }
                }
                tds-tree-node-switcher {
                    .tds-select-tree-switcher-icon {
                        @apply tds-color-icon-disable;
                    }
                }
            }
        }
        // &.tds-select-tree-block-node {
        //     .tds-select-tree-treenode {
        //         tds-tree-node-title {
        //             &.tds-select-tree-node-content-wrapper {
        //                 @apply flex-auto;
        //             }
        //         }
        //     }
        // }
    }
    .tds-tree-indent,
    .tds-select-tree-indent {
        @apply items-stretch whitespace-normal select-none;
    }

    .tds-tree-indent-unit,
    .tds-select-tree-indent-unit {
        @apply inline-block w-7;
    }
    &[multiple] {
        @apply p-0 m-0 border-none bg-transparent;
    }
    &.tds-tree-show-line .tds-tree-indent-unit:not(.tds-tree-indent-unit-end)::before {
        @apply absolute inset-0  -inset-y-3.5 border-l tds-color-border-secondary left-3;
        content: "";
    }
}
