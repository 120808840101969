
/* use for styling the editting region*/
.ck.ck-content.ck-editor__editable.ck-editor__editable_inline { 
  height: 421px;
  overflow-y: auto;
  @apply tds-custom-scroll;
}

// /*use for styling the source editting region*/
// .ck.ck-editor__main > .ck-source-editing-area {
//   height: 300px !important;
//   overflow: hidden;
// }
// .ck-source-editing-area > textarea {
//   overflow: auto !important;
//   @apply tds-custom-scroll;
// }

// /* use for styling the dropdown panel*/
// .ck.ck-dropdown__panel {
// 	max-height: 160px; /* or anything else, more likely ~300px or so */
// 	overflow-y: auto;
//   @apply tds-custom-scroll;
// }

// .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel-visible{
//   display: flex;
//   flex-direction: column;
// }
