.tds-otp-input {
    // @apply block box-border;
    .tds-otp-input-wrapper {
        @apply flex gap-x-3 justify-center;
        .tds-otp-input.tds-input {
            border-style: var(--tds-border-style-base, solid);
            @apply w-[--tds-otp-input-width] h-[--tds-otp-input-height] text-center   tds-heading-5 tds-font-weight-semibold;
        }
    }
    &-theme-dafault {
        .tds-otp-input-wrapper {
            .tds-otp-input.tds-input {
                @apply tds-color-text-primary  placeholder:tds-color-text-placeholder tds-color-surface-lowest;
                &:not(.tds-input-disabled):not(.tds-input-error):hover {
                    @apply tds-color-border-brand-primary;
                }
                &.tds-input-disabled {
                    @apply tds-color-background-disable tds-color-border-disable;
                }
                &.tds-input-error {
                    @apply tds-color-border-error;
                }
            }
        }
    }
    &-theme-light {
        .tds-otp-input-wrapper {
            .tds-otp-input.tds-input {
                @apply tds-color-text-primary-light  placeholder:tds-color-text-placeholder-light tds-color-surface-lowest-light;
                &:not(.tds-input-disabled):not(.tds-input-error):hover {
                    @apply tds-color-border-brand-primary-light;
                }
                &.tds-input-disabled {
                    @apply tds-color-background-disable-light tds-color-border-disable-light;
                }
                &.tds-input-error {
                    @apply tds-color-border-error-light;
                }
            }
        }
    }
    &-theme-dark {
        .tds-otp-input-wrapper {
            .tds-otp-input.tds-input {
                @apply tds-color-text-primary-dark  placeholder:tds-color-text-placeholder-dark tds-color-surface-lowest-dark;
                &:not(.tds-input-disabled):not(.tds-input-error):hover {
                    @apply tds-color-border-brand-primary-dark;
                }
                &.tds-input-disabled {
                    @apply tds-color-background-disable-dark tds-color-border-disable-dark;
                }
                &.tds-input-error {
                    @apply tds-color-border-error-dark;
                }
            }
        }
    }
}
