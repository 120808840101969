:root{
        --tds-tour-padding-s:var(--tds-spacing-5,8px);
--tds-tour-padding-m:var(--tds-spacing-7,12px);

    }
    
            
@layer utilities{

            
.tds-tour-color-dot-background-inverse-inactive
{
                            @apply bg-tds-color-white-light/40 dark:bg-tds-color-white-dark/40;

                        }                
                        
.tds-tour-color-dot-background-inverse-inactive-light
{
                            @apply bg-tds-color-white-light/40;

                        }
                        
.tds-tour-color-dot-background-inverse-inactive-dark
{
                            @apply bg-tds-color-white-dark/40;

                        }
                        
.tds-tour-shadow
{
                            @apply shadow-tds-shadow-xl-light dark:shadow-tds-shadow-xl-dark;

                        }                
                        
.tds-tour-shadow-light
{
                            @apply shadow-tds-shadow-xl-light;

                        }
                        
.tds-tour-shadow-dark
{
                            @apply shadow-tds-shadow-xl-dark;

                        }
                        

        }
            