.tds-filter-result {
    @apply flex w-full box-border flex-row justify-center py-1;
    &.tds-filter-result-empty {
        @apply hidden w-0 h-0 invisible;
    }
}
.tds-filter-result-items {
    @apply max-w-full overflow-hidden whitespace-nowrap;
    > div {
        @apply w-full flex flex-nowrap h-full items-center max-w-full overflow-hidden;
    }
    
}
.tds-filter-result-buttons {
    @apply flex flex-1 shrink-0  px-2;
    tds-tag {
        @apply  mr-0.5;
    }
    button{
        @apply mr-0.5
    }
}
.tds-filter-result-count{
    @apply  flex items-center cursor-pointer;
    &.tds-filter-result-count-hidden{
        @apply invisible w-0 h-0 cursor-none pointer-events-none;
    }
}
.tds-filter-result-dropdown
{
    .tds-popover-inner-content{
        @apply py-2 tds-custom-scroll flex flex-col gap-y-1 overflow-y-auto max-h-[350px];
        .tds-filter-result-dropdown-item{
            tds-tag {
                @apply tds-color-background-info-minimal;
            }
        }
    }    
}
.tds-filter-result-add-popover{
    .tds-popover-inner-content{
        @apply p-0;
    }
}
.tds-filter-result-tag{
    &-title{
        @apply max-w-[200px] truncate tds-body-2 font-tds-font-weight-400;
    }
}