:root{
        --tds-drawer-padding-vertical:var(--tds-spacing-5,8px);
--tds-drawer-padding-horizontal:var(--tds-spacing-9,16px);
--tds-drawer-padding-body:var(--tds-spacing-9,16px);
--tds-drawer-margin-left:var(--tds-spacing-5,8px);
--tds-drawer-width-s:320px;
--tds-drawer-width-m:400px;
--tds-drawer-width-l:640px;
--tds-drawer-width-xl:768px;

    }
    
            
@layer utilities{

            
.tds-drawer-text-title
{
                        @apply  text-tds-font-size-5 leading-tds-line-height-5 font-tds-font-family-heading tds-font-weight-semibold ;

                    }
.tds-drawer-text-default
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular ;

                    }

        }
            