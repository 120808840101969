:root{
    --tds-input-icon-size-xs:14px;

}
.tds-input-number {
    @apply flex items-stretch justify-center box-border relative min-w-0  outline-none w-full border-tds-border-width-s  tds-input-border-radius;

    .tds-input-number-input-wrap {
        @apply flex-auto;
        .tds-input-number-input {
            @apply flex items-center text-body-2  focus:outline-0 focus:border-transparent focus:ring-0  shadow-none border-0 bg-transparent text-current  p-0  m-0 w-full outline-none  box-border;
        }
    }
    .tds-input-number-handler-wrap {
        @apply flex-shrink-0 flex flex-col items-center justify-self-center;
        .tds-input-number-handler {
            @apply flex;
            &.tds-input-number-handler-up {
                @apply w-full text-center  h-1/2 flex flex-col-reverse   rounded-tr cursor-pointer;

                &.tds-input-number-handler-up-disabled {
                    @apply cursor-not-allowed;
                }
            }
            &.tds-input-number-handler-down {
                @apply w-full text-center h-1/2 flex flex-col  rounded-br cursor-pointer;

                &.tds-input-number-handler-down-disabled {
                    @apply cursor-not-allowed;
                }
            }
            .tds-input-number-handler-down-inner,
            .tds-input-number-handler-up-inner {
                font-size: var(--tds-input-icon-size-xs);
                line-height: var(--tds-input-icon-size-xs);
            }
        }
    }

    &-sm {
        .tds-input-number-input-wrap {
            @apply py-[--tds-input-padding-s]  pl-[--tds-input-padding-horizontal-s];
        }

        .tds-input-number-handler-wrap {
            @apply w-[30px];
        }
    }
    &-md {
        .tds-input-number-input-wrap {
            @apply py-[--tds-input-padding-m] pl-[--tds-input-padding-horizontal-m];
        }
        .tds-input-number-handler-wrap {
            @apply w-[34px];
        }
    }
    &-lg {
        .tds-input-number-input-wrap {
            @apply py-[--tds-input-padding-l] pl-[--tds-input-padding-horizontal-l];
        }

        .tds-input-number-handler-wrap {
            @apply w-[38px];
        }
    }
    &-xl {
        .tds-input-number-input-wrap {
            @apply py-[--tds-input-padding-xl] pl-[--tds-input-padding-horizontal-xl];
        }
        .tds-input-number-handler-wrap {
            @apply w-[42px];
        }
    }

    &.tds-input-number-disabled {
        @apply cursor-not-allowed;
        .tds-input-number-handler-wrap {
            @apply hidden;
        }
    }
    &.tds-input-number-hidden-icon {
        .tds-input-number-handler-wrap {
            @apply hidden;
        }
    }
    &.tds-input-number-text-align-right {
        .tds-input-number-input-wrap > input {
            @apply text-right;
        }
    }
    &.tds-input-number-text-align-left {
        .tds-input-number-input-wrap > input {
            @apply text-left;
        }
    }
    &.tds-input-number-text-align-center {
        .tds-input-number-input-wrap > input {
            @apply text-center;
        }
    }
    &-theme-default {
        @apply tds-color-surface-lowest;
        &:not(.tds-input-number-focused) {
            @apply tds-color-border-primary;
            &:not(.tds-input-number-disabled):hover {
                @apply tds-color-border-brand-primary;
            }
        }
        &.tds-input-number-focused {
            @apply tds-color-border-brand-primary ring  tds-color-ring-primary;
        }
        .tds-input-number-input-wrap {
            .tds-input-number-input {
                @apply tds-color-text-primary placeholder:tds-color-text-placeholder;
            }
        }
        .tds-input-number-handler-wrap {
            .tds-input-number-handler {
                &.tds-input-number-handler-up {
                    @apply tds-color-icon-tertiary;
                    &:not(.tds-input-number-handler-up-disabled) {
                        @apply hover:tds-color-background-neutral-minimalist;
                    }
                    &.tds-input-number-handler-up-disabled {
                        @apply tds-color-icon-disable;
                    }
                }
                &.tds-input-number-handler-down {
                    @apply tds-color-icon-tertiary;
                    &:not(.tds-input-number-handler-down-disabled) {
                        @apply hover:tds-color-background-neutral-minimalist;
                    }
                    &.tds-input-number-handler-down-disabled {
                        @apply tds-color-icon-disable;
                    }
                }
            }
        }
        &.tds-input-number-disabled {
            @apply tds-color-background-disable;
        }
    }
    &-theme-light {
        @apply tds-color-surface-lowest-light;
        &:not(.tds-input-number-focused) {
            @apply tds-color-border-primary-light;
            &:not(.tds-input-number-disabled):hover {
                @apply tds-color-border-brand-primary-light;
            }
        }
        &.tds-input-number-focused {
            @apply tds-color-border-brand-primary-light ring  tds-color-ring-primary-light;
        }
        .tds-input-number-input-wrap {
            .tds-input-number-input {
                @apply tds-color-text-primary-light placeholder:tds-color-text-placeholder-light;
            }
        }
        .tds-input-number-handler-wrap {
            .tds-input-number-handler {
                &.tds-input-number-handler-up {
                    @apply tds-color-icon-tertiary-light;
                    &:not(.tds-input-number-handler-up-disabled) {
                        @apply hover:tds-color-background-neutral-minimalist-light;
                    }
                    &.tds-input-number-handler-up-disabled {
                        @apply tds-color-icon-disable-light;
                    }
                }
                &.tds-input-number-handler-down {
                    @apply tds-color-icon-tertiary-light;
                    &:not(.tds-input-number-handler-down-disabled) {
                        @apply hover:tds-color-background-neutral-minimalist-light;
                    }
                    &.tds-input-number-handler-down-disabled {
                        @apply tds-color-icon-disable-light;
                    }
                }
            }
        }
        &.tds-input-number-disabled {
            @apply tds-color-background-disable-light;
        }
    }
    &-theme-dark {
        @apply tds-color-surface-lowest-dark;
        &:not(.tds-input-number-focused) {
            @apply tds-color-border-primary-dark;
            &:not(.tds-input-number-disabled):hover {
                @apply tds-color-border-brand-primary-dark;
            }
        }
        &.tds-input-number-focused {
            @apply tds-color-border-brand-primary-dark ring  tds-color-ring-primary-dark;
        }
        .tds-input-number-input-wrap {
            .tds-input-number-input {
                @apply tds-color-text-primary-dark placeholder:tds-color-text-placeholder-dark;
            }
        }
        .tds-input-number-handler-wrap {
            .tds-input-number-handler {
                &.tds-input-number-handler-up {
                    @apply tds-color-icon-tertiary-dark;
                    &:not(.tds-input-number-handler-up-disabled) {
                        @apply hover:tds-color-background-neutral-minimalist-dark;
                    }
                    &.tds-input-number-handler-up-disabled {
                        @apply tds-color-icon-disable-dark;
                    }
                }
                &.tds-input-number-handler-down {
                    @apply tds-color-icon-tertiary-dark;
                    &:not(.tds-input-number-handler-down-disabled) {
                        @apply hover:tds-color-background-neutral-minimalist-dark;
                    }
                    &.tds-input-number-handler-down-disabled {
                        @apply tds-color-icon-disable-dark;
                    }
                }
            }
        }
        &.tds-input-number-disabled {
            @apply tds-color-background-disable-dark;
        }
    }
}
tds-form-field {
    .tds-input-number {
        @apply border-none ring-0 border-0 ring-transparent;
        &.tds-input-number-focused {
            @apply border-none ring-0 ring-transparent border-0;
        }
    }
    &.tds-form-field-has-prefix {
        .tds-input-number {
            .tds-input-number-input-wrap {
                @apply pl-0;
            }
        }
    }
}
