:root{
        --tds-layout-width-menu-side-collapse:52px;
--tds-layout-width-menu-side-default:256px;
--tds-layout-padding-body:var(--tds-spacing-5,8px);

    }
    
            
@layer utilities{

            
.tds-layout-text-default
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular ;

                    }

        }
            