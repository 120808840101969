:root{
        --tds-btn-height-xs:26px;
--tds-btn-height-s:30px;
--tds-btn-height-m:34px;
--tds-btn-height-l:38px;
--tds-btn-height-xl:42px;
--tds-btn-padding-icon-xs:var(--tds-spacing-3,4px);
--tds-btn-padding-icon-m:var(--tds-spacing-4,6px);
--tds-btn-padding-icon-l:var(--tds-spacing-5,8px);
--tds-btn-padding-icon-xl:var(--tds-spacing-6,10px);
--tds-btn-padding-horizontal-xs:var(--tds-spacing-5,8px);
--tds-btn-padding-horizontal-s:var(--tds-spacing-6,10px);
--tds-btn-padding-horizontal-m:var(--tds-spacing-7,12px);
--tds-btn-padding-horizontal-l:var(--tds-spacing-8,14px);
--tds-btn-padding-horizontal-xl:var(--tds-spacing-9,16px);
--tds-btn-padding-vertical-xs:var(--tds-spacing-3,4px);
--tds-btn-padding-vertical-m:var(--tds-spacing-4,6px);
--tds-btn-padding-vertical-l:var(--tds-spacing-5,8px);
--tds-btn-padding-vertical-xl:var(--tds-spacing-6,10px);
--tds-btn-margin-base:var(--tds-spacing-5,8px);
--tds-btn-margin-xs:var(--tds-spacing-3,4px);
--tds-btn-padding-btn-close-s:var(--tds-spacing-2,2px);
--tds-btn-padding-btn-close-m:var(--tds-spacing-3,4px);
--tds-btn-padding-btn-close-l:var(--tds-spacing-4,6px);
--tds-btn-size-btn-close-s:16px;
--tds-btn-size-btn-close-m:20px;
--tds-btn-size-btn-close-l:24px;
--tds-btn-size-btn-close-xl:28px;

        .compact{
            --tds-btn-height-xs:28px;
--tds-btn-height-s:28px;
--tds-btn-height-m:28px;
--tds-btn-height-l:28px;
--tds-btn-height-xl:28px;
--tds-btn-padding-icon-m:var(--tds-spacing-3,4px);
--tds-btn-padding-icon-l:var(--tds-spacing-3,4px);
--tds-btn-padding-icon-xl:var(--tds-spacing-3,4px);
--tds-btn-padding-horizontal-xs:var(--tds-spacing-4,6px);
--tds-btn-padding-horizontal-s:var(--tds-spacing-4,6px);
--tds-btn-padding-horizontal-m:var(--tds-spacing-4,6px);
--tds-btn-padding-horizontal-l:var(--tds-spacing-4,6px);
--tds-btn-padding-horizontal-xl:var(--tds-spacing-4,6px);
--tds-btn-padding-vertical-m:var(--tds-spacing-3,4px);
--tds-btn-padding-vertical-l:var(--tds-spacing-3,4px);
--tds-btn-padding-vertical-xl:var(--tds-spacing-3,4px);
--tds-btn-margin-base:var(--tds-spacing-3,4px);
--tds-btn-padding-btn-close-m:var(--tds-spacing-2,2px);
--tds-btn-padding-btn-close-l:var(--tds-spacing-2,2px);

        }
    }
    
            
@layer utilities{

            
.tds-btn-color-overlay-icon-disable
{
                            @apply text-tds-color-white-light/40 dark:text-tds-color-white-dark/40;

                        }                
                        
.tds-btn-color-overlay-icon-disable-light
{
                            @apply text-tds-color-white-light/40;

                        }
                        
.tds-btn-color-overlay-icon-disable-dark
{
                            @apply text-tds-color-white-dark/40;

                        }
                        
.tds-btn-color-overlay-loading
{
                            @apply bg-tds-color-white-light/35 dark:bg-tds-color-white-dark/35;

                        }                
                        
.tds-btn-color-overlay-loading-light
{
                            @apply bg-tds-color-white-light/35;

                        }
                        
.tds-btn-color-overlay-loading-dark
{
                            @apply bg-tds-color-white-dark/35;

                        }
                        
.tds-btn-color-overlay-background-hover
{
                            @apply bg-tds-color-black-light/50 dark:bg-tds-color-black-dark/50;

                        }                
                        
.tds-btn-color-overlay-background-hover-light
{
                            @apply bg-tds-color-black-light/50;

                        }
                        
.tds-btn-color-overlay-background-hover-dark
{
                            @apply bg-tds-color-black-dark/50;

                        }
                        
.tds-btn-border-radius-base
{
                        @apply rounded-tds-border-radius-s compact:rounded-tds-border-radius-xs;

                    }
.tds-btn-border-radius-xs
{
                        @apply rounded-tds-border-radius-xs ;

                    }
.tds-btn-text-base
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold compact:text-tds-font-size-2 compact:leading-tds-line-height-3 compact:font-tds-font-family-body compact:tds-font-weight-semibold;

                    }
.tds-btn-text-xs
{
                        @apply  text-tds-font-size-2 leading-tds-line-height-3 font-tds-font-family-body tds-font-weight-semibold ;

                    }
.tds-btn-icon-size-base
{
                        @apply  text-tds-font-size-6 leading-tds-line-height-4 compact:text-tds-font-size-4 compact:leading-tds-line-height-3;

                    }
.tds-btn-icon-size-xs
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-3 ;

                    }

        }
            