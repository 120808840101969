:root{
        --tds-list-padding-horizontal:var(--tds-spacing-7,12px);
--tds-list-padding-vertical:var(--tds-spacing-7,12px);
--tds-list-margin-left-s:var(--tds-spacing-5,8px);
--tds-list-margin-left-m:var(--tds-spacing-7,12px);
--tds-list-margin-left-l:var(--tds-spacing-9,16px);
--tds-list-margin-top-base:var(--tds-spacing-2,2px);
--tds-list-margin-item-top-s:var(--tds-spacing-5,8px);
--tds-list-margin-item-top-m:var(--tds-spacing-7,12px);

    }
    
            
            