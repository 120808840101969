tds-notification-container {
    .tds-notification {
        @apply fixed;
    }
    tds-notification {
        @apply mb-2 block;
        .tds-notification-notice {
            @apply relative mx-[--tds-notification-margin-outside-horizontal];
            > .tds-notification-notice-content {
                @apply tds-color-surface-high p-[--tds-notification-padding] tds-color-text-primary tds-notification-text-default tds-notification-shadow tds-notification-border-radius flex flex-col w-[--tds-notification-width];
                > .tds-notification-notice-content {
                    @apply flex;
                }
                > .tds-notification-notice-with-icon {
                    > div:nth-child(2) {
                        @apply ml-[--tds-notification-margin-left];
                    }
                }
                .tds-notification-notice-icon {
                    @apply tds-notification-icon-size;
                }
                .tds-notification-notice-icon-success {
                    @apply tds-color-icon-success;
                }
                .tds-notification-notice-icon-info {
                    @apply tds-color-icon-info;
                }
                .tds-notification-notice-icon-warning {
                    @apply tds-color-icon-warning;
                }
                .tds-notification-notice-icon-error {
                    @apply tds-color-icon-error;
                }
                .tds-notification-notice-message {
                    @apply tds-notification-title break-words pr-[--tds-notification-padding];
                }
                .tds-notification-notice-description {
                    @apply mt-[--tds-notification-margin-top-s]  break-words pr-[--tds-notification-padding];
                }
            }
        }
        .tds-notification-notice-content-template {
            @apply tds-color-surface-high p-[--tds-notification-padding] tds-color-text-primary tds-notification-text-default tds-notification-shadow tds-notification-border-radius flex flex-col w-[--tds-notification-width];
        }
        .tds-notification-notice-close {
            @apply absolute  top-4 right-4 cursor-pointer;
        }
    }
}
