.tds-steps {
    @apply w-full  flex;
    tds-step {
        @apply overflow-visible relative inline-block align-top last:flex-none;
    }
}
tds-steps.tds-steps-default {
    tds-step {
        .tds-steps-item-tail {
            @apply tds-color-border-primary;
        }
        .tds-steps-icon {
            @apply flex w-full items-center justify-center;
            > span {
                @apply rounded-full flex items-center justify-center;
            }
            .tds-steps-item-show-step {
                @apply tds-color-background-primary-subtle;
            }
            .tds-steps-item-show-icon {
                @apply border tds-color-border-brand-primary;
            }
            .tds-steps-icon-custom {
                @apply flex items-center justify-center;
            }
        }
        .tds-steps-item-content {
            .tds-steps-item-title {
                > div:first-child {
                    @apply tds-color-text-primary;
                }
            }
            .tds-steps-item-subtitle,
            .tds-steps-item-description {
                @apply tds-color-text-secondary;
            }
        }
        &:not(.tds-steps-item-last) {
            @apply flex-1;
        }
        &.tds-steps-item-active:not(.tds-steps-item-error) {
            .tds-steps-item-content {
                .tds-steps-item-title {
                    > div:first-child {
                        @apply tds-color-text-primary;
                    }
                }
            }
        }
        &.tds-steps-item-finish {
            .tds-steps-item-title {
                > div:first-child {
                    @apply tds-color-text-primary;
                }
            }
            .tds-steps-item-show-icon {
                > span {
                    @apply tds-color-icon-brand-primary tds-steps-icon-size-base;
                }
            }
            .tds-steps-item-show-step {
                > span {
                    @apply tds-color-text-white tds-steps-icon-size-base;
                }
            }
            .tds-steps-item-container {
                .tds-steps-item-tail {
                    @apply tds-color-border-brand-primary;
                }
            }
        }

        &.tds-steps-item-error {
            .tds-steps-item-content {
                .tds-steps-item-title {
                    > div:first-child {
                        @apply tds-color-text-error;
                    }
                }
                .tds-steps-item-subtitle,
                .tds-steps-item-description {
                    @apply tds-color-text-error;
                }
            }
            .tds-steps-icon {
                > span {
                    @apply border  tds-color-border-error;
                    > span {
                        @apply tds-color-icon-error tds-steps-icon-size-base;
                    }
                }
            }
        }

        &.tds-steps-item-wait {
            .tds-steps-icon {
                > span {
                    @apply border tds-color-border-primary;
                    > span {
                        @apply tds-color-text-secondary text-body-2 font-semibold;
                    }
                }
            }
        }
    }
    &.tds-steps-horizontal {
        &.tds-steps-label-horizontal {
            tds-step {
                .tds-steps-item-container {
                    @apply relative outline-none;
                    .tds-steps-item-tail {
                        @apply border-t  absolute left-0 right-0;
                    }
                    .tds-steps-item-icon {
                        @apply w-36;
                    }
                    .tds-steps-item-content {
                        @apply text-center w-36 mt-1;
                        .tds-steps-item-title {
                            > div:first-child {
                                @apply text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold;
                            }
                            .tds-steps-item-subtitle {
                                @apply text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-regular;
                            }
                        }
                        .tds-steps-item-description {
                            @apply text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-regular;
                        }
                    }
                }
            }
            &.tds-steps-xs {
                tds-step {
                    .tds-steps-item-tail {
                        @apply ml-[81px] w-[calc(100%-17px)];
                    }
                }
            }
            &.tds-steps-sm {
                tds-step {
                    .tds-steps-item-tail {
                        @apply ml-[85px] w-[calc(100%-25px)];
                    }
                }
            }
            &.tds-steps-md {
                tds-step {
                    .tds-steps-item-tail {
                        @apply ml-[89px] w-[calc(100%-33px)];
                    }
                }
            }
            &.tds-steps-lg {
                tds-step {
                    .tds-steps-item-tail {
                        @apply ml-[93px] w-[calc(100%-41px)];
                    }
                }
            }
            &.tds-steps-xl {
                tds-step {
                    .tds-steps-item-tail {
                        @apply ml-[93px] w-[calc(100%-41px)];
                    }
                }
            }
        }
        &.tds-steps-label-vertical {
            .tds-steps-item-container {
                @apply relative outline-none flex items-stretch;
                .tds-steps-item-content {
                    @apply text-left ml-2 flex-auto;
                    .tds-steps-item-title {
                        @apply w-full flex;
                        > div {
                            @apply text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold pr-2;
                        }
                    }
                }
            }
            .tds-steps-item-tail {
                @apply w-full h-px  absolute left-0 right-0 bg-neutral-2-200 dark:bg-d-neutral-2-200;
            }
            .tds-steps-item-subtitle {
                @apply w-full text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-regular;
            }
            .tds-steps-item-description {
                @apply text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-regular;
            }
            tds-step.tds-steps-item-finish {
                .tds-steps-item-content {
                    .tds-steps-item-title {
                        .tds-steps-item-tail {
                            @apply tds-color-background-primary-subtle;
                        }
                    }
                }
            }
        }
        &.tds-steps-xs {
            tds-step {
                .tds-steps-item-tail {
                    @apply top-2;
                }
            }
        }
        &.tds-steps-sm {
            tds-step {
                .tds-steps-item-tail {
                    @apply top-3;
                }
            }
        }
        &.tds-steps-md {
            tds-step {
                .tds-steps-item-tail {
                    @apply top-4;
                }
            }
        }
        &.tds-steps-lg {
            tds-step {
                .tds-steps-item-tail {
                    @apply top-5;
                }
            }
        }
        &.tds-steps-xl {
            tds-step {
                .tds-steps-item-tail {
                    @apply top-5;
                }
            }
        }
    }
    &.tds-steps-vertical {
        @apply h-full flex-col;
        .tds-steps-item-container {
            @apply relative outline-none flex items-stretch h-full;
            .tds-steps-item-tail {
                @apply border-l top-0 bottom-0 absolute tds-color-border-primary;
            }
            .tds-steps-item-icon {
                @apply flex flex-col items-stretch;
            }
            .tds-steps-item-content {
                @apply pl-2;
            }
            .tds-steps-item-description {
                @apply text-tds-font-size-1 leading-tds-line-height-2 font-tds-font-family-body tds-font-weight-regular;
            }
        }

        &.tds-steps-xs {
            tds-step {
                .tds-steps-item-tail {
                    @apply left-2 mt-4;
                }
            }
        }
        &.tds-steps-sm {
            tds-step {
                .tds-steps-item-tail {
                    @apply left-3 mt-6;
                }
            }
        }
        &.tds-steps-md {
            tds-step {
                .tds-steps-item-tail {
                    @apply left-4 mt-8;
                }
            }
        }
        &.tds-steps-lg {
            tds-step {
                .tds-steps-item-tail {
                    @apply left-5 mt-10;
                }
            }
        }
        &.tds-steps-xl {
            tds-step {
                .tds-steps-item-tail {
                    @apply left-5 mt-10;
                }
            }
        }
        .tds-steps-item {
            &.tds-steps-item-only-title {
                .tds-steps-item-container {
                    .tds-steps-item-title {
                        @apply flex items-center;
                    }
                }
            }
            &:not(.tds-steps-item-last) {
                .tds-steps-item-container {
                    @apply pb-2;
                }
            }
        }
    }
    &.tds-steps-xs {
        tds-step {
            .tds-steps-icon {
                > span {
                    @apply h-[var(--tds-steps-height-xs)] w-[var(--tds-steps-width-xs)];
                }
                .tds-steps-icon-custom {
                    @apply h-[var(--tds-steps-height-xs)] w-[var(--tds-steps-width-xs)];
                }
            }
        }
    }
    &.tds-steps-sm {
        tds-step {
            .tds-steps-icon {
                > span {
                    @apply  size-[--tds-steps-size-s];
                }
                .tds-steps-icon-custom {
                    @apply size-[--tds-steps-size-s];
                }
            }
        }
    }
    &.tds-steps-md {
        tds-step {
            .tds-steps-icon {
                > span {
                    @apply size-[--tds-steps-size-m];
                }
                .tds-steps-icon-custom {
                    @apply size-[--tds-steps-size-m];
                }
            }
        }
    }
    &.tds-steps-lg {
        tds-step {
            .tds-steps-icon {
                > span {
                    @apply size-[--tds-steps-size-l];
                }
                .tds-steps-icon-custom {
                    @apply size-[--tds-steps-size-l];
                }
            }
        }
    }
    &.tds-steps-xl {
        tds-step {
            .tds-steps-icon {
                > span {
                    @apply size-[--tds-steps-size-l];
                }
                .tds-steps-icon-custom {
                    @apply size-[--tds-steps-size-l];
                }
            }
        }
    }
}
//custom steps bar chuyển màu active primary => secondary
tds-steps.tds-steps-bar {
    .tds-step-part-1 {
        @apply tds-color-border-secondary;
    }
    .tds-step-part-3{
        @apply tds-color-border-secondary;
    }
    tds-step {
        .tds-steps-item-container {
            @apply flex outline-none min-w-32  items-stretch relative cursor-pointer;
            .tds-step-part-1 {
                @apply w-0 h-0  border-l-transparent border-r-transparent #{!important};
            }
            .tds-step-part-2 {
                @apply flex-auto text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold  flex items-center justify-center  truncate  tds-color-background-neutral-minimal tds-color-text-secondary;
            }
            .tds-step-rounded-right {
                @apply rounded-r;
            }
            .tds-step-rounded-left {
                @apply rounded-l;
            }
            .tds-step-part-3 {
                @apply w-0 h-0 border-t-transparent border-b-transparent border-r-transparent #{!important};
            }
        }
        .tds-steps-description {
            @apply w-full text-center py-0.5 ;
        }
        .tds-steps-xs-sm-title{
            @apply p-0.5 
        }
        &.tds-steps-item-disabled {
            .tds-steps-item-container {
                @apply cursor-not-allowed;
            }
        }
    }
    // tds-step.tds-steps-item-active {
    //     .tds-step-part-1 {
    //     @apply tds-steps-color-border-active;
    // }
    // .tds-step-part-3{
    //     @apply tds-steps-color-border-active;
    // }
    //     .tds-steps-item-container {
    //         .tds-step-part-1 {
    //             @apply  border-l-transparent border-r-transparent;
    //         }
    //         .tds-step-part-2 {
    //             @apply bg-success-400 dark:bg-d-success-400;
    //         }
    //         .tds-step-part-3 {
    //             @apply border-l-success-400 dark:border-l-d-success-400;
    //         }
    //     }
    // }
    tds-step.tds-steps-item-process {
        .tds-step-part-1 {
            @apply tds-color-border-info;
        }
        .tds-step-part-3{
            @apply tds-color-border-info;
        }
        .tds-steps-item-container {
            // .tds-step-part-1 {
            //     @apply border-t-info-400 dark:border-t-d-info-400 border-b-info-400 dark:border-b-d-info-400;
            // }
            .tds-step-part-2 {
                @apply tds-color-background-info-subtle tds-color-text-white;
            }
            // .tds-step-part-3 {
            //     @apply border-l-info-400 dark:border-l-d-info-400;
            // }
        }
    }

    tds-step.tds-steps-item-error {
        .tds-step-part-1 {
            @apply tds-color-border-error;
        }
        .tds-step-part-3{
            @apply tds-color-border-error;
        }
        .tds-steps-item-container {
            // .tds-step-part-1 {
            //     @apply border-t-error-400 dark:border-t-d-error-400 border-b-error-400 dark:border-b-d-error-400;
            // }
            .tds-step-part-2 {
                @apply tds-color-background-error-subtle tds-color-text-white;
            }
            // .tds-step-part-3 {
            //     @apply border-l-error-400 dark:border-l-d-error-400;
            // }
        }
    }
    tds-step.tds-steps-item-finish {
        .tds-step-part-1 {
            @apply tds-color-border-success;
        }
        .tds-step-part-3{
            @apply tds-color-border-success;
        }
        .tds-steps-item-container {
            // .tds-step-part-1 {
            //     @apply border-t-success-400 dark:border-t-d-success-400 border-b-success-400 dark:border-b-d-success-400;
            // }
            .tds-step-part-2 {
                @apply tds-color-background-success-subtle tds-color-text-white;
            }
            // .tds-step-part-3 {
            //     @apply border-l-success-400 dark:border-l-d-success-400;
            // }
        }
    }
    // tds-step.tds-steps-item-active{
    //     .tds-steps-item-container{
    //         .tds-step-part-1{
    //             @apply border-t-secondary-1 dark:border-t-d-secondary-1 border-b-secondary-1 dark:border-b-d-secondary-1
    //          }
    //         .tds-step-part-2{
    //            @apply bg-secondary-1 dark:bg-d-secondary-1
    //         }
    //         .tds-step-part-3{
    //             @apply border-l-secondary-1 dark:border-l-d-secondary-1
    //         }
    //     }
    // }
}
tds-steps.tds-steps-bar {
    &.tds-steps-xs {
        tds-step {
            .tds-steps-item-container {
                .tds-step-part-1 {
                    @apply border-t-[4px] border-b-[4px] border-l-[3px];
                }
                .tds-step-part-2 {
                    @apply h-2 pl-2 pr-2;
                }
                .tds-step-part-3 {
                    @apply border-t-[4px] border-b-[4px] border-l-[3px];
                }
                .tds-step-rounded-right {
                    @apply rounded-r;
                }
                .tds-step-rounded-left {
                    @apply rounded-l;
                }
              
            }
            .tds-steps-description{
                @apply pr-1.5;
            }
            &:not(:first-child) {
                @apply -ml-0.5;
            }
        }
    }
    &.tds-steps-sm {
        tds-step {
            .tds-steps-item-container {
                .tds-step-part-1 {
                    @apply border-t-[8px] border-b-[8px] border-l-[6px];
                }
                .tds-step-part-2 {
                    @apply h-4 pl-4 pr-4;
                }
                .tds-step-part-3 {
                    @apply border-t-[8px] border-b-[8px] border-l-[6px];
                }
                .tds-step-rounded-right {
                    @apply rounded-r;
                }
                .tds-step-rounded-left {
                    @apply rounded-l;
                }
                
            }
            .tds-steps-description{
                @apply pr-1.5;
            }
            &:not(:first-child) {
                @apply -ml-0.5;
            }
        }
    }
    &.tds-steps-md {
        tds-step {
            .tds-steps-item-container {
                .tds-step-part-1 {
                    @apply border-t-[16px] border-b-[16px] border-l-[12px];
                }
                .tds-step-part-2 {
                    @apply py-1.5 pl-4 pr-4 ;
                }
                .tds-step-part-3 {
                    @apply border-t-[16px] border-b-[16px] border-l-[12px];
                }
                .tds-step-rounded-right {
                    @apply rounded-r;
                }
                .tds-step-rounded-left {
                    @apply rounded-l;
                }
                
            }
            .tds-steps-description{
                @apply pr-2.5;
            }
            &:not(:first-child) {
                @apply -ml-2;
            }
        }
    }
    &.tds-steps-lg {
        tds-step {
            .tds-steps-item-container {
                .tds-step-part-1 {
                    @apply border-t-[29px] border-b-[29px] border-l-[22px];
                }
                .tds-step-part-2 {
                    @apply py-2 pl-4 pr-4;
                }
                .tds-step-part-3 {
                    @apply border-t-[29px] border-b-[29px] border-l-[22px];
                }
                .tds-step-rounded-right {
                    @apply rounded-r;
                }
                .tds-step-rounded-left {
                    @apply rounded-l;
                }
               
            }
            .tds-steps-description{
                @apply pr-5;
            }
            &:not(:first-child) {
                margin-left: -18px;
            }
        }
    }
    &.tds-steps-xl {
        tds-step {
            @apply -ml-5;
            .tds-steps-item-container {
                .tds-step-part-1 {
                    @apply border-t-[32px] border-b-[32px] border-l-[24px];
                }
                .tds-step-part-2 {
                    @apply py-2 pl-4 pr-4;
                }
                .tds-step-part-3 {
                    @apply border-t-[32px] border-b-[32px] border-l-[24px];
                }
                .tds-step-rounded-right {
                    @apply rounded-r;
                }
                .tds-step-rounded-left {
                    @apply rounded-l;
                }
                
            }
            .tds-steps-description{
                @apply pr-5;
            }
        }
    }
    &.rounded-full {
        tds-step {
            .tds-steps-item-container {
                .tds-step-rounded-right {
                    @apply rounded-r-full;
                }
                .tds-step-rounded-left {
                    @apply rounded-l-full;
                }
            }
        }
    }
}
