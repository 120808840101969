:root{
        --tds-card-padding-s:var(--tds-spacing-5,8px);
--tds-card-padding-m:var(--tds-spacing-7,12px);
--tds-card-padding-l:var(--tds-spacing-9,16px);
--tds-card-margin-m:var(--tds-spacing-5,8px);
--tds-card-margin-l:var(--tds-spacing-7,12px);
--tds-card-margin-s:var(--tds-spacing-4,6px);

    }
    
            
@layer utilities{

            
.tds-card-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }

        }
            