:root{
        --tds-message-margin-left:var(--tds-spacing-5,8px);
--tds-message-padding-horizontal:var(--tds-spacing-9,16px);
--tds-message-padding-vertical:var(--tds-spacing-7,12px);
--tds-message-margin-outer-bottom:var(--tds-spacing-3,4px);

    }
    
            
@layer utilities{

            
.tds-message-shadow
{
                            @apply shadow-tds-shadow-xl-light dark:shadow-tds-shadow-xl-dark;

                        }                
                        
.tds-message-shadow-light
{
                            @apply shadow-tds-shadow-xl-light;

                        }
                        
.tds-message-shadow-dark
{
                            @apply shadow-tds-shadow-xl-dark;

                        }
                        
.tds-message-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }
.tds-message-text
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular ;

                    }

        }
            