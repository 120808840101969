tds-modal-container {
    &.tds-modal-wrap {
        @apply fixed top-0 left-0 right-0 bottom-0 overflow-auto outline-none;
        .tds-modal {
            @apply max-w-[calc(100vw-16px)] tds-color-surface-lowest tds-color-text-primary tds-modal-border-radius box-border pointer-events-none relative my-auto mx-auto;
            .tds-modal-content {
                @apply pointer-events-auto relative;
                .tds-modal-title {
                    @apply w-full pr-[--tds-modal-padding-header-right] py-[--tds-modal-padding-header-vertical] pl-[--tds-modal-padding-header-left] flex items-center border-b-tds-border-width-s  tds-color-border-primary;
                    > div {
                        @apply tds-modal-header-title tds-color-text-primary break-words;
                    }
                }
                .tds-modal-close {
                    @apply absolute top-3 right-3;
                }
                .tds-modal-body {
                    @apply pt-[--tds-modal-padding-body-top] px-[--tds-modal-padding-body-horizontal] pb-[--tds-modal-padding-body-bottom] tds-custom-scroll overflow-auto;
                }
            }
        }
    }
    &.tds-modal-centered {
        @apply flex;
        .tds-modal-body {
            @apply max-h-[calc(100vh-148px)] ;
        }
    }
    &.tds-modal-default {
        //min 16px, max 96px
        --tds-top-modal:clamp(16px, 10vh, 96px);
        .tds-modal {
            top: var(--tds-top-modal);            
        }
        .tds-modal-body {
            overflow: auto;
            max-height: calc(100vh - var(--tds-top-modal) - var(--tds-top-modal) - 148px)
        }
    }
}
.tds-modal-footer {
    .tds-modal-footer-default-button {
        @apply w-full flex justify-end p-[--tds-modal-padding-footer];
        button {
            @apply ml-[--tds-btn-margin-base] min-w-[100px];
        }
    }
}
tds-modal-confirm-container {
    &.tds-modal-wrap {
        @apply fixed top-0 left-0 right-0 bottom-0 overflow-auto outline-none;
    }
}
.tds-modal-mask {
    @apply fixed top-0 left-0 right-0 bottom-0 h-full tds-color-overlay-default;
}
