:root{
        --tds-qr-code-padding-s:var(--tds-spacing-2,2px);
--tds-qr-code-padding-m:var(--tds-spacing-5,8px);
--tds-qr-code-margin-left:var(--tds-spacing-7,12px);
--tds-qr-code-margin-top:var(--tds-spacing-7,12px);

    }
    
            
@layer utilities{

            
.tds-qr-code-color-background-loading
{
                            @apply bg-tds-color-white-light/35 dark:bg-tds-color-white-dark/35;

                        }                
                        
.tds-qr-code-color-background-loading-light
{
                            @apply bg-tds-color-white-light/35;

                        }
                        
.tds-qr-code-color-background-loading-dark
{
                            @apply bg-tds-color-white-dark/35;

                        }
                        
.tds-qr-code-text
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular ;

                    }

        }
            