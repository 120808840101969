.tds-button-overlay {
    @apply rounded-full;
    > tds-spinner {
        @apply size-full;
    }
    &-size {
        &-xs {
            @apply p-[--tds-btn-padding-icon-xs] size-[--tds-btn-height-xs];
            // > tds-spinner {
            //     @apply size-4;
            // }
            .tds-button-overlay-icon {
                @apply tds-btn-icon-size-xs;
            }
        }
        &-sm {
            @apply p-[--tds-btn-padding-icon-xs] size-[--tds-btn-height-s];
            .tds-button-overlay-icon {
                @apply tds-btn-icon-size-base;
            }
        }
        &-md {
            @apply p-[--tds-btn-padding-icon-m] size-[--tds-btn-height-m];
            .tds-button-overlay-icon {
                @apply tds-btn-icon-size-base;
            }
        }
        &-lg {
            @apply p-[--tds-btn-padding-icon-l] size-[--tds-btn-height-l];
            .tds-button-overlay-icon {
                @apply tds-btn-icon-size-base;
            }
        }
        &-xl {
            @apply p-[--tds-btn-padding-icon-xl] size-[--tds-btn-height-xl];
            .tds-button-overlay-icon {
                @apply tds-btn-icon-size-base;
            }
        }
    }
    &:not(.tds-button-overlay-disabled) {
        @apply tds-color-text-white;
        &.tds-button-overlay-theme-default {
            @apply tds-color-overlay-default hover:tds-btn-color-overlay-background-hover;
        }

        &.tds-button-overlay-theme-light {
            @apply tds-color-overlay-default-light hover:tds-btn-color-overlay-background-hover-light;
        }

        &.tds-button-overlay-theme-dark {
            @apply tds-color-overlay-default-dark hover:tds-btn-color-overlay-background-hover-dark;
        }
    }
    &.tds-button-overlay-disabled {
        @apply cursor-not-allowed;
        &.tds-button-overlay-theme-default {
            @apply tds-btn-color-overlay-icon-disable tds-color-overlay-default;
        }

        &.tds-button-overlay-theme-light {
            @apply tds-btn-color-overlay-icon-disable-light tds-color-overlay-default-light;
        }

        &.tds-button-overlay-theme-dark {
            @apply tds-btn-color-overlay-icon-disable-dark tds-color-overlay-default-dark;
        }
    }
    &.tds-button-overlay-loading {
        &:not(.tds-button-overlay-disabled) {
            @apply cursor-progress;
        }
    }
}
