:root{
        --tds-tooltip-margin-default:var(--tds-spacing-5,8px);
--tds-tooltip-padding-outer-box-s:var(--tds-spacing-3,4px);
--tds-tooltip-padding-outer-box-m:var(--tds-spacing-4,6px);

        .compact{
            --tds-tooltip-margin-default:var(--tds-spacing-3,4px);

        }
    }
    
            
@layer utilities{

            
.tds-tooltip-color-background-default
{
                            @apply bg-tds-color-black-light/80 dark:tds-color-surface-high-dark;

                        }                
                        
.tds-tooltip-color-background-default-light
{
                            @apply bg-tds-color-black-light/80;

                        }
                        
.tds-tooltip-color-background-default-dark
{
                            @apply tds-color-surface-high-dark;

                        }
                        
.tds-tooltip-border-radius
{
                        @apply rounded-tds-border-radius-xs compact:rounded-tds-border-radius-xxs;

                    }

        }
            