tds-empty {
    @apply flex flex-col items-center tds-color-text-primary;
    .tds-empty-image {
        @apply mb-3
    }
    .tds-empty-description {
        @apply text-tds-font-size-3 tds-font-weight-semibold text-center;
    }
    .tds-empty-footer {
        @apply mt-3;
    }
}
