:root{
        --tds-skeleton-padding-right-l:var(--tds-spacing-9,16px);
--tds-skeleton-margin-top-m:var(--tds-spacing-7,12px);
--tds-skeleton-margin-top-l:var(--tds-spacing-9,16px);
--tds-skeleton-icon-size-image-s:36px;
--tds-skeleton-icon-size-image-m:64px;
--tds-skeleton-icon-size-image-l:112px;
--tds-skeleton-height-avatar-s:32px;
--tds-skeleton-height-avatar-m:48px;
--tds-skeleton-width-avatar-s:32px;
--tds-skeleton-width-avatar-m:48px;
--tds-skeleton-height-title:18px;
--tds-skeleton-height-body:14px;

    }
    
            
@layer utilities{

            
.tds-skeleton-color-gradient-right-active
{
                            @apply bg-gradient-to-r  from-tds-color-neutral-200-light from-[25%]  via-tds-color-neutral-50-light via-[37%]  to-tds-color-neutral-200-light to-[63%] dark:bg-gradient-to-r dark:from-tds-color-neutral-300-dark dark:from-[25%] dark:via-tds-color-neutral-50-dark dark:via-[37%] dark:to-tds-color-neutral-300-dark dark:to-[63%];

                        }                
                        
.tds-skeleton-color-gradient-right-active-light
{
                            @apply bg-gradient-to-r  from-tds-color-neutral-200-light from-[25%]  via-tds-color-neutral-50-light via-[37%]  to-tds-color-neutral-200-light to-[63%];

                        }
                        
.tds-skeleton-color-gradient-right-active-dark
{
                            @apply bg-gradient-to-r  from-tds-color-neutral-300-dark from-[25%]  via-tds-color-neutral-50-dark via-[37%]  to-tds-color-neutral-300-dark to-[63%];

                        }
                        
.tds-skeleton-color-background-default
{
                            @apply tds-color-background-neutral-subtle-light dark:tds-color-background-neutral-boldest-dark;

                        }                
                        
.tds-skeleton-color-background-default-light
{
                            @apply tds-color-background-neutral-subtle-light;

                        }
                        
.tds-skeleton-color-background-default-dark
{
                            @apply tds-color-background-neutral-boldest-dark;

                        }
                        
.tds-skeleton-border-radius
{
                        @apply rounded-tds-border-radius-circle ;

                    }
.tds-skeleton-border-radius-image
{
                        @apply rounded-tds-border-radius-s ;

                    }

        }
            