tds-avatar {
    &.tds-avatar {
        @apply box-border m-0 p-0 relative inline-flex justify-center items-center overflow-hidden align-middle text-center whitespace-nowrap;
    }
    // &.tds-avatar-xs {
    //     @apply h-[--tds-avatar-height-xs] w-[--tds-avatar-width-xs] text-base tds-avatar-border-radius-xs;
    // }
    &.tds-avatar-sm {
        @apply h-[--tds-avatar-height-s] w-[--tds-avatar-width-s] text-base tds-avatar-border-radius-xs;
    }
    &.tds-avatar-md {
        @apply h-[--tds-avatar-height-m] w-[--tds-avatar-width-m] text-lg tds-avatar-border-radius-base;
    }
    &.tds-avatar-lg {
        @apply h-[--tds-avatar-height-l] w-[--tds-avatar-width-l] text-2xl tds-avatar-border-radius-base;
    }
    &.tds-avatar-xl {
        @apply h-[--tds-avatar-height-xl] w-[--tds-avatar-width-xl] text-4xl tds-avatar-border-radius-base;
    }
    &.tds-avatar-xxl {
        @apply h-[--tds-avatar-height-xxl] w-[--tds-avatar-width-xxl] text-4xl tds-avatar-border-radius-base;
    }
    &.tds-avatar-circle {
        @apply rounded-full;
    }
    &.tds-avatar-bordered.tds-avatar-image {
        @apply border-tds-border-width-m relative;
        > img {
            @apply border-2 tds-color-border-white;
        }
        &.tds-avatar-sm {
            > img {
                @apply tds-avatar-border-radius-xs;
            }
        }
        &.tds-avatar-md {
            > img {
                @apply tds-avatar-border-radius-base;
            }
        }
        &.tds-avatar-lg {
            > img {
                @apply tds-avatar-border-radius-base;
            }
        }
        &.tds-avatar-xl {
            > img {
                @apply tds-avatar-border-radius-base;
            }
        }
        &.tds-avatar-xxl {
            > img {
                @apply tds-avatar-border-radius-base;
            }
        }
        &.tds-avatar-circle {
            > img {
                @apply rounded-full;
            }
        }
        &.tds-avatar-theme-default {
            @apply  tds-color-border-brand-primary ;
           
        }
        &.tds-avatar-theme-light {
            @apply  tds-color-border-brand-primary-light ;
            
        }
        &.tds-avatar-theme-dark {
            @apply tds-color-border-brand-primary-dark;
            
        }
    }
    .tds-avatar-string {
        @apply uppercase;
    }
}
tds-avatar-group {
    &.tds-avatar-group {
        @apply inline-flex;
        tds-avatar:not(:first-child) {
            &.tds-avatar {
                @apply ml-[--tds-avatar-margin-left] border tds-color-border-white;
            }
        }
    }
}
