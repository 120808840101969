:root{
        --tds-calendar-padding-xs:var(--tds-spacing-2,2px);
--tds-calendar-padding-s:var(--tds-spacing-3,4px);
--tds-calendar-padding-m:var(--tds-spacing-4,6px);
--tds-calendar-padding-l:var(--tds-spacing-5,8px);
--tds-calendar-padding-xl:var(--tds-spacing-6,10px);
--tds-calendar-padding-header:var(--tds-spacing-7,12px);
--tds-calendar-margin-s:var(--tds-spacing-3,4px);
--tds-calendar-margin-m:var(--tds-spacing-4,6px);
--tds-calendar-margin-l:var(--tds-spacing-5,8px);
--tds-calendar-width-date-value:28px;
--tds-calendar-height-date-value:28px;

    }
    
            
@layer utilities{

            
.tds-calendar-color-border-over
{
                            @apply tds-color-border-white-light dark:border-tds-color-neutral-300-dark;

                        }                
                        
.tds-calendar-color-border-over-light
{
                            @apply tds-color-border-white-light;

                        }
                        
.tds-calendar-color-border-over-dark
{
                            @apply border-tds-color-neutral-300-dark;

                        }
                        
.tds-calendar-color-timeline-indicator
{
                            @apply border-tds-color-primary-500-light/40 dark:border-tds-color-primary-500-dark/40;

                        }                
                        
.tds-calendar-color-timeline-indicator-light
{
                            @apply border-tds-color-primary-500-light/40;

                        }
                        
.tds-calendar-color-timeline-indicator-dark
{
                            @apply border-tds-color-primary-500-dark/40;

                        }
                        
.tds-calendar-border-radius-m
{
                        @apply rounded-tds-border-radius-s ;

                    }
.tds-calendar-border-radius-s
{
                        @apply rounded-tds-border-radius-xs ;

                    }

        }
            