tds-image-preview {
    &.tds-image-preview-wrap {
        @apply fixed inset-0 overflow-auto tds-color-overlay-default-light dark:tds-color-overlay-default-dark;
        .tds-image-preview {
            @apply pointer-events-none h-full	text-center m-0 p-0;
        }
        .tds-image-preview-content {
            @apply m-0 p-0 w-full h-full;
        }
        .tds-image-preview-img-wrapper {
            @apply absolute inset-4 bottom-[88px] flex items-center justify-center;
            .tds-image-preview-img {
                @apply cursor-grab text-center pointer-events-auto max-w-full max-h-full;
            }
        }
        .tds-image-preview-body {
            @apply absolute inset-0  overflow-hidden;

            .tds-image-preview-operations {
                @apply pointer-events-auto box-border absolute m-0  cursor-pointer top-4 right-0 flex  justify-center items-center;
                > ul {
                    @apply flex  justify-center items-center mr-3;
                    .tds-image-preview-operations-operation {
                        @apply first:rounded-l-lg last:rounded-r-lg cursor-pointer  flex px-4 py-3.5 tds-color-overlay-default  tds-color-icon-white;
                        &.tds-image-preview-operations-operation-disabled {
                            @apply tds-btn-color-overlay-icon-disable  cursor-not-allowed;
                        }
                        &:not(.tds-image-preview-operations-operation-disabled):hover {
                            @apply bg-tds-color-black-light/50 dark:bg-tds-color-black-dark/50;
                        }
                    }
                }
                button.tds-image-preview-operations-operation {
                    @apply mr-4;
                }
            }
            .tds-image-preview-switch-left {
                @apply pointer-events-auto -mt-4  cursor-pointer rounded-full  left-5 absolute top-1/2  z-10;
            }
            .tds-image-preview-switch-right {
                @apply -mt-4 pointer-events-auto cursor-pointer   absolute top-1/2 right-5  z-10;
            }
        }
        &.tds-image-preview-gallery {
            .tds-image-preview-img-wrapper {
                @apply bottom-4;
            }
        }
        .tds-image-preview-list-image {
            @apply absolute bottom-0 left-0 right-0 flex items-center z-10 justify-center overflow-x-auto tds-custom-scroll pointer-events-auto h-[86px] max-w-full;
            .tds-image-preview-avatar {
                @apply flex items-center;
                tds-avatar {
                    @apply border border-transparent relative  cursor-pointer after:absolute after:inset-0 after:content-[''];
                    &:after {
                        @apply tds-color-overlay-default-light dark:tds-color-overlay-default-dark;
                    }
                }
                &.active {
                    tds-avatar {
                        @apply border-white after:content-none;
                    }
                }
            }
        }
    }
}
