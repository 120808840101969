.tds-progress {
    @apply box-border m-0 p-0 inline-block tds-color-text-primary;
}

.tds-progress-line {
    @apply relative w-full;
    font-size: 14px;
}

.tds-progress-steps {
    @apply inline-block;
}

.tds-progress-steps-outer {
    @apply flex items-center;
}

.tds-progress-steps-item {
    @apply shrink-0 min-w-[2px] mr-0.5 tds-color-background-neutral-minimal;
    transition: all 0.3s;
}

.tds-progress-steps-item-active {
    @apply tds-color-background-info-subtle;
}

.tds-progress-small.tds-progress-line,
.tds-progress-small.tds-progress-line .tds-progress-text .anticon {
    @apply text-tds-font-size-2;
}

.tds-progress-outer {
    @apply inline-block w-full mr-0 pr-0;
}

.tds-progress-show-info .tds-progress-outer {
    margin-right: calc(-2em - 8px);
    padding-right: calc(2em + 8px);
}

.tds-progress-inner {
    @apply tds-color-background-neutral-minimal relative inline-block w-full overflow-hidden align-middle rounded-tds-border-radius-circle;
}

.tds-progress-circle-trail {
    @apply tds-progress-color-stroke-default;
}

.tds-progress-circle-path {
    animation: tds-progress-appear 0.3s;
}

.tds-progress-inner:not(.tds-progress-circle-gradient) .tds-progress-circle-path {
    @apply tds-progress-color-stroke-info;
}

.tds-progress-success-bg,
.tds-progress-bg {
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    @apply tds-color-background-info-subtle relative rounded-tds-border-radius-circle;
}

.tds-progress-success-bg {
    @apply tds-color-background-success-subtle absolute top-0 left-0;
}

.tds-progress-text {
    @apply inline-block w-[2em] ml-2 tds-color-text-primary whitespace-nowrap text-left align-middle break-normal;
    line-height: 1 !important;
}

.tds-progress-text .anticon {
    @apply text-tds-font-size-3;
}

.tds-progress-status-active .tds-progress-bg:before {
    @apply absolute inset-0 bg-white opacity-0;
    border-radius: 10px;
    animation: tds-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    content: "";
}

.tds-progress-status-exception .tds-progress-bg {
    @apply tds-color-background-error-subtle;
}

.tds-progress-status-exception .tds-progress-text {
    @apply tds-color-text-error;
}

.tds-progress-status-exception .tds-progress-inner:not(.tds-progress-circle-gradient) .tds-progress-circle-path {
    @apply tds-progress-color-stroke-error;
}

.tds-progress-status-success .tds-progress-bg {
    @apply tds-color-background-success-subtle;
}

.tds-progress-status-success .tds-progress-text {
    @apply tds-color-text-success;
}

.tds-progress-status-success .tds-progress-inner:not(.tds-progress-circle-gradient) .tds-progress-circle-path {
    @apply tds-progress-color-stroke-success;
}

.tds-progress-circle .tds-progress-inner {
    @apply relative leading-[1] bg-transparent;
}

.tds-progress-circle .tds-progress-text {
    @apply tds-color-text-primary absolute left-1/2 top-1/2 w-full m-0 p-0 tds-progress-text-l whitespace-normal text-center;
    transform: translate(-50%, -50%);
    line-height: 1 !important;
}

.tds-progress-circle .tds-progress-text .anticon {
    @apply tds-progress-text-m;
}

.tds-progress-circle.tds-progress-status-exception .tds-progress-text {
    @apply tds-color-text-error;
}

.tds-progress-circle.tds-progress-status-success .tds-progress-text {
    @apply tds-color-text-success;
}

@keyframes tds-progress-active {
    0% {
        transform: translate(-100%) scaleX(0);
        opacity: 0.1;
    }

    20% {
        transform: translate(-100%) scaleX(0);
        opacity: 0.5;
    }

    to {
        transform: translate(0) scaleX(1);
        opacity: 0;
    }
}

.tds-progress-rtl {
    direction: rtl;
}

.tds-progress-rtl.tds-progress-show-info .tds-progress-outer {
    margin-right: 0;
    margin-left: calc(-2em - 8px);
    padding-right: 0;
    padding-left: calc(2em + 8px);
}

.tds-progress-rtl .tds-progress-success-bg {
    @apply right-0 left-auto;
}

.tds-progress-rtl.tds-progress-line .tds-progress-text,
.tds-progress-rtl.tds-progress-steps .tds-progress-text {
    @apply mr-2 ml-0 text-right;
}

.tds-progress-color-stroke-default {
    @apply stroke-tds-color-neutral-100-light dark:stroke-tds-color-neutral-100-dark;
}

.tds-progress-color-stroke-default-light {
    @apply stroke-tds-color-neutral-100-light;
}

.tds-progress-color-stroke-default-dark {
    @apply stroke-tds-color-neutral-100-dark;
}

.tds-progress-color-stroke-success {
    @apply stroke-tds-color-green-500-light dark:stroke-tds-color-green-500-dark;
}

.tds-progress-color-stroke-success-light {
    @apply stroke-tds-color-green-500-light;
}

.tds-progress-color-stroke-success-dark {
    @apply stroke-tds-color-green-500-dark;
}

.tds-progress-color-stroke-info {
    @apply stroke-tds-color-sky-500-light dark:stroke-tds-color-sky-500-dark;
}

.tds-progress-color-stroke-info-light {
    @apply stroke-tds-color-sky-500-light;
}

.tds-progress-color-stroke-info-dark {
    @apply stroke-tds-color-sky-500-dark;
}

.tds-progress-color-stroke-error {
    @apply stroke-tds-color-red-500-light dark:stroke-tds-color-red-500-dark;
}

.tds-progress-color-stroke-error-light {
    @apply stroke-tds-color-red-500-light;
}

.tds-progress-color-stroke-error-dark {
    @apply stroke-tds-color-red-500-dark;
}
