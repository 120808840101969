.tds-grid-view {
    @apply block w-full h-full box-border overflow-hidden;
    .tds-grid-view-virtual-scroll {
        @apply w-full h-full tds-custom-scroll overflow-x-hidden;
    }
    .tds-grid-view-row {
        @apply flex ;
    }
    .tds-grid-view-cell{
        @apply  flex items-center justify-center
    }
}
