:root{
        --tds-tabs-margin-base:var(--tds-spacing-3,4px);
--tds-tabs-margin-large:var(--tds-spacing-5,8px);
--tds-tabs-padding-xs:var(--tds-spacing-3,4px);
--tds-tabs-padding-s:var(--tds-spacing-4,6px);
--tds-tabs-padding-m:var(--tds-spacing-5,8px);
--tds-tabs-padding-l:var(--tds-spacing-6,10px);
--tds-tabs-padding-xl:var(--tds-spacing-7,12px);

    }
    
            
@layer utilities{

            
.tds-tabs-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }

        }
            