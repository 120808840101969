:root{
        --tds-pagination-min-height-s:26px;
--tds-pagination-min-height-m:30px;
--tds-pagination-min-width-s:26px;
--tds-pagination-min-width-m:30px;
--tds-pagination-padding-number-s:var(--tds-spacing-2,2px);
--tds-pagination-padding-number-m:var(--tds-spacing-3,4px);
--tds-pagination-margin-s:var(--tds-spacing-3,4px);
--tds-pagination-margin-m:var(--tds-spacing-5,8px);
--tds-pagination-margin-l:var(--tds-spacing-9,16px);
--tds-pagination-margin-xl:var(--tds-spacing-11,24px);
--tds-pagination-width-select-page:80px;

    }
    
            
@layer utilities{

            
.tds-pagination-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }

        }
            