.tds-slider {
    @apply box-border relative cursor-pointer touch-none;
    &.tds-slider-disabled {
        @apply cursor-not-allowed;
        tds-slider-handle {
            @apply cursor-not-allowed;
        }
    }
    &:not(.tds-slider-disabled) {
        @apply cursor-pointer;
        tds-slider-handle {
            @apply cursor-pointer;
        }
    }
    .tds-slider-rail {
        @apply absolute tds-color-background-neutral-minimal rounded-full transition-colors duration-300;
    }
    .tds-slider-handle {
        @apply absolute w-5 h-5 rounded-full border-2 tds-color-border-brand-primary tds-color-surface-lowest;
        &:focus{
            @apply outline-none tds-color-border-brand-primary tds-color-surface-lowest;
            box-shadow: 0px 0px 0px 3px rgba(26, 109, 227, 0.20);
        }
        &:active{
            @apply tds-color-border-brand-primary tds-color-surface-lowest;
        }
    }
    .tds-slider-mark {
        @apply absolute tds-color-text-secondary text-caption-1 font-normal;
        .tds-slider-mark-text {
            @apply absolute inline-block text-center cursor-pointer select-none;
            &.tds-slider-mark-active{
                @apply tds-color-text-brand-primary;
            }
        }
    }
    .tds-slider-step {
        .tds-slider-dot {
            @apply absolute tds-color-border-neutral-subtle;
            &.tds-slider-dot-active{
                @apply tds-color-border-brand-primary;
            }
        }
    }
    .tds-slider-track {
        @apply absolute rounded-full transition-colors duration-300 tds-color-background-primary-subtle;
    }
    &:not(.tds-slider-vertical) {
        @apply mx-1.5 my-2.5 p-0 h-2;
        .tds-slider-rail {
            @apply h-2 w-full;
        }
        .tds-slider-handle {
            @apply -mt-1.5;
        }
        .tds-slider-mark {
            @apply top-3.5 left-0 w-full;
            .tds-slider-mark-text {
                @apply top-3.5;
            }
        }
        .tds-slider-step {
            .tds-slider-dot {
                @apply top-5 h-1.5 -ml-px border-l border-solid ;
            }
        }
        .tds-slider-track {
            @apply h-2
        }
    }
    &.tds-slider-vertical {
        @apply w-5 h-full mx-2.5 my-1.5 px-1 py-0;
        .tds-slider-rail {
            @apply w-2 h-full;
        }
        .tds-slider-handle {
            @apply -ml-1.5;
        }
        .tds-slider-mark {
            @apply top-0 left-3.5 w-4.5 h-full;
            .tds-slider-mark-text {
                @apply left-3.5;
            }
        }
        .tds-slider-step {
            .tds-slider-dot {
                @apply w-1.5 border-t border-solid left-5 -mb-0.5;
            }
        }
        .tds-slider-track {
            @apply w-2;
        }
    }
    &.tds-slider-with-marks {
        @apply mb-7;
    }
}
