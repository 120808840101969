:root{
        --tds-color-selection-margin-s:var(--tds-spacing-5,8px);
--tds-color-selection-margin-m:var(--tds-spacing-7,12px);
--tds-color-selection-margin-l:var(--tds-spacing-9,16px);
--tds-color-selection-padding-s:var(--tds-spacing-7,12px);
--tds-color-selection-padding-m:var(--tds-spacing-9,16px);
--tds-color-selection-width-swatch-item:20px;
--tds-color-selection-height-swatch-item:20px;
--tds-color-selection-height-color-block-s:20px;
--tds-color-selection-width-color-block-s:20px;

    }
    
            
@layer utilities{

            
.tds-color-selection-color-picker-handler-border
{
                            @apply #E4E8ED dark:#E4E8ED;

                        }                
                        
.tds-color-selection-color-picker-handler-border-light
{
                            @apply #E4E8ED;

                        }
                        
.tds-color-selection-color-picker-handler-border-dark
{
                            @apply #E4E8ED;

                        }
                        
.tds-color-selection-color-picker-border
{
                            @apply border-tds-color-black-light/10 dark:border-tds-color-white-dark/10;

                        }                
                        
.tds-color-selection-color-picker-border-light
{
                            @apply border-tds-color-black-light/10;

                        }
                        
.tds-color-selection-color-picker-border-dark
{
                            @apply border-tds-color-white-dark/10;

                        }
                        
.tds-color-selection-icon-size-check
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-3 ;

                    }
.tds-color-selection-text
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold ;

                    }

        }
            