:root{
        --tds-radio-margin:var(--tds-spacing-5,8px);
--tds-radio-height-s:16px;
--tds-radio-height-m:18px;
--tds-radio-height-l:20px;

        .compact{
            --tds-radio-margin:var(--tds-spacing-3,4px);
--tds-radio-height-m:16px;
--tds-radio-height-l:16px;

        }
    }
    
            
            