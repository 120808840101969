.tds-typography {
    .tds-typography-copy{
        @apply cursor-pointer bg-transparent border-0 no-underline outline-none ml-1 tds-color-text-brand-primary-light dark:tds-color-text-brand-primary-dark ;
    }
    .tds-typography-edit{
        @apply cursor-pointer bg-transparent border-0 no-underline outline-none ml-1 tds-color-text-brand-primary-light dark:tds-color-text-brand-primary-dark ;
    }
    .tds-typography-expand{
        @apply tds-color-text-brand-primary-light dark:tds-color-text-brand-primary-dark cursor-pointer;
    }
    // ============ Ellipsis ============
    &-single-line {
        white-space: nowrap;
    }

    &-ellipsis-single-line {
        overflow: hidden;
        text-overflow: ellipsis;

        // https://blog.csdn.net/iefreer/article/details/50421025
        &a,
        &span {
            vertical-align: bottom;
        }
    }

    &-ellipsis-multiple-line {
        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;

        /*! autoprefixer: ignore next */
        -webkit-box-orient: vertical;
    }
    
}
