.tds-input-search {
    @apply relative w-full min-w-0 inline-flex  tds-input-text-base box-border  border-tds-border-width-s tds-input-border-radius justify-between;

    .tds-input-search-input {
        @apply flex-auto relative;
        input.tds-input-element {
            @apply focus:ring-0 p-0 text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular  focus:outline-none focus:border-transparent shadow-none border-0 bg-transparent text-current m-0 w-full outline-none  box-border placeholder:tds-color-text-placeholder;
        }
        .tds-input-search-button-clear {
            @apply absolute top-0 right-0;
        }
    }
    .tds-input-suffix {
        @apply tds-color-icon-secondary tds-input-icon-size-m   flex items-center ml-2;
    }
    .tds-input-prefix {
        @apply tds-color-icon-secondary tds-input-icon-size-m flex items-center mr-2;
    }
    &:not(.tds-input-search-disabled) {
        @apply tds-color-border-primary;
        &:hover {
            @apply tds-color-border-brand-primary;
        }
        &.tds-input-search-focused {
            @apply ring tds-color-ring-primary tds-color-border-brand-primary;
        }
    }
    &.tds-input-search-allow-clear {
        .tds-input-search-input {
            input {
                @apply pr-7;
            }
            .tds-input-search-button-clear {
                @apply absolute top-0 right-0 cursor-pointer;
            }
        }
    }
    &.tds-input-search-size-sm {
        @apply py-[--tds-input-padding-s] px-[--tds-input-padding-horizontal-s];
    }
    &.tds-input-search-size-md {
        @apply py-[--tds-input-padding-m] px-[--tds-input-padding-horizontal-m];
    }
    &.tds-input-search-size-lg {
        @apply py-[--tds-input-padding-l] px-[--tds-input-padding-horizontal-l];
    }
    &.tds-input-search-size-xl {
        @apply py-[--tds-input-padding-xl] px-[--tds-input-padding-horizontal-xl];
    }
}
