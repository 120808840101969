:root{
        --tds-badge-margin-s:var(--tds-spacing-2,2px);
--tds-badge-margin-m:var(--tds-spacing-5,8px);
--tds-badge-padding-m:var(--tds-spacing-2,2px);
--tds-badge-padding-l:var(--tds-spacing-3,4px);
--tds-badge-padding-horizontal-s:var(--tds-spacing-3,4px);
--tds-badge-padding-horizontal-m:var(--tds-spacing-4,6px);
--tds-badge-padding-horizontal-l:var(--tds-spacing-5,8px);
--tds-badge-min-width-s:14px;
--tds-badge-min-width-m:20px;
--tds-badge-min-width-l:28px;
--tds-badge-min-height-s:14px;
--tds-badge-min-height-m:20px;
--tds-badge-min-height-l:28px;
--tds-badge-width-dot-s:6px;
--tds-badge-width-dot-m:8px;
--tds-badge-width-dot-l:10px;
--tds-badge-height-dot-s:6px;
--tds-badge-height-dot-m:8px;
--tds-badge-height-dot-l:10px;

    }
    
            
            