/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "../public/assets/font-icon/style.css";
@import "../node_modules/tds-ui/assets/v2/scss/theme/theme.green.scss";//Theme green is use for this project
@import "../node_modules/tds-ui/assets/v2/scss/components/tds.component.scss";
@import '../node_modules/editor-classic-core/build/styles.css'; //Import editor


// Import custom styles
@import "./custom-styles/custom-scroll.scss";
@import "./custom-styles/custom-padding-popover.scss";
@import "./custom-styles/custom-upload-image.scss";
@import "./custom-styles/custom-editor.scss";
