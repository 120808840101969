.tds-message {
    --tds-message-size-spinner:20px;
    @apply pointer-events-none w-full left-0 fixed flex items-center flex-col;
    &-notice {
        @apply mb-[--tds-message-margin-outer-bottom];
        &-content {
            @apply tds-color-surface-high  tds-message-shadow tds-message-border-radius py-[--tds-message-padding-vertical] px-[--tds-message-padding-horizontal];
            .tds-message-custom-content {
                @apply flex items-center text-center;
                > i {
                    @apply tds-icon-size-l;
                }
                &.tds-message-success {
                    @apply tds-color-icon-success;
                }
                &.tds-message-info {
                    @apply tds-color-icon-info;
                }
                &.tds-message-warning {
                    @apply tds-color-icon-warning;
                }
                &.tds-message-error {
                    @apply tds-color-icon-error;
                }
                &.tds-message-loading {
                    tds-spinner {
                        @apply size-[--tds-message-size-spinner] tds-color-icon-brand-primary;
                    }
                }
                > span {
                    @apply tds-color-text-primary tds-message-text ml-[--tds-message-margin-left];
                }
            }
        }
    }
}
