:root{
        --tds-collapse-padding-s:var(--tds-spacing-7,12px);
--tds-collapse-padding-m:var(--tds-spacing-15,40px);
--tds-collapse-margin:var(--tds-spacing-5,8px);

    }
    
            
@layer utilities{

            
.tds-collapse-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }

        }
            