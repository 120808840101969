:root{
        --tds-alert-margin-s:var(--tds-spacing-5,8px);
--tds-alert-margin-m:var(--tds-spacing-7,12px);
--tds-alert-margin-top-s:var(--tds-spacing-5,8px);
--tds-alert-margin-top-m:var(--tds-spacing-9,16px);
--tds-alert-padding:var(--tds-spacing-7,12px);

    }
    
            
@layer utilities{

            
.tds-alert-border-radius
{
                        @apply rounded-tds-border-radius-m ;

                    }

        }
            