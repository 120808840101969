:root{
        --tds-avatar-margin-left:-16px;
--tds-avatar-width-s:var(--tds-spacing-11,24px);
--tds-avatar-width-m:var(--tds-spacing-13,32px);
--tds-avatar-width-l:var(--tds-spacing-16,48px);
--tds-avatar-width-xl:var(--tds-spacing-18,64px);
--tds-avatar-width-xxl:var(--tds-spacing-20,96px);
--tds-avatar-height-s:var(--tds-spacing-11,24px);
--tds-avatar-height-m:var(--tds-spacing-13,32px);
--tds-avatar-height-l:var(--tds-spacing-16,48px);
--tds-avatar-height-xl:var(--tds-spacing-18,64px);
--tds-avatar-height-xxl:var(--tds-spacing-20,96px);

    }
    
            
@layer utilities{

            
.tds-avatar-border-radius-base
{
                        @apply rounded-tds-border-radius-s ;

                    }
.tds-avatar-border-radius-xs
{
                        @apply rounded-tds-border-radius-xs ;

                    }

        }
            