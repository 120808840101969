:root{
        --tds-popover-padding:var(--tds-spacing-9,16px);
--tds-popover-margin-s:var(--tds-spacing-5,8px);
--tds-popover-margin-m:var(--tds-spacing-9,16px);

    }
    
            
@layer utilities{

            
.tds-popover-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }

        }
            