.tds-segmented {
    @apply box-border m-0  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold list-none inline-block relative;
}

.tds-segmented-group {
    @apply relative flex items-stretch justify-start w-full rounded;
}

.tds-segmented.tds-segmented-block {
    @apply flex;
}

.tds-segmented.tds-segmented-block .tds-segmented-item {
    @apply flex-1 min-w-0;
}
// .tds-segmented:not(.tds-segmented-disabled):hover,
// .tds-segmented:not(.tds-segmented-disabled):focus {
//     // background-color: #0000000f
// }

.tds-segmented-item-input {
    @apply top-0 left-0 absolute opacity-0 h-0 w-0 pointer-events-none;
}

.tds-segmented-item-icon + * {
    margin-left: 12px / 2;
}

.tds-segmented-item-disabled,
.tds-segmented-item-disabled:hover,
.tds-segmented-item-disabled:focus {
    color: #00000040;
    cursor: not-allowed;
}

.tds-segmented.tds-segmented-rtl {
    direction: rtl;
}

.tds-segmented.tds-segmented-rtl .tds-segmented-item-icon {
    margin-right: 0;
    margin-left: 6px;
}
// ===================== Default ======================
.tds-segmented {
    &.tds-segmented-type-default {
        .tds-segmented-item {
            @apply relative text-center cursor-pointer border-y;
            &:first-child {
                @apply rounded-l border-l;
            }
            &:last-child {
                @apply rounded-r border-r;
            }
            &:not(:last-child) {
                border-right: none;
            }
        }
        .tds-segmented-thumb {
            @apply absolute top-0 left-0 w-0 h-full;
        }
        &.tds-segmented-theme-default {
            &.tds-segmented-color-primary {
                @apply tds-color-text-secondary;
                .tds-segmented-item {
                    @apply tds-color-border-primary;
                }
                .tds-segmented-thumb {
                    @apply tds-color-background-primary-subtle tds-color-border-brand-primary;
                }
                .tds-segmented-item-selected {
                    @apply tds-color-text-white tds-color-background-primary-subtle tds-color-border-brand-primary;
                }
                
            }
            &.tds-segmented-color-secondary {
                @apply tds-color-text-secondary;
                .tds-segmented-item {
                    @apply tds-color-border-primary;
                }
                .tds-segmented-thumb {
                    @apply tds-color-background-neutral-minimal border-transparent;
                }
                .tds-segmented-item-selected {
                    @apply tds-color-text-quaternary tds-color-background-neutral-minimal border-transparent;
                }
              
            }
        }
        &.tds-segmented-theme-light {
            &.tds-segmented-color-primary {
                @apply tds-color-text-secondary-light;
                .tds-segmented-item {
                    @apply tds-color-border-primary-light;
                }
                .tds-segmented-thumb {
                    @apply tds-color-background-primary-subtle-light tds-color-border-brand-primary-light;
                }
                .tds-segmented-item-selected {
                    @apply tds-color-text-white-light tds-color-background-primary-subtle-light tds-color-border-brand-primary-light ;
                }
               
            }
            &.tds-segmented-color-secondary {
                @apply tds-color-text-secondary-light;
                .tds-segmented-item {
                    @apply tds-color-border-primary-light;
                }
                .tds-segmented-thumb {
                    @apply tds-color-background-neutral-minimal-light;
                }
                .tds-segmented-item-selected {
                    @apply tds-color-text-quaternary-light tds-color-background-neutral-minimal-light;
                }
               
            }
        }
        &.tds-segmented-theme-dark {
            &.tds-segmented-color-primary {
                @apply tds-color-text-secondary-dark;
                .tds-segmented-item {
                    @apply tds-color-border-primary-dark;
                }
                .tds-segmented-thumb {
                    @apply tds-color-background-primary-subtle-dark tds-color-border-brand-primary-dark;
                }
                .tds-segmented-item-selected {
                    @apply tds-color-text-white-dark tds-color-background-primary-subtle-dark tds-color-border-brand-primary-dark ;
                }
                
            }
            &.tds-segmented-color-secondary {
                @apply tds-color-text-secondary-dark;
                .tds-segmented-item {
                    @apply tds-color-border-primary-dark;
                }
                .tds-segmented-thumb {
                    @apply tds-color-background-neutral-minimal-dark;
                }
                .tds-segmented-item-selected {
                    @apply tds-color-text-quaternary-dark tds-color-background-neutral-minimal-dark;
                }
                
            }
        }
      
    }
}
// ===================== Outline ======================
.tds-segmented {
    &.tds-segmented-type-outline {
        @apply border  rounded;
        .tds-segmented-item {
            @apply relative text-center cursor-pointer  ;

            &:first-child {
                @apply rounded-l tds-color-border-primary;
            }
            &:last-child {
                @apply rounded-r tds-color-border-primary;
            }
            &:not(:last-child) {
                border-right: none;
            }
            
        }
        .tds-segmented-item-selected {
            &:before {
                @apply absolute -inset-px border tds-color-border-brand-primary;
                content: "";
                &:first-child {
                    @apply rounded-l;
                }
                &:last-child {
                    @apply rounded-r;
                }
            }
        }
        .tds-segmented-thumb {
            @apply absolute -top-px -left-px -bottom-px   w-0  border-y;
            &.tds-segmented-thumb-first {
                @apply rounded-l;
            }
            &.tds-segmented-thumb-last {
                @apply rounded-r;
            }
        }
        &.tds-segmented-theme-light {
            @apply tds-color-text-secondary-light ;
          
            .tds-segmented-thumb {
                @apply tds-color-border-brand-primary-light tds-color-background-primary-subtle-light;
            }
            .tds-segmented-item-selected {
                @apply tds-color-text-white-light tds-color-border-brand-primary-light tds-color-background-primary-subtle-light;
            }
            
        }
        &.tds-segmented-theme-dark {
            @apply tds-color-text-secondary-dark ;
          
            .tds-segmented-thumb {
                @apply tds-color-border-brand-primary-dark tds-color-background-primary-subtle-dark;
            }
            .tds-segmented-item-selected {
                @apply tds-color-text-white-dark tds-color-border-brand-primary-dark tds-color-background-primary-subtle-dark;
            }
           
        }
        &.tds-segmented-theme-default {
            @apply tds-color-text-secondary tds-color-border-primary;
            .tds-segmented-thumb {
                @apply  tds-color-border-brand-primary border tds-color-background-primary-minimal;
            }
            .tds-segmented-item-selected {
                @apply tds-color-text-brand-primary tds-color-background-primary-minimal;
                
            }
          

           
        }
    }
}

// ===================== Size =========================
.tds-segmented-item-label {
    @apply truncate break-keep px-[--tds-segment-padding-horizontal-m] py-[--tds-segment-padding-vertical-m] text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-semibold;
}
.tds-segmented.tds-segmented-sm .tds-segmented-item-label {
    @apply px-[--tds-segment-padding-horizontal-s] py-[--tds-segment-padding-vertical-s] ;
}
.tds-segmented.tds-segmented-md .tds-segmented-item-label {
    @apply px-[--tds-segment-padding-horizontal-m] py-[--tds-segment-padding-vertical-m];
}
.tds-segmented.tds-segmented-lg .tds-segmented-item-label {
    @apply px-[--tds-segment-padding-horizontal-l] py-[--tds-segment-padding-vertical-l];
}

.tds-segmented-icon {
    .tds-segmented-item-label {
        @apply flex items-center justify-center;
    }
    .tds-segmented-lable-icon {
        @apply flex items-center justify-center;
        > span {
            @apply text-xl leading-5;
        }
    }
    &.tds-segmented.tds-segmented-sm .tds-segmented-item-label {
        @apply p-[--tds-segment-padding-icon-s];
    }
    &.tds-segmented.tds-segmented-md .tds-segmented-item-label {
        @apply p-[--tds-segment-padding-icon-m];
    }
    &.tds-segmented.tds-segmented-lg .tds-segmented-item-label {
        @apply p-[--tds-segment-padding-icon-l];
    }
    &.tds-segmented.tds-segmented-xs .tds-segmented-item-label {
        @apply p-tds-spacing-2;
        > span {
            @apply text-base leading-4;
        }
    }
}
