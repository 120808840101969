tds-form-field {
    @apply block relative text-left;
    .tds-input-add-on {
        &-left {
            @apply h-full  border-tds-border-width-s rounded-l-tds-border-radius-xs  flex tds-input-text-base;
            > i {
                @apply tds-input-icon-size-m;
            }
        }
        &-right {
            @apply h-full border-tds-border-width-s rounded-r-tds-border-radius-xs  flex tds-input-text-base;
            > i {
                @apply tds-input-icon-size-m;
            }
        }
        &-left {
            @apply border-r-0 #{!important};
        }
        &-right {
            @apply border-l-0 #{!important};
        }
    }
    &.tds-form-field-sm {
        .tds-form-field-prefix {
            @apply pl-[--tds-input-padding-horizontal-s];
        }
        .tds-form-field-suffix {
            @apply px-1;
        }
        .tds-input-add-on-left,
        .tds-input-add-on-right {
            @apply p-[--tds-input-padding-s];
        }
    }
    &.tds-form-field-md {
        &.tds-form-field-has-suffix {
            .tds-form-field-infix {
                @apply pr-[--tds-input-padding-s];
            }
        }
        .tds-form-field-suffix {
            @apply pr-[--tds-input-padding-s];
        }
        .tds-input-add-on-left,
        .tds-input-add-on-right {
            @apply p-[--tds-input-padding-m];
        }
    }
    &.tds-form-field-lg {
        &.tds-form-field-has-suffix {
            .tds-form-field-infix {
                @apply pr-[--tds-input-padding-s];
            }
        }
        .tds-form-field-suffix {
            @apply pr-[--tds-input-padding-s];
        }
        .tds-input-add-on-left,
        .tds-input-add-on-right {
            @apply p-[--tds-input-padding-l];
        }
    }
    &.tds-form-field-xl {
        &.tds-form-field-has-suffix {
            .tds-form-field-infix {
                @apply pr-[--tds-input-padding-s];
            }
        }
        .tds-form-field-suffix {
            @apply pr-[--tds-input-padding-s];
        }
        .tds-input-add-on-left,
        .tds-input-add-on-right {
            @apply p-[--tds-input-padding-xl];
        }
    }
    .tds-formfield-sufix,
    .tds-formfield-prefix {
        @apply flex items-center justify-center;
    }
    .tds-form-field-wrapper {
        @apply flex flex-col w-full;
        .tds-form-field-label {
            @apply flex w-full mb-[--tds-input-margin-m];
        }
        .tds-form-field-container {
            @apply flex w-full;
            .tds-form-field-container-input {
                @apply flex flex-auto flex-col;
                .tds-form-field-control.form-field-connection-container {
                    @apply flex items-end relative w-full max-w-full border-tds-border-width-s  tds-input-border-radius;
                    .tds-form-field-prefix {
                        @apply self-center pl-[--tds-input-padding-horizontal-s] mr-[--tds-input-margin-m];
                    }
                    .tds-form-field-infix {
                        .tds-input-element {
                            @apply focus:outline-none focus:border-transparent  border-0  outline-none focus:ring-0;
                        }
                    }
                    .tds-form-field-suffix {
                        @apply self-center;
                    }
                    .tds-form-field-suffix,
                    .tds-form-field-prefix {
                        i {
                            @apply tds-input-icon-size-m;
                        }
                    }
                }
            }
        }
    }

    &.tds-form-field-focused {
        &.tds-form-field-default {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply outline-none ring-opacity-20 dark:ring-opacity-20 ring;
                    }
                }
            }
        }
    }
    &.tds-form-field-disabled {
        .tds-form-field-wrapper {
            .tds-form-field-container {
                .tds-form-field-container-input {
                    .tds-form-field-control.form-field-connection-container {
                        .tds-input-element {
                            @apply tds-input-border-radius;
                        }
                    }
                }
            }
        }
    }
    &.tds-form-field-error,
    &.tds-form-field-warning,
    &.tds-form-field-success {
        .tds-form-field-wrapper {
            .tds-form-field-container {
                .tds-form-field-control.form-field-connection-container {
                    @apply outline-none;
                }
            }
        }
    }
    &.tds-form-field-has-add-on-right {
        .tds-form-field-wrapper {
            .tds-form-field-container {
                .tds-form-field-control.form-field-connection-container {
                    @apply rounded-r-none;
                }
            }
        }
    }
    &.tds-form-field-has-add-on-left {
        .tds-form-field-wrapper {
            .tds-form-field-container {
                .tds-form-field-control.form-field-connection-container {
                    @apply rounded-l-none;
                }
            }
        }
    }
    &:not(.tds-form-field-has-add-on-left):not(.tds-form-field-has-add-on-right) {
        .tds-form-field-container-input {
            @apply w-full;
        }
    }
    &.tds-form-field-theme-default {
        .tds-form-field-wrapper {
            .tds-form-field-container {
                .tds-form-field-container-input {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-surface-lowest tds-color-border-primary;
                        .tds-form-field-prefix {
                            @apply tds-color-icon-secondary;
                        }
                        .tds-form-field-suffix {
                            @apply tds-color-icon-secondary;
                        }
                        .tds-form-field-prefix {
                            @apply tds-color-text-tertiary;
                        }
                    }
                }
            }
        }
        &:not(.tds-form-field-disabled) {
            &.tds-form-field-default {
                .tds-form-field-wrapper {
                    .tds-form-field-container {
                        .tds-form-field-container-input {
                            .tds-form-field-control.form-field-connection-container {
                                @apply hover:tds-color-border-brand-primary;
                            }
                        }
                    }
                }
            }
            .tds-input-add-on {
                &-left {
                    @apply tds-color-border-primary;
                    > i {
                        @apply tds-color-icon-primary;
                    }
                }
                &-right {
                    @apply tds-color-border-primary;
                    > i {
                        @apply tds-color-icon-primary;
                    }
                }
            }
        }
        &.tds-form-field-focused {
            &.tds-form-field-default {
                .tds-form-field-wrapper {
                    .tds-form-field-container {
                        .tds-form-field-control.form-field-connection-container {
                            @apply tds-color-ring-primary tds-color-border-brand-primary hover:tds-color-border-brand-primary;
                        }
                    }
                }
            }
        }
        &.tds-form-field-disabled {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-container-input {
                        .tds-form-field-control.form-field-connection-container {
                            @apply tds-color-background-disable tds-color-border-disable;
                            .tds-input-element {
                                @apply tds-color-background-disable tds-color-text-disable;
                            }
                        }
                    }
                }
            }
            .tds-input-add-on {
                &-left {
                    @apply tds-color-border-disable;
                    > i {
                        @apply tds-color-icon-disable;
                    }
                }
                &-right {
                    @apply tds-color-border-disable;
                    > i {
                        @apply tds-color-icon-disable;
                    }
                }
            }
        }
        &.tds-form-field-error {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-error hover:tds-color-border-error;
                    }
                }
            }
        }
        &.tds-form-field-warning {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-warning;
                    }
                }
            }
        }
        &.tds-form-field-success {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-success;
                    }
                }
            }
        }
        .tds-input-element {
            @apply placeholder:tds-color-text-placeholder;
        }
    }
    &.tds-form-field-theme-light {
        .tds-form-field-wrapper {
            .tds-form-field-container {
                .tds-form-field-container-input {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-surface-lowest-light tds-color-border-primary-light;
                        .tds-form-field-prefix {
                            @apply tds-color-icon-secondary-light;
                        }
                        .tds-form-field-suffix {
                            @apply tds-color-icon-secondary-light;
                        }
                        .tds-form-field-prefix {
                            @apply tds-color-text-tertiary-light;
                        }
                    }
                }
            }
        }
        &:not(.tds-form-field-disabled) {
            &.tds-form-field-default {
                .tds-form-field-wrapper {
                    .tds-form-field-container {
                        .tds-form-field-container-input {
                            .tds-form-field-control.form-field-connection-container {
                                @apply hover:tds-color-border-brand-primary-light;
                            }
                        }
                    }
                }
            }
            .tds-input-add-on {
                &-left {
                    @apply tds-color-border-primary-light;
                    > i {
                        @apply tds-color-icon-primary-light;
                    }
                }
                &-right {
                    @apply tds-color-border-primary-light;
                    > i {
                        @apply tds-color-icon-primary-light;
                    }
                }
            }
        }
        &.tds-form-field-focused {
            &.tds-form-field-default {
                .tds-form-field-wrapper {
                    .tds-form-field-container {
                        .tds-form-field-control.form-field-connection-container {
                            @apply tds-color-ring-primary-light tds-color-border-brand-primary-light hover:tds-color-border-brand-primary-light;
                        }
                    }
                }
            }
        }
        &.tds-form-field-disabled {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-container-input {
                        .tds-form-field-control.form-field-connection-container {
                            @apply tds-color-background-disable-light tds-color-border-disable-light;
                            .tds-input-element {
                                @apply tds-color-background-disable-light tds-color-text-disable-light;
                            }
                        }
                    }
                }
            }
            .tds-input-add-on {
                &-left {
                    @apply tds-color-border-disable-light;
                    > i {
                        @apply tds-color-icon-disable-light;
                    }
                }
                &-right {
                    @apply tds-color-border-disable-light;
                    > i {
                        @apply tds-color-icon-disable-light;
                    }
                }
            }
        }
        &.tds-form-field-error {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-error-light hover:tds-color-border-error-light;
                    }
                }
            }
        }
        &.tds-form-field-warning {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-warning-light;
                    }
                }
            }
        }
        &.tds-form-field-success {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-success-light;
                    }
                }
            }
        }
        .tds-input-element {
            @apply placeholder:tds-color-text-placeholder-light;
        }
    }
    &.tds-form-field-theme-dark {
        .tds-form-field-wrapper {
            .tds-form-field-container {
                .tds-form-field-container-input {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-surface-lowest-dark tds-color-border-primary-dark;
                        .tds-form-field-prefix {
                            @apply tds-color-icon-secondary-dark;
                        }
                        .tds-form-field-suffix {
                            @apply tds-color-icon-secondary-dark;
                        }
                        .tds-form-field-prefix {
                            @apply tds-color-text-tertiary-dark;
                        }
                    }
                }
            }
        }
        &:not(.tds-form-field-disabled) {
            &.tds-form-field-default {
                .tds-form-field-wrapper {
                    .tds-form-field-container {
                        .tds-form-field-container-input {
                            .tds-form-field-control.form-field-connection-container {
                                @apply hover:tds-color-border-brand-primary-dark;
                            }
                        }
                    }
                }
            }
            .tds-input-add-on {
                &-left {
                    @apply tds-color-border-primary-dark;
                    > i {
                        @apply tds-color-icon-primary-dark;
                    }
                }
                &-right {
                    @apply tds-color-border-primary-dark;
                    > i {
                        @apply tds-color-icon-primary-dark;
                    }
                }
            }
        }
        &.tds-form-field-focused {
            &.tds-form-field-default {
                .tds-form-field-wrapper {
                    .tds-form-field-container {
                        .tds-form-field-control.form-field-connection-container {
                            @apply tds-color-ring-primary-dark tds-color-border-brand-primary-dark hover:tds-color-border-brand-primary-dark;
                        }
                    }
                }
            }
        }
        &.tds-form-field-disabled {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-container-input {
                        .tds-form-field-control.form-field-connection-container {
                            @apply tds-color-background-disable-dark tds-color-border-disable-dark;
                            .tds-input-element {
                                @apply tds-color-background-disable-dark tds-color-text-disable-dark;
                            }
                        }
                    }
                }
            }
            .tds-input-add-on {
                &-left {
                    @apply tds-color-border-disable-dark;
                    > i {
                        @apply tds-color-icon-disable-dark;
                    }
                }
                &-right {
                    @apply tds-color-border-disable-dark;
                    > i {
                        @apply tds-color-icon-disable-dark;
                    }
                }
            }
        }
        &.tds-form-field-error {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-error-dark hover:tds-color-border-error-dark;
                    }
                }
            }
        }
        &.tds-form-field-warning {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-warning-dark;
                    }
                }
            }
        }
        &.tds-form-field-success {
            .tds-form-field-wrapper {
                .tds-form-field-container {
                    .tds-form-field-control.form-field-connection-container {
                        @apply tds-color-border-success-dark;
                    }
                }
            }
        }
        .tds-input-element {
            @apply placeholder:tds-color-text-placeholder-dark;
        }
    }
    .tds-input-element {
        &-size-sm {
            @apply py-0 my-[--tds-input-padding-s];
        }
        &-size-md {
            @apply py-0  my-[--tds-input-padding-m];
        }
        &-size-lg {
            @apply py-0  my-[--tds-input-padding-l] ;
        }
        &-size-xl {
            @apply py-0   my-[--tds-input-padding-xl] ;
        }
    }
}
textarea {
    @apply tds-input-text-base;
}
textarea.tds-textarea-autosize-measuring {
    height: auto !important;
    overflow: hidden !important;
    padding: 2px 0 !important;
}
.tds-input-element {
    @apply flex  items-center tds-input-text-base  focus:outline-none focus:border-transparent shadow-none border-0 bg-transparent text-current  p-0  m-0 w-full outline-none  box-border;
    &-has-add-on-right {
        &.tds-input-element-size-sm {
            @apply pr-[--tds-input-padding-s];
        }
        &.tds-input-element-size-md {
            @apply pr-[--tds-input-padding-m];
        }
        &.tds-input-element-size-lg {
            @apply pr-[--tds-input-padding-l];
        }
        &.tds-input-element-size-xl {
            @apply pr-[--tds-input-padding-xl];
        }
    }
    &-has-add-on-left {
        @apply pl-2;
    }
    &-has-prefix-left {
        &.tds-input-element-size-sm {
            @apply pl-[--tds-input-padding-horizontal-s];
        }
        &.tds-input-element-size-md {
            @apply pl-[--tds-input-padding-horizontal-m];
        }
        &.tds-input-element-size-lg {
            @apply pl-[--tds-input-padding-horizontal-l];
        }
        &.tds-input-element-size-xl {
            @apply pl-[--tds-input-padding-horizontal-xl];
        }
    }

    &-size-sm {
        @apply px-[--tds-input-padding-s];
    }
    &-size-md {
        @apply px-[--tds-input-padding-m];
    }
    &-size-lg {
        @apply px-[--tds-input-padding-l];
    }
    &-size-xl {
        @apply px-[--tds-input-padding-xl];
    }
}
.tds-error {
    @apply tds-input-text-xs  mt-1 inline-flex items-center;
    .tds-error-icon {
        @apply mr-1  tds-input-icon-size-s;
    }
    &.tds-error-theme-default {
        @apply tds-color-text-error;
    }
    &.tds-error-theme-light {
        @apply tds-color-text-error-light;
    }
    &.tds-error-theme-dark {
        @apply tds-color-text-error-dark;
    }
}
.tds-success {
    @apply tds-input-text-xs  mt-1 inline-flex items-center;
    .tds-success-icon {
        @apply mr-1  tds-input-icon-size-s;
    }
    &.tds-success-theme-default {
        @apply tds-color-text-success;
    }
    &.tds-success-theme-light {
        @apply tds-color-text-success-light;
    }
    &.tds-success-theme-dark {
        @apply tds-color-text-success-dark;
    }
}
.tds-warning {
    @apply tds-input-text-xs  mt-1 inline-flex items-center;
    .tds-warning-icon {
        @apply mr-1  tds-input-icon-size-s;
    }
    &.tds-warning-theme-default {
        @apply tds-color-text-warning;
    }
    &.tds-warning-theme-light {
        @apply tds-color-text-warning-light;
    }
    &.tds-warning-theme-dark {
        @apply tds-color-text-warning-dark;
    }
}
tds-label {
    > label {
        @apply tds-input-label block;
        .tds-label-required {
            @apply ml-1;
        }
    }

    &.tds-label-theme-default {
        > label {
            @apply tds-color-text-primary;
            .tds-label-required {
                @apply tds-color-text-error;
            }
        }
    }
    &.tds-label-theme-light {
        > label {
            @apply tds-color-text-primary-light;
            .tds-label-required {
                @apply tds-color-text-error-light;
            }
        }
    }
    &.tds-label-theme-dark {
        > label {
            @apply tds-color-text-primary-dark;
            .tds-label-required {
                @apply tds-color-text-error-dark;
            }
        }
    }
}
input:-internal-autofill-selected {
    @apply text-current;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: rgba(var(--tds-color-neutral-900-light, 12, 16, 20), 1) !important;
    -webkit-background-clip: text;
}
.dark {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: rgba(var(--tds-color-neutral-900-dark, 242, 245, 248), 1) !important;
    }
}
