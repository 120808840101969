:root{
        --tds-table-padding-horizontal:var(--tds-spacing-6,10px);
--tds-table-padding-header-vertical-s:var(--tds-spacing-4,6px);
--tds-table-padding-header-vertical-m:var(--tds-spacing-5,8px);
--tds-table-padding-vertical-xs:var(--tds-spacing-3,4px);
--tds-table-padding-vertical-s:var(--tds-spacing-4,6px);
--tds-table-padding-vertical-m:var(--tds-spacing-5,8px);
--tds-table-padding-vertical-l:var(--tds-spacing-6,10px);
--tds-table-padding-collapse-cell-horizontal:var(--tds-spacing-3,4px);
--tds-table-margin-s:var(--tds-spacing-2,2px);
--tds-table-margin-m:var(--tds-spacing-3,4px);
--tds-table-margin-base:var(--tds-spacing-5,8px);
--tds-table-width-empty-cell:24px;

    }
    
            
            