:root{
        --tds-segment-padding-horizontal-s:var(--tds-spacing-6,10px);
--tds-segment-padding-horizontal-m:var(--tds-spacing-7,12px);
--tds-segment-padding-horizontal-l:var(--tds-spacing-8,14px);
--tds-segment-padding-vertical-s:var(--tds-spacing-3,4px);
--tds-segment-padding-vertical-m:var(--tds-spacing-4,6px);
--tds-segment-padding-vertical-l:var(--tds-spacing-5,8px);
--tds-segment-padding-icon-s:var(--tds-spacing-3,4px);
--tds-segment-padding-icon-m:var(--tds-spacing-4,6px);
--tds-segment-padding-icon-l:var(--tds-spacing-5,8px);
--tds-segment-height-s:30px;
--tds-segment-height-m:34px;
--tds-segment-height-l:38px;

    }
    
            
@layer utilities{

            
.tds-segment-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }
.tds-segment-icon-size
{
                        @apply  text-tds-font-size-6 leading-tds-line-height-4 ;

                    }

        }
            