.tds-qrcode {
    @apply inline-block relative tds-color-background-white p-[--tds-qr-code-padding-m];
    & > canvas {
        @apply block tds-color-background-white;
    }
    &.tds-qrcode-status-active {
        .tds-qrcode-active-mask {
            @apply hidden transition-all duration-300 ease-in-out;
        }
        &:hover {
            .tds-qrcode-active-mask {
                @apply flex;
            }
        }
    }
    &-size-xs {
        @apply p-[--tds-qr-code-padding-s];
        
    }
}
.tds-qrcode-border {
    @apply border rounded tds-color-border-primary;
    .tds-qrcode-mask {
        @apply rounded;
    }
    .tds-qrcode-active-mask {
        @apply rounded;
    }
}

.tds-qrcode-mask,
.tds-qrcode-active-mask {
    @apply absolute top-0 right-0 z-10 flex flex-col justify-center items-center w-full h-full text-center;
}
.tds-qrcode-status-loading {
    .tds-qrcode-mask {
        @apply tds-qr-code-color-background-loading;
    }
}
.tds-qrcode-status-expired {
    .tds-qrcode-mask {
        @apply tds-color-background-white;
    }
}
.tds-qrcode-active-mask {
    @apply tds-color-overlay-default;
}
.tds-qrcode-expired {
    @apply tds-color-text-error tds-qr-code-text;
}
