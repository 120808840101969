.tds-header {
    @apply flex w-full items-center justify-between py-[--tds-header-padding-vertical-m] px-[--tds-header-padding-horizontal-m]  min-h-[--tds-header-min-height];
    .tds-header-left {
        @apply flex items-center;
        .logo {
            @apply block xl:hidden mr-[--tds-header-margin-s];
            > img {
                @apply h-[--tds-header-height-logo] cursor-pointer;
            }
        }
        .logo-text {
            @apply hidden xl:block  mr-[--tds-header-margin-m];
            > img {
                @apply h-[--tds-header-height-logo] cursor-pointer;
            }
        }
        .tds-header-toggle {
            @apply block lg:hidden mr-[--tds-header-margin-s];
        }
        .tds-header-search {
            @apply lg:min-w-[234px];
            .tds-header-search-btn {
                @apply md:hidden;
            }
            > tds-form-field {
                @apply hidden md:block;
            }
        }
    }
    .tds-header-right {
        @apply flex;
    }
    &-default {
        @apply tds-color-surface-lowest;
        .tds-header-search {
            > tds-form-field {
                .tds-form-field-wrapper
                    .tds-form-field-container
                    .tds-form-field-container-input
                    .tds-form-field-control.form-field-connection-container {
                    @apply tds-color-background-neutral-minimal border-transparent;
                    .tds-input-element {
                        @apply placeholder:tds-color-text-placeholder tds-color-text-primary;
                    }
                }
            }
        }
    }
    &-light {
        @apply tds-color-surface-lowest-light;
        .tds-header-search > tds-form-field {
            .tds-form-field-wrapper
                .tds-form-field-container
                .tds-form-field-container-input
                .tds-form-field-control.form-field-connection-container {
                @apply tds-color-background-neutral-minimal-light border-transparent;
                ipnut.tds-input-element {
                    @apply placeholder:tds-color-text-placeholder-light tds-color-text-primary-light;
                }
            }
        }
    }
    &-dark {
        @apply tds-color-surface-lowest-dark;
        .tds-header-search > tds-form-field {
            .tds-form-field-wrapper
                .tds-form-field-container
                .tds-form-field-container-input
                .tds-form-field-control.form-field-connection-container {
                @apply tds-color-background-neutral-minimal-dark border-transparent;
                .tds-input-element {
                    @apply placeholder:tds-color-text-placeholder-dark tds-color-text-primary-dark;
                }
            }
        }
    }
}
