tds-cascader {
    @apply inline-block relative box-border m-0 p-0;
    &:focus-visible {
        @apply outline-none;
    }
    .tds-cascader-wrapper {
        border-style:var(--tds-border-style-base,solid);
        @apply w-full flex items-center tds-color-surface-lowest border-tds-border-width-s rounded-tds-border-radius-xs cursor-pointer;
        .tds-select-selector {
            @apply flex-auto relative h-5 tds-color-text-primary;
            .tds-select-selection-search {
                @apply h-5;
                .tds-select-selection-search-input {
                    @apply outline-none h-full w-full align-super border-none m-0 p-0 ring-0 cursor-text bg-transparent;
                }
            }
            .tds-select-selection-item {
                @apply absolute inset-0 truncate tds-font-weight-regular tds-body-2;
            }
            .tds-select-selection-placeholder {
                @apply absolute inset-0  flex items-center tds-font-weight-regular tds-body-2 tds-color-text-placeholder;
            }
        }
        .tds-select-clear {
            @apply flex items-center justify-center absolute inset-0 z-[1] opacity-0;
            transition: all 0.1s ease-out;
            // i {
            //     @apply tds-select-color-icon-default tds-icon-size-m;
            // }
        }
        .tds-select-arrow {
            @apply tds-color-text-primary tds-icon-size-m flex items-center justify-center relative select-none pointer-events-none;
        }

        &.tds-select-sm {
            .tds-select-arrow {
                @apply py-1  pl-2.5 px-1.5;
            }
        }
    }
    &:not(.tds-select-disabled) {
        .tds-cascader-wrapper:hover {
            @apply tds-color-border-brand-primary;
            // tds-color-surface-high-hover
        }
        &.tds-select-has-clear-icon:hover {
            .tds-select-clear {
                @apply opacity-100 select-auto pointer-events-auto;
            }
        }
    }
    &.tds-select-sm {
        .tds-select-arrow {
            @apply px-1.5;
        }
        .tds-cascader-wrapper {
            @apply py-1  pl-2.5;
        }
    }
    &.tds-select-md {
        .tds-select-arrow {
            @apply px-2.5;
        }
        .tds-cascader-wrapper {
            @apply py-1.5  pl-3;
        }
    }
    &.tds-select-lg {
        .tds-select-arrow {
            @apply px-3;
        }
        .tds-cascader-wrapper {
            @apply py-2  pl-3.5;
        }
    }
    &.tds-select-xl {
        .tds-select-arrow {
            @apply px-3.5;
        }
        .tds-cascader-wrapper {
            @apply py-2.5 pl-4;
        }
    }
    &.tds-select-close {
        .tds-cascader-wrapper {
            @apply tds-color-border-primary;
        }
    }
    &.tds-select-open {
        .tds-cascader-wrapper {
            @apply outline-none  tds-color-border-brand-primary ring tds-color-ring-primary;
        }
    }
    &.tds-select-show-search {
        .tds-cascader-wrapper {
            .tds-select-selector{
                @apply cursor-text;
            }
            
        }
    }
    &.tds-select-disabled {
        .tds-cascader-wrapper {
            @apply cursor-not-allowed tds-color-background-disable;
            .tds-select-selector {
                @apply tds-color-text-disable cursor-not-allowed;
                .tds-select-selection-search {
                    .tds-select-selection-search-input {
                        @apply select-none bg-transparent;
                    }
                }
                .tds-select-selection-item {
                    @apply select-none;
                }
            }
            .tds-select-arrow {
                @apply tds-color-icon-disable cursor-not-allowed;
            }
        }
    }
}
.tds-cascader-dropdown {
    @apply min-w-[176px];
    .tds-cascader-menus {
        @apply flex flex-nowrap items-stretch relative my-1 tds-color-surface-high tds-color-text-primary rounded shadow-tds-shadow-l-light dark:shadow-tds-shadow-l-dark;
        .tds-cascader-menu {
            @apply py-[--tds-dropdown-padding-vertical-s] min-w-[176px] max-h-[265px] overflow-auto tds-panel-scroll border-r-tds-border-width-s tds-color-border-primary;
            .tds-cascader-menu-item {
                @apply px-[--tds-dropdown-padding-horizontal]  flex items-center justify-between cursor-pointer tds-font-weight-regular tds-body-2;
                &.tds-cascader-menu-item-active {
                    @apply tds-color-surface-high-hover;
                }
                &.tds-cascader-menu-item-disabled {
                    @apply cursor-not-allowed;
                }
                &-sm {
                    @apply py-[--tds-cascader-padding-vertical-s];
                }
                &-md {
                    @apply py-[--tds-cascader-padding-vertical-m];
                }
                &-lg {
                    @apply py-[--tds-cascader-padding-vertical-l];
                }
                &-xl {
                    @apply py-[--tds-cascader-padding-vertical-l];
                }
                &.tds-cascader-menu-item-empty {
                    @apply justify-center;
                }
                &:not(.tds-cascader-menu-item-disabled) {
                    @apply tds-color-text-primary;
                    &:hover {
                        @apply tds-color-surface-high-hover;
                    }
                }
                .tds-cascader-menu-item-expand-icon {
                    @apply tds-color-icon-secondary tds-icon-size-m;
                    > tds-spinner {
                        @apply h-4 w-4;
                    }
                }
                .tds-cascader-menu-item-keyword {
                    @apply tds-color-text-info;
                }
            }
        }
    }
}
tds-form-field {
    tds-cascader {
        @apply block w-full;
        .tds-cascader-wrapper {
            @apply border-none;
        }
        &.tds-select-open {
            .tds-cascader-wrapper {
                @apply outline-none ring-0;
            }
        }
    }
}
