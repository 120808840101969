.tds-checkbox {
    @apply inline-block;
    .tds-checkbox-wrapper {
        @apply flex items-center;
        .tds-checkbox-wrapper-svg {
            @apply p-0.5 border-tds-border-width-s border-solid  tds-checkbox-border-radius relative flex items-center justify-center box-content;
            > input {
                @apply border-0 overflow-hidden p-0 absolute whitespace-nowrap outline outline-none h-px w-px;
            }
        }
    }
    .tds-checkbox-svg {
        svg {
            @apply w-full h-full;
            .tds-checkbox-path {
                @apply fill-current;
            }
        }
    }
    &.tds-checkbox-disabled {
        // @apply opacity-65;
        .tds-checkbox-wrapper-svg {
            @apply cursor-not-allowed;
        }

        .tds-checkbox-label {
            @apply cursor-not-allowed;
        }
    }
    &:not(.tds-checkbox-disabled) {
        .tds-checkbox-wrapper-svg {
            @apply cursor-pointer active:outline-none active:ring ;
        }

        .tds-checkbox-label {
            @apply cursor-pointer;
        }
    }
    .tds-checkbox-label {
        @apply ml-[--tds-checkbox-margin] tds-checkbox-text select-none empty:hidden;
    }
    &.tds-checkbox-theme-default {
        .tds-checkbox-wrapper {
            .tds-checkbox-wrapper-svg {
                @apply tds-color-border-primary tds-color-surface-lowest;
            }
        }
        .tds-checkbox-svg {
            svg {
                @apply w-full h-full;
                .tds-checkbox-path {
                    @apply fill-current tds-color-icon-white;
                }
            }
        }
        &.tds-checkbox-disabled {
            // @apply opacity-65;
            .tds-checkbox-wrapper-svg {
                @apply tds-color-background-disable;
            }
            &.tds-checkbox-indeterminate,
            &.tds-checkbox-checked {
                .tds-checkbox-wrapper{
                    .tds-checkbox-wrapper-svg {
                        @apply tds-color-background-neutral-bold;
                    }
                }
                
            }
            .tds-checkbox-label {
                @apply tds-color-text-primary;
            }
            .tds-checkbox-svg {
                svg {
                    .tds-checkbox-path {
                        @apply tds-color-icon-white;
                    }
                }
            }
        }
        &:not(.tds-checkbox-disabled) {
            .tds-checkbox-wrapper-svg {
                @apply hover:tds-color-border-brand-primary active:tds-color-ring-primary;
            }

            &.tds-checkbox-indeterminate,
            &.tds-checkbox-checked {
                .tds-checkbox-wrapper-svg {
                    @apply tds-color-background-primary-subtle tds-color-border-brand-primary;
                }
            }
            .tds-checkbox-label {
                @apply tds-color-text-primary;
            }
        }
    }

    &.tds-checkbox-theme-light {
        .tds-checkbox-wrapper {
            .tds-checkbox-wrapper-svg {
                @apply tds-color-border-primary-light tds-color-surface-lowest-light;
            }
        }
        .tds-checkbox-svg {
            svg {
                @apply w-full h-full;
                .tds-checkbox-path {
                    @apply fill-current tds-color-icon-white-light;
                }
            }
        }
        &.tds-checkbox-disabled {
            // @apply opacity-65;
            .tds-checkbox-wrapper-svg {
                @apply tds-color-background-disable-light;
            }
            &.tds-checkbox-indeterminate,
            &.tds-checkbox-checked {
                .tds-checkbox-wrapper
                .tds-checkbox-wrapper-svg {
                    @apply tds-color-background-neutral-bold-light ;
                }
            }
            .tds-checkbox-label {
                @apply tds-color-text-primary-light;
            }
            .tds-checkbox-svg {
                svg {
                    .tds-checkbox-path {
                        @apply tds-color-icon-white-light;
                    }
                }
            }
        }
        &:not(.tds-checkbox-disabled) {
            .tds-checkbox-wrapper-svg {
                @apply hover:tds-color-border-brand-primary-light active:tds-color-ring-primary-light;
            }

            &.tds-checkbox-indeterminate,
            &.tds-checkbox-checked {
                .tds-checkbox-wrapper-svg {
                    @apply tds-color-background-primary-subtle-light tds-color-border-brand-primary-light;
                }
            }
            .tds-checkbox-label {
                @apply tds-color-text-primary-light;
            }
        }
    }
    &.tds-checkbox-theme-dark {
        .tds-checkbox-wrapper {
            .tds-checkbox-wrapper-svg {
                @apply tds-color-border-primary-dark tds-color-surface-lowest-dark;
            }
        }
        .tds-checkbox-svg {
            svg {
                @apply w-full h-full;
                .tds-checkbox-path {
                    @apply fill-current tds-color-icon-white-dark;
                }
            }
        }
        &.tds-checkbox-disabled {
            // @apply opacity-65;
            .tds-checkbox-wrapper-svg {
                @apply tds-color-background-disable-dark;
            }
            &.tds-checkbox-indeterminate,
            &.tds-checkbox-checked {
                .tds-checkbox-wrapper
                .tds-checkbox-wrapper-svg {
                    @apply tds-color-background-neutral-bold-dark;
                }
            }
            .tds-checkbox-label {
                @apply tds-color-text-primary-dark;
            }
            .tds-checkbox-svg {
                svg {
                    .tds-checkbox-path {
                        @apply tds-color-icon-white-dark;
                    }
                }
            }
        }
        &:not(.tds-checkbox-disabled) {
            .tds-checkbox-wrapper-svg {
                @apply hover:tds-color-border-brand-primary-dark active:tds-color-ring-primary-dark;
            }

            &.tds-checkbox-indeterminate,
            &.tds-checkbox-checked {
                .tds-checkbox-wrapper-svg {
                    @apply tds-color-background-primary-subtle-dark tds-color-border-brand-primary-dark;
                }
            }
            .tds-checkbox-label {
                @apply tds-color-text-primary-dark;
            }
        }
    }
    &-sm {
        .tds-checkbox-svg {
            @apply h-[--tds-checkbox-icon-size-s] w-[--tds-checkbox-icon-size-s];
        }
    }
    &-md {
        .tds-checkbox-svg {
            @apply h-[--tds-checkbox-icon-size-m] w-[--tds-checkbox-icon-size-m];
        }
    }
    &-lg {
        .tds-checkbox-svg {
            @apply h-[--tds-checkbox-icon-size-l] w-[--tds-checkbox-icon-size-l];
        }
    }
}
