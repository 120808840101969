.tds-spinner {
    @apply block relative ;
    &.tds-spinner-primary {
        @apply tds-color-text-brand-primary;
    }
    &.tds-spinner-secondary {
        @apply tds-color-text-primary;
    }
    &.tds-spinner-success {
        @apply tds-color-text-success;
    }
    &.tds-spinner-info {
        @apply tds-color-text-info;
    }
    &.tds-spinner-warning {
        @apply tds-color-text-warning;
    }
    &.tds-spinner-error {
        @apply tds-color-text-error;
    }
    &.tds-spinner-black {
        @apply text-black;
    }
    &.tds-spinner-white {
        @apply text-white;
    }
    &.tds-spinner-red {
        @apply text-base-red-500 dark:text-d-base-red-500;
    }
    &.tds-spinner-yellow {
        @apply text-base-yellow-500 dark:text-d-base-yellow-500;
    }
    &.tds-spinner-green {
        @apply text-base-green-500 dark:text-d-base-green-500;
    }
    &.tds-spinner-blue {
        @apply text-base-blue-500 dark:text-d-base-blue-500;
    }
    &.tds-spinner-indigo {
        @apply text-base-indigo-500 dark:text-d-base-indigo-500;
    }
    &.tds-spinner-purple {
        @apply text-base-purple-500 dark:text-d-base-purple-500;
    }
    &.tds-spinner-pink {
        @apply text-base-pink-500 dark:text-d-base-pink-500;
    }
    &.tds-spinner-amber {
        @apply text-base-amber-500 dark:text-d-base-amber-500;
    }
    &.tds-spinner-sky {
        @apply text-base-sky-500 dark:text-d-base-sky-500;
    }
    &.tds-spinner-lime {
        @apply text-base-lime-500 dark:text-d-base-lime-500;
    }
    &.tds-spinner-teal {
        @apply text-base-teal-500 dark:text-d-base-teal-500;
    }
    &.tds-spinner-orange {
        @apply text-base-orange-500 dark:text-d-base-orange-500;
    }
}
.tds-spin {
    @apply absolute top-0 left-0 z-10  w-full h-full box-border m-0 p-0 text-center flex flex-col items-center justify-center;
    .tds-spin-text {
        @apply mt-[--tds-spinner-margin-top-s] tds-color-text-primary text-tds-font-size-2 leading-tds-line-height-3 font-tds-font-family-body tds-font-weight-regular ;
    }
    .tds-spiner-default {
        @apply w-[--tds-spinner-width-s] h-[--tds-spinner-height-s];
    }
    &-sm {
        .tds-spiner-default {
            @apply w-[--tds-spinner-width-s] h-[--tds-spinner-height-s];
        }
    }
    &-md {
        .tds-spiner-default {
            @apply w-[var(--tds-spinner-width-m,32px)] h-[var(--tds-spinner-height-m,32px)];
        }
    }
    &-lg {
        .tds-spiner-default {
            @apply w-[--tds-spinner-width-l] h-[--tds-spinner-height-l];
        }
    }
    &-xl {
        .tds-spiner-default {
            @apply w-[--tds-spinner-width-l] h-[--tds-spinner-height-l];
        }
    }
    
}
tds-spin{
    >.tds-spin-container {
        @apply flex flex-col h-full w-full;
        &.tds-spin-blur {
            @apply select-none opacity-50 overflow-hidden pointer-events-none clear-both;
        }
    }
}
.tds-animate-spin{
    transform: rotate(0);
    animation: tdsRotate 1.2s infinite linear;
}
@keyframes tdsRotate {
    to {
        transform: rotate(360deg)
    }
}