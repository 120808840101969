.tds-button-toggle {
    @apply tds-btn-border-radius-base;
    &-size {
        &-sm {
            @apply p-[--tds-btn-padding-icon-xs] size-[--tds-btn-height-s] tds-btn-icon-size-base;
        }
        &-md {
            @apply p-[--tds-btn-padding-icon-m] size-[--tds-btn-height-m] tds-btn-icon-size-base;
        }
    }
    &:not(.tds-button-toggle-disabled) {
        &:not(.tds-button-toggle-active) {
            &.tds-button-toggle-theme-default {
                @apply tds-color-icon-primary hover:tds-color-background-neutral-minimalist;
            }

            &.tds-button-toggle-theme-light {
                @apply tds-color-icon-primary-light hover:tds-color-background-neutral-minimalist-light;
            }

            &.tds-button-toggle-theme-dark {
                @apply tds-color-icon-primary-dark hover:tds-color-background-neutral-minimalist-dark;
            }
        }
        &.tds-button-toggle-active {
            &.tds-button-toggle-theme-default {
                @apply tds-color-icon-brand-primary tds-color-background-primary-minimal hover:tds-color-background-primary-minimal-hover;
            }

            &.tds-button-toggle-theme-light {
                @apply tds-color-icon-brand-primary-light tds-color-background-primary-minimal-light hover:tds-color-background-primary-minimal-hover-light;
            }

            &.tds-button-toggle-theme-dark {
                @apply tds-color-icon-brand-primary-dark tds-color-background-primary-minimal-dark hover:tds-color-background-primary-minimal-hover-dark;
            }
        }
    }
    &.tds-button-toggle-disabled {
        @apply cursor-not-allowed;
        &.tds-button-toggle-theme-default {
            @apply tds-color-icon-disable;
        }

        &.tds-button-toggle-theme-light {
            @apply tds-color-icon-disable-light;
        }

        &.tds-button-toggle-theme-dark {
            @apply tds-color-icon-disable-dark;
        }
    }
}
