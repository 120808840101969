:root{
        --tds-checkbox-icon-size-s:10px;
--tds-checkbox-icon-size-m:12px;
--tds-checkbox-icon-size-l:14px;
--tds-checkbox-padding:var(--tds-spacing-2,2px);
--tds-checkbox-margin:var(--tds-spacing-5,8px);

        .compact{
            --tds-checkbox-icon-size-m:10px;
--tds-checkbox-icon-size-l:10px;
--tds-checkbox-margin:var(--tds-spacing-3,4px);

        }
    }
    
            
@layer utilities{

            
.tds-checkbox-border-radius
{
                        @apply rounded-tds-border-radius-xs ;

                    }
.tds-checkbox-text
{
                        @apply  text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular compact:text-tds-font-size-2 compact:leading-tds-line-height-3 compact:font-tds-font-family-body compact:tds-font-weight-regular;

                    }

        }
            