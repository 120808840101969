tds-switch {
    @apply inline-block box-border;
    &.tds-switch-disabled {
        @apply cursor-not-allowed ;
    }
    .tds-switch {
        @apply cursor-pointer m-0 p-0 focus-visible:outline-none relative inline-block rounded-tds-border-radius-circle select-none transition-all duration-300;
        &.tds-switch-disabled {
            @apply cursor-not-allowed ;
            .tds-switch-handle{
                @apply cursor-not-allowed ;
            }
        }
        &.tds-switch-loading {
            @apply cursor-wait;
        }
        .tds-switch-handle {
            @apply absolute top-[2px]  rounded-tds-border-radius-circle transition-all duration-200;
        }
        &:not(.tds-switch-checked) {
            @apply border;
            .tds-switch-handle {
                @apply left-[3px];
            }
            &.tds-switch-loading {
                .tds-switch-handle {
                    @apply left-[3px];
                }
            }
        }

        &.tds-switch-checked {
            @apply border border-transparent;

            .tds-switch-handle {
                @apply right-[3px];
            }
            &.tds-switch-loading {
                .tds-switch-handle {
                    @apply bg-transparent;
                }
            }
        }

        &.tds-switch-size-xs,
        &.tds-switch-size-sm {
            @apply min-w-[--tds-switch-width-s] min-h-[--tds-switch-height-s];
            .tds-switch-handle {
                @apply w-[--tds-switch-width-handle-s] h-[--tds-switch-width-handle-s] top-[0.156rem];
            }
            &:not(.tds-switch-checked) {
                .tds-switch-handle {
                    @apply left-[3px];
                }
            }
            &.tds-switch-checked {
                .tds-switch-handle {
                    @apply right-[3px];
                }
            }
        }
        &.tds-switch-size-md {
            @apply min-w-[--tds-switch-width-m] min-h-[--tds-switch-height-m];
            .tds-switch-handle {
                @apply w-[--tds-switch-width-handle-m] h-[--tds-switch-width-handle-m];
            }
        }
        &.tds-switch-size-lg,
        &.tds-switch-size-xl {
            @apply min-w-[--tds-switch-width-l] min-h-[--tds-switch-height-l];
            .tds-switch-handle {
                @apply w-[--tds-switch-width-handle-l] h-[--tds-switch-width-handle-l] top-0.5;
            }
        }
    }
    &.tds-switch-theme-default {
        .tds-switch {
            &:not(.tds-switch-checked) {
                @apply tds-color-surface-lowest  tds-color-border-primary;
                .tds-switch-handle {
                    @apply tds-color-background-neutral-boldest;
                }
                &.tds-switch-loading {
                    .tds-switch-handle {
                        @apply tds-color-surface-lowest  ;
                        tds-spinner{
                            @apply tds-color-text-brand-primary
                        }
                    }
                }
                &.tds-switch-disabled,
                &.tds-switch-loading {
                    @apply tds-color-surface-lowest tds-color-border-disable;
                    
                }
                &:not(.tds-switch-disabled):not(.tds-switch-loading)
                {
                    @apply  hover:tds-color-border-brand-primary
                }
            }
            &.tds-switch-checked {
                @apply tds-color-background-primary-subtle ;
                &.tds-switch-disabled,
                &.tds-switch-loading {
                    @apply tds-color-background-primary-subtle ;
                    tds-spinner{
                        @apply tds-color-icon-white
                    }
                }
                .tds-switch-handle {
                    @apply tds-color-surface-lowest ;
                }
                &.tds-switch-loading {
                    .tds-switch-handle {
                        @apply bg-transparent;
                    }
                }
                &.tds-switch-disabled {
                    .tds-switch-handle {
                        @apply tds-color-background-white;
                    }
                }
            }
            &:not(.tds-switch-disabled):not(.tds-switch-loading) {
                &:not(.tds-switch-checked) {
                    @apply active:tds-color-border-brand-primary active:outline-none active:ring active:tds-color-ring-primary ;
                }
            }
            
        }
    }
    &.tds-switch-theme-light {
        .tds-switch {
            &:not(.tds-switch-checked) {
                @apply tds-color-surface-lowest-light  tds-color-border-primary-light;
                .tds-switch-handle {
                    @apply tds-color-background-neutral-boldest-light;
                }
                &.tds-switch-loading {
                    .tds-switch-handle {
                        @apply tds-color-surface-lowest-light  ;
                        tds-spinner{
                            @apply tds-color-text-brand-primary-light
                        }
                    }
                }
                &.tds-switch-disabled,
                &.tds-switch-loading {
                    @apply tds-color-surface-lowest-light tds-color-border-disable-light;
                    
                }
                &:not(.tds-switch-disabled):not(.tds-switch-loading)
                {
                    @apply  hover:tds-color-border-brand-primary-light
                }
            }
            &.tds-switch-checked {
                @apply tds-color-background-primary-subtle-light ;
                &.tds-switch-disabled,
                &.tds-switch-loading {
                    @apply tds-color-background-primary-subtle-light  ;
                    tds-spinner{
                        @apply tds-color-icon-white-light
                    }
                }
                .tds-switch-handle {
                    @apply tds-color-surface-lowest-light ;
                }
                &.tds-switch-loading {
                    .tds-switch-handle {
                        @apply bg-transparent;
                    }
                }
                &.tds-switch-disabled {
                    .tds-switch-handle {
                        @apply tds-color-background-white;
                    }
                }
            }
            &:not(.tds-switch-disabled):not(.tds-switch-loading) {
                &:not(.tds-switch-checked) {
                    @apply active:tds-color-border-brand-primary-light active:outline-none active:ring active:tds-color-ring-primary-light ;
                }
            }
            
        }
    }
    &.tds-switch-theme-dark {
        .tds-switch {
            &:not(.tds-switch-checked) {
                @apply tds-color-surface-lowest-dark  tds-color-border-primary-dark;
                .tds-switch-handle {
                    @apply tds-color-background-neutral-boldest-dark;
                }
                &.tds-switch-loading {
                    .tds-switch-handle {
                        @apply tds-color-surface-lowest-dark  ;
                        tds-spinner{
                            @apply tds-color-text-brand-primary-dark
                        }
                    }
                }
                &.tds-switch-disabled,
                &.tds-switch-loading {
                    @apply tds-color-surface-lowest-dark tds-color-border-disable-dark;
                    
                }
                &:not(.tds-switch-disabled):not(.tds-switch-loading)
                {
                    @apply  hover:tds-color-border-brand-primary-dark
                }
            }
            &.tds-switch-checked {
                @apply tds-color-background-primary-subtle-dark ;
                &.tds-switch-disabled,
                &.tds-switch-loading {
                    @apply tds-color-background-primary-subtle-dark  ;
                    tds-spinner{
                        @apply tds-color-icon-white-dark
                    }
                }
                .tds-switch-handle {
                    @apply tds-color-surface-lowest-dark ;
                }
                &.tds-switch-loading {
                    .tds-switch-handle {
                        @apply bg-transparent;
                    }
                }
                &.tds-switch-disabled {
                    .tds-switch-handle {
                        @apply tds-color-background-white;
                    }
                }
            }
            &:not(.tds-switch-disabled):not(.tds-switch-loading) {
                &:not(.tds-switch-checked) {
                    @apply active:tds-color-border-brand-primary-dark  active:ring active:tds-color-ring-primary-dark ;
                }
            }
            
        }
    }
}
