:root{
        --tds-progress-height:4px;
--tds-progress-height:8px;

    }
    
            
@layer utilities{

            
.tds-progress-stroke-width
{
                        @apply var(--tds-border-radius-circle,9999px) ;

                    }
.tds-progress-text-l
{
                        @apply  text-tds-font-size-7 leading-tds-line-height-7 font-tds-font-family-heading tds-font-weight-semibold ;

                    }
.tds-progress-text-m
{
                        @apply  text-tds-font-size-4 leading-tds-line-height-5 font-tds-font-family-body tds-font-weight-semibold ;

                    }

        }
            