.tds-collapse {
    @apply block box-border m-0 p-0;
    &.tds-collapse-bordered {
        border-style: var(--tds-border-style-base, solid);
        @apply rounded border;
        > .tds-collapse-item {
            > .tds-collapse-header {
                @apply rounded;
            }
        }

        > .tds-collapse-item-active {
            > .tds-collapse-header {
                @apply rounded-b-none;
            }
            > .tds-collapse-content-box {
                @apply rounded-b  border-t;
            }
        }
    }
    > .tds-collapse-item {
        @apply block rounded;
        > .tds-collapse-header {
            @apply w-full flex items-stretch justify-between p-[--tds-collapse-padding-s];
        }
        > .tds-collapse-content {
            > .tds-collapse-content-box {
                @apply text-tds-font-size-3 leading-tds-line-height-4 font-tds-font-family-body tds-font-weight-regular;
            }
        }
    }
    > .tds-collapse-item:not(.tds-collapse-item-disabled) {
        .tds-collapse-header {
            @apply cursor-pointer;
        }
    }
    > .tds-collapse-item:not(:only-child):first-child {
        .tds-collapse-header {
            @apply rounded-b-none;
        }
    }
    > .tds-collapse-item:not(:only-child):last-child {
        .tds-collapse-header {
            @apply rounded-t-none;
        }
    }
    > .tds-collapse-item:not(:only-child):not(:last-child) {
        border-style: var(--tds-border-style-base, solid);
        @apply rounded-b-none;
    }
    .tds-collapse-content-box {
        @apply w-full  p-[--tds-collapse-padding-s];
    }
    .tds-collapse-content {
        @apply w-full;
    }
    .tds-collapse-expand-icon {
        @apply tds-icon-size-m mr-2;
    }
    .tds-collapse-expand-icon-right {
        @apply tds-icon-size-m ml-[--tds-collapse-margin];
    }
    .tds-collapse-template-header {
        @apply text-tds-font-size-5 leading-tds-line-height-5 font-tds-font-family-heading tds-font-weight-semibold;
    }
}

.tds-collapse {
    &.tds-collapse-theme-default {
        @apply tds-color-surface-lowest tds-color-text-primary;
        &.tds-collapse-bordered {
            @apply tds-color-border-primary;

            > .tds-collapse-item-active {
                > .tds-collapse-content {
                    > .tds-collapse-content-box {
                        @apply tds-color-border-primary;
                    }
                }
            }
        }
        > .tds-collapse-item:not(:only-child):not(:last-child) {
            @apply border-b-tds-border-width-s tds-color-border-primary;
        }
        // > .tds-collapse-item:not(.tds-collapse-item-disabled) {
        //     > .tds-collapse-header {
        //         &:hover {
        //             @apply tds-collapse-color-background-hover;
        //         }
        //     }
        // }
        > .tds-collapse-item {
            > .tds-collapse-header {
                @apply tds-color-text-primary;
                .tds-collapse-expand-icon {
                    @apply tds-color-icon-secondary;
                }
                .tds-collapse-expand-icon-right {
                    @apply tds-color-icon-secondary;
                }
            }
            &.tds-collapse-item-disabled {
                @apply cursor-not-allowed;
                .tds-collapse-header {
                    @apply cursor-not-allowed tds-color-text-disable;
                    .tds-collapse-template-header {
                        @apply tds-color-text-disable;
                    }
                }
                .tds-collapse-expand-icon,
                .tds-collapse-expand-icon-right {
                    @apply tds-color-icon-disable;
                }
            }
            // &.tds-collapse-item-active {
            //     .tds-collapse-header {
            //         @apply tds-collapse-color-header-background-active;
            //     }
            //     .tds-collapse-content-box {
            //         @apply tds-collapse-color-body-background-active;
            //     }
            // }
            > .tds-collapse-content {
                > .tds-collapse-content-box {
                    @apply tds-color-text-primary;
                }
            }
        }
    }
    &.tds-collapse-theme-light {
        @apply tds-color-surface-lowest-light tds-color-text-primary-light;
        &.tds-collapse-bordered {
            @apply tds-color-border-primary-light;

            > .tds-collapse-item-active {
                > .tds-collapse-content {
                    > .tds-collapse-content-box {
                        @apply tds-color-border-primary-light;
                    }
                }
            }
        }
        > .tds-collapse-item:not(:only-child):not(:last-child) {
            @apply border-b-tds-border-width-s tds-color-border-primary-light;
        }
        // > .tds-collapse-item:not(.tds-collapse-item-disabled) {
        //     > .tds-collapse-header {
        //         &:hover {
        //             @apply tds-collapse-color-background-hover-light;
        //         }
        //     }
        // }
        > .tds-collapse-item {
            > .tds-collapse-header {
                @apply tds-color-text-primary-light;
                .tds-collapse-expand-icon {
                    @apply tds-color-icon-secondary-light;
                }
                .tds-collapse-expand-icon-right {
                    @apply tds-color-icon-secondary-light;
                }
            }
            &.tds-collapse-item-disabled {
                @apply cursor-not-allowed;
                .tds-collapse-header {
                    @apply cursor-not-allowed tds-color-text-disable-light;
                    .tds-collapse-template-header {
                        @apply tds-color-text-disable-light;
                    }
                }
                .tds-collapse-expand-icon,
                .tds-collapse-expand-icon-right {
                    @apply tds-color-icon-disable-light;
                }
            }
            // &.tds-collapse-item-active {
            //     .tds-collapse-header {
            //         @apply tds-collapse-color-header-background-active-light;
            //     }
            //     .tds-collapse-content-box {
            //         @apply tds-collapse-color-body-background-active-light;
            //     }
            // }
            > .tds-collapse-content {
                > .tds-collapse-content-box {
                    @apply tds-color-text-primary-light;
                }
            }
        }
    }
    &.tds-collapse-theme-dark {
        @apply tds-color-surface-lowest-dark tds-color-text-primary-dark;
        &.tds-collapse-bordered {
            @apply tds-color-border-primary-dark;

            > .tds-collapse-item-active {
                > .tds-collapse-content {
                    > .tds-collapse-content-box {
                        @apply tds-color-border-primary-dark;
                    }
                }
            }
        }
        > .tds-collapse-item:not(:only-child):not(:last-child) {
            @apply border-b-tds-border-width-s tds-color-border-primary-dark;
        }
        // > .tds-collapse-item:not(.tds-collapse-item-disabled) {
        //     > .tds-collapse-header {
        //         &:hover {
        //             @apply tds-collapse-color-background-hover-dark;
        //         }
        //     }
        // }
        > .tds-collapse-item {
            > .tds-collapse-header {
                @apply tds-color-text-primary-dark;
                .tds-collapse-expand-icon {
                    @apply tds-color-icon-secondary-dark;
                }
                .tds-collapse-expand-icon-right {
                    @apply tds-color-icon-secondary-dark;
                }
            }
            &.tds-collapse-item-disabled {
                @apply cursor-not-allowed;
                .tds-collapse-header {
                    @apply cursor-not-allowed tds-color-text-disable-dark;
                    .tds-collapse-template-header {
                        @apply tds-color-text-disable-dark;
                    }
                }
                .tds-collapse-expand-icon,
                .tds-collapse-expand-icon-right {
                    @apply tds-color-icon-disable-dark;
                }
            }
            // &.tds-collapse-item-active {
            //     .tds-collapse-header {
            //         @apply tds-collapse-color-header-background-active-dark;
            //     }
            //     .tds-collapse-content-box {
            //         @apply tds-collapse-color-body-background-active-dark;
            //     }
            // }
            > .tds-collapse-content {
                > .tds-collapse-content-box {
                    @apply tds-color-text-primary-dark;
                }
            }
        }
    }
}
