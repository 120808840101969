:root{
        --tds-date-time-picker-padding-cell-date-range-horizontal:var(--tds-spacing-7,12px);
--tds-date-time-picker-padding-cell-date-range-vertical:var(--tds-spacing-4,6px);
--tds-date-time-picker-padding-calendar-header:var(--tds-spacing-5,8px);
--tds-date-time-picker-padding-calendar-body-vertical:var(--tds-spacing-3,4px);
--tds-date-time-picker-padding-calendar-body-horizontal:var(--tds-spacing-7,12px);
--tds-date-time-picker-padding-calendar-body:var(--tds-spacing-7,12px);
--tds-date-time-picker-padding-calendar-vertical:var(--tds-spacing-3,4px);
--tds-date-time-picker-padding-calendar-footer-vertical:var(--tds-spacing-2,2px);
--tds-date-time-picker-padding-calendar-footer-horizontal:var(--tds-spacing-7,12px);
--tds-date-time-picker-padding-cell-date-horizontal:var(--tds-spacing-3,4px);
--tds-date-time-picker-padding-date-range-hyphen-horizontal:var(--tds-spacing-4,6px);
--tds-date-time-picker-padding-time-wheel-vertical:var(--tds-spacing-4,6px);
--tds-date-time-picker-padding-time-cell-vertical:var(--tds-spacing-3,4px);
--tds-date-time-picker-padding-quick-view-panel-vertical:var(--tds-spacing-7,12px);
--tds-date-time-picker-margin:var(--tds-spacing-5,8px);
--tds-date-time-picker-height-cell-date:32px;
--tds-date-time-picker-width-cell-date:var(--tds-spacing-13,32px);
--tds-date-time-picker-width-time-cell:40px;
--tds-date-time-picker-width-time-wheel:56px;
--tds-date-time-picker-width-cell-month-year:88px;
--tds-date-time-picker-width-cell-date-range-menu:120px;

    }
    
            
@layer utilities{

            
.tds-date-time-picker-color-background-in-range
{
                            @apply bg-tds-color-primary-600-light/10 dark:bg-tds-color-primary-600-dark/10;

                        }                
                        
.tds-date-time-picker-color-background-in-range-light
{
                            @apply bg-tds-color-primary-600-light/10;

                        }
                        
.tds-date-time-picker-color-background-in-range-dark
{
                            @apply bg-tds-color-primary-600-dark/10;

                        }
                        
.tds-date-time-picker-border-radius
{
                        @apply rounded-tds-border-radius-s ;

                    }

        }
            