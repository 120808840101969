tds-menu {
    @apply h-full flex flex-col box-border transition-colors duration-300 ease-in-out;
    &.tds-menu-collapsed {
        @apply w-[--tds-menu-width-collapse-menu];
        .tds-menu-wrapper{
            .tds-menu-footer{
                .tds-menu-footer-icon {
                    @apply mt-[var(--tds-menu-margin-l)];
                }
            }
        }
    }
    .tds-menu-wrapper {
        @apply h-full w-full flex flex-col;
        .tds-menu-header {
            @apply w-full flex justify-center items-center py-[var(--tds-menu-padding-s)];
        }
        .tds-menu-body {
            @apply w-full flex-auto p-[var(--tds-menu-padding-m)] overflow-y-hidden no-scrollbar ;
            &:hover{
                @apply overflow-y-auto;
            }
        }
        .tds-menu-footer {
            @apply w-full flex justify-between items-center p-[var(--tds-menu-padding-m)];
            .tds-menu-footer-icon {
                @apply  rounded-full flex items-center justify-center max-h-[--tds-btn-height-s] p-[var(--tds-menu-padding-s)] cursor-pointer;
            }
        }
    }
    .tds-icon-secondary {
        @apply fill-neutral-3-100;
    }
    .tds-icon-primary {
        @apply fill-neutral-1-200;
    }
    tds-menu-group-inline.tds-menu-group-active,
    tds-menu-group-popup.tds-menu-group-active,
    tds-menu-group-inline:hover,
    tds-menu-group-popup:hover {
        .tds-icon-secondary {
            @apply fill-primary-5;
        }
        .tds-icon-primary {
            @apply fill-primary-1;
        }
    }
    tds-menu-group-inline,
    tds-menu-group-popup {
        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    tds-menu-group-inline {
        @apply w-full flex flex-col box-content text-body-2 cursor-pointer  tds-menu-border-radius-item;
        .tds-menu-group-inline-group-title,
        .tds-menu-group-inline-item {
            @apply w-full p-[var(--tds-menu-padding-m)] flex flex-row items-center  tds-menu-border-radius-item cursor-pointer;
        }
        .tds-menu-group-inline-item {
            .tds-menu-group-inline-icon {
                @apply w-5 h-5 shrink-0;
                .tds-menu-group-inline-font-icon {
                    @apply text-xl leading-5;
                }
                .tds-menu-html-icon {
                    @apply w-full h-full;
                }
            }
            .tds-menu-group-inline-content-wrapper {
                @apply flex-auto flex items-center ml-[var(--tds-menu-margin-m)];
                .tds-menu-group-inline-has-link,
                .tds-menu-group-inline-text {
                    @apply w-full flex items-center cursor-pointer;
                    > span {
                        @apply flex-auto font-semibold truncate;
                    }
                    > tds-badge {
                        @apply shrink-0 ml-[var(--tds-menu-margin-m)];
                    }
                    > tds-tag {
                        @apply shrink-0 ml-[var(--tds-menu-margin-m)];
                    }
                }
            }
            .tds-menu-group-inline-arrow {
                @apply w-4 h-4 ml-[var(--tds-menu-margin-m)] flex justify-center items-center shrink-0;
                > span {
                    @apply text-base leading-4 transform delay-100 duration-300 rotate-0;
                }
            }
        }
        .tds-menu-list-child {
            @apply w-full py-[--tds-menu-padding-s] tds-menu-border-radius-item transition-all ease-in-out delay-100;
            tds-menu-item:last-child {
                @apply mb-0;
            }
        }
        &.tds-menu-group-inline-opened {
            .tds-menu-group-inline-item {
                .tds-menu-group-inline-arrow {
                    > span {
                        @apply rotate-90;
                    }
                }
            }
        }
        &:not(.tds-menu-group-inline-opened) {
            .tds-menu-list-child {
                @apply py-0 box-content;
            }
        }
        .tds-menu-item-parent-is-group {
            > .tds-menu-item-wrapper {
                @apply pl-[--tds-menu-padding-submenu-left-s];
            }
        }
    }
    .tds-menu-body {
        tds-menu-group-inline {
            @apply mb-[--tds-menu-padding-s];
        }
        tds-menu-group-popup {
            @apply mb-[--tds-menu-padding-s];
        }
    }
    tds-menu-group-popup {
        @apply w-full flex flex-col box-content relative;
        .tds-menu-group-popup-wrapper {
            @apply w-full flex tds-menu-border-radius-item cursor-pointer;
            .tds-menu-group-popup-wrapper-link {
                @apply w-full flex items-center p-[var(--tds-menu-padding-m)];
                .tds-menu-font-icon {
                    @apply text-xl leading-5;
                }
            }
        }
    }
}
tds-menu-item {
    @apply w-full flex flex-col box-content items-center mb-[--tds-menu-padding-s];
    .tds-menu-item-wrapper {
        @apply w-full p-[var(--tds-menu-padding-m)] pl-[--tds-menu-padding-submenu-left-s] flex items-center  tds-menu-border-radius-item cursor-pointer;
        .tds-menu-item-badge {
            @apply w-[--tds-badge-width-dot-m] h-[--tds-badge-height-dot-m] flex items-center shrink-0;
        }
        .tds-menu-item-title {
            @apply flex-auto flex items-center ml-[var(--tds-menu-margin-m)];
            .tds-menu-item-has-link,
            .tds-menu-item-text {
                @apply w-full flex items-center cursor-pointer text-body-2;
                > span {
                    @apply flex-auto font-regular;
                }
                > tds-badge {
                    @apply shrink-0 ml-[var(--tds-menu-margin-m)];
                }
                > tds-tag {
                    @apply shrink-0 ml-[var(--tds-menu-margin-m)];
                }
            }
        }
        .tds-menu-item-arrow {
            @apply w-4 h-4 ml-[var(--tds-menu-margin-m)] flex justify-center items-center shrink-0;
            > span {
                @apply text-base leading-4 transform delay-100 duration-300 rotate-0;
            }
        }
    }
    .tds-menu-item-list-child {
        @apply w-full py-[--tds-menu-padding-s] transition-all duration-200 ease-in-out;
        .tds-menu-item-parent-is-item {
            > .tds-menu-item-wrapper {
                @apply pl-[--tds-menu-padding-submenu-left-m];
            }
        }
    }
    &.tds-menu-item-opened {
        .tds-menu-item-wrapper {
            .tds-menu-item-arrow {
                > span {
                    @apply rotate-90;
                }
            }
        }
    }
    &:not(.tds-menu-item-opened) {
        .tds-menu-item-list-child {
            @apply py-0 box-content;
        }
    }
    &.tds-menu-default {
        .tds-menu-item-wrapper {
            @apply bg-transparent;
            .tds-menu-item-title {
                .tds-menu-item-has-link,
                .tds-menu-item-text {
                    > span {
                        @apply tds-color-text-secondary;
                    }
                }
            }
            .tds-menu-item-arrow {
                > span {
                    @apply tds-color-icon-inactive;
                }
            }
            &:hover {
                @apply tds-color-surface-medium;
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary;
                        }
                    }
                }
                .tds-menu-item-arrow {
                    > span {
                        @apply tds-color-icon-inactive;
                    }
                }
            }
        }
        &.tds-menu-item-active {
            > .tds-menu-item-wrapper {
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary;
                        }
                    }
                }
            }
        }
        &.tds-menu-item-opened {
            > .tds-menu-item-wrapper {
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary;
                        }
                    }
                }
                .tds-menu-item-arrow {
                    > span {
                        @apply tds-color-icon-inactive;
                    }
                }
            }
        }
    }
    &.tds-menu-dark {
        .tds-menu-item-wrapper {
            @apply bg-transparent;
            .tds-menu-item-title {
                .tds-menu-item-has-link,
                .tds-menu-item-text {
                    > span {
                        @apply tds-color-text-secondary-dark;
                    }
                }
            }
            .tds-menu-item-arrow {
                > span {
                    @apply tds-color-icon-inactive-dark;
                }
            }
            &:hover {
                @apply tds-color-surface-medium-dark;
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary-dark;
                        }
                    }
                }
                .tds-menu-item-arrow {
                    > span {
                        @apply tds-color-icon-inactive-dark;
                    }
                }
            }
        }
        &.tds-menu-item-active {
            > .tds-menu-item-wrapper {
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary-dark;
                        }
                    }
                }
            }
        }
        &.tds-menu-item-opened {
            > .tds-menu-item-wrapper {
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary-dark;
                        }
                    }
                }
                .tds-menu-item-arrow {
                    > span {
                        @apply tds-color-icon-inactive-dark;
                    }
                }
            }
        }
    }
    &.tds-menu-light {
        .tds-menu-item-wrapper {
            @apply bg-transparent;
            .tds-menu-item-title {
                .tds-menu-item-has-link,
                .tds-menu-item-text {
                    > span {
                        @apply tds-color-text-secondary-light;
                    }
                }
            }
            .tds-menu-item-arrow {
                > span {
                    @apply tds-color-icon-inactive-light;
                }
            }
            &:hover {
                @apply tds-color-surface-medium-light;
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary-light;
                        }
                    }
                }
                .tds-menu-item-arrow {
                    > span {
                        @apply tds-color-icon-inactive-light;
                    }
                }
            }
        }
        &.tds-menu-item-active {
            > .tds-menu-item-wrapper {
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary-light;
                        }
                    }
                }
            }
        }
        &.tds-menu-item-opened {
            > .tds-menu-item-wrapper {
                .tds-menu-item-title {
                    .tds-menu-item-has-link,
                    .tds-menu-item-text {
                        > span {
                            @apply tds-color-text-primary-light;
                        }
                    }
                }
                .tds-menu-item-arrow {
                    > span {
                        @apply tds-color-icon-inactive-light;
                    }
                }
            }
        }
    }
    &.tds-menu-item-hidden{
        @apply  hidden invisible w-0 h-0 border-none;
    }
}
.tds-menu-panel {
    @apply w-full py-[--tds-menu-padding-s] rounded shadow-1-lg;
    tds-menu-item {
        > .tds-menu-item-wrapper {
            @apply pl-[--tds-menu-padding-submenu-left-s];
        }
    }
}
.tds-menu-default {
    .tds-menu-panel {
        @apply tds-color-surface-medium;
    }
    .tds-menu-wrapper {
        .tds-menu-header {
            @apply tds-color-surface-lowest;
        } 
        .tds-menu-body{
            @apply tds-color-surface-lowest p-[var(--tds-menu-padding-m)] ;
        }
        .tds-menu-footer {
            @apply tds-color-surface-lowest px-[--tds-menu-padding-l];
            .tds-menu-footer-icon {
                @apply tds-color-background-neutral-minimalist-light dark:tds-color-background-neutral-minimalist-dark;
                >span{
                    @apply tds-color-icon-secondary tds-btn-icon-size-base; 
                }
              
            }
            .tds-menu-footer-svg{
                @apply tds-color-text-brand-primary;
                
            }
            
        }
    }

    .tds-icon-secondary {
        @apply fill-neutral-3-100 dark:fill-d-neutral-1-300;
    }
    .tds-icon-primary {
        @apply fill-neutral-1-200 dark:fill-d-neutral-1-500;
    }
    tds-menu-group-inline.tds-menu-group-active,
    tds-menu-group-popup.tds-menu-group-active,
    tds-menu-group-inline:hover,
    tds-menu-group-popup:hover {
        .tds-icon-secondary {
            @apply fill-primary-5 dark:fill-d-neutral-1-300;
        }
        .tds-icon-primary {
            @apply fill-primary-1 dark:fill-d-neutral-1-500;
        }
    }
    tds-menu-group-inline {
        .tds-menu-group-inline-group-title,
        .tds-menu-group-inline-item {
            @apply tds-color-surface-lowest;
            &:hover {
                @apply tds-color-background-neutral-minimalist;
            }
        }
        .tds-menu-group-inline-item {
            .tds-menu-group-inline-font-icon {
                @apply tds-color-icon-inactive;
            }
            .tds-menu-group-inline-has-link,
            .tds-menu-group-inline-text {
                > span {
                    @apply tds-menu-color-text-default;
                }
            }
            .tds-menu-group-inline-arrow {
                @apply tds-color-icon-inactive;
            }
            &:hover {
                .tds-menu-group-inline-font-icon {
                    @apply tds-color-icon-inactive;
                }
                .tds-menu-group-inline-has-link,
                .tds-menu-group-inline-text {
                    > span {
                        @apply tds-color-text-primary;
                    }
                }
                .tds-menu-group-inline-arrow {
                    @apply tds-color-icon-inactive;
                }
            }
        }
        &.tds-menu-group-active {
            .tds-menu-group-inline-item {
                @apply tds-color-background-neutral-minimalist;
                .tds-menu-group-inline-font-icon {
                    @apply tds-menu-color-menu-icon-active;
                }
                .tds-menu-group-inline-has-link,
                .tds-menu-group-inline-text {
                    > span {
                        @apply tds-color-text-primary;
                    }
                }
                .tds-menu-group-inline-arrow {
                    @apply tds-color-icon-inactive;
                }
            }
        }
        &.tds-menu-group-inline-opened {
            .tds-menu-group-inline-item {
                .tds-menu-group-inline-font-icon {
                    @apply tds-menu-color-menu-icon-active;
                }
            }
        }
    }
    tds-menu-group-popup {
        .tds-menu-group-popup-wrapper {
            @apply bg-transparent;
            .tds-menu-group-popup-wrapper-link {
                .tds-menu-font-icon {
                    @apply tds-color-icon-inactive;
                }
            }
            &:hover {
                @apply tds-color-background-neutral-minimalist;
                .tds-menu-font-icon {
                    @apply tds-menu-color-menu-icon-active;
                }
            }
        }
        &.tds-menu-group-active {
            .tds-menu-group-popup-wrapper {
                @apply tds-color-background-neutral-minimalist;
                .tds-menu-group-popup-wrapper-link {
                    .tds-menu-font-icon {
                        @apply tds-menu-color-menu-icon-active;
                    }
                }
            }
        }
    }
}
.tds-menu-dark {
    .tds-menu-panel {
        @apply tds-color-surface-medium-dark;
    }
    .tds-menu-wrapper {
        .tds-menu-header {
            @apply tds-color-surface-lowest-dark;
        }
        .tds-menu-body,
        .tds-menu-footer {
            @apply tds-color-surface-lowest-dark;
            .tds-menu-footer-icon {
                @apply tds-color-background-neutral-minimalist-dark;
                > span {
                    @apply tds-color-icon-secondary-dark tds-btn-icon-size-base;
                }
            }
            .tds-menu-footer-svg{
                @apply tds-color-text-brand-primary-dark;
                
            }
        }
    }

    .tds-icon-secondary {
        @apply fill-d-neutral-1-300;
    }
    .tds-icon-primary {
        @apply fill-d-neutral-1-500;
    }
    tds-menu-group-inline.tds-menu-group-active,
    tds-menu-group-popup.tds-menu-group-active,
    tds-menu-group-inline:hover,
    tds-menu-group-popup:hover {
        .tds-icon-secondary {
            @apply fill-d-neutral-1-300;
        }
        .tds-icon-primary {
            @apply fill-d-neutral-1-500;
        }
    }
    tds-menu-group-inline {
        .tds-menu-group-inline-group-title,
        .tds-menu-group-inline-item {
            @apply tds-color-surface-lowest-dark;
            &:hover {
                @apply tds-color-background-neutral-minimalist-dark;
            }
        }
        .tds-menu-group-inline-item {
            .tds-menu-group-inline-font-icon {
                @apply tds-color-icon-inactive-dark;
            }
            .tds-menu-group-inline-has-link,
            .tds-menu-group-inline-text {
                > span {
                    @apply tds-menu-color-text-default-dark;
                }
            }
            .tds-menu-group-inline-arrow {
                @apply tds-color-icon-inactive-dark;
            }
            &:hover {
                .tds-menu-group-inline-font-icon {
                    @apply tds-color-icon-inactive-dark;
                }
                .tds-menu-group-inline-has-link,
                .tds-menu-group-inline-text {
                    > span {
                        @apply tds-color-text-primary-dark;
                    }
                }
                .tds-menu-group-inline-arrow {
                    @apply tds-color-icon-inactive-dark;
                }
            }
        }
        &.tds-menu-group-active {
            .tds-menu-group-inline-item {
                @apply tds-color-background-neutral-minimalist-dark;
                .tds-menu-group-inline-font-icon {
                    @apply tds-menu-color-menu-icon-active-dark;
                }
                .tds-menu-group-inline-has-link,
                .tds-menu-group-inline-text {
                    > span {
                        @apply tds-color-text-primary-dark;
                    }
                }
                .tds-menu-group-inline-arrow {
                    @apply tds-color-icon-inactive-dark;
                }
            }
        }
        &.tds-menu-group-inline-opened {
            .tds-menu-group-inline-item {
                .tds-menu-group-inline-font-icon {
                    @apply  tds-menu-color-menu-icon-active-dark;
                }
            }
        }
    }
    tds-menu-group-popup {
        .tds-menu-group-popup-wrapper {
            @apply bg-transparent;
            .tds-menu-group-popup-wrapper-link {
                .tds-menu-font-icon {
                    @apply tds-color-icon-inactive-dark;
                }
            }
            &:hover {
                @apply tds-color-background-neutral-minimalist-dark;
                .tds-menu-font-icon {
                    @apply tds-menu-color-menu-icon-active-dark;
                }
            }
        }
        &.tds-menu-group-active {
            .tds-menu-group-popup-wrapper {
                @apply tds-color-background-neutral-minimalist-dark;
                .tds-menu-group-popup-wrapper-link {
                    .tds-menu-font-icon {
                        @apply tds-menu-color-menu-icon-active-dark;
                    }
                }
            }
        }
    }
}
.tds-menu-light {
    .tds-menu-panel {
        @apply tds-color-surface-medium-light;
    }
    .tds-menu-body,
    .tds-menu-footer,
    .tds-menu-header {
        @apply tds-color-surface-lowest-light;
    }
    .tds-menu-footer {
        .tds-menu-footer-icon {
            @apply tds-color-background-neutral-minimalist-light;
            > span {
                @apply tds-color-icon-secondary-light tds-btn-icon-size-base;
            }
        }
        .tds-menu-footer-svg{
            @apply tds-color-text-brand-primary-light;
            
        }
    }

    .tds-icon-secondary {
        @apply fill-neutral-3-100;
    }
    .tds-icon-primary {
        @apply fill-neutral-1-200;
    }
    tds-menu-group-inline.tds-menu-group-active,
    tds-menu-group-popup.tds-menu-group-active,
    tds-menu-group-inline:hover,
    tds-menu-group-popup:hover {
        .tds-icon-secondary {
            @apply fill-primary-5;
        }
        .tds-icon-primary {
            @apply fill-primary-1;
        }
    }
    tds-menu-group-inline {
        .tds-menu-group-inline-group-title,
        .tds-menu-group-inline-item {
            @apply tds-color-surface-lowest-light;
            &:hover {
                @apply tds-color-background-neutral-minimalist-light;
            }
        }
        .tds-menu-group-inline-item {
            .tds-menu-group-inline-font-icon {
                @apply tds-color-icon-inactive-light;
            }
            .tds-menu-group-inline-has-link,
            .tds-menu-group-inline-text {
                > span {
                    @apply tds-menu-color-text-default-light;
                }
            }
            .tds-menu-group-inline-arrow {
                @apply tds-color-icon-inactive-light;
            }
            &:hover {
                .tds-menu-group-inline-font-icon {
                    @apply tds-color-icon-inactive-light;
                }
                .tds-menu-group-inline-has-link,
                .tds-menu-group-inline-text {
                    > span {
                        @apply tds-menu-color-text-default-light;
                    }
                }
            }
        }
        &.tds-menu-group-active {
            .tds-menu-group-inline-item {
                @apply tds-color-background-neutral-minimalist-light;
                .tds-menu-group-inline-font-icon {
                    @apply tds-menu-color-menu-icon-active-light;
                }
                .tds-menu-group-inline-has-link,
                .tds-menu-group-inline-text {
                    > span {
                        @apply tds-menu-color-text-default-light;
                    }
                }
                .tds-menu-group-inline-arrow {
                    @apply tds-color-icon-inactive-light;
                }
            }
        }
        &.tds-menu-group-inline-opened {
            .tds-menu-group-inline-item {
                .tds-menu-group-inline-font-icon {
                    @apply tds-menu-color-menu-icon-active-light;
                }
            }
        }
    }
    tds-menu-group-popup {
        .tds-menu-group-popup-wrapper {
            @apply bg-transparent;
            .tds-menu-group-popup-wrapper-link {
                .tds-menu-font-icon {
                    @apply tds-color-icon-inactive-light;
                }
            }
            &:hover {
                @apply tds-color-background-neutral-minimalist-light;
                .tds-menu-font-icon {
                    @apply tds-menu-color-menu-icon-active-light;
                }
            }
        }
        &.tds-menu-group-active {
            .tds-menu-group-popup-wrapper {
                @apply tds-color-background-neutral-minimalist-light;
                .tds-menu-group-popup-wrapper-link {
                    .tds-menu-font-icon {
                        @apply tds-menu-color-menu-icon-active-light;
                    }
                }
            }
        }
    }
}
